<div>
    <div class="container-max" align="center" *ngIf="companies_loading">
        <img src="./assets/images/loader.svg" width="60" alt="">
    </div>
</div>
<div *ngIf="!companies_loading">
    <div class="flex-content {{authService.userCan('company.anadir_empresa') ? 'f-between': 'f-end'}}" >
        <a routerLink="/perfiles/crear-empresa" nz-button class="c-primary margins m-b-15" *ngIf="authService.userCan('company.anadir_empresa')">
            <i nz-icon nzType="plus" nzTheme="outline"></i>Añadir Empresa
        </a>
        <div class="flex-content a-center" *ngIf="companies_data.total > 0">
            <!-- <ul class="list-header-prepaid">
                <li>
                    <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
                </li>
                <li>
                    <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></button>
                </li>
            </ul> -->
            <p class="font s-14 margins m-r-15" *ngIf="companies_data?.total && companies_data?.total !== ''">
                Elementos: <b>{{ companies_data?.total }}</b>
            </p>
            <p class="font s-14 margins m-r-15" *ngIf="companies_data != null">
                Página <b>{{ companies_data.current_page + "/" + companies_data.last_page }}</b>
            </p>

            <ul class="list-header-prepaid">
                <li *ngIf="companies_data != null && companies_data.current_page > 1">
                    <button nz-button class="size-md c-primary" [disabled]="companies_loading" (click)="setCurrentPage(companies_data.current_page - 1)">
                        <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
                    </button>
                </li>
                <li *ngIf="companies_data != null && companies_data.current_page < companies_data.last_page">
                    <button nz-button class="size-md c-primary" [disabled]="companies_loading" (click)="setCurrentPage(companies_data.current_page + 1)">
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="wrapper-pdv-table" *ngIf="companies_data.total > 0">
        <div class="header-table-pdv">
            <div class="th th-extra-extra-large" (click)="setCompanyOrder(order != CompanyOrder.EMPRESA_RAZON_SOCIAL ? CompanyOrder.EMPRESA_RAZON_SOCIAL : CompanyOrder.EMPRESA_RAZON_SOCIAL_DESC)">
                <span class="font s-13 w-bold c-black">Razón social</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.EMPRESA_RAZON_SOCIAL"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.EMPRESA_RAZON_SOCIAL_DESC"></i>
            </div>
            <div class="th th-large" *ngIf="authService.userCan('company.info_extra')" (click)="setCompanyOrder(order != CompanyOrder.DELEGACION_DENOMINACION ? CompanyOrder.DELEGACION_DENOMINACION : CompanyOrder.DELEGACION_DENOMINACION_DESC)">
                <span class="font s-13 w-bold c-black">Delegación</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.DELEGACION_DENOMINACION"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.DELEGACION_DENOMINACION_DESC"></i>
            </div>
            <div class="th th-large" *ngIf="authService.userCan('company.info_extra')" (click)="setCompanyOrder(order != CompanyOrder.TIPO_CANAL_NOMBRE ? CompanyOrder.TIPO_CANAL_NOMBRE : CompanyOrder.TIPO_CANAL_NOMBRE_DESC)">
                <span class="font s-13 w-bold c-black">Tipo Canal</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.TIPO_CANAL_NOMBRE"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.TIPO_CANAL_NOMBRE_DESC"></i>
            </div>
            <div class="th th-large" *ngIf="authService.userCan('company.info_extra')" (click)="setCompanyOrder(order != CompanyOrder.MERCHAN_NOMBRE ? CompanyOrder.MERCHAN_NOMBRE : CompanyOrder.MERCHAN_NOMBRE_DESC)">
                <span class="font s-13 w-bold c-black">GPV</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.MERCHAN_NOMBRE"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.MERCHAN_NOMBRE_DESC"></i>
            </div>
            <div class="th th-large" (click)="setCompanyOrder(order != CompanyOrder.EMPRESA_ACTIVADO_DES_AUTO ? CompanyOrder.EMPRESA_ACTIVADO_DES_AUTO : CompanyOrder.EMPRESA_ACTIVADO_DES_AUTO_DESC)">
                <span class="font s-13 w-bold c-black">Marcas</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.EMPRESA_ACTIVADO_DES_AUTO"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.EMPRESA_ACTIVADO_DES_AUTO_DESC"></i>
            </div>
            <div class="th th-middle" (click)="setCompanyOrder(order != CompanyOrder.SEGMENTACION ? CompanyOrder.SEGMENTACION : CompanyOrder.SEGMENTACION_DESC)">
                <span class="font s-13 w-bold c-black">Segmentación</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == CompanyOrder.SEGMENTACION"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order == CompanyOrder.SEGMENTACION_DESC"></i>
            </div>
            <div class="th-small">
                <span class="font s-13 w-bold c-black">PDV</span>
            </div>
        </div>

        <ng-container *ngIf="companies_data != null && !companies_loading">
            <a [routerLink]="['/perfiles/empresa', company.idCompany]" class="card-pdv font" *ngFor="let company of companies_data.data" [ngClass]="company.activated != 1 ? 'c-gray': 'c-black'">
                <div class="th-extra-extra-large" style="padding-right: 5px;">
                    <span class="font s-14 w-bold">{{ company.businessName }}</span>
                </div>
                <div *ngIf="authService.userCan('company.info_extra')" class="th-large" style="padding-right: 5px;">
                    <span class="font s-13">{{ company.delegation }}</span>
                </div>
                <div *ngIf="authService.userCan('company.info_extra')" class="th-large" style="padding-right: 5px;">
                    <span class="font s-13">{{ company.channelType }}</span>
                </div>
                <div *ngIf="authService.userCan('company.info_extra')" class="th-large">
                    <span class="font s-13">{{ company.merchan }}</span>
                </div>
                <div class="th-large">
                    <div class="content-images" *ngIf="company.activated == 1">
                        <!--<div>
                            <img src="../../../../assets/images/pdv/rebranding-active.svg" class="element" alt="Rebranding" *ngIf="isRebranding(company)"/>
                            <img src="../../../../assets/images/pdv/rebranding-disabled.svg" class="element" alt="Rebranding" *ngIf="!isRebranding(company)"/>
                        </div>-->
                        <div>
                            <img src="../../../../assets/images/pdv/jazztel-active.svg" class="element" alt="Jazztel" *ngIf="isJazztel(company)"/>
                            <img src="../../../../assets/images/pdv/jazztel-disabled.svg" class="element" alt="Jazztel" *ngIf="!isJazztel(company)"/>
                        </div>
                        <div>
                            <img src="../../../../assets/images/pdv/simyo-active.svg" class="element" alt="Simyo" *ngIf="isSimyo(company)"/>
                            <img src="../../../../assets/images/pdv/simyo-disabled.svg" class="element" alt="Simyo" *ngIf="!isSimyo(company)"/>
                        </div>
                        <!-- <div class="classification-center">
                            <img src="./assets/images/pdv/estado-null.svg" class="element" alt="Estado" *ngIf="company.pdv == null || company.pdv.clasificacion == null"/>
                            <span class="letters success" *ngIf="company.pdv && company.pdv.clasificacion">{{ getClassification(company) }}</span>
                        </div> -->
                    </div>
                    <div class="content-images" *ngIf="company.activated != 1">
                        <div>
                            <img src="../../../../assets/images/pdv/jazztel-disabled.svg" class="element" alt="Jazztel" *ngIf="!isJazztel(company)"/>
                        </div>
                        <div>
                            <img src="../../../../assets/images/pdv/simyo-disabled.svg" class="element" alt="Simyo" *ngIf="!isSimyo(company)"/>
                        </div>
                    </div>
                </div>
                <div class="th-middle">
                    <span class="font s-10">{{ company.pdv.clasificacion }}</span>
                </div>
                <div class="th-small">
                    <span class="font s-13">{{ company.nPdv }}</span>
                </div>
            </a>
        </ng-container>
    </div>
</div>
<div *ngIf="!companies_loading && (companies_data == null || companies_data.total == 0)" align="center">
    Los sentimos pero con estos filtros no hay datos para mostrar.
    <br>
    Seleccione otros filtros para obtener un resultado
</div>
