import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IClickRowTable, IListFilter, IListPaginator, ITableDigo } from 'src/app/core/models/lists.models';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {

    public tableSize = 18;

    @Input() filters: IListFilter[] | any[] = [];
    @Input() table: ITableDigo | null = null;
    @Input() paginator: IListPaginator | null = null;
    @Input() loading: boolean = false;
    @Input() error: string | boolean = false;
    @Input() updatePage: boolean = false;
    @Input() orderBy?: string;
    @Input() customStyle?: string;
    @Input() type: string = '';
    @Input() addElement: boolean = false;
    @Input() noResultsText?: string;

    @Output() tableClick = new EventEmitter<IClickRowTable>();
    @Output() filtersApplied = new EventEmitter();
    @Output() filterChanged = new EventEmitter();
    @Output() pageChanged = new EventEmitter();
    @Output() updatePageClicked = new EventEmitter();
    @Output() addClicked = new EventEmitter();

    public getScreenWidth: any;
    public isCollapsed: boolean = true;

    constructor() { }

    ngOnInit(): void {
        this.getScreenWidth = window.innerWidth;
        this.stateFilters();
        if (!this.filters || this.filters.length === 0) {
            this.tableSize = 24;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.filters && this.filters.length) {
            this.tableSize = 18;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.getScreenWidth = window.innerWidth;
        this.stateFilters();
    }

    stateFilters() {
        this.isCollapsed = this.getScreenWidth < 992 && this.isCollapsed;
    }

    filtersSubmited(filters: any) {
        this.filters = filters;
        this.filtersApplied.emit(filters);
    }

}
