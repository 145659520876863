import {Component, OnInit} from '@angular/core';
import {pageLimit} from "../../../../../shared/constantes";
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {ListsFiltersService} from "../../../../../services/lists.filters.service";
import {environment} from "../../../../../../environments/environment";
import {AccessService} from "../../../../../services/access.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-listado-articulos',
    templateUrl: './listado-articulos.component.html',
    styleUrls: ['./listado-articulos.component.scss']
})
export class ListadoArticulosComponent implements OnInit {

    public groups: any = [];

    public loading = false;
    public error: string | boolean = false;
    public orderBy: any;

    public paginator: any = {
        limit: 15,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filters: any = null

    public table: any = null;

    constructor(
        private router: Router,
        private logistic: LogisticsService,
        private filterService: ListsFiltersService,
        private access: AccessService
    ) {
    }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/articulos')
        this.setFilters()
        this.loadGroups()
    }

    setFilters() {
        this.filters = this.logistic.getListadoArticulosFilter();
        this.paginator = this.logistic.getListadoArticulosPaginator();
        this.orderBy = this.logistic.getListadoArticulosOrderBy();

        if (this.filters == null) {
            this.filters = [
                {
                    type: 'text',
                    name: 'codeEan',
                    label: 'Código EAN',
                    value: null
                },
                {
                    type: 'text',
                    name: 'codeSap',
                    label: 'Código SAP',
                    value: null
                },
                {
                    type: 'select',
                    name: 'idArticle', // (idArticleGroup), esta mal escrito
                    label: 'Grupo',
                    options: [],
                    value: null
                },
                {
                    type: 'select',
                    name: 'available',
                    label: 'Estado',
                    options: [
                        {name: 'Todos', value: ""},
                        {name: 'Agotado', value: "0"},
                        {name: 'Disponible', value: "1"}
                    ],
                    value: '1'
                }
            ];
            this.logistic.setListadoArticulosFilter(this.filters);
            this.logistic.setListadoArticulosOrderBy(this.orderBy);
            this.logistic.setListadoArticulosPaginator(this.paginator);
        }
    }

    loadGroups() {
        this.loading = true;
        this.logistic.getGroups()
            .subscribe ({
                next: (data: any) => {
                    this.error = false;
                    if (data) {
                        this.groups = data.data;
                        (this.filters[2].options as any).push({name: 'Todos', value: ''});
                        Object.keys(this.groups).forEach(key => {
                            (this.filters[2].options as any).push({name: this.groups[key].name, value: key});
                        })
                        this.load();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                }
            });
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.logistic.getArticles(this.filterService.getPayload(this.paginator, this.filters, this.orderBy))
            .subscribe({
                next: (data: any) => {
                    this.error = false;
                    console.log(data);
                    if (data) {
                        this.prepareTable(data.data);
                        this.preparePaginator(data);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.prepareTable([]);
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                    this.loading = false;
                }
            });
    }

    tableClicked(ev: any) {
        // Estamos pulsando el header para ordenar
        if (ev && !ev.rowId && ev.cell?.order) {
            this.orderBy = ev.cell.orderValue;
            this.logistic.setListadoArticulosOrderBy(this.orderBy);
            this.load();
        }
        // Estamos pulsando en el body para acceder al detalle
        if (ev && ev.rowId) {
            this.router.navigate(['herramientas/' + 'Herramientas-Detalle artículos' + '/' + 'logis_articulo.itx?ac=r&logis_articulo_id_logis_articulo=' + ev.rowId]);
        }
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {
                                value: 'SAP',
                                order: {
                                    desc: 'sap_desc',
                                    asc: 'sap_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'EAN',
                                order: {
                                    desc: 'ean_desc',
                                    asc: 'ean_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'Nombre',
                                order: {
                                    desc: 'name_desc',
                                    asc: 'name_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'Grupo',
                                order: {
                                    desc: 'groupName_desc',
                                    asc: 'groupName_asc'
                                },
                                orderValue: null
                            },
                            {value: 'Estado'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            table.body.rows.push({
                clickValue: item.idArticle,
                cells: [
                    {value: item.codeSap, styles: this.getRowColor(item)},
                    {value: item.codeEan},
                    {value: item.name},
                    {value: this.getGroupName(item)},
                    {
                        styles: this.getStateStyle(item),
                        value: item.state
                    },
                ]
            })
        });

        this.table = table;
    }

    getGroupName(item: any) {
        if (item.idGroupArticle && item.idGroupArticle !== '') {
            const found = this.groups.find((group: any) => group.idGroupArticle === item.idGroupArticle);
            return found ? found.name : '';
        }
        return '';
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    getRowColor(item: any) {
        const base = '5px solid ';
        switch (item.available) {
            case "0":
                return {'border-left': base + '#d600a7'};
            case "1":
                return {'border-left': base + '#009900'};
        }
        return {}
    }

    getStateStyle(item: any) {
        switch (item.available) {
            case "0":
                return {'color': '#d600a7'};
            case "1":
                return {'color': '#009900'};
        }
        return {}
    }

    pageChanged(paginator: any) {
        console.log(paginator)
        this.paginator = paginator;
        this.load();
    }

    filtersApplied(filters: any) {
        console.log(filters);
        this.filters = filters;
        this.paginator.currentPage = 1;
        this.logistic.setListadoArticulosFilter(filters)
        this.load();
    }

    gotoNewArticle() {
        this.router.navigate(['herramientas/' + 'Herramientas-Nuevo artículo' + '/' + 'logis_articulo.itx?ac=n']);
        //antes:
        //window.location.href = environment.oldDigoFront + 'logis_articulo.itx?ac=n';
    }
}
