import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-modal-observation',
    templateUrl: './modal-observation.component.html',
    styleUrls: ['./modal-observation.component.scss']
})
export class ModalObservationComponent implements OnInit {
    @Input() company: any

    isVisible = false;

    constructor() {
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    ngOnInit(): void {
    }

}
