import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pdv-form-table',
    templateUrl: './pdv-form-table.component.html',
    styleUrls: ['./pdv-form-table.component.scss']
})
export class PdvFormTableComponent implements OnInit {
    @Input() masters: any
    @Input() filters: any = {}
    @Output() submit = new EventEmitter<any>()
    @Output() filterDistributorChanged = new EventEmitter();

    public isCollapsed: boolean = false;
    public selectFocused: string = '';
    public collapsedText: string = "+ Ver más filtros";

    constructor() {
    }

    ngOnInit(): void {
        this.isCollapsed = Object.keys(this.filters).length > 1;
        this.collapsedText = this.isCollapsed ? "- Ver menos filtros" : "+ Ver más filtros";

        document.addEventListener("keypress", (event) => {
            if (event.keyCode == 13) {
                this.onSubmit();
            }
        });
    }

    onShow() {
        this.isCollapsed = !this.isCollapsed;
        this.collapsedText = this.isCollapsed ? "- Ver menos filtros" : "+ Ver más filtros";
    }

    onSubmit() {
        this.submit.emit(this.removeEmpty(this.filters));
    }

    removeEmpty(o: any) {
        return Object.entries(o)
            .filter(([_, v]) => v != null && v != '')
            .reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
    }

    getDenominationName = (item: any): string => {
        let name = item.razon_social;
        if (item.denomination) {
            name += ' - ' + item.denomination;
        }
        return name;
    }

    changeFilterDistributor(event: any) {
        this.filters.merchant_id = null;
        this.filters.delegation_id = null;
        this.filterDistributorChanged.emit(event);
    }

    public showLabelSelect(name: string) {
        return this.filters && this.filters[name] || this.filters[name] === '' || name === this.selectFocused;
    }

    public focusSelect(name: string, focused: boolean) {
        this.selectFocused = '';
        if (focused)
            this.selectFocused = name;
    }

}
