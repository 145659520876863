export interface IListPaginator {
    limit: number;
    totalPages: number;
    currentPage: number;
    totalItems: number;
}

export interface IListFilter {
    type: 'number' | 'text' | 'from-to' | 'select';
    label: string;
    name?: string;
    min?: number;
    value?: any;
    options?: GenericList[];
    name_from?: string;
    name_to?: string;
    value_from?: any;
    value_to?: any;
    default_value?: GenericList;
    loading?: boolean;
    parent_form_from?: string[];
    format_date?: TDateFilterFormat;
}

export interface GenericList {
    name: string;
    value: any;
}

export interface IListRequestBody {
    limit: number;
    page: number;
    orderBy?: any;
    filters?: any;
}

export interface IListRequestBodyPoints extends IListRequestBody {
    pdvs?: any[];
}

export interface ITableDigo {
    headers: {
        rows: ICellTableDigo[];
    },
    body: {
        rows: ICellTableDigo[];
    }
}

export interface ICellTableDigo {
    cells: ICellValue[];
    clickValue?: any;
    classes?: string;
}

export interface ICellValue {
    value: any;
    name?: string;
    type?: 'status' | '' | 'image';
    brand?: 'orange' | 'simyo' | 'jazztel';
    styles?: any;
    title?: string;
    colspan?: any;
    contingency?: boolean;
    classes?: string;
    order?: {
        desc?: string;
        asc?: string;
    },
    orderValue?: string | null;
}

export interface IClickRowTable {
    event: any;
    rowId: any;
    cellIndex: number,
    cell: any;
}

export type TDateFilterFormat = 'normal' | 'with-hours' | 'one-line' | 'one-line-with-hours';

export enum CompanyOrder {
    EMPRESA_RAZON_SOCIAL = "1a",
    EMPRESA_RAZON_SOCIAL_DESC = "1b",
    EMPRESA_NIF = "2a",
    EMPRESA_NIF_DESC = "2b",
    DELEGACION_DENOMINACION = "3a",
    DELEGACION_DENOMINACION_DESC = "3b",
    TIPO_CANAL_NOMBRE = "4a",
    TIPO_CANAL_NOMBRE_DESC = "4b",
    MERCHAN_NOMBRE = "5a",
    MERCHAN_NOMBRE_DESC = "5b",
    EMPRESA_ACTIVADO_DES_AUTO = "6a",
    EMPRESA_ACTIVADO_DES_AUTO_DESC = "6b",
    SEGMENTACION = "7a",
    SEGMENTACION_DESC = "7b"
}

export enum PdvOrder {
    PDV_DENOMINACION = "1a",
    PDV_DENOMINACION_DESC = "1b",
    EMPRESA_RAZON_SOCIAL = "2a",
    EMPRESA_RAZON_SOCIAL_DESC = "2b",
    TIPO_CANAL_NOMBRE = "3a",
    TIPO_CANAL_NOMBRE_DESC = "3b",
    MERCHAN_NOMBRE = "4a",
    MERCHAN_NOMBRE_DESC = "4b",
    PDV_ACTIVADO = "6a",
    PDV_ACTIVADO_DESC = "6b",
    SEGMENTACION = "7a",
    SEGMENTACION_DESC = "7b"
}

export enum GpvOrder {
    GPV_NOMBRE = "{ \"merchan\": {\"nombre\":\"asc\"} }",
    GPV_NOMBRE_DESC = "{ \"merchan\": {\"nombre\":\"desc\"} }",
    DELEGACION = "{ \"delegacion\": {\"denominacion\":\"asc\"} }",
    DELEGACION_DESC = "{ \"delegacion\": {\"denominacion\":\"desc\"} }"
}
export enum DistributorOrder {
    DISTRIBUIDOR_NOMBRE = "{ \"distribuidor\": {\"nombre_comercial\":\"asc\"} }",
    DISTRIBUIDOR_NOMBRE_DESC = "{ \"distribuidor\": {\"nombre_comercial\":\"desc\"} }",
    DIRECCION = "{ \"distribuidor\": {\"d_gnr_direccion\":\"asc\"} }",
    DIRECCION_DESC = "{ \"distribuidor\": {\"d_gnr_direccion\":\"desc\"} }",
    FECHA_ALTA = "{ \"distribuidor\": {\"fecha_alta\":\"asc\"} }",
    FECHA_ALTA_DESC = "{ \"distribuidor\": {\"fecha_alta\":\"desc\"} }",
}

export enum DelegationOrder {
    DISTRIBUIDOR_NOMBRE = "{ \"delegacion\": {\"denominacion\":\"asc\"} }",
    DISTRIBUIDOR_NOMBRE_DESC = "{ \"delegacion\": {\"denominacion\":\"desc\"} }",
    DISTRIBUIDOR = "{ \"delegacion\": {\"id_distribuidor\":\"asc\"} }",
    DISTRIBUIDOR_DESC = "{ \"delegacion\": {\"id_distribuidor\":\"desc\"} }",
    SUPERVISOR = "{ \"delegacion\": {\"id_cabecera_dho\":\"asc\"} }",
    SUPERVISOR_DESC = "{ \"delegacion\": {\"id_cabecera_dho\":\"desc\"} }",
}
export enum SupervisorOrder {
    SUPERVISOR_NOMBRE = '{"cabecera_dho": {"nombre":"asc"}}',
    SUPERVISOR_NOMBRE_DESC = '{"cabecera_dho": {"nombre":"desc"}}',
    EMAIL = '{"cabecera_dho": {"c_gnr_email":"asc"}}',
    EMAIL_DESC = '{"cabecera_dho": {"c_gnr_email":"desc"}}',
    PHONE = '{"cabecera_dho": {"c_gnr_telefono":"asc"}}',
    PHONE_DESC = '{"cabecera_dho": {"c_gnr_telefono":"desc"}}',
    START_DATE = '{"cabecera_dho": {"fecha_alta":"asc"}}',
    START_DATE_DESC = '{"cabecera_dho": {"fecha_alta":"desc"}}'
}
export enum ActionPlanOrder {
    ACTION_PLAN_NOMBRE = "{ \"action_plan\": {\"name\":\"asc\"} }",
    ACTION_PLAN_NOMBRE_DESC = "{ \"action_plan\": {\"name\":\"desc\"} }",
    ACTION_PLAN_TIPO = "{ \"action_plan\": {\"type\":\"asc\"} }",
    ACTION_PLAN_TIPO_DESC = "{ \"action_plan\": {\"type\":\"desc\"} }",
    ACTION_PLAN_DISTRIBUIDOR = "{ \"action_plan\": {\"distributor_name\":\"asc\"} }",
    ACTION_PLAN_DISTRIBUIDOR_DESC = "{ \"action_plan\": {\"distributor_name\":\"desc\"} }",
    ACTION_PLAN_GPV = "{ \"action_plan\": {\"merchan_name\":\"asc\"} }",
    ACTION_PLAN_GPV_DESC = "{ \"action_plan\": {\"merchan_name\":\"desc\"} }",
    ACTION_PLAN_RESULTADO = "{ \"action_plan\": {\"result\":\"asc\"} }",
    ACTION_PLAN_RESULTADO_DESC = "{ \"action_plan\": {\"result\":\"desc\"} }",

}

