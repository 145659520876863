<div class="container-max">
  <app-breadcrumbs
          [breacrumbList]="['Herramientas', 'Bloquear usuarios']"
          [title]="'Herramientas <span class=\'font c-primary\'>Bloquear usuarios</span>'"
  ></app-breadcrumbs>
  <div class="inner-content-max" style="position: relative; top: 40px; left: 5px; z-index: 1" *ngIf="!loading && !error">
    <div nz-row>
    <div nz-col nzSpan="4" nzOffset="1" class="margins m-l-5">
      <a (click)="AddBlockedUser()" nz-button class="c-primary margins m-t-5 cursor-pointer">
        <i nz-icon nzType="plus" nzTheme="outline"></i>Añadir usuario bloqueado
      </a>
  </div>
</div>
  </div>
    <app-list
    (tableClick)="tableClicked($event)"
    (pageChanged)="pageChanged($event)"

    [table]="table"
    [orderBy]="orderBy"
    [paginator]="paginator"
    [loading]="loading"
    [error]="error"
  >

  </app-list>

  <app-modal-edit-blocked-user *ngIf="showUserDetail" [blockedUser]="userDetail" [isVisible]="showUserDetail" (hideDetail)="showUserDetail=!$event" (updateTable)="updateTable($event)"></app-modal-edit-blocked-user>
  <app-modal-add-blocked-user *ngIf="showAddUser" [isVisible]="showAddUser" (hideModal)="showAddUser=!$event" (updateTable)="updateTable($event)"></app-modal-add-blocked-user>

</div>