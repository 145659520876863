import { Injectable } from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class MenuService {

    private menuObject: any = null;
    private menu: BehaviorSubject<any>;

    constructor(
    ) {
        this.menu = new BehaviorSubject(null);
    }

    setMenu(menu: any) {
        this.menuObject = menu;
        this.menu.next(menu)
    }

    getMenu() {
        return this.menu.asObservable();
    }

    // determinar si tiene acceso a un elemento del menú (getMenu nos devuelve el menú filtrado para cada user, lo que nos sirve para expulsar al que entre escribiendo la url en el navegador a un sitio en que no deberia estar)
    userHasAccess(url: string, strict: boolean = true) {
        let access = false;
        if (this.menuObject) {
            Object.keys(this.menuObject).some(key => {
                this.menuObject[key].some((block: any) => {
                    if (block.contents) {
                        block.contents.some((contentBlock: any) => {
                            if (contentBlock.links) {
                                contentBlock.links.some((link: any) => {
                                    access = this.recSearchLink(link, url, strict, access)
                                    return access;
                                })
                            }
                            return access;
                        })
                    }
                    return access;
                })
                return access;
            })
        }
        return access;
    }

    recSearchLink(link: any, url: string, strict: boolean = true, access = false): boolean {
        if (typeof link.newAdmin === 'undefined') {
            for (const key of Object.keys(link)) {
                if (Array.isArray(link[key])) {
                    for (let j = 0; j < link[key].length; j++) {
                        if (!access) {
                            access = this.recSearchLink(link[key][j], url, strict, access);
                        }
                    }
                } else {
                    access = this.recSearchLink(link[key], url, strict, access);
                }
            }
        }
        access = access || (link.url  && (strict ? link.url === url : link.url.includes(url)));
        return access;
    }
}