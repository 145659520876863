import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { AuthService } from "../../auth.service";
import { HttpClient} from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IListRequestBody } from "src/app/core/models/lists.models";
import { Observable } from "rxjs";
import { IorderDetailResponseBodyOrangePrepaid } from "src/app/pages/orange/models/IorderDetailOrange";
import { IorderListResponseBodyOrangePrepaid } from "src/app/pages/orange/models/IorderListOrange";
import { pageLimit } from "src/app/shared/constantes";

@Injectable({
    providedIn: 'root'
})
export class OrangeService extends ApiService {
    private filters: any = null;
    private pagintor: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};
    private orderBy: any = null;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    public setFilters(filters: any) {
        this.filters = filters;
    }

    public getFilters(): any {
        return this.filters;
    }

    public setPaginator(pagintor: any) {
        this.pagintor = pagintor;
    }

    public getPaginator(): any {
        return this.pagintor;
    }

    public setOrderBy(orderBy: any) {
        this.orderBy = orderBy;
    }

    public getOrderBy(): any {
        return this.orderBy;
    }

    public getOrangeListOrderFilter(filters: IListRequestBody): Observable<IorderListResponseBodyOrangePrepaid> {
        return this.http.post<IorderListResponseBodyOrangePrepaid>(environment.digoApiEndpoint + 'orange/order/list/filter', filters, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public getOrangeDetailOrder(orderId: any): Observable<IorderDetailResponseBodyOrangePrepaid> {
        return this.http.post<IorderDetailResponseBodyOrangePrepaid>(environment.digoApiEndpoint + `orange/order/${orderId}`, null, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public getMasterOrange(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + 'orange/order/form_filter/masters', { headers: this.getHeaders()} );
    }

    public getCompanyByDistributorOrange(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'orange/order/form_filter/company', {filters: { idDistributor: distributor}}, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public getPdvXMLByDistributorOrange(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'orange/order/form_filter/pdv_xml', {filters: { idDistributor: distributor}}, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public updateStatusLine(line:string) {
        return this.http.get<any>(`${environment.oldDigoFront}ws_teide/orderStatus.php?id_recogida=${line}`);
    }
}
