import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ApiService } from '../api.service';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserBlockService extends ApiService {

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    getUserBlockFromService(ipOrUser: any): Observable<any> {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipOrUser)) {  
            ipOrUser = '?ip_locked=' + ipOrUser;
        } else {
            ipOrUser = '?username_locked=' + ipOrUser;
        }
        return this.http.get<any>(this.endpoint + '/access/get_locks' + ipOrUser, {headers: this.getHeaders()});
    }

    resetLockUser(idAccess: number): Observable<any> {
        return this.http.post<any>(this.endpoint + '/access/reset_lock', {"id_acceso_bloqueo_ip": idAccess }, {headers: this.getHeaders()});
    }
}
