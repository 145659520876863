import { Component, OnInit } from '@angular/core';
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {pageLimit} from "../../../../../shared/constantes";
import {ListsFiltersService} from "../../../../../services/lists.filters.service";
import {Router} from "@angular/router";
import {SimyoService} from "../../../../../services/http/simyo/simyo.service";
import {EuroPipe} from "../../../../../core/custom-pipes/euro.pipe";
import {environment} from "../../../../../../environments/environment";
import {isIterable} from "../../../../../core/functions";
import {AuthService} from "../../../../../services/auth.service";
import {AccessService} from "../../../../../services/access.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {

    public loading = false;
    public error: string | boolean = false;

    public paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filters: any = null
    public table: any = null;
    public orderBy: any;

    constructor(
        private logistic: LogisticsService,
        private simyo: SimyoService,
        private filterService: ListsFiltersService,
        private router: Router,
        private euro: EuroPipe,
        public auth: AuthService,
        private access: AccessService
    ) { }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/pedidos');

        this.setFilters();
        this.loadAll();
    }

    setFilters() {
        this.filters = this.logistic.getListadoPedidosFilter();
        this.paginator = this.logistic.getListadoPedidosPaginator();
        this.orderBy = this.logistic.getListadoPedidosOrderBy();

        if (this.filters == null) {
            this.filters = [
                {
                    type: 'number',
                    name: 'orderId',
                    label: 'Nº Pedido',
                    min: 0,
                    value: null
                },
                {
                    type: 'from-to',
                    name_from: 'dateInit', //dateInit //dateEnd
                    name_to: 'dateEnd', //dateInit //dateEnd
                    label: 'Fecha pedido',
                    value_from: null,
                    value_to: null
                },
                {
                    type: 'select',
                    name: 'idOrderState',
                    label: 'Estado',
                    options: [
                        {name: 'Todos', value: ''},
                        {name: 'Enviado a logística', value: 1},
                        {name: 'Pendiente de stock', value: 2},
                        {name: 'Pendiente de pago', value: 3},
                        {name: 'Cancelado por el usuario', value: 4},
                        {name: 'Cancelado por Orange', value: 5},
                        {name: 'Tramitado', value: 6},
                        {name: 'Tramitado parcial', value: 7},
                    ],
                    value: null
                }
            ];
            if (this.auth.userCan('logis_pedido.ver_distribuidor')) {
                this.filters.splice(1, 0, {
                    type: 'select',
                    name: 'distributor',
                    label: 'Distribuidor',
                    options: [
                        {name: 'Todos', value: ''},

                    ],
                    value: null
                });
            }
            this.logistic.setListadoPedidosPaginator(this.paginator);
            this.logistic.setListadoPedidosFilter(this.filters);
            this.logistic.setListadoPedidosOrderBy(this.orderBy);
        }
    }

    loadAll() {
        this.loading = true;
        this.simyo.getDistributors().subscribe((data: any) => {
            const indexDistributor = this.filters.findIndex((elem:any) => elem.name == 'distributor')
            if (indexDistributor !== -1) {
                data.forEach((val: any) => {
                    this.filters![indexDistributor].options!.push({name: val.title, value: val.id})
                })
                if (data.length === 1) {
                    this.filters[indexDistributor].options.splice(0, 1)
                    this.filters[indexDistributor].value = data[0].id
                }

                console.log(this.filters)
            }

            this.load()
        }, () => this.load());
    }


    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.logistic.getOrders(this.filterService.getPayload(this.paginator, this.filters, this.orderBy))
            .subscribe({
                next: (data: any) => {
                    this.error = false;
                    if (data) {
                        this.prepareTable(data.data);
                        this.preparePaginator(data);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.prepareTable([]);
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                    this.loading = false;
                }
            });
    }

    tableClicked(ev: any) {
        // Estamos pulsando el header para ordenar
        if (ev && !ev.rowId && ev.cell?.order) {
            this.orderBy = ev.cell.orderValue;
            this.logistic.setListadoPedidosOrderBy(this.orderBy);
            this.load();
        }
        // Estamos pulsando en el body para acceder al detalle
        if (ev && ev.rowId) {
            this.router.navigate(['/herramientas/logistica/pedidos/' + ev.rowId]);
        }
    }

    prepareTable(data: any = null) {
        let table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'Nº pedido Digo'},
                            {
                                value: 'Nº pedido',
                                order: {
                                    desc: 'orderId_desc',
                                    asc: 'orderId_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'SFID',
                                order: {
                                    desc: 'sfid_desc',
                                    asc: 'sfid_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'Fecha de pedido',
                                order: {
                                    desc: 'date_desc',
                                    asc: 'date_asc'
                                },
                                orderValue: null
                            },
                            {value: 'Importe'},
                            {value: 'Estado'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        if (this.auth.userCan('logis_pedido.ver_distribuidor')) {
            table.headers.rows[0].cells.splice(3, 0, {
                value: 'Distribuidor',
                order: {
                    desc: 'distributor_desc',
                    asc: 'distributor_asc'
                },
                orderValue: null
            });
        }

        Object.keys(data).forEach((key, index) => {
            const item: any = data[key];
            table.body.rows.push({
                // styles: this.getRowStyle(item),
                clickValue: item.idLogisticOrder,
                cells: [
                    {value: item.digoOrder, styles: this.getRowStyle(item)},
                    {value: item.codeOrder ?? '-'},
                    {value: item.sfid},
                    // {value: item.distributor},
                    {value: item.dateOrder},
                    {value: item.price === null ? '--': this.euro.transform(item.price)},
                    {
                        styles: this.getStateStyle(item),
                        value: this.parseState(item)
                    },
                ]
            })
            if (this.auth.userCan('logis_pedido.ver_distribuidor')) {
                table.body.rows[index].cells.splice(3, 0, {value: item.distributor});
            }
        });

        this.table = table;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    getStateStyle(item: any) {
        switch (item.idOrderState) {
            case "1": return {'color': '#d600a7'};
            case "2": return {'color': '#42bff5'};
            case "3": return {'color': '#4254f5'};
            case "4": return {'color': '#990000'};
            case "5": return {'color': '#990000'};
            case "6": return {'color': '#009900'};
            case "7": return {'color': '#d600a7'};
            case "8": return {'color': '#e55608'};
            default:
                if (item.excel) {
                    return {'color':  '#d600a7'}
                }
                return {'color':  '#000099'}
        }
        return {}
    }

    getRowStyle(item: any) {
        const base = '5px solid ';
        switch (item.idOrderState) {
            case "1": return {'border-left': base + '#d600a7'};
            case "2": return {'border-left': base + '#42bff5'};
            case "3": return {'border-left': base + '#4254f5'};
            case "4": return {'border-left': base + '#990000'};
            case "5": return {'border-left': base + '#990000'};
            case "6": return {'border-left': base + '#009900'};
            case "7": return {'border-left': base + '#d600a7'};
            case "8": return {'border-left': base + '#e55608'};
            default:
                if (item.excel) {
                    return {'border-left': base + '#d600a7'}
                }
                return {'border-left': base + '#000099'}
        }
        return {}
    }

    pageChanged(paginator: any) {
        console.log(paginator)
        this.paginator = paginator;
        this.logistic.setListadoPedidosPaginator(this.paginator);
        this.load();
    }

    filtersApplied(filters: any) {
        console.log(filters);
        this.filters = filters;
        this.paginator.currentPage = 1;
        this.logistic.setListadoPedidosPaginator(this.paginator);
        this.logistic.setListadoPedidosFilter(this.filters);
        this.load();
    }

    orderColumn(order: any) {
        this.load();
    }

    gotoLegacyNewPedido() {
        window.location.href = environment.oldDigoFront + 'logis_pedido.itx?ac=n';
    }
    goAddOrder(canGo: boolean){
        if (canGo){
            this.router.navigate(['/herramientas/logistica/pedidos/new']);
        }
    }
    parseState(item: any) {
        if (item.state) {
            return item.state;
        } else if (item.excel) {
            return "Enviado a Logística";
        }
        return "Pendiente";
    }
}
