import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import * as moment from 'moment';

export function isIterable(obj: any) {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function' || typeof obj[Symbol.asyncIterator] === 'function';
}

export function incrementMonth(date: Date, delta: number): Date {
    return new Date(date.getFullYear(), date.getMonth() + delta, date.getDate());
}


export function maxDateValidator(max: string): ValidatorFn {
    const FORMAT_DATE = 'YYYY-MM-DD';
    const maxDate: Date = moment(max, "DD/MM/YYYY").toDate();
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == null) {
            return null;
        }
        const controlDate = moment(control.value, FORMAT_DATE);

        if (!controlDate.isValid()) {
            return null;
        }

        let result = controlDate.isBefore(maxDate) ? null : {
            'date_maximum': {
                'date_maximum': moment(maxDate).format(FORMAT_DATE),
                'actual': controlDate.format(FORMAT_DATE)
            }};
        
        return result;
    };
}