import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment/moment';
import { TactionUserSimyo } from 'src/app/pages/profiles/components/profile-pdv-sheet/profile-pdv-sheet.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-modal-more-info-pdv',
    templateUrl: './modal-more-info-pdv.component.html',
    styleUrls: ['./modal-more-info-pdv.component.scss']
})
export class ModalMoreInfoPdvComponent implements OnInit {
    @Input() pdv: any;
    @Input() simyoUserStatus!: TactionUserSimyo;
    @Input() withText: string = '';
    @Output() openModalSimyoUser: EventEmitter<any> = new EventEmitter();
    
    isVisible = false;

    constructor(
        public authService: AuthService
    ) { }

    showModal(): void {
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    ngOnInit(): void {
    }

    getMarcoRetribuidoValue = (key: string): string => {
        switch (key) {
            case 'prep_or_normal':
                return 'Normal';
            case 'prep_or_acesp':
                return 'Acciones Especiales';
            case 'prep_or_ctaver':
                return 'Cuentas Verticales';
            case 'prep_or_rebran':
                return 'Rebranding';
            case 'prep_or_xml':
                return 'Integracion';
            default:
                return '--';
        }
    }

    public convertDate(date: string, withHours: boolean = true): string {
        return date && date !== '' ? moment(date).format('DD/MM/YYYY' + (withHours ? ', HH:mm' : '')) : '';
    }

    public closeModalAndOpenModalSimyo() {
        this.isVisible = false;
        this.openModalSimyoUser.emit(true);
    }
    
}
