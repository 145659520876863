import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogisticsService } from 'src/app/services/http/herramientas/logistics.service';

@Component({
  selector: 'app-modal-cancel-order',
  templateUrl: './modal-cancel-order.component.html',
  styleUrls: ['./modal-cancel-order.component.scss']
})
export class ModalCancelOrderComponent implements OnInit {
  @Input() order: any;
  @Output() showModalEvent = new EventEmitter<boolean>();
  @Output() canceledEvent = new EventEmitter<boolean>();
  public isLoading: boolean = false;
  public error: string | boolean = false;
  public error_msg: string= '';

  constructor(
    private logisticService: LogisticsService
    ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    console.log(this.order);
    this.isLoading = false;
    this.showModalEvent.emit(false);
  }


  cancelOrder = () => {
    this.error = false;
    this.isLoading = true;
    this.logisticService.cancelOrder(this.order.idLogisticOrder).subscribe({
        next: (data) => {

        },
        error: (err) => {
          this.error = true;
          this.error_msg = "Lo sentimos, no se ha podido cancelar el pedido. Por favor, inténtelo de nuevo más tarde";
        },
        complete: () => {
          this.isLoading = false;
          this.showModalEvent.emit(false);
          this.canceledEvent.emit(true);
        }
    });
  }
  showError(showError: boolean) {
    this.error = showError;
}

}
