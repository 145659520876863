import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-details-lateral-menu',
    templateUrl: './details-lateral-menu.component.html',
    styleUrls: ['./details-lateral-menu.component.scss']
})
export class DetailsLateralMenuComponent implements OnInit {
    @Input() colsize = 24;
    @Input() links: any;
    @Input() back_url: string|null = null;
    @Input() back_text: string = 'Volver';

    constructor() { }

    ngOnInit(): void {
        console.log(this.links);
    }

}
