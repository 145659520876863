import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
    @Input() paginator: any = null;
    @Input() loading: boolean = false;
    @Input() updatePage: boolean = false;
    @Input() type: string = '';
    @Input() addElement: boolean = false;

    @Output() pageChanged = new EventEmitter();
    @Output() updatePageClicked = new EventEmitter();
    @Output() addClicked = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    changePage(pages: number) {
        if (this.loading) {
            return;
        }
        this.paginator.currentPage += pages;
        this.pageChanged.emit(this.paginator);
    }

    public updatePageEvent() {
        this.updatePageClicked.emit(true);
    }

    public add() {
        this.addClicked.emit();
    }

}
