import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccessService} from "../../../../../services/access.service";

@Component({
    selector: 'app-detalle-grupo-logistica',
    templateUrl: './detalle-grupo-logistica.component.html',
    styleUrls: ['./detalle-grupo-logistica.component.scss']
})
export class DetalleGrupoLogisticaComponent implements OnInit {
    public groupId: any;
    public group: any;
    public error: string = '';
    public loading: boolean = true;
    public form: FormGroup|null = null;
    public showModal: boolean = false;

    constructor(
        private logistic: LogisticsService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private access: AccessService
    ) { }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/grupos')
        this.route.params.subscribe( {
            next: (params: any) => {
                this.group = null;
                this.loading = true;
                this.groupId = params.id;
                if (params.id === 'new') {
                    this.loading = false;
                    this.createForm(this.group);
                    return;
                }
                this.load(params.id);
            }
        });
    }


    createForm(group: any) {

        this.form = this.fb.group({
            nombre: [group ? group.name : '', Validators.required],
            descripcion: [group ? group.description : '', Validators.required],
            activado: [group ? group.active : false],
        });
    }

    load(id: number) {
        this.logistic.getGroups({limit: 1, page:1, filters: {idGroup: this.groupId}}).subscribe({next: (response) => {
            console.log(response)
            if (response.data && response.data.length == 1) {
                this.group = response.data[0];
                this.createForm(this.group);
            }

            if (!this.group) {
                this.error = 'El grupo no existe'
            }
        }, error: () => {
                this.error = 'Ha ocurrido un error'
            }}).add(() => this.loading = false);
    }

    formSubmit() {
        if (this.form) {
            this.form.updateValueAndValidity();
            this.form.markAllAsTouched();
            if (this.form.valid) {
                this.loading = true;
                if (this.groupId === 'new') {
                    const rawValue = this.form.getRawValue();
                    rawValue.activado = rawValue.activado ? "1" : "0";
                    this.logistic.createGroup(rawValue).subscribe((data: any) => {
                        this.router.navigate(['/herramientas/logistica/grupos']).then();
                    }, err => this.error = 'Ocurrió un error').add(() => this.loading = false);
                } else {
                    const rawValue = this.form.getRawValue();
                    rawValue.activado = rawValue.activado ? "1" : "0";
                    this.logistic.editGroup(rawValue, this.groupId).subscribe((data: any) => {
                        this.router.navigate(['/herramientas/logistica/grupos']).then();
                    }, err => this.error = 'Ocurrió un error').add(() => this.loading = false);
                }

            }
        }
    }

    public showModalRemoveLogisticGroup() {
        this.showModal = true;
    }

    public handleCancel(): void {
        this.showModal = false;
    }

    deleteGroup() {
        this.logistic.deleteGroup( this.groupId).subscribe((data: any) => {
            this.showModal = false;
            this.router.navigate(['/herramientas/logistica/grupos']).then();
        }, err => {
            console.log(err)
            if (err.status == 409) {
                this.error = 'Este grupo tiene articulos asociados y no es posible borrarlo';
            } else {
                this.error = 'Ha ocurrido un error al eliminar. Por favor, inténtelo de nuevo más tarde';
            }
            this.showModal = false;
        }).add(() => this.loading = false);
    }
}
