import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Subject } from 'rxjs';
import {AccessService} from "../../../services/access.service";
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-informes',
    templateUrl: './informes.component.html',
    styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit {
    public currentIndex: number = 0;
    public tabs: string[] = [];
    public currentTab = '';
    public visitedTab: string[] = [];
    public changeTab: Subject<any> = new Subject<any>();
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private access: AccessService,
        public auth: AuthService
    ) { }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/informes');

        this.buildTabsArray();

        this.route.params.subscribe( {
            next: (params: any) => {
                if (params.tabid) {
                    this.loadTab(params.tabid)
                } else {
                    this.loadTab('informes')
                }
            }
        });
    }

    loadTab(tabname: string) {
        this.currentTab = tabname;
        if (!this.visitedTab.includes(this.currentTab)) {
            this.visitedTab.push(this.currentTab)
        }
        this.auth.userCan('informes.' + this.currentTab)
        if (this.setIndex()) {
            this.router.navigate(["/herramientas/informes/" + this.currentTab])
        }

    }

    buildTabsArray() {
        if (this.auth.userCan('informes.informes')) {
            this.tabs.push('informes')
        }
        if (this.auth.userCan('informes.mapas')) {
            this.tabs.push('mapas')
        }
        if (this.auth.userCan('informes.solicitados')) {
            this.tabs.push('solicitados')
        }
        if (this.auth.userCan('informes.diarios')) {
            this.tabs.push('diarios')
        }
        if (this.auth.userCan('informes.suscripciones')) {
            this.tabs.push('suscripciones')
        }
    }

    setIndex() {
        const index = this.tabs.findIndex(element => element === this.currentTab)
        if (!this.currentTab) {
            this.currentIndex = 0;
        } else if (index === -1) {
            this.router.navigate(['/']).then();
            return false;
        } else {
            this.currentIndex = index
        }
        return true;
    }
    
    public tabChanged(event: any) {
        this.changeTab.next(event);
    }

}
