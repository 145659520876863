<div nz-col nzXXl="{{ colsize }}" nzXl="{{ colsize }}" nzLg="{{ colsize }}" nzMd="{{ colsize }}" nzXs="{{ colsize }}" nzSm="{{ colsize }}">
    <br>
    <div class="digo-card form-card card-gray" style="margin-top: 10px">

        <div class="title-content-max" *ngFor="let link of links">
            <p class="font s-13 c-black t-up no-bottom-extra-large"><a routerLink="{{ link.route }}">{{ link.name }}</a></p>
            <!--<p class="font s-13 c-black">asdasdasdasd</p>-->
        </div>
        <br><br>
        <div class="title-content-max" *ngIf="back_url">
            <p class="font s-13 c-black t-up no-bottom-extra-large"><a routerLink="{{ back_url }}">{{ back_text }}</a></p>
            <!--<p class="font s-13 c-black">asdasdasdasd</p>-->
        </div>
        <!--<div class="title-content-max">-->
            <!--<p class="font s-13 c-gray t-up no-bottom-extra-large">FECHA DE ALTA</p>-->
            <!--<p class="font s-13 c-black">15/01/2021 - 08:58</p>-->
        <!--</div>-->
        <!--<div class="title-content-max">-->
            <!--<p class="font s-13 c-gray t-up no-bottom-extra-large">USUARIO CREADOR</p>-->
            <!--<p class="font s-13 c-black">demo_pdv</p>-->
        <!--</div>-->
        <!--<div class="title-content-max">-->
            <!--<p class="font s-13 c-gray t-up no-bottom-extra-large">FECHA CAMBIO DE ESTADO</p>-->
            <!--<p class="font s-13 c-black">-</p>-->
        <!--</div>-->
        <!--<div class="title-content-max">-->
            <!--<p class="font s-13 c-gray t-up no-bottom-extra-large">USUARIO PROCESADOR</p>-->
            <!--<p class="font s-13 c-black">-</p>-->
        <!--</div>-->
        <!--<button-->
                <!--nz-button-->
                <!--class="c-dark size-large"-->
                <!--nzBlock-->
        <!--&gt;-->
            <!--Ir a tienda PDV-->
        <!--</button>-->
    </div>
</div>