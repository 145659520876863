

<nz-modal
        [(nzVisible)]="showModal"
        [nzFooter]="null"
        (nzOnCancel)="showModal = false; modalMode = ''"
        nzWidth="687px"
        nzCentered
>
    <ng-container *nzModalContent class="wrapper-modal">
        <app-modals-new-pedido-logistica
                [mode]="modalMode"
                (action)="modalEventOcurred($event)"
        >

        </app-modals-new-pedido-logistica>
    </ng-container>
</nz-modal>







<div class="container-max" >
    <app-breadcrumbs 
        [title]="'Logística<span class=\'font c-primary\'> Nuevo Pedido</span>'"
        [breacrumbList]="['Herramientas', 'Logística', 'Nuevo Pedido']"
        [showButtonBack]="true">
    </app-breadcrumbs>

    <div class="wrapper-view-edit-list" >

        <div class="inner-content-max">
            <p *ngIf="error">{{error}}</p>
            <app-loader [text]="'Obteniendo datos...'" *ngIf="loading"></app-loader>
            <ng-container *ngIf="!loading">
                <form [formGroup]="form" *ngIf="form" (submit)="formSubmit($event)">
                    <h3>Nuevo Pedido</h3>

                    <div class="digo-card form-card">
                        <div class="flex-content f-between">
                            <div  class="w40">
                                <p>Creación de nuevo pedido</p>
                            </div>

                            <div style="width:50%" class="form-group">

                                <nz-select id="sfid" class="select-prepaid" (ngModelChange)="setSelectedSfid($event)" formControlName="sfid" nzPlaceHolder="Selecciona SFID" nzBorderless style="width: 100%;">
                                    <nz-option nzValue="" nzLabel="Selecciona SFID" ></nz-option>
                                    <nz-option [nzValue]="sfid.sfid" [nzLabel]="sfid.sfid + ' ' + sfid.n_distribuidor" *ngFor="let sfid of sfidList"></nz-option>
                                </nz-select>
                                <i class="bar"></i>
                            </div>
                        </div>

                    </div>


                    <ng-container *ngIf="selectedSfid !== ''">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up title-head">Datos del pedido</span>
                        </div>
                        <div class="digo-card form-card">
                            <div nz-row>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="24" nzSm="24">
                                    <div class="form-group"  style="width: 90%">
                                        <input type="text" oninvalid="" required="" id="code" formControlName="code">
                                        <label for="code" class="control-label flex-content f-between" style="width: 90%">
                                            <div>
                                                <span class=""><b>Nº pedido propio</b></span>
                                            </div>
                                        </label><i class="bar"></i>
                                    </div>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="24" nzSm="24">
                                    <label  class="control-label flex-content f-between" style="width: 90%">
                                        <div>
                                            <span class=""><b>Nº pedido digo</b></span>
                                            <p>-</p>
                                        </div>
                                    </label><i class="bar"></i>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="24" nzSm="24">
                                    <label class="control-label flex-content f-between" style="width: 90%">
                                        <div>
                                            <span class=""><b>Fecha pedido</b></span>
                                            <p>-</p>
                                        </div>
                                    </label><i class="bar"></i>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="24" nzSm="24">
                                    <label class="control-label flex-content f-between" style="width: 90%">
                                        <div>
                                            <span class=""><b>Estado del pedido</b></span>
                                            <p>Pendiente</p>
                                        </div>
                                    </label><i class="bar"></i>
                                </div>
                            </div>
                        </div>


                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up title-head">Dirección de entrega</span>
                        </div>
                        <div class="digo-card form-card">
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <div style="width: 90%">
                                        <p class="cursor-pointer" (click)="form.get('addressType')?.patchValue('known')">
                                            <input type="radio" oninvalid="" required="" formControlName="addressType" value="known" class="margins m-r-5">Utilizar una dirección habitual
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div nz-row [nzGutter]="24" *ngIf="form.get('addressType')?.value === 'known'" style="margin-bottom: 1em;">
                                <div nz-col nzSpan="12"class="form-group" >
                                    <nz-select id="address_id" class="select-prepaid" (ngModelChange)="setAddressIdx($event)" formControlName="address_id"  nzPlaceHolder="Selecciona dirección" nzBorderless  >
                                        <nz-option nzValue="-1" nzLabel="-- Selecciona dirección --"></nz-option>
                                        <nz-option [nzValue]="i" [nzLabel]="getAddressLabel(i)" *ngFor="let address of addressList; let i = index"></nz-option>
                                    </nz-select>
                                    <i class="bar"></i>
                                </div>
                            </div>

                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <div style="width: 90%">
                                        <p class="cursor-pointer" (click)="form.get('addressType')?.patchValue('custom')">
                                            <input type="radio" oninvalid="" required="" formControlName="addressType" value="custom" class="margins m-r-5">Especificar
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div nz-row [nzGutter]="24" *ngIf="form.get('addressType')?.value === 'custom'">
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <div class="form-group" style="width: 90%">
                                        <input type="text" oninvalid="" required="" id="company" formControlName="empresa">
                                        <label for="code" class="control-label flex-content f-between" >
                                            <div>
                                                <span class=""><b>Empresa</b></span>
                                            </div>
                                        </label><i class="bar"></i>
                                    </div>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <div class="form-group" style="width: 90%" >
                                        <input type="text" oninvalid="" required="" id="a2" formControlName="contacto">
                                        <label for="a2" class="control-label flex-content f-between">
                                            <div>
                                                <span class=""><b>Contacto</b></span>
                                            </div>
                                        </label><i class="bar"></i>
                                    </div>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <div class="form-group" style="width: 90%">
                                        <input type="text" oninvalid="" required="" id="a4" formControlName="direccion">
                                        <label for="a4" class="control-label flex-content f-between">
                                            <div>
                                                <span class=""><b>Dirección</b></span>
                                            </div>
                                        </label><i class="bar"></i>
                                    </div>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <div class="form-group"  style="width: 90%">
                                        <input type="string" required="" id="a1" formControlName="cp" oninput="javascript: if (this.value.length > 5) this.value = this.value.slice(0, 5);">
                                        <label for="a1" class="control-label flex-content f-between" >
                                            <div>
                                                <span class=""><b>C.P.</b></span>
                                            </div>

                                        </label><i class="bar"></i>
                                        <span class="error-form" *ngIf="form.get('cp')?.touched && form.get('cp')?.errors?.['pattern']">
                                            Código postal inválido
                                        </span>
                                    </div>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <div class="form-group" style="width: 90%">
                                        <input type="text" oninvalid="" required="" id="a3" formControlName="localidad">
                                        <label for="a3" class="control-label flex-content f-between" >
                                            <div>
                                                <span class=""><b>Población</b></span>
                                            </div>
                                        </label><i class="bar"></i>
                                    </div>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                    <label class="control-label flex-content f-between" style="width: 90%">
                                        <div>
                                            <span class=""><b>País</b></span>
                                            <p>España</p>
                                        </div>
                                    </label><i class="bar"></i>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="validAddress()">

                            <p *ngIf="loadingArticles">
                                <i nz-icon nzType="loading" nzTheme="outline" ></i>  Cargando artículos
                            </p>

                            <ng-container *ngIf="!loadingArticles">
                                <div class="bar-head">
                                    <span class="font s-14 w-bold c-gray t-up title-head">Líneas de pedido</span>
                                </div>
                                <div class="digo-card form-card">
                                    <table style="width: 100%">
                                        <tr>
                                            <th align="left"><label class="font s-13 c-gray t-up">Artículo/Referencia</label></th>
                                            <th align="left"><label class="font s-13 c-gray t-up">Stock</label></th>
                                            <th align="left"><label class="font s-13 c-gray t-up">Precio</label></th>
                                            <th align="left"><label class="font s-13 c-gray t-up">Cantidad</label></th>
                                            <th align="left"><label class="font s-13 c-gray t-up">Importe</label></th>
                                        </tr>
                                        <tr *ngFor="let article of articlesList; let i = index">
                                            <td align="left" nz-row class="flex-content">
                                                <img [src]="article.imgPath ?? ''" style="max-width: 50px; margin-right: 10px;"/>
                                                <p style="margin-bottom:0px">{{ article.name }}
                                                    <br>
                                                    <small class="font c-gray">{{ article.codeSap }}</small>
                                                </p>
                                            </td>
                                            <td align="left">
                                                <span style="color: red" *ngIf="!articleStock(article) || articleStock(article).unidades == 0">Agotado</span>
                                                <span style="color: green" *ngIf="articleStock(article) &&  articleStock(article).unidades > 0">Disponible</span>
                                            </td>
                                            <td  align="left">
                                                {{ article.price | euro }}
                                            </td>
                                            <td  align="left">
                                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="24">
                                                    <div class="form-group" style="width: 90%" >
                                                        <input type="number" #precio [value]="article.qty" (keyup)="setArticleQty(i,precio.value)">
                                                        <i class="bar"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td  align="left">
                                                {{ article.qty * article.price | euro }}
                                            </td>
                                            <td  align="left" width="60px">
                                                <!--<i title="Añadir" (click)="sumArticleQty(i, 1)" nz-icon nzType="plus-square" nzTheme="outline" class="icon" style="font-size: 20px; cursor:pointer;margin-right: 5px;"></i>-->
                                                <!--<i title="Quitar" (click)="sumArticleQty(i, -1)" nz-icon nzType="minus-square" nzTheme="outline" class="icon" style="font-size: 20px; cursor:pointer;"></i>-->
                                                <i title="Añadir" (mousedown)="startAdditionInterval(i, 1)" (mouseup)="stopAdditionInterval()" nz-icon nzType="plus-square" nzTheme="outline" class="icon" style="font-size: 20px; cursor:pointer;margin-right: 5px;"></i>
                                                <i title="Quitar" (mousedown)="startAdditionInterval(i, -1)" (mouseup)="stopAdditionInterval()"  nz-icon nzType="minus-square" nzTheme="outline" class="icon" style="font-size: 20px; cursor:pointer;"></i>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="bar-head">
                                    <span class="font s-14 w-bold c-gray t-up">Total pedido</span>

                                </div>
                                <div class="digo-card form-card" >
                                    <div class="flex-content f-end">
                                        <div style="margin-right: 150px">
                                            <p>{{ getTotal() | euro }}</p>
                                        </div>

                                    </div>
                                    <ng-container *ngIf="getTotalItems() > 0">
                                        <br>
                                        <br>
                                        <div nz-row>
                                            <div nz-col nzSpan="8" nzOffset="16">
                                                <button nz-button class="c-dark  margins" (click)="modalMode = 'confirmOrder'; showModal = true;" style="width: 48%">Tramitar</button>&nbsp;
                                                <button nz-button class="c-primary " (click)="modalMode = 'cancelOrder'; showModal = true;" style="width: 48%">Cancelar</button>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </ng-container>

                        </ng-container>

                    </ng-container>






                </form>
            </ng-container>

        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>


