import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/http/user/user.service';

@Injectable({
    providedIn: 'root',
})
class Auth  {
    private userRedirectToReports = ['op_fraude', 'administracion_ventas','security_user'];
    private userRedirectToSimyo = ['op_simyo', 'e_sac']
    constructor(public userService: UserService,
      private router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
            console.log('url', state.url);
        return this.userService.userInfo().pipe(
            map((user) => {
                if (state.url == '/' && this.userRedirectToReports.includes(user.specialUser)) {
                    this.router.navigateByUrl('/herramientas/informes/informes')
                    return false;
                }

                if (state.url == '/' && (this.userRedirectToSimyo.includes(user.specialUser))) {
                    this.router.navigateByUrl('/simyo/op');
                    return false;
                }
                return true;
            })
        );
        //return true;
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(Auth).canActivate(next, state);
};
