import {FormGroup} from '@angular/forms';

export function HasSpecial(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors['hasSpecial']) {
            // return if another validator has already found an error on the control
            return;
        }

        // set error on control if validation fails
        if (!/[\W]/.test(control.value)) {
            control.setErrors({hasSpecial: true});
        } else {
            control.setErrors(null);
        }
    };
}