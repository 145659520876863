import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permissions-step-delivery',
  templateUrl: './permissions-step-delivery.component.html',
  styleUrls: ['./permissions-step-delivery.component.scss']
})
export class PermissionsStepDeliveryComponent implements OnInit {

  visible = false;

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
