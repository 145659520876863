import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

if (!environment.allowConsoleLog) {
    window.console.log = () => {}
    // const consoleLog = console.log;
    // window.console.log = () => {
    //     consoleLog('-- NOTICE: CONSOLE LOG IS DISABLED --')
    // }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
