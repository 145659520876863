import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import { pageLimit } from "src/app/shared/constantes";

@Injectable({
    providedIn: 'root'
})

export class LogisticsService extends ApiService {
    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    private listadoArticulosFilter: any = null;
    private stockArticulosFilter: any = null;
    private listadoGruposFilter: any = null;
    private listadoGruposOrderBy: any = null;
    private listadoPedidosFilter: any = null;
    private listadoPedidosOrderBy: any = null;
    private listadoArticulosOrderBy: any = null;
    private listadoPedidosPaginator: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};
    private listadoArticulosPaginator: any = {limit: 15, totalPages: 0, currentPage: 1, totalItems: 0};
    private listadoGruposPaginator: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};
    private stockArticulosPaginator: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};

    setListadoArticulosFilter(filter: any) {
        this.listadoArticulosFilter = filter;
    }

    getListadoArticulosFilter(): any {
        return this.listadoArticulosFilter;
    }

    setListadoArticulosPaginator(paginator: any) {
        this.listadoArticulosPaginator = paginator;
    }

    getListadoArticulosPaginator(): any {
        return this.listadoArticulosPaginator;
    }

    setListadoArticulosOrderBy(orderBy: any) {
        this.listadoArticulosOrderBy = orderBy;
    }

    getListadoArticulosOrderBy(): any {
        return this.listadoArticulosOrderBy;
    }

    setStockArticulosFilter(filter: any) {
        this.stockArticulosFilter = filter;
    }

    getStockArticulosFilter(): any {
        return this.stockArticulosFilter;
    }

    setStockArticulosPaginator(paginator: any) {
        this.stockArticulosPaginator = paginator;
    }

    getStockArticulosPaginator(): any {
        return this.stockArticulosPaginator;
    }

    setListadoGruposFilter(filter: any) {
        this.listadoGruposFilter = filter;
    }

    getListadoGruposFilter(): any {
        return this.listadoGruposFilter;
    }

    setListadoGruposPaginator(paginator: any) {
        this.listadoGruposPaginator = paginator;
    }

    getListadoGruposPaginator(): any {
        return this.listadoGruposPaginator;
    }

    setListadoGruposOrderBy(orderBy: any) {
        this.listadoGruposOrderBy = orderBy;
    }

    getListadoGruposOrderBy(): any {
        return this.listadoGruposOrderBy;
    }

    setListadoPedidosFilter(filter: any) {
        this.listadoPedidosFilter = filter;
    }

    getListadoPedidosFilter(): any {
        return this.listadoPedidosFilter;
    }

    setListadoPedidosOrderBy(orderBy: any) {
        this.listadoPedidosOrderBy = orderBy;
    }

    getListadoPedidosOrderBy(): any {
        return this.listadoPedidosOrderBy;
    }

    setListadoPedidosPaginator(paginator: any) {
        this.listadoPedidosPaginator = paginator;
    }

    getListadoPedidosPaginator(): any {
        return this.listadoPedidosPaginator;
    }

    getOrders(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'logistic/order/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getOrder(id: number) {
        return this.http.get(this.endpoint + 'logistic/order/' + id, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getArticles(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post<any>(this.endpoint + 'logistic/article/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getArticle(id: number) {
        return this.http.get(this.endpoint + 'logistic/article/' + id, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getGroups(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'logistic/article/group/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    createGroup(group: any = null) {
        return this.http.post(this.endpoint + 'logistic/article/group/add', group, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    editGroup(group: any, id: any) {
        return this.http.post(this.endpoint + 'logistic/article/group/' + id + '/edit', group, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    deleteGroup(id: any) {
        return this.http.post(this.endpoint + 'logistic/article/group/' + id + '/delete', {}, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getArticleStocks(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'logistic/article/stock/list/filter', filters, {headers: this.getHeaders()})
    }

    updateArticleStock(update: any) {
        const id = update.idArticle;
        delete update.idArticle;
        return this.http.post(this.endpoint + '/logistic/article/stock/' + id + '/update', update, {headers: this.getHeaders()})
    }

    uploadXlsStock(blob: any) {
        const form = new FormData();
        form.append('file', blob, blob.name);
        return this.http.post(this.endpoint + '/logistic/article/stock/add', form, {headers: this.getFileHeaders()})
    }


    createOrderFormData() {
        return this.http.get<any>(this.endpoint + '/logistic/order/form_filter/distributor', {headers: this.getHeaders()});
    }

    createOrder(body: any) {
        return this.http.post<any>(this.endpoint + '/logistic/order/add', body, {headers: this.getHeaders()});
    }

    cancelOrder(order_id: any) {
        return this.http.post<any>(this.endpoint + '/logistic/order/' + order_id + '/cancel', {}, {headers: this.getHeaders()});
    }
    
}
