import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IOrderDetailProduct } from '../../models/IorderDetail';

@Component({
    selector: 'app-list-contracted-products',
    templateUrl: './list-contracted-products.component.html',
    styleUrls: ['./list-contracted-products.component.scss']
})
export class ListContractedProductsComponent implements OnInit {

    @Input() products: IOrderDetailProduct[] | undefined;

    public iframeUrl: string = '';
    public showModal: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    public openIframe(value: string) {
        this.iframeUrl = environment.oldDigoFront + value;
        this.showModal = true;
    }
}
