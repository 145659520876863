import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TrustHtmlPipe} from "./trust-html.pipe";
import {TrustUrl} from "./trust-url";
import {EuroPipe} from "./euro.pipe";
import { OrderHeaderPipe } from './orderHeader.pipe';



@NgModule({
    declarations: [
        TrustHtmlPipe, 
        TrustUrl, 
        EuroPipe,
        OrderHeaderPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TrustHtmlPipe,
        TrustUrl,
        EuroPipe,
        OrderHeaderPipe
    ],
    providers: [
        TrustHtmlPipe,
        TrustUrl,
        EuroPipe,
        OrderHeaderPipe
    ]
})
export class CustomPipesModule { }
