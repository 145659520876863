<div class="container-max">
    <app-breadcrumbs 
        [title]="'Logística Grupo <span class=\'font c-primary\'> ' + (groupId === 'new' ? 'Nuevo' : 'id ' + groupId) + '</span>'"
        [breacrumbList]="['Herramientas', 'Logística', 'Grupo ' +( groupId === 'new' ? 'Nuevo' : 'id ' + groupId)]"
        [showButtonBack]="true">
    </app-breadcrumbs>

    <div class="wrapper-view-edit-list">
        <div class="inner-content-max">
            <p *ngIf="error">{{ error }}</p>
            <div nz-row nzGutter="16">
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
                <ng-container *ngIf="!loading && !error">
                    <!--<app-details-lateral-menu-->
                            <!--[links]="[{name:'Datos', route: '/logistica/grupos/' + groupId}]"-->
                            <!--[back_url]="'/logistica/grupos'"-->
                    <!--&gt;-->
                    <!--</app-details-lateral-menu>-->
                    <div nz-col nzXXl="24" nzXl="24" nzLg="24" nzMd="24" nzXs="24" nzSm="24" >
                        <!--<i nz-icon nzType="edit" nzTheme="outline" class="icon s-22" style="margin-right: 5px; cursor:pointer;" ></i>-->

                        <!--<i *ngIf="groupId !== 'new'" (click)="deleteGroup()" nz-icon nzType="delete" nzTheme="outline" class="icon s-22" style="margin-right: 5px; cursor:pointer;" ></i>-->

                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up title-head">{{ groupId == 'new' ? 'Crear Grupo' : 'Edición de grupo'}}</span>
                            <!--<span class="tag success">Nº de Productos</span>-->
                        </div>
                        <div class="digo-card form-card">
                            <form [formGroup]="form" *ngIf="form">
                                <!--<p class="font s-13 c-gray t-up">DIRECCIÓN PRINCIPAL</p>-->
                                <div class="flex-content f-between">
                                    <div class="w40">
                                        <label class="font s-13 c-gray t-up">Nombre</label>
                                        <p><input type="text" formControlName="nombre"></p>
                                    </div>
                                    <div class="w40">
                                        <label class="font s-13 c-gray t-up">Descripción</label>
                                        <p><input type="text" formControlName="descripcion"></p>
                                    </div>
                                    <div class="w20">
                                        <label class="font s-13 c-gray t-up">Activado</label>
                                        <p><input type="checkbox" formControlName="activado"></p>
                                    </div>
                                </div>
                                <br>
                                <br>

                                <div class="flex-content">

                                    <div class="margins m-r-10" *ngIf="groupId !== 'new'">
                                        <button style="max-width: 200px" nz-button class="c-primary size-large" nzBlock (click)="showModalRemoveLogisticGroup()">
                                            Borrar
                                        </button>
                                    </div>
                                    <div>
                                        <button style="max-width: 200px" nz-button class="c-primary size-large" nzBlock (click)="formSubmit()">
                                            Guardar
                                        </button>
                                    </div>

                                </div>

                            </form>


                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>

<nz-modal
    [(nzVisible)]="showModal"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="deleteGroup()"
    nzWidth="687px"
    [nzCentered]="true"
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="wrapper-modal">
            <div class="header-modal">
                <div class="flex-content a-center a-base">
                    <span class="font s-22 w-bold a-center margins m-r-10">Grupo {{ groupId }}</span>
                </div>
            </div>
            <div class="body-modal">
                <div class="flex-content a-center margins m-b-20" align="center">
                    Se eliminará el registro actual
                    <br>
                    ¿Desea continuar?
                </div>
                <div class="flex-content a-center">
                    <button nz-button
                        class="c-primary margins m-r-10"
                        (click)="deleteGroup()">
                        Eliminar
                    </button>
                    <button nz-button
                        class="c-dark"
                        (click)="handleCancel()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>


