<!-- Datos del cliente -->
<div class="digo-card form-card custom-space" *ngIf="(customer?.email && customer?.email !== '') || (customer?.phoneContact && customer?.phoneContact !== '')">
    <p class="font s-13 c-gray t-up">Datos de contacto</p>
    <div nz-row nzAlign="bottom">
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">Teléfono</label>
            <p>{{ customer?.phoneContact && customer?.phoneContact !== '' ? customer?.phoneContact : '-' }}</p>
        </div>
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">Email</label>
            <p>{{ customer?.email && customer?.email !== '' ? customer?.email : '-' }}</p>
        </div>
    </div>
</div>

<!-- Direcciones -->
<div class="digo-card form-card custom-space" *ngFor="let address of customer?.addresses">
    <p class="font s-13 c-gray t-up">DIRECCIÓN DE {{ address.type }}</p>
    <div nz-row>
        <ng-container *ngFor="let element of address.elements; let i = index">
            <div nz-col [nzSpan]="element.nzSpan ? element.nzSpan : '6'" *ngIf="!element.hidden && element.type !== 'arrow'">
                <div class="content-card">
                    <span *ngIf="element.label && element.label !== ''" class="font s-13 c-gray t-up">{{ element.label }}</span>
                    <p>{{ element.value && element.value !== '' ? element.value : '-' }}</p>
                </div>
            </div>
            <div nz-col nzSpan="1" *ngIf="element.type === 'arrow'" class="cursor-pointer" (click)="changeShowMoreInfo(address)">
                <div class="content-arrow flex-content a-center">
                    <label nz-icon nzType="right" 
                        nzTheme="outline"
                        class="cursor-pointer"
                        [ngClass]="address.showMoreInfo === true ? 'arrow-active' : 'arrow-no-active'">
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- Datos del cliente antiguo en portabilidad con cambio de titular -->
<div class="digo-card form-card custom-space" *ngIf="customerDonor">
    <p class="font s-13 c-gray t-up">Datos de contacto del antiguo titular</p>
    <div nz-row>
        <div nz-col nzSpan="6">
            <div class="content-card">
                <label class="font s-13 c-gray t-up">Nombre</label>
                <p>{{ customerDonor.name && customerDonor.name !== '' ? customerDonor.name : '-' }}</p>
            </div>
        </div>
        <div nz-col nzSpan="6">
            <div class="content-card">
                <label class="font s-13 c-gray t-up">Apellidos</label>
                <p>{{ customerDonor.last_name && customerDonor.last_name !== '' ? customerDonor.last_name : '-' }}</p>
            </div>
        </div>
        <div nz-col nzSpan="6">
            <div class="content-card">
                <label class="font s-13 c-gray t-up">Teléfono</label>
                <p>{{ customerDonor.contact_phone && customerDonor.contact_phone !== '' ? customerDonor.contact_phone : '-' }}</p>
            </div>
        </div>
        <div nz-col nzSpan="5">
            <div class="content-card">
                <label class="font s-13 c-gray t-up">Email</label>
                <p>{{ customerDonor.email && customerDonor.email !== '' ? customerDonor.email : '-' }}</p>
            </div>
        </div>
        <div nz-col nzSpan="1" class="cursor-pointer" (click)="changeShowMoreInfoDonor()">
            <div class="content-arrow flex-content a-center">
                <label nz-icon nzType="right" 
                    nzTheme="outline"
                    class="cursor-pointer"
                    [ngClass]="showMoreInfoDonor === true ? 'arrow-active' : 'arrow-no-active'">
                </label>
            </div>
        </div>
    </div>
    <div nz-row nzAlign="bottom" *ngIf="showMoreInfoDonor">
        <div nz-col nzSpan="6" >
            <label class="font s-13 c-gray t-up">Tipo de Documento</label>
            <p>{{ customerDonor.document_type && customerDonor.document_type !== '' ? customerDonor.document_type : '-' }}</p>
        </div>
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">Número de documento</label>
            <p>{{ customerDonor.document_number && customerDonor.document_number !== '' ? customerDonor.document_number : '-' }}</p>
        </div>
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">Fecha de nacimiento</label>
            <p>{{ customerDonor.birth_date && customerDonor.birth_date !== '' ? convertDate(customerDonor.birth_date, false) : '-' }}</p>
        </div>
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">Nacionalidad</label>
            <p>{{ customerDonor.nationality_id && customerDonor.nationality_id !== '' ? customerDonor.nationality_id : '-' }}</p>
        </div>
    </div>
</div>

<!-- Datos bancarios -->
<div class="digo-card form-card custom-space" *ngIf="customer?.payer?.iban && customer?.payer?.iban !== ''">
    <p class="font s-13 c-gray t-up">Datos Bancarios</p>
    <div nz-row nzAlign="bottom">
        <div nz-col nzSpan="6">
            <label class="font s-13 c-gray t-up">IBAN</label>
            <p>{{ customer?.payer?.iban }}</p>
        </div>
    </div>
</div>

