import { Component, HostListener, OnInit } from '@angular/core';
import {PermissionsService} from "../../../services/http/herramientas/permissions.service";
import {AccessService} from "../../../services/access.service";

@Component({
    selector: 'app-permisos',
    templateUrl: './permisos.component.html',
    styleUrls: ['./permisos.component.scss']
})
export class PermisosComponent implements OnInit {

    /**
     * general no tiene filtros
     dis=> filtro permisos
     grupo => +filtro distribuidor
     gpv => +filtro grupo
     empresa => + filtro gpv
     pdv => +filtro empresa
     */
    public loading: boolean = true;
    public permissions: any[] = [];
    public permissionsFiltered: any[] = [];
    public selectedPermission: any = null;

    public getScreenWidth: any;
    public isCollapsed: boolean = false;

    constructor(
        private permissionService: PermissionsService,
        private access: AccessService
    ) { }

    ngOnInit(): void {
        this.getScreenWidth = window.innerWidth;
        this.loadList()
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.getScreenWidth = window.innerWidth;
        this.statePermissions();
    }
    statePermissions() {
        this.isCollapsed = (this.getScreenWidth < 992 && this.selectedPermission != null) && this.isCollapsed;
    }
    
    loadList() {
        this.access.checkAccess('herramientas/permisos');
        this.permissionService.getPermissionsMaster().subscribe({next: (permissions) => {
            const permissionsVisible = permissions.filter((perm: any) => perm.visible === true);
            this.permissions = permissionsVisible;
            this.permissionsFiltered = permissionsVisible;
        }}).add(() => this.loading = false);
    }

    filter(ev: any) {
        const text = ev.target.value.trim().toLowerCase();
        this.permissionsFiltered = this.permissions.filter(perm => perm.nombre.toLowerCase().includes(text));
    }

    selectPermission(permission: any) {
        this.selectedPermission = permission;
        window.scrollTo(0, 0);
        this.isCollapsed = !this.isCollapsed;
        this.statePermissions();
    }

}
