<div class="wrapper-menu-mobile">
    <ul >
        <li>
            <nz-collapse nzAccordion
                [nzGhost]="true">
                <ng-container *ngFor="let data of menuData; let i = index">
                    <nz-collapse-panel
                    *ngIf="titles[i].toString() != 'divider'"
                    [nzHeader]="titles[i]"
                    [nzActive]="false"
                    [nzShowArrow]="false">
                    <ul *ngFor="let submenuData of data; let i = index">
                        <li *ngFor="let contents of submenuData.contents">
                            <p class="font s-16 c-primary">{{contents.title}}</p>
                            <a class="font s-12 c-white" (click)="gotoAndClose(link, titles[i])" *ngFor="let link of contents.links">
                                {{link.label}}
                                <br>
                            </a>
                            <div class="divider-menu"></div>
                        </li>
                    </ul>
                </nz-collapse-panel>
                </ng-container>
            </nz-collapse>
            <nz-collapse nzAccordion
                [nzGhost]="true"
                (click)="gotoAndClose(null, titles[titles.length-2])">
                <nz-collapse-panel
                    [nzHeader]="titles[titles.length-2]"
                    [nzActive]="false"
                    [nzShowArrow]="false">
                </nz-collapse-panel>
            </nz-collapse>
            <nz-collapse nzAccordion
                [nzGhost]="true"
                (click)="gotoAndClose(null, titles[titles.length-1])">
                <nz-collapse-panel
                    [nzHeader]="titles[titles.length-1]"
                    [nzActive]="false"
                    [nzShowArrow]="false">
                </nz-collapse-panel>
            </nz-collapse>
        </li>
    </ul>
    <div class="divider-menu"></div>
    <ul>
        <li class="li-icon">
            <i nz-icon nzType="safety" class="icon s-22 c-orange" nzTheme="outline"></i>
            <a (click)="changePassword(true)" class="font c-primary">Cambiar contraseña</a>
        </li>
    </ul>
    <div class="divider-menu"></div>
    <div class="content-user-mobile flex-content f-between">
        <div class="subcontent-name">
            <span *ngIf="username"
                  class="font s-13 c-primary">
                Hola {{ username }}
                <span class="font c-white">
                    ({{ username }})
                </span>
            </span>
        </div>
        <div class="subcontent-menu">
            <a nz-dropdown [nzDropdownMenu]="menu">
                <i nz-icon nzType="user" class="icon s-14 c-white icon-user" nzTheme="outline"></i>
                <i nz-icon nzType="down" class="icon s-14 c-white" nzTheme="outline" tabindex="2"></i>
            </a>
            <nz-dropdown-menu  #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <li nz-menu-item nzDanger (click)="closeSession()">Cerrar sesión</li>
            </ul>
            </nz-dropdown-menu>
        </div>
    </div>
</div>
<app-modal-global-change-password *ngIf="showModal" (showModalEvent)="changePassword($event)"></app-modal-global-change-password>
