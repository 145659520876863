<!--<div class="card-section" >-->
    <!--<div class="header">-->
        <!--&lt;!&ndash;<p class="font s-10 c-primary w-bold t-up">Informes solicitados</p>&ndash;&gt;-->
        <!--<div>-->
            <!--<span class="font s-10 c-primary w-bold t-up">Informes solicitados</span>-->
            <!--<i class="icon s-22 drop" nz-icon nzType="pic-center" (click)="onShow()" nzTheme="outline"></i>-->
            <!--<i class="icon s-22 drop" nz-icon nzType="menu" (click)="onShow()" nzTheme="outline"></i>-->
        <!--</div>-->
    <!--</div>-->
    <!--<div class="body">-->
        <!--<p *ngIf="error && !loading">{{ error }}</p>-->
        <!--<i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>-->
        <!--<div nz-row [nzGutter]="5" *ngIf="!loading">-->
            <!--<div *ngFor="let report of reportsList" nz-col [nzSpan]="isColumn ? '8' : '24'">-->
                <!--<div [className]="isColumn ? 'card-link-report inline-column' : 'card-link-report inline-row'">-->
                    <!--<span class="font s-12 w-bold">{{report.title ? report.title : report.identificador_informe }}</span>-->
                    <!--<i nz-icon nzType="arrow-right" nzTheme="outline"></i>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->

<app-list
        (tableClick)="tableClicked($event)"
        (pageChanged)="pageChanged($event)"
        (filtersApplied)="filtersApplied($event)"

        [filters]="[]"
        [table]="table"
        [paginator]="paginator"
        [loading]="loading"
        [error]="error"
>
</app-list>