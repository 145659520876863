<div class="cards-other-data" (click)="showModal()" *ngIf="!withText">
    <div class="flex-content a-column a-center">
        <i class="icon-type" nz-icon nzType="info-circle" nzTheme="outline"></i>
        <p class="font s-14 w-bold">Más info</p>
    </div>
    <i class="icon-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
</div>
<div (click)="showModal()" *ngIf="withText">
    {{ withText }}
</div>
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    nzWidth="1262px"
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="wrapper-modal modal-container">
            <div class="header-modal">
                <div class="flex-content a-center">
                    <span class="font s-28 w-bold a-center margins m-r-10">Más información</span>
                    <span class="tag purple" style="margin-top: 10px;">
                        <span>{{ pdv.denominacion ?? pdv.pdv_name }}</span>
                    </span>
                </div>
            </div>
            <div class="body-modal">
                <div class="flex-content f-end margins m-b-5" *ngIf="authService.userCan('pdv.editar')">
                    <a class="margins m-r-10" [routerLink]="['/perfiles/editar-pdv/', pdv.id_pdv]">Editar</a>
                </div>
                <div nz-row nzGutter="10" style="width: 100%;">
                    <div nz-col nzXXl="18" nzXl="18" nzLg="18" nzMd="24" nzXs="24" nzSm="24">
                        <div class="card-more-info-pdv card-grid">
                            <div nz-row nzGutter="25" class="margins m-b-30">
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Marco retributivo</p>
                                    <p>{{ getMarcoRetribuidoValue(pdv.marco_retributivo) }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">SFID</p>
                                    <p>{{ pdv.sfid ?? '--' }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Aviso límite de saldo (€)</p>
                                    <p>{{ pdv.limite_saldo ?? '--' }}</p>
                                </div>
                                <!-- <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Cuenta bancaria</p>
                                    <p>{{ pdv.pay_ccc ?? '--' }}</p>
                                </div> -->
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">CCC</p>
                                    <p>{{ pdv.ccc ?? '--' }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">SFID MásMovil</p>
                                    <p>{{ pdv?.dealercode ?? '--' }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Rebranding Simyo</p>
                                    <p>{{ pdv.multimarca_premium_club_name ?? '--' }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Dtil Simyo</p>
                                    <p>{{ pdv?.dtil_simyo === '1' ? 'Sí' : 'No' }}</p>
                                </div>
                                <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="12" nzXs="24" nzSm="12">
                                    <p class="font s-12 c-light-gray">Dtil MásMovil</p>
                                    <p>{{ pdv?.dtil_masmovil === '1' ? 'Sí' : 'No'  }}</p>
                                </div>
                            </div>
                            <div nz-row nzGutter="25">
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="12" nzXs="24" nzSm="24">
                                    <div class="flex-content">
                                        <label nz-checkbox [nzDisabled]="true" [ngModel]="pdv.ver_facturas_recargas">
                                            <span class="font s-12 c-black">Mostrar factura recargas</span>
                                        </label>
                                    </div>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="12" nzXs="24" nzSm="24">
                                    <div class="flex-content">
                                        <label nz-checkbox [nzDisabled]="true" [ngModel]="pdv.no_info_comisionado">
                                            <span class="font s-12 c-black">Ocultar datos de comisionado</span>
                                        </label>
                                    </div>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="12" nzXs="24" nzSm="24">
                                    <div class="flex-content">
                                        <label nz-checkbox [nzDisabled]="true" [ngModel]="pdv.otros_servicios" class="font s-12 c-black">
                                            <span class="font s-12 c-black">Otros servicios</span>
                                        </label>
                                    </div>
                                </div>
                                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="12" nzXs="24" nzSm="24">
                                    <div class="flex-content">
                                        <label nz-checkbox [nzDisabled]="true" [ngModel]="pdv.semirrebranding" class="font s-12 c-black">
                                            <span class="font s-12 c-black">Semirrebranding</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="24" nzXs="24" nzSm="24">
                            <div class="card-more-info-pdv card-button margins m-b-30" *ngIf="simyoUserStatus === 'subscribe' && authService.userCan('pdv.editar')">
                                <p class="font s-26">Venta de Simyo</p>
                                <button nz-button class="c-primary size-medium" (click)="closeModalAndOpenModalSimyo()">
                                    Solicitar usuario
                                </button>
                            </div>
                            <div class="card-more-info-pdv card-button margins m-b-30" *ngIf="simyoUserStatus === 'unsubscribe'" style="align-items: baseline;">
                                    <p class="font s-26">Usuario Simyo: <b>{{ pdv.smy_user.usuario }}</b></p>
                                    <p class="font s-26">Contraseña: <b>{{ pdv.smy_user.password }}</b></p>
                                    <p *ngIf="pdv.smy_user.fecha_solicitud!= null" class="font s-26">Fecha Solicitud: <b>{{ convertDate(pdv.smy_user.fecha_solicitud, false) }}</b></p>
                                    <p *ngIf="pdv.smy_user.fecha_alta != null" class="font s-26">Fecha Alta: <b>{{ convertDate(pdv.smy_user.fecha_alta, false) }}</b></p>
                                    <p *ngIf="pdv.smy_user.fecha_baja != null" class="font s-26">Fecha de baja: <b>{{ convertDate(pdv.smy_user.fecha_baja, false) }}</b></p>
                                    <p *ngIf="pdv.smy_user.duplicado_SIM != null" class="font s-26">Duplicado SIM: <b>{{ pdv.smy_user.duplicado_SIM }}</b></p>
                                <button *ngIf="authService.userCan('pdv.editar')" nz-button class="c-primary w-full" (click)="closeModalAndOpenModalSimyo()">
                                    Dar de baja
                                </button>
                            </div>
                            <div class="card-more-info-pdv card-button margins m-b-30" *ngIf="simyoUserStatus === 'resubscribe' && (authService.userCan('pdv.reactivar_simyo_merchan') || authService.userCan('pdv.reactivar_simyo_root'))">
                                <p class="font s-26">Usuario Simyo: <b>{{ pdv.smy_user.usuario }}</b></p>
                                <p class="font s-26">Contraseña: <b>{{ pdv.smy_user.password }}</b></p>

                                <p *ngIf="pdv.smy_user.fecha_solicitud!= null" class="font s-26">Fecha Solicitud: <b>{{ convertDate(pdv.smy_user.fecha_solicitud, false) }}</b></p>
                                <p *ngIf="pdv.smy_user.fecha_alta != null" class="font s-26">Fecha Alta: <b>{{ convertDate(pdv.smy_user.fecha_alta, false) }}</b></p>
                                <p *ngIf="pdv.smy_user.fecha_baja != null" class="font s-26">Fecha de baja: <b>{{ convertDate(pdv.smy_user.fecha_baja,false) }}</b></p>

                                <p *ngIf="pdv.smy_user.duplicado_SIM != null" class="font s-26">Duplicado SIM: <b>{{ pdv.smy_user.duplicado_SIM }}</b></p>
                                <button nz-button class="c-primary size-medium" (click)="closeModalAndOpenModalSimyo()">
                                    Volver a activar
                                </button>
                            </div>
                            <div class="card-more-info-pdv card-button margins m-b-30" *ngIf="simyoUserStatus === 'pending'">
                                <span>Se ha solicitado el usuario de Simyo <br>el día {{ convertDate(pdv.smy_user.fecha_solicitud, false) }}</span>
                            </div>
                        <div class="card-more-info-pdv card-button" *ngIf="pdv.observaciones || pdv.observaciones_2" style="align-items: baseline;text-align: left;">
                            <p class="font s-12 c-light-gray">Observaciones</p>
                            <p class="font s-14">{{pdv.observaciones}}</p>
                            <p class="font s-14">{{pdv.observaciones_2}}</p>
                            <!--<button nz-button class="c-primary size-medium">+ Añadir</button>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="footer-modal">
                <div class="flex-content a-center margins m-b-30">
                    <a class="font s-14 c-primary a-center">Desactivar usuario</a>
                </div>
                <div class="flex-content f-evenly">
                    <div>
                        <p class="font s-12 c-light-gray">Fecha alta</p>
                        <p>30/10/2020</p>
                    </div>
                    <div>
                        <p class="font s-12 c-light-gray">Último acceso</p>
                        <p>30/10/2020</p>
                    </div>
                    <div>
                        <p class="font s-12 c-light-gray">Nº accesos</p>
                        <p>150</p>
                    </div>
                </div>
            </div> -->
        </div>
    </ng-container>
</nz-modal>
