

    <div class="form-group" style="width: 100%;">
        <div nz-row [nzGutter]="24">
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="mes" nzPlaceHolder="Este mes" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="state" nzPlaceHolder="Estado" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="groups" nzPlaceHolder="Grupos" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="3" style="margin: 13px 0;">
                <input type="date"oninvalid="">
                <label for="input" class="control-label flex-content f-between" style="width: 100%">
                    <div>
                        <span class=""><b>Desde</b></span>
                    </div>
                </label><i class="bar"></i>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="3" style="margin: 13px 0;">
                <input type="date"oninvalid="">
                <label for="input" class="control-label flex-content f-between" style="width: 100%">
                    <div>
                        <span class=""><b>Hasta</b></span>
                    </div>
                </label><i class="bar"></i>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="area" nzPlaceHolder="Área" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="delivery" nzPlaceHolder="Dsitribuidor" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
                <div class="form-group">
                    <nz-select class="select-prepaid" ngModel="gpv" nzPlaceHolder="GPV" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="A Option"></nz-option>
                        <nz-option nzValue="b" nzLabel="B Option"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
        </div>
    </div>
