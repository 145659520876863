import { Component, OnInit, ViewChild  } from '@angular/core';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'app-amena-chart',
  templateUrl: './amena-chart.component.html',
  styleUrls: ['./amena-chart.component.scss']
})
export class AmenaChartComponent implements OnInit {

  @ViewChild("chart") chart: any;
  public chartOptions: Partial<ChartOptions> | any ;

  constructor() {
    this.chartOptions = {
      series: [65, 15, 20],
      chart: {
        type: "donut"
      },
      labels: ["Team A", "Team B", "Team C"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {
  }

}
