import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { simyoDocStatus, superStatus } from '../../constantes';

@Component({
    selector: 'app-detail-order',
    templateUrl: './detail-order.component.html',
    styleUrls: ['./detail-order.component.scss']
})
export class DetailOrderComponent implements OnInit {

    @Input() orderDetail: any;
    @Input() breadcrumbsList: string[] = [];
    @Input() breadcrumbsTitle: string = '';
    
    @Output() updateOrderDetail = new EventEmitter<boolean>();
    
    public isMobile: boolean = false;
    public isCollapsedAdditionalInfo: boolean = false;
    public superStatus = superStatus;
    
    constructor(
        private router: Router,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.isMobile = window.innerWidth < 992;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.isMobile = window.innerWidth < 992;
    }

    public onShow() {
        this.isCollapsedAdditionalInfo = !this.isCollapsedAdditionalInfo;
    }

    public getClassDocStatus(status: string): string {
        switch (status) {
            case simyoDocStatus['ok']:
                return 'dot-complete';
            case simyoDocStatus['pte']:
                return 'dot-pending';
            case simyoDocStatus['notSend']:
            case simyoDocStatus['ko']:
                return 'dot-cancel-red';
            default:
                return 'dot-white';
        }
    }
    
    public getSignTypeDocStatus(sign_type: string): string {
        if (sign_type !== null && (sign_type === 'digital' || sign_type === 'manual')) {
            return sign_type.charAt(0).toUpperCase();
        }
        else return "";
    }

    public goTo(type: any, id: any): void {
        if (type && type !== '') {
            if (id !== null && id !== undefined) {
                if (type === 'pdv') {
                    this.router.navigate(['/perfiles/pdv/' + id.toString()]);
                }
                if (type === 'xml') {
                    this.router.navigate(['/perfiles/ext-pdv/' + id.toString()]);
                }
            }
        } else if (id !== null && id !== undefined) {
            this.router.navigate(['/perfiles/pdv/' + id.toString()]);
        }
    }

    public showButtonProfile(type: any): boolean {
        if (type && type !== '' && type === 'xml') {
            return this.auth.userCan('list_empresa_ext');
        }
        return this.auth.getPermissions()?.pdv?.mostrar_boton_perfil_pdv;
    }

    public updateOrder(): void{
        this.updateOrderDetail.emit(true);
    }

}
