<div class="flex-content f-between" *ngIf="paginator && paginator?.totalPages > 0">
    <div>
        <a *ngIf="updatePage" (click)="updatePageEvent()" nz-button class="c-primary margins m-b-15 cursor-pointer m-r-15">
            <i nz-icon nzType="sync" nzTheme="outline"></i>Actualizar listado
        </a>

        <a *ngIf="addElement" (click)="add()" nz-button class="c-primary margins m-b-15 cursor-pointer">
            <i nz-icon nzType="plus" nzTheme="outline"></i>Añadir {{type}}
        </a>
    </div>
    <div class="flex-content a-center page-btns">
        <div nz-row style="justify-content: flex-end;">
            <p class="font s-14 margins m-r-15 xs-no-margins" *ngIf="paginator?.totalItems && paginator?.totalItems !== ''">Elementos: <b>{{ paginator?.totalItems }}</b></p>
            <p class="font s-14">Página: <b>{{ paginator?.currentPage }}/{{ paginator?.totalPages }}</b></p>
        </div>
        <ul class="list-header-prepaid margins m-l-10" *ngIf="paginator && paginator?.totalPages > 1">
            <li *ngIf="paginator?.currentPage > 1">
                <button [disabled]="loading" nz-button class="size-md c-primary" (click)="changePage(-1)" [ngStyle]="{'visibility': paginator?.currentPage <= 1 ? 'hidden' : 'inherit'}">
                    <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
                </button>
            </li>
            <li>
                <button [disabled]="loading" nz-button class="size-md c-primary" (click)="changePage(1)" [ngStyle]="{'visibility': paginator?.currentPage == paginator?.totalPages ? 'hidden' : 'inherit'}">
                    <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                </button>
            </li>
        </ul>
    </div>
</div>
