import { Component, OnInit } from '@angular/core';
import {pageLimit} from "../../../../shared/constantes";
import {ReportsService} from "../../../../services/http/reports/reports.service";
import {ListsFiltersService} from "../../../../services/lists.filters.service";
import {environment} from "../../../../../environments/environment";
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from 'src/app/services/modal.service';
import { switchMap, take, tap } from 'rxjs';

@Component({
    selector: 'app-informes-diarios',
    templateUrl: './informes-diarios.component.html',
    styleUrls: ['./informes-diarios.component.scss']
})
export class InformesDiariosComponent implements OnInit {

    public loading: boolean = true;
    public error: string | boolean = false;
    public isColumn: boolean = false;

    public paginator: any = {
        limit: 10000,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public table: any = null;

    constructor(
        private reportsService: ReportsService,
        private filterService: ListsFiltersService,
        private modalService: ModalService
    ) { }

    onShow() {
        this.isColumn = !this.isColumn;
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.reportsService.listDaily(this.filterService.getPayload(this.paginator, []))
        .subscribe({
            next: (reports:any) => {
            this.error = false;
            if (reports.data) {
                this.prepareTable(reports.data);
                this.preparePaginator(reports);
            }
            // if (reports.data) {
            //     Object.keys(reports.data).forEach(key => {
            //         this.reportsList.push(reports.data[key]);
            //         this.prepareTable(this.reportsList)
            //     });
            //
            // }
            },
            error: (error: HttpErrorResponse) => {
                this.prepareTable([]);
                this.error = "ERROR";
                this.loading = false
            },
            complete:() => {
                this.loading = false;
            }
        });
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'ID solicitud'},
                            {value: 'ID informe'},
                            // {value: 'Título'},
                            {value: 'Fecha solicitud'},
                            {value: 'Estado'},
                            {value: 'Fichero'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            table.body.rows.push({
                clickValue: item.idReportDaily,
                cells: [
                    {value: item.idReportDaily, styles: this.getRowStyle(item)},
                    {value: item.reportIdentify},
                    // {value: item.title ? item.title : ''},
                    {value: item.dateRequest},
                    {value: this.getStatusText(item)},
                    {value: this.getActions(item)}
                ]
            })
        });

        this.table = table;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    tableClicked(ev: any){
        if (ev.event.target.classList.contains('cancel-report')) {
            this.modalService.confirm('¿Seguro que deseas cancelar este informe?')
            .pipe(
                take(1),
                tap(result => {
                    if(result) {
                        const id_solicitud = ev.event.target!.dataset!.id;
                        this.loading = true;
                        this.reportsService.cancelReport(id_solicitud).subscribe(res => {
                        }).add(() => window.location.reload());
                    }
                })
            ).subscribe()

        }
    }

    pageChanged(paginator: any) {
        this.paginator = paginator;
        this.load();
    }

    filtersApplied(filters: any) {
    }

    getActions(item: any) {
        let html = '';
        if (item.file) {
            item.file.split('|').forEach((link: string) => {
                if (link) {
                    html += '<a style="margin-left: 5px" href="' + environment.oldDigoFront + link + '">';
                    if (!link.includes('download.itx') && (link.includes('@') || link.includes('%40'))) {
                        html += '<img src="assets/images/legacy/icon_reload.png" title="Lanzar de nuevo" style="width: 25px"/></a>'
                    } else if (link.includes('.xlsx') || link.includes('=xlsx')) {
                        html += '<img src="assets/images/legacy/xlsx.png" title="Fichero Xlsx" style="width: 25px"/></a>'
                    } else {
                        html += '<img src="assets/images/legacy/csv.png" title="Fichero Csv" style="width: 25px"/></a>'
                    }
                }
            });
        }
        // if (parseInt(item.finalized) === 0) {
        //     html += '<img style="margin-left: 5px;" title="Cancelar informe" class="cancel-report" data-id="'+item.id_solicitud_informe + '" src="assets/images/legacy/icon_ko.png" />'
        // }
        return html;
    }



    getStatusText(item: any) {
        const status = parseInt(item.finalized);
        console.log(status)
        switch (status) {
            case 0:
                return 'En curso';
            case 1:
                return 'Finalizado';
            case 2:
            case 4:
                return 'Sin resultados'
            case 3:
                return 'Cancelado'
        }
    }

    getRowStyle(item: any) {
        const base = '5px solid ';
        const status = parseInt(item.finalized);
        switch (status) {
            case 0:
                return {};
            case 1:
                return {'border-left': base + '#009900'}
            case 2:
            case 4:
                return {'border-left': base + '#42bff5'};
            case 3:
                return {'border-left': base + 'red'}
        }
        return {};
    }

}
