<div class="container-max">
    <app-breadcrumbs
            [title]="'Logística <span class=\'font c-primary\'>Stock Artículos</span>'"
            [breacrumbList]="['Herramientas', 'Logística', 'Stock Artículos']"
    ></app-breadcrumbs>

    <div *ngIf="!error" class="inner-content-max">
        <div nz-row class="margins m-b-10" style="justify-content: flex-end;">
            <div nz-col>
                <input type="file" (change)="uploadFile($event)" name="file_xls" accept=".xls, .xlsx" style="display: none;" id="uploadFile">
                <button class="margins m-r-10">
                    <label for="uploadFile" id="labelarchivo">Selecciona un archivo</label>
                </button>
                <input type="button" name="submit-edited-stocks" (click)="uploadEditedStocks()" *ngIf="!uploadingStock" value="Guardar cambios">
                <div class="wrapper-prepaid-list" style="justify-content: center;" *ngIf="uploadingStock">
                    <i nz-icon nzType="loading" nzTheme="outline"></i>
                </div>
            </div>
        </div>
    </div>
    <app-list
            (tableClick)="tableClicked($event)"
            (pageChanged)="pageChanged($event)"
            (filtersApplied)="filtersApplied($event)"

            [filters]="filters"
            [table]="table"
            [paginator]="paginator"
            [loading]="loading"
            [error]="error"
    >

    </app-list>
</div>