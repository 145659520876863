<div class="body-modal">
    <ng-container *ngIf="mode === 'stock'">
        <p class="font s-16 w-bold a-center c-black">Cantidad no disponible</p>
        <br>
        <p class="font s-16  a-center margins m-b-30">Contacte con su supervisor</p>
        <div nz-row nzGutter="24">
            <div nz-col nzSpan="8" nzOffset="8">
                <button nz-button class="c-primary size-large" (click)="actionSelected({accepted: true})" nzBlock>Aceptar</button>
            </div>
            <!--<div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">-->
            <!--<button nz-button class="c-dark size-large" nzBlock (click)="deleteModal = false; deleteCandidate = null">Cancelar</button>-->
            <!--</div>-->
        </div>
    </ng-container>

    <ng-container *ngIf="mode === 'cancelOrder'">
        <p class="font s-16 w-bold a-center c-black">Cancelar Pedido</p>
        <br>
        <p class="font s-16  a-center margins m-b-30">¿Desea cancelar el pedido en curso?</p>
        <div nz-row nzGutter="24">
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                <button nz-button class="c-primary size-large" (click)="actionSelected({accepted: true})" nzBlock>Sí</button>
            </div>
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
            <button nz-button class="c-dark size-large" nzBlock (click)="actionSelected({accepted: false})">No</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="mode === 'confirmOrder'">
        <p class="font s-16 w-bold a-center c-black">Confirmar Pedido</p>
        <br>
        <p class="font s-16  a-center margins m-b-30">¿Desea confirmar el pedido?</p>
        <div nz-row nzGutter="24">
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                <button nz-button class="c-primary size-large" (click)="actionSelected({accepted: true})" nzBlock>Confirmar</button>
            </div>
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                <button nz-button class="c-dark size-large" nzBlock (click)="actionSelected({accepted: false})">Cancelar</button>
            </div>
        </div>
    </ng-container>

</div>