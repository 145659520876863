import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'trustHtml'
})

export class TrustHtmlPipe implements PipeTransform  {

    constructor(private _sanitizer: DomSanitizer){}

    transform(v: string) : SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }
}
