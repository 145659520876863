import { Component, Input, OnInit } from '@angular/core';
import { TsimyoSuperStatus } from 'src/app/pages/simyo/models/globalsSimyo';
import { superStatus } from '../../constantes';

@Component({
    selector: 'app-status-order',
    templateUrl: './status-order.component.html',
    styleUrls: ['./status-order.component.scss']
})
export class StatusOrderComponent implements OnInit {

    @Input() status: TsimyoSuperStatus | string | undefined;
    @Input() title: string | undefined = '';
    @Input() class: string | undefined;
    @Input() brand: string | undefined;
    @Input() statusDescription?: string;
    @Input() statusName?: string;

    public superStatus: { [key: string]: string }  = superStatus;

    constructor() { }

    ngOnInit(): void {
    }

}
