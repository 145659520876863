import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router } from '@angular/router';

@Component({
    selector: 'app-generic-error',
    templateUrl: './generic-error.component.html',
    styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

    @Input() text: string = '';
    @Input() textButton: string = '';
    @Input() url: string | boolean = false;

    constructor(
        private location: Location,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    public go(){
        if (this.url) {
            this.goNavigate();
        }
        else{
            this.goBack();
        }
    }
    public goBack() {
        this.location.back();
    }

    public goNavigate(){
        this.router.navigate([this.url]);
    }

}
