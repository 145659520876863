<div class="container-max">
    <app-breadcrumbs
            [title]="'General <span class=\'font c-primary\'>Artículos</span>'"
            [breacrumbList]="['Herramientas', 'General', 'Listado Artículos']"
    ></app-breadcrumbs>

    <app-list
            (tableClick)="tableClicked($event)"
            (pageChanged)="pageChanged($event)"
            (filtersApplied)="filtersApplied($event)"

            [filters]="filters"
            [table]="table"
            [paginator]="paginator"
            [loading]="loading"
            [error]="error"
    >

    </app-list>
</div>