import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ReportsService extends ApiService {
    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    list(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'report/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    listMaps(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'statmap/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    listRequested(filters: any) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'report/requested/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    listDaily(filters: any) {
        if (!filters) {
            filters = {};
        }
        return this.http.get(this.endpoint + 'report/daily/list', {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    listSubscriptions(filters: any) {
        if (!filters) {
            filters = {};
        }
        return this.http.get(this.endpoint + 'report/subscription/list', {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    cancelReport(id_solicitud_informe: string) {
        return this.http.get(environment.oldDigoFront + 'informes_solicitados.itx?cancelar_informe=' + id_solicitud_informe + '&jwt=' + this.auth.getToken());
    }

    getReportsList(): Observable<any> {
        return this.http.get<any>(this.endpoint + `report/subscription/generate/list`, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    addReportSubscription(data: any) {
        return this.http.post(this.endpoint + 'report/subscription/add', data, {headers: this.getHeaders()})
    }

    updateReportSubscription(data: any, id: string) {
        return this.http.post(this.endpoint + 'report/subscription/'+id+'/update', data, {headers: this.getHeaders()})
    }

    deleteReportSubscription(id: string) {
        return this.http.post(this.endpoint + 'report/subscription/'+id+'/delete', {}, {headers: this.getHeaders()})
    }
    
    generateReport(id_solicitud_informe: string) {
        const key = 'theyUJu3tes@efaswejahEZ@afR5Fenu';
        return this.http.get(`${environment.oldDigoFront}current/generador_informes.itx?key=${key}&id_solicitud=${id_solicitud_informe}`);
    }
}