import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map} from "rxjs/operators";
import { Observable } from "rxjs";
import { IincentiveCreateBody, IincentiveDetail, IincentiveListResponse } from "src/app/pages/profiles/models/IpointsPdv";

@Injectable({
    providedIn: 'root'
})

export class DinamizacionService extends ApiService {
    private filters: any = null
    private page = 1;
    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    getProducts() {
        return this.http.get(this.endpointComun + 'points/product/list', {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getListFilter() {
        return this.http.get(this.endpointComun + 'points/list/filters', {headers: this.getHeaders()}).pipe(map((response: any) => response))
    }

    getPdvPoints(pdvId: any) {
        return this.http.get(this.endpointComun + 'pdv/points', {headers: this.getHeaders(), params: {'pdv_id': pdvId}}).pipe(map((response: any) => response.msg))
    }

    getIncentiveListFromService(body: any): Observable<IincentiveListResponse> {
        return this.http.post<IincentiveListResponse>(this.endpointComun + 'points/incentive/list', body, {headers: this.getHeaders()}).pipe(map((response: any) => response && response.length && response[0]));
    }

    getIncentiveDetail(incentiveId: any): Observable<IincentiveDetail> {
        return this.http.post<IincentiveDetail>(`${this.endpointComun}points/incentive`, {incentive_id: incentiveId}, {headers: this.getHeaders()}).pipe(map((response: any) => response && response.data));
    }

    getIncentiveDetailPdvList(body:any): Observable<IincentiveDetail> {
        return this.http.post<IincentiveDetail>(`${this.endpointComun}points/incentive/pdv/list` , body, {headers: this.getHeaders()}).pipe(map((response: any) => response && response.length && response[0]));
    }

    createEditIncentive(body: IincentiveCreateBody): Observable<any> {
        return this.http.post<any>(this.endpointComun + 'points/incentive/create', body, {headers: this.getHeaders()});
    }

    uploadPoints(blob: any) {
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
        const form = new FormData();
        form.append('file', blob, blob.name);
        return this.http.post(this.endpointComun + 'points/import', form, {headers: header})
    }

    uploadIncentive(blob: any) {
        const header = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
        const form = new FormData();
        form.append('file', blob, blob.name);
        return this.http.post(this.endpointComun + 'points/incentive/import', form, {headers: header})
    }

    setFilters(filters: any) {
        this.filters = filters
    }

    getFilters(): any {
        return this.filters
    }

    setPage(page: number) {
        this.page = page
    }

    getPage(): number {
        return this.page
    }

    deleteIncentive(incentive_id: string) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
        return this.http.delete(`${this.endpointComun}points/incentive/${incentive_id}`, {headers})
    }
}