<nz-modal
    [nzVisible]="true" 
    [nzFooter]="null"
    (nzOnCancel)="closeModal()"
    (nzOnOk)="closeModal()"
    nzCentered>
>
    <ng-container *nzModalContent class="wrapper-modal">
        <p class="font s-16 w-bold a-center c-black">Se ha producido un error</p>
        <br>
        <p class="font s-16 a-center margins m-b-30">{{error_msg}}</p>
        <div nz-row nzGutter="24">
            <div nz-col nzXXl="24" nzXl="24" nzLg="24" nzMd="24" nzXs="24" nzSm="24">
                <button nz-button class="c-primary size-large" (click)="closeModal()" nzBlock>Ok</button>
            </div>
        </div>
    </ng-container>
</nz-modal>
