<div class="form-group" style="width: 100%;">
    <div class="form-group">
        <input type="text" oninvalid="" required="">
        <label for="input" class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>Nombre</b></span>
            </div>
        </label><i class="bar"></i>
     </div>
     <div class="form-group">
        <input type="text" oninvalid="" required="">
        <label for="input" class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>NIF</b></span>
            </div>
        </label><i class="bar"></i>
     </div>
     <div class="form-group">
        <input type="text" oninvalid="" required="">
        <label for="input" class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>MSISDN</b></span>
            </div>
        </label><i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select class="select-prepaid" ngModel="distribuidor" nzPlaceHolder="Distribuidor" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select ngModel="empresa" nzPlaceHolder="Empresa" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select ngModel="pdvxml" nzPlaceHolder="PdV XML" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select ngModel="altasxml" nzPlaceHolder="Altas XML" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select ngModel="articulo" nzPlaceHolder="Artículo" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <div nz-row [nzGutter]="24">
            <div nz-col [nzXs]="12" [nzSm]="12" [nzLg]="12" [nzMd]="24"[nzLg]="12"[nzXl]="12"[nzXXl]="12">
                <input type="date"oninvalid="" required="">
                <label for="input" class="control-label flex-content f-between" style="width: 100%">
                    <div>
                        <span class=""><b>Desde</b></span>
                    </div>
                </label><i class="bar"></i>
            </div>
            <div nz-col [nzXs]="12" [nzSm]="12" [nzLg]="12" [nzMd]="24"[nzLg]="12"[nzXl]="12"[nzXXl]="12">
                <input type="date"oninvalid="" required="">
                <label for="input" class="control-label flex-content f-between" style="width: 100%">
                    <div>
                        <span class=""><b>Hasta</b></span>
                    </div>
                </label><i class="bar"></i>
            </div>
        </div>
     </div>
     <div class="form-group">
        <nz-select ngModel="estado" nzPlaceHolder="Estado" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group">
        <nz-select ngModel="creador" nzPlaceHolder="Usuario creador" nzBorderless style="width: 100%;">
            <nz-option nzValue="a" nzLabel="A Option"></nz-option>
            <nz-option nzValue="b" nzLabel="B Option"></nz-option>
        </nz-select>
        <i class="bar"></i>
     </div>
     <div class="form-group form-group-footer">
        <div class="flex-content a-center">
            <button nz-button class="c-primary size-large" nzBlock>Buscar</button>
        </div>
     </div>
</div>
