import { Component, Input, OnInit } from '@angular/core';
import { PdvService } from "../../../services/http/pdv/pdv.service";
import { NzUploadChangeParam } from "ng-zorro-antd/upload";
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-modal-carousel-images',
    templateUrl: './modal-carousel-images.component.html',
    styleUrls: ['./modal-carousel-images.component.scss']
})
export class ModalCarouselImagesComponent implements OnInit {
    @Input() pdv: any

    public isVisible = false;
    public imgSelected: string | null = "";
    public imgUploadHeaders: any;
    public imgUploadEndpoint = "";
    public showUploadList = true;
    public errorRemoveImg: boolean = false;
    public loadingPdvImgs!: Array<boolean>;

    constructor(
        private pdvService: PdvService,
        public authService: AuthService
    ) { }

    ngOnInit(): void {
        this.imgSelected = this.pdv.imgs && this.pdv.imgs.length > 0 ? this.pdv.imgs[0] : null;
        this.imgUploadHeaders = this.pdvService.getImageUploadHeaders();
        this.imgUploadEndpoint = this.pdvService.getImageUploadEndpoint(this.pdv.id_pdv);
        if (this.pdv.imgs) {
            this.loadingPdvImgs = this.pdv.imgs.map((img: any) => {return false});
        }
    }

    showModal(): void {
        this.errorRemoveImg = false;
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    setImgSelected = (img: string) => {
        if(this.authService.userCan('pdv.editar')){
            this.imgSelected = img
        }
    }

    setDate(img: string){
        var queryString = img.split('?')[1];
        var queryParams = new URLSearchParams(queryString);
        var dateString = '' + queryParams.get('date')?.replace(/-/g, '/');
        return dateString;
    }

    handleChange(info: NzUploadChangeParam) {
        console.log(info.file.status);

        if (info.file.status === 'uploading') {
            this.showUploadList = true;
        }

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (info.file.status === 'done') {
            this.pdvService.get(this.pdv.id_pdv)
                .subscribe((response: any) => {
                    this.pdv.imgs = response.data.imgs

                    if (this.imgSelected == null || this.imgSelected == "") {
                        this.imgSelected = this.pdv.imgs && this.pdv.imgs.length > 0 ? this.pdv.imgs[0] : null;
                    }
                });

            setTimeout(() => this.showUploadList = false);

            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.error(`${info.file.name} file upload failed.`);
        }
    }

    public removeImg(image: any, index: number): void {
        this.loadingPdvImgs[index] = true;
        this.errorRemoveImg = false;
        var queryString = image.split('?')[1];
        var queryParams = new URLSearchParams(queryString);
        var imageFullId = '' + queryParams.get('key')?.replace(/-/g, '/');
        var imgId = '';
        if (!imageFullId || imageFullId === 'undefined') {
            imgId = '' + queryParams.get('i')?.replace(/-/g, '/');
        } else {
            imgId = imageFullId.split('@')[1];
        }
        this.pdvService.deleteImgFromPDV(this.pdv.id_pdv, imgId)
            .subscribe({
                next: (response: any) => {
                    this.pdv.imgs.splice(index, 1);
                    this.loadingPdvImgs.splice(index, 1);
                    if (this.pdv.imgs?.length) {
                        if (this.imgSelected === image) {
                            this.imgSelected = this.pdv.imgs[0];
                        }
                    } else {
                        this.imgSelected = null;
                        this.handleCancel();
                    }
                },
                error: (error: any) => {
                    this.errorRemoveImg = true;
                    this.loadingPdvImgs[index] = false;
                },
                complete: () => {
                }
            });
    }
}
