import {Component, OnInit} from '@angular/core';
import {pageLimit} from "../../../../../shared/constantes";
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {catchError, lastValueFrom, of, switchMap, take, tap} from "rxjs";
import {isIterable} from "../../../../../core/functions";
import {ListsFiltersService} from "../../../../../services/lists.filters.service";
import {environment} from "../../../../../../environments/environment";
import {AccessService} from "../../../../../services/access.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-stock-articulos',
    templateUrl: './stock-articulos.component.html',
    styleUrls: ['./stock-articulos.component.scss']
})
export class StockArticulosComponent implements OnInit {

    public loading = false;
    public error: string | boolean = false;
    public uploadingStock = false;

    public paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filters: any = null;
    public table: any = null;
    public orderBy: any;

    constructor(
        private logistic: LogisticsService,
        private filterService: ListsFiltersService,
        private access: AccessService,
        private router: Router,
        private modalService: ModalService
    ) { }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/stock-articulos')
        this.setFilters()
        this.loadGroups()
    }

    setFilters() {
        this.filters = this.logistic.getStockArticulosFilter();
        this.paginator = this.logistic.getStockArticulosPaginator();

        if (this.filters == null) {
            this.filters = [
                {
                    type: 'text',
                    name: 'codeEan',
                    label: 'Código EAN',
                    value: null
                },
                {
                    type: 'text',
                    name: 'codeSap',
                    label: 'Código SAP',
                    value: null
                },
                {
                    type: 'select',
                    name: 'idArticle', // (idArticleGroup), esta mal escrito
                    label: 'Grupo',
                    options: [],
                    value: null
                },
                {
                    type: 'select',
                    name: 'available',
                    label: 'Estado',
                    options: [
                        {name: 'Todos', value: ""},
                        {name: 'Agotado', value: "0"},
                        {name: 'Disponible', value: "1"}
                    ],
                    value: "1"
                }
            ];
            this.logistic.setStockArticulosFilter(this.filters);
            this.logistic.setStockArticulosPaginator(this.paginator);
        }
    }

    loadGroups() {
        this.loading = true;
        this.logistic.getGroups()
        .subscribe({
            next: (data: any) => {
                this.error = false;
                if (data) {
                    const groups = data.data;
                    (this.filters[2].options as any).push({name: 'Todos', value: ''});
                    Object.keys(groups).forEach(key => {
                        (this.filters[2].options as any).push({name: groups[key].name, value: key});
                    })
                    this.load();
                }
            },
            error: (error: HttpErrorResponse) => {
                this.error = "ERROR";
                this.loading = false;
            },
            complete:() => {
            }
        });
    }


    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.logistic.getArticles(this.filterService.getPayload(this.paginator, this.filters))
            .subscribe({
                next: (data: any) => {
                    this.error = false;
                    if (data) {
                        this.prepareTable(data.data);
                        this.preparePaginator(data);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.prepareTable([]);
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                    this.loading = false;
                }
            });
    }

    stockCalls(data: any) {
        const calls: any = [];
        const ids: any = [];
        Object.keys(data).forEach((key) => {
            const item: any = data[key];
            if (!ids.includes(item.idArticle)) {
                calls.push(lastValueFrom(this.logistic.getArticleStocks({pag: 1, limit: 10000, filters: {idArticle: item.idArticle}})));
            }
            ids.push(item.idArticle);
        });
        return Promise.all(calls);
    }


    tableClicked(ev: any) {
        try {
            if (ev.event.target.classList.contains('stock-input')) {
                return;
            }
        } catch (e) {
        }

        if (ev.rowId) {
            this.router.navigate(['herramientas/' + 'Herramientas-Logística-Stock Artículos' + '/' + 'logis_articulo.itx?ac=r&logis_articulo_id_logis_articulo=' + ev.rowId]);
        }
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'Artículo', colspan: 2},
                            {value: 'SAP'},
                            {value: 'EAN'},
                            {value: 'Península'},
                            {value: 'Canarias'},
                            {value: 'Stock Península'},
                            {value: 'Stock Canarias'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            console.log(item);
            // const stock = this.getStocks(item.idArticle, stocks);
            table.body.rows.push({
                clickValue: item.idArticle,
                cells: [
                    {
                        value: '<img src="' + item.imgPath + '" style="width: 50px;">',
                        styles: {'border': '1px solid #EEEEEE'}
                    },
                    {
                        value: item.name,
                        styles: {'border-top': '1px solid #EEEEEE', 'border-bottom': '1px solid #EEEEEE'}
                    },
                    {
                        value: item.codeSap,
                        styles: {'border': '1px solid #EEEEEE'}
                    },
                    {
                        value: item.codeEan,
                        styles: {'border-top': '1px solid #EEEEEE', 'border-bottom': '1px solid #EEEEEE'}
                    },
                    {
                        value: '<strong>' + item.stocks["GC01"].unidades + '</strong><br><span style="font-size:10px">' + (item.stocks["GC01"].fecha ?? '') + '</span>&nbsp;',
                        styles: {'text-align': 'right', 'border': '1px solid #EEEEEE'}
                    },
                    {
                        value: '<strong>' + item.stocks["GC04"].unidades + '</strong><br><span style="font-size:10px">' + (item.stocks["GC04"].fecha ?? '') + '</span>&nbsp;',
                        styles: {'text-align': 'right', 'border-top': '1px solid #EEEEEE', 'border-bottom': '1px solid #EEEEEE'}
                    },
                    {
                        value: '<input data-article="' + item.idArticle + '" class="stock-input stock-es stock-es-' + item.idArticle + '" type="number" min="0" step="10" value="">',
                        styles: {'border': '1px solid #EEEEEE'}
                    },
                    {
                        value: '<input data-article="' + item.idArticle + '" class="stock-input stock-gc stock-gc-' + item.idArticle + '" type="number" min="0" step="10" value="">',
                        styles: {'border-top': '1px solid #EEEEEE', 'border-bottom': '1px solid #EEEEEE'}
                    },

                ]
            })
        });

        this.table = table;

    }

    getStocks(idArticle: any, stocks: any) {
        const stock: any = {
            gc: '', ///gran canarias
            es: '', // peninsula
            date_gc: '',
            date_es: '',
        };

        if (isIterable(stocks)) {
            stocks.forEach((resp: any) => {
                const results = resp.msg.data;
                Object.keys(results).forEach((key) => {
                    const res = results[key];
                    if (res.idLogisArticle === idArticle) {
                        if (res.warehouse === 'GC01') {
                            stock.es = res.units;
                            stock.date_es = res.date;
                        } else {
                            stock.gc = res.units;
                            stock.date_gc = res.date
                        }
                    }
                });
            });
        }
        return stock;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    getStateStyle(item: any) {
        switch (item.available) {
            case "0":
                return {'color': '#d600a7'};
            case "1":
                return {'color': '#009900'};
        }
        return {}
    }

    getStateName(item: any) {
        switch (item.available) {
            case "0":
                return 'Agotado';
            case "1":
                return 'Disponible'
        }
        return '';
    }

    pageChanged(paginator: any) {
        console.log(paginator)
        this.paginator = paginator;
        this.logistic.setStockArticulosPaginator(this.paginator);
        this.load();
    }

    filtersApplied(filters: any) {
        console.log(filters);
        this.filters = filters;
        this.paginator.currentPage = 1;
        this.logistic.setStockArticulosPaginator(this.paginator);
        this.logistic.setStockArticulosFilter(this.filters);
        this.load();
    }

    uploadFile(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.modalService.confirm('Se va a subir el fichero ' + file.name + '\n\n¿Desea continuar?')
            .pipe(
                take(1),
                switchMap(result => result ? this.logistic.uploadXlsStock(file) : of({action: 'cancel'})),
                catchError(err => {
                    let msg = 'Ha ocurrido un error';
                    if (err && err.error && err.error.message) {
                        msg += '\n\n' + err.error.message;
                    }
                    this.modalService.error('Error', msg);
                    return of({msg: 'ko'})
                }),
                tap((data:any) => {
                    if(data.action === 'cancel') return;
                    if(data.msg === true) {
                        window.location.reload();
                        return;
                    }
                    this.modalService.error('Error', 'Ha ocurrido un error');
                }))
            .subscribe();
        }
    }

    uploadEditedStocks() {
        this.uploadingStock = true;
        const inputs = document.querySelectorAll('.stock-input');
        const updates: any = [];
        inputs.forEach((input: any) => {
            if (input.value != '') {
                updates.push({
                    idArticle: input.dataset.article,
                    unidades: input.value,
                    almacen: input.className.includes('gc') ? 'GC04' : 'GC01'
                })
            }
        });
        const promises: any = [];
        updates.forEach((update: any) => {
            promises.push(lastValueFrom(this.logistic.updateArticleStock(update)));
        });
        Promise.all(promises).then(res => {
            window.location.reload();
        }).catch(err => {
            this.modalService.error('Ocurrió un error');
            this.uploadingStock = false;
        })
    }

}
