import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-modals-new-pedido-logistica',
    templateUrl: './modals-new-pedido-logistica.component.html',
    styleUrls: ['./modals-new-pedido-logistica.component.scss']
})
export class ModalsNewPedidoLogisticaComponent implements OnInit {

    @Input() mode = 'stock'; //stock, cancelOrder, confirmOrder
    @Output() action = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
    }

    actionSelected(action: any) {
        action.mode = this.mode;
        this.action.emit(action)
    }
}
