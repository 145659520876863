import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ReportsService} from "../../../../services/http/reports/reports.service";
import {pageLimit} from "../../../../shared/constantes";
import {ListsFiltersService} from "../../../../services/lists.filters.service";
import {environment} from "../../../../../environments/environment";
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from 'src/app/services/modal.service';
import { catchError, interval, map, Observable, of, Subscription, switchMap, take, takeWhile, tap } from 'rxjs';

@Component({
    selector: 'app-informes-solicitados',
    templateUrl: './informes-solicitados.component.html',
    styleUrls: ['./informes-solicitados.component.scss']
})
export class InformesSolicitadosComponent implements OnInit, OnDestroy {
    public loading: boolean = true;
    public error: string | boolean = false;
    public isColumn: boolean = false;
    public reportsList: any[] = [];
    @Input() initInformesSolicitados?: Observable<any>;
    private changeTabSubscription?: Subscription;
    private updateSubscription?: Subscription;
    public reportsGeneratePending: string[] = [];

    public paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public table: any = null;

    constructor(
        private reportsService: ReportsService,
        private filterService: ListsFiltersService,
        private modalService: ModalService
    ) { }

    onShow() {
        this.isColumn = !this.isColumn;
    }

    ngOnInit(): void {
        this.load();
        this.subscribeInformesSolicitados();
        if (this.initInformesSolicitados) {
            this.changeTabSubscription = this.initInformesSolicitados
                .subscribe((data) => {
                    if (data?.tab?.nzTitle === 'Informes solicitados') {
                        if (!this.loading) this.load();
                        this.subscribeInformesSolicitados();
                    } else {
                        this.unsubscribeInformesSolicitados();
                    }
                });
        }
    }

    public subscribeInformesSolicitados() {
        if (!this.updateSubscription || this.updateSubscription.closed) {
            this.updateSubscription = interval(30000)
                .subscribe((element: any) => {
                    this.load();
                });
        }
    }

    public unsubscribeInformesSolicitados() {
        if (this.updateSubscription) this.updateSubscription?.unsubscribe();
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.reportsService.listRequested(this.filterService.getPayload(this.paginator, []))
        .subscribe({
            next: (reports:any) => {
                this.error = false;
                if (reports.data) {
                    this.prepareTable(reports.data);
                    this.preparePaginator(reports);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.prepareTable([]);
                this.error = "ERROR";
                this.loading = false
            },
            complete:() => {
                this.loading = false;
            }
        });
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'ID solicitud'},
                            {value: 'ID informe'},
                            {value: 'Título'},
                            {value: 'Fecha solicitud'},
                            {value: 'Estado'},
                            {value: 'Fichero'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            table.body.rows.push({
                clickValue: item.id_solicitud_informe,
                cells: [
                    {value: item.id_solicitud_informe, styles: this.getRowStyle(item)},
                    {value: item.identificador_informe},
                    {value: item.title ? item.title : ''},
                    {value: item.fecha_solicitud},
                    {value: this.getStatusText(item)},
                    {value: this.getActions(item)}
                ]
            })
        });

        this.table = table;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    tableClicked(ev: any){
        if (ev.event.target.classList.contains('cancel-report')) {
            this.modalService.confirm('¿Seguro que deseas cancelar este informe?')
            .pipe(
                take(1),
                tap(result => {
                    if(result) {
                        const id_solicitud = ev.event.target!.dataset!.id;
                        this.loading = true;
                        this.reportsService.cancelReport(id_solicitud)
                            .subscribe(res => {})
                            .add(() => window.location.reload());
                            }
                })
            ).subscribe()

        }
        if (ev.event.target.classList.contains('generate-report')) {  
            const id_solicitud = ev.event.target!.dataset!.id;
            if(this.reportsGeneratePending.includes(id_solicitud)) {
                return;
            }
            this.modalService.confirm('¿Seguro que deseas generar este informe?')
            .pipe(
                take(1),
                tap(result => {
                    if (result) {
                        this.reportsGeneratePending.push(id_solicitud);
                        this.generateReport(id_solicitud);
                    }
                })
            ).subscribe();
        }
    }

    pageChanged(paginator: any) {
        this.paginator = paginator;
        this.load();
    }

    filtersApplied(filters: any) {
    }

    getActions(item: any) {
        let html = '';
        if (parseInt(item.finalizado) === 0) {
            html += '<img src="./assets/images/loader.svg" width="30" alt="">';
        }
        if (item.fichero) {
            item.fichero.split('|').forEach((link: string) => {
                if (link) {
                    html += '<a style="margin-left: 5px" href="' + environment.oldDigoFront + link + '">';
                    if (!link.includes('download.itx') && (link.includes('@') || link.includes('%40'))) {
                        html += '<img src="assets/images/legacy/icon_reload.png" title="Lanzar de nuevo" style="width: 25px"/></a>';
                    } else if (link.includes('.xlsx') || link.includes('=xlsx')) {
                        html += '<img src="assets/images/legacy/xlsx.png" title="Fichero Xlsx" style="width: 25px"/></a>';
                    } else {
                        html += '<img src="assets/images/legacy/csv.png" title="Fichero Csv" style="width: 25px"/></a>';
                    }
                }
            });
        }
        if (parseInt(item.finalizado) === 0) {
            html += '<img style="margin-left: 5px;" title="Cancelar informe" class="cancel-report" data-id="'+item.id_solicitud_informe + '" src="assets/images/legacy/icon_ko.png" />';
        }
        if ((parseInt(item.finalizado) === 0 && !environment.production)) {
            html += `<img style="margin-left: 5px;" title="Generar informe" class="generate-report ${this.reportsGeneratePending.includes(item.id_solicitud_informe) ? 'disabled': ''}" data-id="${item.id_solicitud_informe}" src="assets/images/legacy/icon_play.png" />`;
        }
        return html;
    }



    getStatusText(item: any) {
        const status = parseInt(item.finalizado);
        switch (status) {
            case 0:
                return 'En curso';
            case 1:
                return 'Finalizado';
            case 2:
            case 4:
                return 'Sin resultados'
            case 3:
                return 'Cancelado'
        }
    }

    getRowStyle(item: any) {
        const base = '5px solid ';
        const status = parseInt(item.finalizado);
        switch (status) {
            case 0:
                return {};
            case 1:
                return {'border-left': base + '#009900'}
            case 2:
            case 4:
                return {'border-left': base + '#42bff5'};
            case 3:
                return {'border-left': base + 'red'}
        }
        return {};
    }

    ngOnDestroy(): void {
        if (this.changeTabSubscription) this.changeTabSubscription.unsubscribe();
        this.unsubscribeInformesSolicitados();
    }

    generateReport(id_solicitud: string) {
        const elementGenerateReport = document.querySelector(`[data-id="${id_solicitud}"].generate-report`);
        elementGenerateReport?.classList.add('disabled');

        interval(5000).pipe(
            takeWhile(() => this.reportsGeneratePending.includes(id_solicitud))
            ).subscribe(() => {
                this.reportsService.generateReport(id_solicitud).pipe(
                    take(1),
                    map(() => of({error:false})),
                    catchError(error => of({error: error.status !== 200})),
                    tap((data:any) => {
                        if(data.error === false) {
                            let index = this.reportsGeneratePending.indexOf(id_solicitud);
                            if (index !== -1) {
                                this.reportsGeneratePending.splice(index, 1);
                            }
                            elementGenerateReport?.classList.remove('disabled');
                        }
                    })
                ).subscribe()
            });
    }
}
