import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {
  @Output() showModalEvent = new EventEmitter<boolean>();
  @Input() error_msg: string = '';
  constructor() { }

  ngOnInit(): void {
  }
  closeModal(): void {
    this.showModalEvent.emit(false);
  }

}
