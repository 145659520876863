<div class="card-section">
    <div class="header">
        <p class="font s-10 c-primary w-bold t-up">Mis Informes</p>
    </div>
    <div class="body">
        <div nz-row [nzGutter]="5">
            <div *ngFor="let report of reportsList" nz-col [nzSpan]="isColumn ? '8' : '24'">
                <div [className]="isColumn ? 'card-link-report inline-column' : 'card-link-report inline-row'">
                    <span class="font s-12 w-bold">{{report.name}}</span>
                    <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                </div>
            </div>
        </div>
    </div>
</div>
