<div nz-row [nzGutter]="16">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24"[nzLg]="6"[nzXl]="6"[nzXXl]="6">
        <div class="card-section">
            <div class="header">
                <span class="font s-10 c-primary w-bold t-up">Categorías</span>
            </div>
            <div class="body">
                <div class="section-input-search-clients">
                    <ul class="list-reports-checkboxes">
                        <li>
                            <input type="checkbox" id="tr1" />
                            <label for="tr1" class="content-li">
                                <i nz-icon nzType="menu" nzTheme="outline"></i>
                                <span class="font s-8">Todos</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr2" />
                            <label for="tr2" class="content-li">
                                <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
                                <span class="font s-8">Detalle de ventas</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr3" />
                            <label for="tr3" class="content-li">
                                <i nz-icon nzType="eye" nzTheme="outline"></i>
                                <span class="font s-8">Fraude</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr4" />
                            <label for="tr4" class="content-li">
                                <i nz-icon nzType="layout" nzTheme="outline"></i>
                                <span class="font s-8">Bases de datos</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr5" />
                            <label for="tr5" class="content-li">
                                <i nz-icon nzType="euro" nzTheme="outline"></i>
                                <span class="font s-8">Comisiones</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr6" />
                            <label for="tr6" class="content-li">
                                <i nz-icon nzType="mobile" nzTheme="outline"></i>
                                <span class="font s-8">Digoapp</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr7" />
                            <label for="tr7" class="content-li">
                                <i nz-icon nzType="appstore" nzTheme="outline"></i>
                                <span class="font s-8">Otros</span>
                            </label>
                        </li>
                        <li>
                            <input type="checkbox" id="tr8" />
                            <label for="tr8" class="content-li">
                                <i nz-icon nzType="line-chart" nzTheme="outline"></i>
                                <span class="font s-8">Reportes de actividad</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24"[nzLg]="18"[nzXl]="18"[nzXXl]="18">
        <div class="card-section">
            <div class="header flex-content f-between">
                <div>
                    <span class="font s-10 c-primary w-bold t-up">Elige el informe</span>
                    <i class="icon s-22 drop" nz-icon nzType="pic-center" (click)="onShow()" nzTheme="outline"></i>
                    <i class="icon s-22 drop" nz-icon nzType="menu" (click)="onShow()" nzTheme="outline"></i>
                </div>
                <div class="tag-info">
                    <i class="icon-tag-info" nz-icon nzType="info-circle" nzTheme="outline"></i>
                    <span class="font s-10 c-default">Posible variación de datos hace 4 horas</span>
                </div>
            </div>
            <div class="body">
                <div nz-row [nzGutter]="5">
                    <div *ngFor="let report of reportsList" nz-col [nzSpan]="isColumn ? '8' : '24'">
                        <div [className]="isColumn ? 'card-link-report inline-column' : 'card-link-report inline-row'">
                            <span class="font s-12 w-bold">{{report.name}}</span>
                            <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
