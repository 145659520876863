<div class="content-open" (click)="open()">
  <i nz-icon nzType="filter" nzTheme="fill"></i>
  <span>Filtrar</span>
</div>
<nz-drawer
  [nzClosable]="false"
  [nzMaskClosable]="false"
  [nzVisible]="visible"
  nzWidth="100%"
  nzPlacement="right"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <div class="header-drawer">
      <span class="font s-16 c-black">Filtrar listado <span class="font c-primary">Postpago</span></span>
      <i 
        class="icon-close" 
        nz-icon 
        nzType="close" 
        nzTheme="outline"
        (click)="close()"
      ></i>
    </div>
    <div class="wrapper-form-drawer">
      <app-prepaid-form></app-prepaid-form>
    </div>
  </ng-container>
</nz-drawer>