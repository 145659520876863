import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import {IcreatePdvBodyRequest} from 'src/app/pages/profiles/models/IcreatePdv';
import {IeditPdvBodyRequest} from 'src/app/pages/profiles/models/IeditPdv';
import {DatePipe} from "@angular/common";
import {TactionUserSimyo} from 'src/app/pages/profiles/components/profile-pdv-sheet/profile-pdv-sheet.component';
import {IListRequestBody, IListRequestBodyPoints, PdvOrder} from "../../../core/models/lists.models";
import { IorderListResponsePointsPdv, ItransitionListResponsePointsPdv } from 'src/app/pages/profiles/models/IpointsPdv';

@Injectable({
    providedIn: 'root'
})
export class PdvService extends ApiService {
    private page = 1
    private filters: any = {activated: 'true'}

    constructor(auth: AuthService, http: HttpClient) {
        super(auth, http);
    }

    list(page = 1, filters = null, order = PdvOrder.PDV_DENOMINACION, limit = 15) {
        let url = `${this.endpoint}pdv/list/filter`
        let headers = this.getHeaders()
        let params = {
            limit,
            page,
            order,
            filters
        }

        return this.http.post(url, params, {headers}).pipe(map((response: any) => response.msg))
    }

    get(pdvId: any) {
        let url = `${this.endpoint}pdv/${pdvId}`

        return this.http.get(url, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getActivations(pdvId: any, date: Date) {
        let datePipe = new DatePipe("es-419");
        let date_from = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        let date_to = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        let url = `${this.endpoint}pdv/${pdvId}/stats`
        let body = {
            date_from: datePipe.transform(date_from, "yyyy-MM-dd"),
            date_to: datePipe.transform(date_to, "yyyy-MM-dd")
        }

        return this.http.post(url, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    addNewUser(id_pdv: any, denomination: string, alias: string, email:string) {
        let url = `${this.endpoint}pdv/${id_pdv}/add/user`
        let body = {email, alias, denomination}

        return this.http.post(url, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getImageUploadEndpoint(pdvId: any): string {
        return `${this.endpoint}pdv/${pdvId}/image/upload`
    }

    setPage(page: number) {
        this.page = page
    }

    getPage(): number {
        return this.page
    }

    setFilters(filters: any) {
        this.filters = filters;
    }

    getFilters(): any {
        return this.filters;
    }

    public createPdv(companyId: any, body: IcreatePdvBodyRequest): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `company/${companyId}/pdv`, body, {headers: this.getHeaders()});
    }

    public getMasterPdv(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + 'pdv/add_form_filter/masters', {headers: this.getHeaders()});
    }

    public editPdv(pdvId: any, comapnyId: any, body: IeditPdvBodyRequest): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `company/${comapnyId}/pdv/${pdvId}`, body, {headers: this.getHeaders()});
    }

    public getDelegationPdv(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'pdv/form_filter/delegation', {filters: {id_distributor: distributor}}, {headers: this.getHeaders()});
    }

    public getMerchanPdv(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'pdv/form_filter/merchan', {filters: {id_distributor: distributor}}, {headers: this.getHeaders()});
    }

    public changeStateUserSimyoPdv(status: TactionUserSimyo, pdvId: any, empresaId: any) {
        if (status === 'subscribe') {
            return this.http.get<any>(environment.oldDigoFront + `pdv.itx?ac=altaSmy&pdv_id_pdv=${pdvId}&empresa_id_empresa=${empresaId}&jwt=${this.auth.getToken()}`);
        }
        if (status === 'unsubscribe') {
            return this.http.get<any>(environment.oldDigoFront + `pdv.itx?ac=bajaSmy&pdv_id_pdv=${pdvId}&empresa_id_empresa=${empresaId}&jwt=${this.auth.getToken()}`);
        }
        if (status === 'resubscribe') {
            return this.http.get<any>(environment.oldDigoFront + `pdv.itx?ac=reAltaSmy&pdv_id_pdv=${pdvId}&empresa_id_empresa=${empresaId}&jwt=${this.auth.getToken()}`);
        }
        return of(1, 2, 3);
    }

    public changeStateUserSimyoPdvXML(status: TactionUserSimyo, pdvId: any) {
        if (status === 'subscribe') {
            return this.http.get<any>(environment.oldDigoFront + `empresa_ext.itx?ac=simyo&id=${pdvId}&jwt=${this.auth.getToken()}`);
        }
        if (status === 'unsubscribe') {
            // return this.http.get<any>(environment.oldDigoFront + `pdv.itx?ac=bajaSmy&pdv_id_pdv=${pdvId}&empresa_id_empresa=${empresaId}&jwt=${this.auth.getToken()}`);
        }
        if (status === 'resubscribe') {
            // return this.http.get<any>(environment.oldDigoFront + `pdv.itx?ac=reAltaSmy&pdv_id_pdv=${pdvId}&empresa_id_empresa=${empresaId}&jwt=${this.auth.getToken()}`);
        }
        return of(1, 2, 3);
    }

    public deleteImgFromPDV(pdvId: any, imageId: any) {
        return this.http.get<any>(environment.digoApiEndpoint + `pdv/${pdvId}/image/${imageId}/delete`, {headers: this.getHeaders()});
    }

    public getImagesPdvRebranding(pdvId: any): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + `pdv/${pdvId}/rebranding/images`, {headers: this.getHeaders()});
    }

    public changeStatusPdvRebranding(pdvId: any, body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `pdv/${pdvId}/rebranding/status`, body, {headers: this.getHeaders()});
    }

    public ordersPointsPdv(pdvId: any, body: IListRequestBodyPoints): Observable<IorderListResponsePointsPdv> {
        if (pdvId !== null && pdvId !== undefined) {
            body['pdvs'] = [pdvId];
        }
        return this.http.post<IorderListResponsePointsPdv>(this.endpointComun + 'points/redeem/list', body, {headers: this.getHeaders()}).pipe(map((response: any) => response?.length && response[0]));
    }

    public transationsPointsPdv(pdvId: any, body: IListRequestBodyPoints): Observable<ItransitionListResponsePointsPdv> {
        if (pdvId !== null && pdvId !== undefined) {
            body['pdvs'] = [pdvId];
        }
        return this.http.post<ItransitionListResponsePointsPdv>(this.endpointComun + 'points/list', body, {headers: this.getHeaders()}).pipe(map((response: any) => response?.length && response[0]));
    }
}
