import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'euro'
})

export class EuroPipe implements PipeTransform  {

    transform(decimal: any) {
        return (parseFloat(decimal + '').toFixed(2) + ' €').replace('.', ',');
    }
}
