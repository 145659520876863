import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { allLocalStorage } from "../../constantes";
import { environment } from "../../../../environments/environment";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public getScreenWidth: any;
    isCollapsed = false;
    public username = '';
    public userInfo: any;

    onShow() {
        this.isCollapsed = !this.isCollapsed;
    }

    constructor(
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.getScreenWidth = window.innerWidth;
        this.username = localStorage.getItem(allLocalStorage.username) ? localStorage.getItem(allLocalStorage.username) + '' : '';

        this.auth.getUsername()
            .subscribe((name: string) => {
                if (name) {
                    this.username = name;
                }
            });

        this.auth.getUserInfoSuscription()
            .subscribe((userInfo: any) => {
                if (userInfo) {
                    this.userInfo = userInfo;
                }
            });
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.getScreenWidth = window.innerWidth;
        if (this.getScreenWidth > 768 && this.isCollapsed) {
            this.onShow();
        }
    }

    closeSession() {
        localStorage.clear();
        window.location.href = environment.oldDigoFront + '/signin.itx?ac=999';
    }

}
