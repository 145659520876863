import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isIterable} from "../../../../../core/functions";
import {AccessService} from "../../../../../services/access.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-detalle-pedido-logistica',
    templateUrl: './detalle-pedido-logistica.component.html',
    styleUrls: ['./detalle-pedido-logistica.component.scss']
})
export class DetallePedidoLogisticaComponent implements OnInit {
    @Output() close = new EventEmitter();
    
    public order: any = null;
    public orderId: any = '';
    public loading = true;
    public error = '';
    public showModal: boolean = false;

    constructor(
        private logistic: LogisticsService,
        private router: Router,
        private route: ActivatedRoute,
        private access: AccessService,
        public auth: AuthService
    ) { }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/pedidos')
        this.route.params.subscribe( {
            next: (params: any) => {
                this.orderId = params.id;
                this.load(params.id);
            }
        });
    }

    load(id: number) {
        this.logistic.getOrder(id).subscribe((data: any) => {
            if (data) {
                this.order = data;
            } else {
                this.error = 'No ha sido posible cargar este pedido';
            }

        }, err => {
            this.error = 'No ha sido posible cargar este pedido';
        }).add(() => this.loading = false);
    }

    getTotal(articles: any) {
        let total = 0.0;
        if (isIterable(articles)) {
            articles.forEach((article: any) => {
                total += article.units * article.importe;
            });
        }
        return total;
    }

    cancelOrder(showModal: boolean) {
        this.showModal = showModal;
    }

    canceled(cancel: boolean) {
        if (cancel) 
        this.load(this.orderId);    
    }
}
