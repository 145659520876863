import {Component, Input, OnInit} from '@angular/core';
import {CompanyService} from "../../../services/http/company/company.service";
import { catchError, finalize, of, take, tap } from 'rxjs';

@Component({
    selector: 'app-modal-confirm-delete',
    templateUrl: './modal-confirm-delete.component.html',
    styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {
    @Input() user: any

    isVisible = false;
    isLoading = false
    showMsgError = false;
    msgError = '';
    constructor(private companyService: CompanyService) {
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    ngOnInit(): void {
    }

    changeStatus = () => {
        this.isLoading = true
        this.companyService.setActionUser(this.user.id_user, 'cambiar_estado')
        .pipe(
            take(1),
            tap(response => {
                this.user.status = !this.user.status
                this.handleOk()
            }),
            catchError(err => {
                this.showMsgError = true;
                this.msgError = err.error?.error?.msg_complete ?? 'Ha ocurrido un error al realizar el cambio de estado.';
                return of({});
            }),
            finalize(() => this.isLoading = false)
        )
        .subscribe()
    }

}
