import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, of, tap} from 'rxjs';
import {AuthService} from '../../auth.service';
import {ApiService} from '../api.service';
import {environment} from "../../../../environments/environment";
import { MenuService } from '../../menu.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ApiService {

    constructor(
        auth: AuthService,
        http: HttpClient,
        public menuService: MenuService
    ) {
        super(auth, http);
    }

    userInfo() {
        if (this.auth.checkIfTokenHasExpired()) {
            console.log('token expirado')
            setTimeout(() => {
                this.auth.check();
            }, 5000);
            return of({});
        }
        if(this.auth.getUserInfo() !== undefined) {
            return this.auth.getUserInfoSuscription();
        }
        return this.http.get<any>(environment.digoApiEndpoint + 'user/info' + (environment.fakeLogin ? '?dev' : ''), {headers: this.getHeaders()})
        .pipe(
            map((response: any) => response.msg),
            tap(user => {
                this.auth.setUserInfo(user);
                const menus = user.menus;
                delete user.menus;
                if (!menus || menus.length === 0) {
                    this.auth.closeSession();
                }
                this.menuService.setMenu(menus);
            })
        );
    }
}
