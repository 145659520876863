<div>
    <app-header></app-header>

    <app-loader [text]="'Comprobando acceso...'" *ngIf="loading"></app-loader>
    <ng-container *ngIf="!loading">
        <p *ngIf="error"style="padding-top: 150px; padding-left: 50px">{{ error }}</p>
        <ng-container *ngIf="!error">
            <app-nav-menu [menus]="menus"></app-nav-menu>
            <router-outlet></router-outlet>
        </ng-container>
    </ng-container>
</div>

<nz-modal
    [(nzVisible)]="showModalCloseSession"
    [nzFooter]="null"
    [nzWidth]="594"
    [nzClosable]="false"
    [nzCentered]="true"
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="wrapper-modal">
            <div class="header-modal">
                <div class="flex-content a-center a-base">
                    <span class="font s-22 w-bold a-center margins m-r-10">Sesión caducada</span>
                </div>
            </div>
            <div class="body-modal">
                <div class="flex-content margins m-b-20">
                    Para poder usar la aplicación con normalidad, pulse el botón "Cerrar sesión" y vuelva a entrar.
                </div>
                <div class="flex-content a-center">
                    <button nz-button
                        class="c-dark"
                        (click)="auth.closeSession()">
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>
