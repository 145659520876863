<div class="container-max">
    <app-breadcrumbs
        [title]="'Logística Pedido <span class=\'font c-primary\'> ID: ' +orderId+ '</span>'"
        [breacrumbList]="['Herramientas', 'Logística', 'Pedido ' + orderId]"
        [showButtonBack]="true">
    </app-breadcrumbs>
    <div class="wrapper-view-edit-list">
        <div class="inner-content-max">
            <!-- <button routerLink="/herramientas/logistica/pedidos" nz-button class="size-xxs c-primary"  style="margin-bottom: 20px" title="Volver">
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </button> -->
            <app-loader [text]="'Obteniendo datos...'" *ngIf="loading"></app-loader>
            <div nz-row nzGutter="16" >

                <p *ngIf="error">{{ error }}</p>
                <ng-container *ngIf="!loading && !error">

                    <!--<app-details-lateral-menu-->
                            <!--[links]="[{name:'Datos', route: '/logistica/pedidos/' + orderId}]"-->
                            <!--[back_url]="'/logistica/pedidos'"-->
                    <!--&gt;-->
                    <!--</app-details-lateral-menu>-->
                    <div nz-col nzXXl="24" nzXl="24" nzLg="24" nzMd="24" nzXs="24" nzSm="24" >
                        <h3>SFID: {{ order.sfid }} {{ auth.userCan('logis_pedido.ver_distribuidor') ? (' - ' + order.distributor) : '' }}</h3>


                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up title-head">Datos del pedido</span>
                            <!--<span class="tag success">Nº de Productos</span>-->
                        </div>
                        <div class="digo-card form-card">
                            <!--<p class="font s-13 c-gray t-up">DIRECCIÓN PRINCIPAL</p>-->
                            <div class="flex-content f-between">
                                <div>
                                    <label class="font s-13 c-gray t-up">Nº pedido propio</label>
                                    <p>{{ order.codeOrder ?? '-' }}</p>
                                </div>
                                <div>
                                    <label class="font s-13 c-gray t-up">Nº pedido digo</label>
                                    <p>{{ order.digoOrder ?? '-' }}</p>
                                </div>
                                <div>
                                    <label class="font s-13 c-gray t-up">Fecha del pedido</label>
                                    <p>{{ order.dateOrder }}</p>
                                </div>
                                <div>
                                    <label class="font s-13 c-gray t-up">Estado del pedido</label>
                                    <p>{{ order.state }}</p>
                                </div>
                            </div>
                            <br>

                            <div class="flex-content f-between" >
                                <div>
                                    <label class="font s-13 c-gray t-up">Contacto Entrega</label>
                                    <p>{{ order.company}}</p>
                                    <p>A/A: {{ order.contact ?? '' }}</p>
                                </div>
                                <div>
                                    <label class="font s-13 c-gray t-up">Direccion Entrega</label>
                                    <p>{{ order.address }}</p>
                                    <p>{{ order.zip}} - {{ order.town}}</p>
                                </div>
                            </div>

                        </div>

                        <div class="bar-head" >
                            <span class="font s-14 w-bold c-gray t-up">Líneas del pedido</span>
                        </div>
                        <div class="digo-card form-card">
                            <table style="width: 100%">
                                <tr>
                                    <th align="left" colspan="2"><label class="font s-13 c-gray t-up">Artículo/Referencia</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Precio</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Cantidad</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Importe</label></th>
                                </tr>
                                <tr  *ngFor="let article of order.articles">
                                    <td align="left" style="width:120px">
                                        <img src="{{ article.imgPath ?? '' }}" style="max-width: 80px"/>
                                    </td>
                                    <td align="left">
                                        <p style="margin-bottom:0px">{{ article.nombreArticulo ?? '' }}
                                            <br>
                                            <small class="font c-gray">{{ article.codeSap ?? '' }}</small>
                                        </p>
                                    </td>
                                    <td align="left">
                                        <p>{{ article.importe ?? '' | euro }}</p>
                                    </td>
                                    <td  align="left">
                                        <p>{{ article.units }}</p>
                                    </td>
                                    <td  align="left">
                                        <p>{{ article.units * article.importe | euro }}</p>
                                    </td>
                                </tr>
                            </table>
                            <!--<p class="font s-13 c-gray t-up">DIRECCIÓN PRINCIPAL</p>-->


                        </div>

                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up">Total pedido</span>

                        </div>
                        <div class="digo-card form-card invisible">
                            <table style="width: 100%">
                                <tr class="header-invisible">
                                    <th align="left" colspan="2"><label class="font s-13 c-gray t-up">Artículo/Referencia</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Precio</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Cantidad</label></th>
                                    <th align="left"><label class="font s-13 c-gray t-up">Importe</label></th>
                                </tr>
                                <tr  *ngFor="let article of order.articles; let first = first;" [ngClass]="first ? 'first' : 'not-first'">
                                    <td align="left" style="width:120px">
                                        <img src="{{ article.imgPath ?? '' }}" style="max-width: 80px"/>
                                    </td>
                                    <td align="left">
                                        <p style="margin-bottom:0px">{{ article.nombreArticulo ?? '' }}
                                            <br>
                                            <small class="font c-gray">{{ article.codeSap ?? '' }}</small>
                                        </p>
                                    </td>
                                    <td align="left">
                                        <p>{{ article.importe ?? '' | euro }}</p>
                                    </td>
                                    <td  align="left">
                                        <p>{{ article.units }}</p>
                                    </td>
                                    <td class="total" align="left">
                                        <p>{{ getTotal(order.articles) | euro }}</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div nz-row nzJustify="end" *ngIf="order && auth.userCan('logis_pedido.cancelar_pedido') && order.cancelMethod=='cancel'">
                            <div nz-col>
                                <button nz-button class="c-dark size-large" (click)=" showModal = true;">Cancelar Pedido</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-modal-cancel-order *ngIf="showModal" (showModalEvent)="cancelOrder($event)" (canceledEvent)="canceled($event)" [order]="order"></app-modal-cancel-order>