<div class="form-group" [ngClass]="{'form-group-margin': screenName == 'ext-pdv'}">
    <input [id]="id"
           [name]="controlName"
           [type]="type"
           [formControl]="control"
           [readonly]="readonly"
           [maxlength]="maxlength"
           [ngClass]="{'has-value': control.value}">
    <label [for]="controlName" class="control-label flex-content f-between">
        <div>
            <span class=""><b>{{title}}</b></span>
        </div>
    </label>
    <i class="bar" *ngIf="!readonly"></i>

    <!-- Error Handling -->
    <span class="error-form" *ngIf="control.touched && control.errors">
        <ng-container *ngIf="control.errors['required']">Campo obligatorio</ng-container>
        <ng-container *ngIf="control.errors['blankSpace'] && !control.errors['required']">El campo no puede contener solo espacios en blanco</ng-container>
        <ng-container *ngIf="control.errors['email']">Formato incorrecto</ng-container>
        <ng-container *ngIf="control.errors['phone']">Formato incorrecto</ng-container>
        <ng-container *ngIf="control.errors['mustMatch']">El email no coincide</ng-container>
        <ng-container *ngIf="control.errors['doc'] && !control.errors['required']">Número de documento erróneo</ng-container>

    </span>
</div>
