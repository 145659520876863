import { Component, OnInit } from '@angular/core';
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {AuthService} from "../../../../../services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {isIterable} from "../../../../../core/functions";
import {Router} from "@angular/router";
import {AccessService} from "../../../../../services/access.service";

@Component({
    selector: 'app-new-pedido-logistica',
    templateUrl: './new-pedido-logistica.component.html',
    styleUrls: ['./new-pedido-logistica.component.scss']
})
export class NewPedidoLogisticaComponent implements OnInit {
    public loading = true;
    public loadingArticles = false;
    public distributor: any = null;
    public sfidList: any[] = [];
    public selectedSfid: string = '';

    public addressList: any[] = [];
    public articlesList: any = null;

    public error = '';
    public form: FormGroup|null = null;

    public currentWharehouse: any = null;
    public showModal:boolean = false;
    public modalMode:string = '';

    private interval: any = null;
    private pressed: boolean = false;

    constructor(
        private logistic: LogisticsService,
        private auth: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private access: AccessService
    ) { }

    ngOnInit(): void {
        if (!this.auth.userCan('logistic_add_order')) {
            this.router.navigate(['/herramientas/logistica/pedidos']);
        } else {
            this.loading = true;
            this.auth.check()
    
            this.logistic.createOrderFormData()
                .subscribe({
                    next: (data) => {
                        if (data && data.msg && data.msg.length > 0) {
                            this.distributor = data.msg[0];
                            if (isIterable(this.distributor.array_sfids)) {
                                this.distributor.array_sfids.forEach((sfid: any) => {
                                    this.sfidList.push(sfid);
                                });
                                this.prepareForm();
                            } else {
                                this.error = 'No se han encontrado SFIDs asociados a tu cuenta';
                            }
        
                        } else {
                            this.error = 'Solo los distribuidores pueden crear pedidos.';
                        }
                    },
                    error: (err) => {
        
                    }
                })
                .add(() => this.loading = false);
        }
    }

    prepareForm(): void {
        this.form = this.fb.group({
            sfid: ['', Validators.required],
            code: [''],
            addressType: ['known'],
            address_id: [-1],
            empresa: [null],
            contacto: [null],
            cp: [null, Validators.pattern("0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}")],
            localidad: [null],
            direccion: [null],
            add_country: ['España'],
        });

        this.form.valueChanges.subscribe(data => {
            if (this.validAddress()) {
                if (this.currentWharehouse !== this.getWharehouse()) {
                    this.articlesList = null;
                    this.loadArticles()
                }

            }
        })
    }

    setAddress(address: any) {
        this.form?.get('empresa')?.patchValue(address ? address.empresa : null);
        this.form?.get('contacto')?.patchValue(address ? address.contacto : null);
        this.form?.get('cp')?.patchValue(address ? address.cp : null);
        this.form?.get('localidad')?.patchValue(address ? address.localidad : null);
        this.form?.get('direccion')?.patchValue(address ? address.direccion : null);
    }

    validAddress() {
        const valid = this.form?.get('empresa')?.value !== null &&
            this.form?.get('contacto')?.value !== null &&
            this.form?.get('cp')?.value !== null &&
            this.form?.get('cp')?.value.toString().length >= 5 &&
            this.form?.get('localidad')?.value !== null &&
            this.form?.get('direccion')?.value !== null;

        return valid;
    }

    setAddressMode(type: string) {
        if (type === 'custom') {
            this.setAddress(null);
        }
    }

    getAddressLabel(idx: number) {
        const address = this.addressList[idx]
        return address.empresa + ': ' + address.localidad + ' - ' + address.direccion + ' (' + address.cp + ') - A/A ' + address.contacto;
    }

    setSelectedSfid(e: any) {
        this.selectedSfid = e;
        const sfidObj = this.distributor.array_sfids.filter((sfid: any) => sfid.sfid === this.selectedSfid)[0];
        if (isIterable(sfidObj.direcciones)) {
            this.addressList = sfidObj.direcciones;
        }
    }

    loadArticles() {
        this.currentWharehouse = this.getWharehouse();
        if (!this.loadingArticles && this.articlesList === null) {
            this.articlesList = [];
            this.loadingArticles = true;
            const filters = {
                "limit": 1000,
                "page": 1,
                "filters": {
                    "activeNow": true,
                    "idDistributorNotBlocked": this.distributor.id_distribuidor
                }
            }
            this.logistic.getArticles(filters)
                .subscribe({
                    next: (data) => {
                        this.articlesList = data.data;
                        this.articlesList.forEach((article: any) => {
                            article.qty = 0;
                        })
                    },
                    error: (err) => {
                        this.error = 'Error cargando artículos'
                    }
                })
                .add(() => this.loadingArticles = false)
        }
    }

    setAddressIdx(idx: any) {
        this.setAddress(idx == -1 ? null : this.addressList[idx])
        if (this.validAddress()) {
            this.articlesList = null;
            this.loadArticles()
        }
    }

    getWharehouse() {
        const cp_provincia_code =  (this.form?.get('cp')?.value + '').substring(0, 2);
        if (['35', '38'].includes(cp_provincia_code)) {
            return 'GC04';
        }
        return 'GC01';
    }

    articleStock(article: any) {
        if (typeof article.stocks[this.currentWharehouse] !== 'undefined') {
            return article.stocks[this.currentWharehouse];
        }
        return null;
    }
    setArticleQty(idx:number, qty: string){
        if (this.showModal || qty === null || qty === '') {
            return;
        }
        const article = this.articlesList[idx];
        const stock = this.articleStock(article);
        article.qty = Number(qty);
        if (article.qty < 0) {
            article.qty = 0;
        }
        if (article.qty > Number(stock.unidades)) {
            article.qty = Number(stock.unidades);
            this.modalMode = 'stock';
            this.showModal = true;
        }

        this.articlesList[idx] = article;
    }
    sumArticleQty(idx: number, qty: number) {
        if (this.showModal) {
            return;
        }
        const article = this.articlesList[idx];
        const currentQty = article.qty;
        const stock = this.articleStock(article);
        article.qty += qty;
        if (article.qty < 0) {
            this.stopAdditionInterval();
            article.qty = 0;
        }
        if (article.qty > stock.unidades) {
            article.qty = currentQty;
            this.stopAdditionInterval();
            this.modalMode = 'stock';
            this.showModal = true;
        }
        this.articlesList[idx] = article;
    }

    resetAll() {
        this.currentWharehouse = null;
        this.articlesList = null;
        this.showModal = false;
        this.selectedSfid = ''
        this.form = this.fb.group({
            sfid: ['', Validators.required],
            code: [''],
            addressType: ['known'],
            address_id: [-1],
            empresa: [null],
            contacto: [null],
            cp: [null],
            localidad: [null],
            direccion: [null],
            add_country: ['España'],
        });
    }

    getTotalItems() {
        let total = 0;
        if (isIterable(this.articlesList)) {
            this.articlesList.forEach((article: any) => {
                total += article.qty;
            })
        }
        return total;
    }

    getTotal() {
        let total = 0;
        if (isIterable(this.articlesList)) {
            this.articlesList.forEach((article: any) => {
                const val = article.qty * article.price;
                if (!isNaN(val)) {
                    total += val;
                }
            })
        }
        return total;
    }

    formSubmit(e: any) {

    }

    modalEventOcurred(event: any) {
        this.showModal = false;
        this.modalMode = '';
        switch (event.mode) {
            case 'cancelOrder':
                if (event.accepted) {
                    this.resetAll();
                }
                break;
            case 'confirmOrder':
                if (event.accepted) {
                    this.save();
                }
                break;
        }
    }

    save() {
        this.loading = true;
        const sendData: any = this.form?.getRawValue();
        sendData.id_distributor = this.distributor.id_distribuidor
        sendData.orderLines = {};
        if (isIterable(this.articlesList)) {
            this.articlesList.forEach((article: any) => {
                if (article.qty) {
                    sendData.orderLines['idArticle' + article.idArticle] = article;
                }
            })
        }
        this.logistic.createOrder(sendData)
            .subscribe({
                next: (data) => {
                    this.router.navigate(['/herramientas/logistica/pedidos']);
                },
                error: (err) => {
                    this.error = 'Ocurrió un error al crear el pedido';
                }
            })
            .add(() => {
                this.loading = false;
            });
    }

    startAdditionInterval(idx: number, qty: number) {
        this.sumArticleQty(idx, qty);
        this.pressed = true;
        setTimeout(() => {
            if (this.pressed && !this.interval) {
                this.interval = setInterval(() => {
                    this.sumArticleQty(idx, qty);
                }, 50);
            }
        }, 500);
    }

    stopAdditionInterval() {
        setTimeout(() => {
            this.pressed = false;
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.interval = null;
        }, 10);
    }

}
