import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PdvOrder} from "../../../core/models/lists.models";

@Component({
    selector: 'app-pdv-table',
    templateUrl: './pdv-table.component.html',
    styleUrls: ['./pdv-table.component.scss']
})
export class PdvTableComponent implements OnInit {
    @Input() pdvs_data: any = null;
    @Input() pdvs_loading: boolean = true;
    @Input() setCurrentPage = (page: number) => {};
    @Output() setOrder = new EventEmitter<PdvOrder>();

    PdvOrder = PdvOrder
    order = PdvOrder.PDV_DENOMINACION

    constructor() {
    }

    ngOnInit(): void {
    }

    getClassification(pdv: any): string {
        switch (pdv.clasificacion) {
            case "TOP":
                return "T"
            case "VALOR":
                return "V"
            case "PREPAGO TOP":
                return "X"
            case "PREPAGO COLABORADOR":
                return "C"
            case "AAEE":
                return "A"
            case "PREPAGO":
                return "R"
            case "PREPAGO MEDIUM":
                return "M"
            case "EXPERTO":
                return "E"
            case "BASICO":
                return "B"
            case "PREMIUM":
                return "P"
            default:
                return ""
        }
    }

    setPdvOrder(order: PdvOrder) {
        this.order = order
        this.setOrder.emit(this.order)
    }
}
