export const allLocalStorage = {
    accessToken: 'access_token',
    username: 'username',
    view: 'vista'
}

export const pageLimit = 20;

// Simyo
export const superStatus = {
    cancelled: 'cancelled',
    in_process: 'in_process',
    active: 'active',
    error: 'error',
    draft: 'draft',
    rejected: 'rejected',
    normal: 'normal'
}

export const superStatusTranslate: { [key: string]: string } = {
    cancelled: 'Cancelado',
    in_process: 'En progreso',
    active: 'Activado',
    error: 'Error de procesamiento',
    draft: 'Borrador',
    rejected: 'Rechazado'
}

export const simyoDocStatus = {
    ok: 'Correcta',
    notSend: 'Sin enviar',
    ko: 'Documentación KO',
    pte: 'Pdte. revisión'
}

export const daysOfWeek = [
    { value:"7", label: "Lunes a Viernes" },
    { value:"1", label: "Lunes" },
    { value:"2", label: "Martes" },
    { value:"3", label: "Miércoles" },
    { value:"4", label: "Jueves" },
    { value:"5", label: "Viernes" },
    { value:"6", label: "Sábado" },
    { value:"0", label: "Domingo" },
]