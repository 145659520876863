import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {environment} from 'src/environments/environment';
import {AccessService} from "../../../services/access.service";
import { detectIncognito } from "detect-incognito";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-load-iframe',
    templateUrl: './load-iframe.component.html',
    styleUrls: ['./load-iframe.component.scss']
})
export class LoadIframeComponent implements OnInit, AfterViewInit, OnDestroy {

    public page: any;
    public iframeSrc: string = '';
    public hiddenIframe: boolean = true;
    public afterviewInit: boolean = false;
    public isIncognitoMode: boolean = false;
    private iframeSubscribe!: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService,
        private access: AccessService
    ) { }

    ngOnInit(): void {
        //this.access.checkAccess(this.activatedRoute.snapshot.paramMap.get('location') + '')
        this.hiddenIframe = true;
        this.iframeSrc = this.generateUrl();
        this.page = this.activatedRoute.snapshot.paramMap.get('label')?.split('-');


        this.iframeSubscribe = this.router.events
            .subscribe((val:any) => {
                this.hiddenIframe = true;
                this.iframeSrc = '';
                if (val.routerEvent instanceof NavigationEnd && this.activatedRoute.snapshot.paramMap.get('location') && this.activatedRoute.snapshot.paramMap.get('label')) {
                    this.iframeSrc = this.generateUrl();
                    console.log('iframeSrc 4', this.iframeSrc);
                    this.page = this.activatedRoute.snapshot.paramMap.get('label')?.split('-');
                }
            });

        this.auth.getResetIframeState()
            .subscribe((state: any) => {
                if (state) {
                    this.hiddenIframe = true;
                    this.iframeSrc = '';
                }
            })

        detectIncognito().then((result) => {
            this.isIncognitoMode = result.isPrivate;
        });
    }

    ngAfterViewInit(): void {
        this.afterviewInit = true;
    }

    private generateUrl(): string {
        return environment.oldDigoFront + 
            this.activatedRoute.snapshot.paramMap.get('location') + 
            ((this.activatedRoute.snapshot.paramMap.get('location') && this.activatedRoute.snapshot.paramMap.get('location') !== '' && this.activatedRoute.snapshot.paramMap.get('location')?.includes('?')) ? '&' : '?') + 
            'jwt=' + this.auth.getToken();
    }

    public onloadIframe(event: any, iframe: any) {
        if (this.afterviewInit) {
            this.hiddenIframe = false;
        }
    }

    ngOnDestroy(): void {
        if (this.iframeSubscribe) this.iframeSubscribe.unsubscribe();
    }

}
