<div class="inner-content-max {{customStyle}}" >
    <app-loader [text]="'Obteniendo datos...'" *ngIf="loading"></app-loader>

    <div nz-row nzGutter="24" *ngIf="!loading">
        <div nz-col nzXXl="{{ 24 - tableSize }}" nzXl="{{ 24 - tableSize }}" nzLg="{{ 24 - tableSize }}" nzMd="24" nzXs="24" nzSm="24" *ngIf="filters && filters.length" style="padding-bottom: 24px;">
            <div class="wrapper-card-form">
                <div *ngIf="getScreenWidth < 992" class="flex-content f-between">
                    <span class="font s-10 c-primary w-bold t-up">Filtros</span>
                    <button (click)="isCollapsed = !isCollapsed" nz-button class="c-primary">
                        {{ isCollapsed ? 'Mostrar filtros' : 'Ocultar filtros' }}
                    </button>
                </div>
                <div class="digo-card form-card filters-card" [hidden]="isCollapsed">
                    <app-filters
                        [filters]="filters"
                        (filtersAplied)="filtersSubmited($event)"
                        (filterChanged)="filterChanged.emit($event)">
                    </app-filters>
                </div>
            </div>
        </div>

        <div nz-col nzXXl="{{ tableSize }}" nzXl="{{ tableSize }}" nzLg="{{ tableSize }}" nzMd="24" nzXs="24" nzSm="24">
            <div *ngIf="!error">
                <app-paginator
                    [loading]="loading"
                    [paginator]="paginator"
                    [updatePage]="updatePage"
                    [addElement]="addElement"
                    [type]="type"
                    (pageChanged)="pageChanged.emit($event)"
                    (updatePageClicked)="updatePageClicked.emit($event)"
                    (addClicked)="addClicked.emit()">
                </app-paginator>
                <app-table
                    *ngIf="!loading"
                    [table]="table"
                    [orderBy]="orderBy"
                    [noResultsText]="noResultsText"
                    (clickEvent)="tableClick.emit($event)">
                </app-table>
            </div>
            <div *ngIf="error" class="error-msg">
                Los sentimos, ha ocurrido un error y no podemos mostrar los datos.
                <br>
                Por favor, inténtelo de nuevo más tarde.
            </div>
        </div>
    </div>
</div>
