<div class="wrapper-list" style="margin-bottom: 10px">
    <div class="wrapper-card-contracted" style="border: 1px solid lightgrey;" [ngClass]="{'is-collapse-padding': product.showMoreInfo }" *ngFor="let product of products">
        <div nz-row nzAlign="bottom">
            <ng-container *ngFor="let element of product.elements; let i = index">
                <div nz-col [nzSpan]="product.elements && product.elements.length > 4 && i === 3 ? '5' : '6'" *ngIf="product.showMoreInfo || i <= 3">
                    <div class="content-card" *ngIf="!element.type && element.label && element.label !== ''">
                        <span class="font s-13 c-gray t-up">{{ element.label }}</span>
                        <p>{{ element.value && element.value !== '' ? element.value : '-' }}</p>
                    </div>
                    <div class="content-card" *ngIf="element.type === 'status'">
                        <span class="font s-13 c-gray t-up">{{ element.label }}</span>
                        <p *ngIf="element.showProductStatusWithText">{{ element.value }}</p>
                        <app-status-order 
                            *ngIf="!element.showProductStatusWithText" 
                            [statusName]="element.name" 
                            [brand]="element.brand" 
                            [status]="element.value" 
                            [title]="element.title" 
                            [statusDescription]="element.moreInfo"
                            [class]="'product-status'">
                        </app-status-order>
                    </div>
                    <div class="content-card" *ngIf="element.type === 'terminal'">
                        <span class="font s-13 c-gray t-up">{{ element.label }}</span>
                        <p>{{ element.value && element.value !== '' ? 'Si' : 'No' }} <span class="font c-primary" *ngIf="element.value && element.value !== ''">({{ element.value }})</span></p>
                    </div>
                    <div class="content-card" style="padding-bottom: 15px; display: inline-block;" *ngIf="element.type === 'esim' && element.value != null">
                        <button nz-button
                            class="c-dark"
                            (click)="openIframe(element.value)">
                            Mostrar datos eSIM
                        </button>
                    </div>
                </div>
                <div nz-col nzSpan="1" *ngIf="product.elements && product.elements.length > 4 && i === 3" class="cursor-pointer" (click)="product.showMoreInfo = !product.showMoreInfo">
                    <div class="content-arrow flex-content a-center">
                        <label nz-icon nzType="right"
                            nzTheme="outline"
                            class="cursor-pointer"
                            [ngClass]="product.showMoreInfo === true ? 'arrow-active' : 'arrow-no-active'">
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<nz-modal
    [(nzVisible)]="showModal"
    [nzFooter]="null"
    (nzOnCancel)="showModal = false"
    nzWidth="750px"
    nzCentered>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal" style="padding-top: 25px;">
            <iframe width="100%" height="300" [src]="iframeUrl | trustUrl" style="border: none"></iframe>
        </div>
    </ng-container>
</nz-modal>
