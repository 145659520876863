<div class="wrapper-inner-modal">
    <span class="font s-10 c-gray">Estás guardando</span>
    <p class="font s-16 c-black">Solicitudes de activación por punto de venta (002)</p>
    <p class="font s-10 c-black">Para terminar el proceso, introduce una descripción para el informe guardado</p>
    <p class="font s-8 c-gray">Alias del informe</p>
    <div class="form-group">
        <input type="text" oninvalid="" required="">
        <label for="input" class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>_</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
    <div class="footer-inner-modal">
        <div class="form-group form-group-footer">
            <div class="flex-content a-center">
                <button nz-button class="c-primary size-large" nzBlock>Guardar</button>
            </div>
         </div>
    </div>  
</div>
