import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { AuthService } from "../../auth.service";
import { HttpClient} from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IListRequestBody } from "src/app/core/models/lists.models";
import { Observable } from "rxjs";
import { IorderListResponseBodyJazztel } from "src/app/pages/jazztel/models/IorderListJazztel";
import { IorderDetailResponseBodyJazztel } from "src/app/pages/jazztel/models/IorderDetailJazztel";
import { pageLimit } from "src/app/shared/constantes";

@Injectable({
    providedIn: 'root'
})
export class JazztelService extends ApiService {
    private filters: any = null;
    private orderBy: any = null;
    private pagintor: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    public setFilters(filters: any) {
        this.filters = filters;
    }

    public getFilters(): any {
        return this.filters;
    }

    public setPaginator(pagintor: any) {
        this.pagintor = pagintor;
    }

    public getPaginator(): any {
        return this.pagintor;
    }

    public setOrderBy(orderBy: any) {
        this.orderBy = orderBy;
    }

    public getOrderBy(): any {
        return this.orderBy;
    }

    public getJazztelListOrderFilter(filters: IListRequestBody): Observable<IorderListResponseBodyJazztel> {
        return this.http.post<IorderListResponseBodyJazztel>(environment.digoApiEndpoint + 'jazztel/order/list/filter', filters, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public getJazztelDetailOrder(orderId: any): Observable<IorderDetailResponseBodyJazztel> {
        return this.http.get<IorderDetailResponseBodyJazztel>(environment.digoApiEndpoint + `jazztel/order/${orderId}`, { headers: this.getHeaders()} ).pipe(map((response: any) => response?.msg?.length && response?.msg[0]));
    }

    public getMasterJazztel(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + '/jazztel/order/form_filter/masters', { headers: this.getHeaders()} );
    }

    public getCompanyByDistributorJazztel(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + '/jazztel/order/form_filter/company', {filters: { idDistributor: distributor}}, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }
}
