<div class="margins m-b-10">
    <button style="width:200px" (click)="modalSuscripcion()" nz-button class="c-primary size-large" nzBlock>
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Añadir suscripción
    </button>
</div>

<div id="edition_template" style="display: none">
    <i nz-icon class="icon edit-suscription icon-action"  nzType="edit" nzTheme="outline" style="margin-right: 5px; padding:10px"></i>
    <i nz-icon class="icon delete-suscription icon-action" nzType="delete" nzTheme="outline" style="padding: 10px"></i>
</div>

<nz-modal
    [(nzVisible)]="modalVisible"
    nzCentered
    (nzOnCancel)="this.modalVisible = false"
    [nzFooter]="null"
    [nzWidth]="594">

    <ng-container *nzModalContent>
        <app-modal-suscripcion (saved)="saveModalSuscription($event)" [suscription]="suscription"></app-modal-suscripcion>
    </ng-container>
</nz-modal>

<nz-modal
    [(nzVisible)]="deleteModal"
    [nzFooter]="null"
    (nzOnCancel)="deleteModal = false;  deleteCandidate = null"
    (nzOnOk)="deleteSubscription()"
    nzWidth="687px"
    nzCentered>

    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal">
            <p class="font s-16 c-black">¿Seguro que deseas borrar esta suscripción?</p>
            <br>
            <!--<p class="font s-28 w-bold a-center margins m-b-30">¿Seguro que deseas borrar esta suscripción?</p>-->
            <div nz-row nzGutter="24">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                    <button nz-button class="c-primary size-large" (click)="deleteSubscription()" nzBlock>Borrar</button>
                </div>
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                    <button nz-button class="c-dark size-large" nzBlock (click)="deleteModal = false; deleteCandidate = null">Cancelar</button>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

<div style="margin-left: -12px; margin-right: -12px;">
    <app-list
        (tableClick)="tableClicked($event)"
        (pageChanged)="pageChanged($event)"

        [filters]="[]"
        [table]="table"
        [paginator]="null"
        [loading]="loading"
        [error]="error">
    </app-list>
</div>