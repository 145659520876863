import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

    @Input() breacrumbList: string[] = [];
    @Input() title: string = '';
    @Input() showButtonBack: boolean = false;
    @Input() showButtonCancel: boolean = false;
    @Input() backRoute?: string;

    public today = Date.now();

    constructor(
        private location: Location,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    public goBack() {
        if(this.backRoute){
            this.router.navigate(([this.backRoute])).then()
        }else{
            this.location.back();
        };
    }
}
