<div class="container-max" >
    <div class="inner-content-max">
        <div>
            <h1 class="font s-16 c-black">Herramientas <i nz-icon nzType="loading" nzTheme="outline" *ngIf="!menu"></i></h1>
        </div>
    </div>

    <div class="inner-content-max" *ngIf="menu">
        <div nz-row [nzGutter]="10">
            <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="getMainColSize()" [nzLg]="getMainColSize()" [nzXl]="getMainColSize()" [nzXXl]="getMainColSize()"  *ngFor="let menuColumn of menu; let i = index">
                <ng-container *ngFor="let menuBlockContents of menuColumn.contents">

                    <!-- CARD simple -->
                    <ng-container *ngIf="mustFragmentThisContent(menuBlockContents)">
                        <ng-container *ngFor="let link of menuBlockContents.links">
                            <div class="digo-card tools-card" *ngIf="isVisible(link)" >
                                <div class="header-tools-card">
                                    <img [src]="getImage(link.img)" alt=""/>
                                    <span class="font s-18 w-bold" style="cursor: pointer" (click)="gotoLink(link)">{{ link.label }}</span>
                                </div>
                            </div>

                            <div class="margins m-b-15 m-t-15" *ngIf="i === 0 && lastVisible(link) && hasHiddeableElements()">
                                <a class="font s-14 c-primary" (click)="showAll = !showAll">{{ showAll ? '- Ver menos' : '+ Ver más' }}</a>
                            </div>

                        </ng-container>

                    </ng-container>

                    <!-- CARD con links dentro -->
                    <ng-container *ngIf="!mustFragmentThisContent(menuBlockContents) && isVisible(menuBlockContents)">
                        <div class="digo-card tools-card" >
                            <div class="header-tools-card">
                                <img [src]="getImage(menuBlockContents.img)" alt=""/>
                                <span class="font s-18 w-bold">{{ menuBlockContents.title }}</span>
                            </div>
                            <div class="body-tools-card">
                                <div nz-row [nzGutter]="24">
                                    <!--<ul class="{{ 'group' + (menuColumn.colspan ? menuColumn.colspan : '1') }}">-->
                                    <ul class="group2 default">
                                        <li [ngStyle]="{'margin-left': menuBlockContents.title ? '10px' : '-10px'}" class="box4" *ngFor="let link of menuBlockContents.links">
                                            <a *ngIf="!isIterable(link)" (click)="gotoLink(link, menuBlockContents.title)" class="font s-14 c-light-gray" style="margin-left: 0">{{link.label}}</a>

                                            <ul  style="margin-bottom: 10px;" *ngIf="isIterable(link)">
                                                <div class="header-list">
                                                    <span class="font s-14 c-light-gray down-line">{{ getName(link) }}</span>
                                                </div>
                                                <li style="margin-left: 10px;" class="box4" *ngFor="let link2 of link[getName(link)]">
                                                    <a *ngIf="!isIterable(link2)" (click)="gotoLink(link2, menuBlockContents.title + '-' + getName(link))" class="font s-12 c-light-gray" >{{link2.label}}</a>

                                                    <ul style="margin-bottom: 10px;" *ngIf="isIterable(link2)">
                                                        <div class="header-list">
                                                            <span class="font s-14 c-light-gray down-line">{{ getName(link2) }}</span>
                                                        </div>
                                                        <li style="margin-left: 10px;" class="box4" *ngFor="let link3 of link2[getName(link2)]">
                                                            <a *ngIf="!isIterable(link3)" (click)="gotoLink(link3, menuBlockContents.title + '-' + getName(link) + '-' + getName(link2))" class="font s-12 c-light-gray">{{link3.label}}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </ng-container>


                </ng-container>
            </div>
            <!--<div class="margins m-b-30 m-t-30">-->
                <!--<a class="font s-14 c-primary" (click)="showAll = !showAll">{{ showAll ? '- Ver menos' : '+ Ver más' }}</a>-->
            <!--</div>-->
        </div>
    </div>



    <!--<div class="inner-content-max" *ngIf="menu">-->
        <!--<div nz-row [nzGutter]="10">-->
            <!--<div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="getMainColSize()" [nzLg]="getMainColSize()" [nzXl]="getMainColSize()" [nzXXl]="getMainColSize()">-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('file-text.svg')"/>-->
                        <!--<span class="font s-18 w-bold" >Informes</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('package.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Importaciones en segundo plano</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('package.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Importaciones</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Datos de recargas</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Ventas Simyo (XLS)</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Estados Simyo</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Estados Simyo</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Asignación de objetivos</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Estados Amena</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Seguimiento de Activaciones diarias</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Saldo de recargas</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul>-->
                                    <!--<li>-->
                                        <!--<p class="font s-14 c-light-gray">Datos de recargas</p>-->
                                        <!--<ul class="sublist">-->
                                            <!--<li><a class="font s-12 c-light-gray">Clasificación</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Perfiles</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Rebranding</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Canales Empresa</a></li>-->

                                        <!--</ul>-->
                                    <!--</li>-->
                                <!--</ul>-->
                                <!--<ul>-->
                                    <!--<li>-->
                                        <!--<p class="font s-14 c-light-gray">Importar usuarios</p>-->
                                        <!--<ul class="sublist">-->
                                            <!--<li><a class="font s-12 c-light-gray">XML</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">No XML</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Jazztel</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Canales Empresa</a></li>-->

                                        <!--</ul>-->
                                    <!--</li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('lock.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Permisos y seguridad</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Pedidos</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Artículos</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Grupos de artículos</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Stock</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('airplay.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Pedidos Log</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Pedidos</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Artículos</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Grupos de artículos</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Stock</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8">-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('thumbs-up.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Marketing</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul>-->
                                    <!--<li>-->
                                        <!--<p class="font s-14 c-light-gray">Puntos Digo</p>-->
                                        <!--<ul class="sublist">-->
                                            <!--<li><a class="font s-12 c-light-gray">Pedidos</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Promociones</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Lista de regalos</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Stock Dexga</a></li>-->

                                        <!--</ul>-->
                                    <!--</li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Información al pdv</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Rebranding</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('message-circle.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Comunicados</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('bell.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Notificaciones</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('alert-circle.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Suscripciones a alertas</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('circle.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Comisionado</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Top 100</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">MSISDN</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('target.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Asignación de objetivos</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('settings.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Herramientas CAU</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Usuarios</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Usuarios XML</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Crear Empresas</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Cancelación altas pendientes</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">Prepago</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul>-->
                                    <!--<li>-->
                                        <!--<p class="font s-14 c-light-gray">Reenvio a</p>-->
                                        <!--<ul class="sublist">-->
                                            <!--<li><a class="font s-12 c-light-gray">Robot Portabilidad</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">Robot Amena</a></li>-->
                                            <!--<li><a class="font s-12 c-light-gray">usuario Simyo</a></li>-->
                                        <!--</ul>-->
                                    <!--</li>-->
                                <!--</ul>-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Stock Dexga</a></li>-->
                                    <!--<li><a class="font s-14 c-light-gray">App-desbloqueo</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8" [nzXl]="8" [nzXXl]="8">-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('git-commit.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Artículos</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('git-pull-request.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Familias</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('git-merge.svg')"/>-->
                        <!--<span class="font s-18 w-bold">PLV</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('user.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Acceso</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">PDV</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Autologin</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('codepen.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Gestión de IPs</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('codesandbox.svg')"/>-->
                        <!--<span class="font s-18 w-bold">IP</span>-->
                    <!--</div>-->
                    <!--<div class="body-tools-card">-->
                        <!--<div nz-row [nzGutter]="24">-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Desbloqueo IP</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                            <!--<div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">-->
                                <!--<ul class="default">-->
                                    <!--<li><a class="font s-14 c-light-gray">Control IP</a></li>-->
                                <!--</ul>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('database.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Consolidar datos</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('corner-down-right.svg')"/>-->
                        <!--<span class="font s-18 w-bold">SFID</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="digo-card tools-card">-->
                    <!--<div class="header-tools-card">-->
                        <!--<img [src]="getImage('refresh-cw.svg')"/>-->
                        <!--<span class="font s-18 w-bold">Reasignar MR</span>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
</div>
