import {AuthService} from "../auth.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";

/**
 * Base comun a servicios que vayan a llamar al api
 */

export class ApiService {
    protected auth: AuthService;
    protected http: HttpClient;
    protected endpoint = environment.digoApiEndpoint;
    protected endpointComun = environment.comunEndpoint;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        this.auth = auth;
        this.http = http;
    }

    public getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }

    public getFileHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
    }
    
    public getImageUploadHeaders(): any {
        return {Authorization: 'Bearer ' + this.auth.getToken()}
    }
}
