import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class ArticleService extends ApiService {
    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }



    getArticles(filters: any = null) {
        if (!filters) {
            filters = {};
        }
        return this.http.post(this.endpoint + 'article/list/filter', filters, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getArticle(id: number) {
        return this.http.get(this.endpoint + 'article/' + id,  {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getGroups() {
        return this.http.get(this.endpoint + 'article/group/list' ,{headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }
}