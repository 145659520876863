
<div>
    <div class="pagination-head">
        <ul>
            <li>
                <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
            </li>
            <li>
                <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></button>
            </li>
            <li>
                <span class="font s-10 w-bold">1 página de 1</span>
            </li>
        </ul>
    </div>
    <div class="wrapper-client-list">
        <div class="header-table-clients">
            <div class="th-name">
               <span class="font s-13 c-black">Nombre</span>
            </div>
            <div class="th-global">
                <span class="font s-13 c-black">Nº de c oontacto</span>
            </div>
            <div class="th-global">
                <span class="font s-13 c-black">Tipo de alta</span>
            </div>
            <div class="th-global">
                <span class="font s-13 c-black">Dispositivos</span>
            </div>
            <div class="th-global">
                <span class="font s-13 c-black">Estado de activación</span> 
            </div>
            <div class="th-global">
                <span class="font s-13 c-black">Fecha de solicitud</span>
            </div>
            <div class="th-img">
                <span class="font s-13 c-black">Marca</span>
            </div>
        </div>

        <div class="card-client">
            <div class="flex-content td-name">
                <span class="dot c-orange"></span>
                <span class="font s-12 c-black">Juan Francisco Martínez</span>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-10 c-black">42439990046</span>
            </div>
            <div class="item flex-content td-global">
                <div>
                    <span class="font s-12 c-black">Alta Fibra + Alta Móvil</span>
                    <p class="font s-10 c-primary">+ 2 líneas asociadas</p>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black">Si <span class="font s-10 c-primary">+ 1 terminal</span></span>
            </div>
            <div class="item flex-content td-global">
                <div style="width: 100%;">
                    <div class="flex-content f-between">
                        <p class="font s-10 c-pending no-bottom-medium">Pendiente</p>
                        <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                    </div>
                    <nz-progress [nzPercent]="90" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#A885D8', '100%': '#A885D8' }"></nz-progress>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black"><b>23/01/2022,</b> 15:50</span>
            </div>
            <div class="item flex-content" class="item flex-content" style="width: 5%;">
                <img src="../../../../assets/images/logo-orange-large.png" width="40px"/>
            </div>
        </div>
        <div class="card-client">
            <div class="flex-content td-name">
                <span class="dot c-amena"></span>
                <span class="font s-12 c-black">Juan Francisco Martínez</span>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-10 c-black">42439990046</span>
            </div>
            <div class="item flex-content td-global">
                <div>
                    <span class="font s-12 c-black">Alta Fibra + Alta Móvil</span>
                    <p class="font s-10 c-primary">+ 2 líneas asociadas</p>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black">Si <span class="font s-10 c-primary">+ 1 terminal</span></span>
            </div>
            <div class="item flex-content td-global">
                <div style="width: 100%;">
                    <div class="flex-content f-between">
                        <p class="font s-10 c-pending no-bottom-medium">Pendiente</p>
                        <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                    </div>
                    <nz-progress [nzPercent]="50" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#A885D8', '100%': '#A885D8' }"></nz-progress>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black"><b>23/01/2022,</b> 15:50</span>
            </div>
            <div class="item flex-content" class="item flex-content" style="width: 5%;">
                <img src="../../../../assets/images/logo-amena-large.png" width="40px"/>
            </div>
        </div>
        <div class="card-client">
            <div class="flex-content td-name">
                <span class="dot c-simyo"></span>
                <span class="font s-12 c-black">Juan Francisco Martínez</span>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-10 c-black">42439990046</span>
            </div>
            <div class="item flex-content td-global">
                <div>
                    <span class="font s-12 c-black">Alta Móvil</span>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black">Si <span class="font s-10 c-primary">+ 1 terminal</span></span>
            </div>
            <div class="item flex-content td-global">
                <div style="width: 100%;">
                    <div class="flex-content f-between">
                        <p class="font s-10 c-success no-bottom-medium">Completado</p>
                        <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                    </div>
                    <nz-progress [nzPercent]="100" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#50BE87', '100%': '#50BE87' }"></nz-progress>
                </div>
            </div>
            <div class="item flex-content td-global">
                <span class="font s-12 c-black"><b>23/01/2022,</b> 15:50</span>
            </div>
            <div class="item flex-content" class="item flex-content" style="width: 5%;">
                <img src="../../../../assets/images/logo-simyo-large.png" width="40px"/>
            </div>
        </div>
    </div>
</div>
