<div class="digo-card big-card">
    <div class="flex-content f-between">
        <div class="margins m-r-15">
            <span class="font s-18">{{title}}</span><span class="font s-12" *ngIf="emptyText !== '' && readOnly && !showObservations"> ({{emptyText}})</span>
        </div>
        <div *ngIf="!showRemoveMsg">
            <!-- Añadimos un comentario -->
            <ng-container *ngIf="!readOnly && !showObservations">
                <button nz-button class="c-primary size-medium btn-add" (click)="changeStateObservations(true)">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>Añadir
                </button>
            </ng-container>
            <!-- Guardamos un comentario -->
            <ng-container *ngIf="!readOnly && showObservations && !textAreaForm?.disabled">
                <button nz-button class="c-dark size-medium" (click)="saveObservation()">
                    Guardar
                </button>
            </ng-container>
            <!-- Elimnamos o editamos un comentario -->
            <ng-container *ngIf="!readOnly && showObservations && textAreaForm?.disabled">
                <i nz-icon nzType="delete" nzTheme="outline" class="cursor-pointer margins m-r-10" (click)="removeObservations()"></i> 
                <span class="cursor-pointer" (click)="editObservations()">Editar</span>
            </ng-container>
        </div>
        <div *ngIf="showRemoveMsg">
            <span class="font c-primary">Se ha eliminado la observación</span>
        </div>
    </div>
    <div class="collapse-active margins m-t-15" *ngIf="showObservations">
        <textarea rows="2" nz-input placeholder="Escribir aqui" [formControl]="textAreaForm"></textarea> 
    </div>
</div>