<div class="flex-content f-between">
    <ul class="list-header-prepaid">
        <li>
            <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
        </li>
        <li>
            <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></button>
        </li>
    </ul>
    <span class="font s-12 w-bold">Página 1/4566</span>
</div>
<div class="wrapper-prepaid-list">
    <div class="header-table-postpaid">
        <div class="th-large">
           <span class="font s-13 w-bold c-black">Nombre</span>
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">MSISDN</span>
        </div>
        <div class="th-large">
            <span class="font s-13 w-bold c-black">Punto de venta</span>
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">Fecha</span>
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">Tarifa</span> 
        </div>
        <div class="th-middle">
            <span class="font s-13 w-bold c-black">Tipo de pedido</span> 
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">Producto</span> 
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">Estado</span> 
        </div>
        <div class="th-small">
            <span class="font s-13 w-bold c-black">Documentación</span>
        </div>
    </div>

    <div class="card-postpaid c-cancel">
        <div class="th-large">
            <span class="font s-12 c-gray w-bold">Alberto Fernández</span>
         </div>
         <div class="th-small">
            <span class="font s-12 c-gray w-bold">No aplica</span>
        </div>
        <div class="th-large">
            <span class="font s-12 c-gray no-bottom-medium">Tienda Gran Vía, 34 Bajo, 28013 - Madrid</span>
        </div>
        <div class="th-small">
            <span class="font s-12 c-gray">15/01/2021 - 08:58</span>
        </div>
        <div class="th-small">
            <span class="font s-12 c-gray">100MB</span>
        </div>
         <div class="th-middle">
             <span class="font s-12 c-gray w-bold">Solo Fibra</span>
         </div>
         <div class="th-small">
            <span class="font s-12 c-gray w-bold">Fibra</span>
        </div>
         <div class="th-small">
            <div style="width: 100%;">
                <div class="flex-content f-between">
                    <p class="font s-10 c-danger no-bottom-medium">Cancelada (Otros)</p>
                </div>
            </div>
         </div>
         <div class="th-small last-th">
            <div class="flex-content a-center">
                <nz-badge nzStatus="processing"></nz-badge>
                <i class="icon i-rotate" nz-icon nzType="holder" nzTheme="outline"></i>
            </div>
         </div>
    </div>
    <div class="card-postpaid c-complete">
        <div class="th-large">
            <span class="font s-12 c-black w-bold">Alberto Fernández</span>
         </div>
         <div class="th-small">
            <span class="font s-12 c-black w-bold">No aplica</span>
        </div>
        <div class="th-large">
            <span class="font s-12 c-black no-bottom-medium">Tienda Gran Vía, 34 Bajo, 28013 - Madrid</span>
        </div>
        <div class="th-small">
            <span class="font s-12 c-black">15/01/2021 - 08:58</span>
        </div>
        <div class="th-small">
            <span class="font s-12 c-black">100MB</span>
        </div>
         <div class="th-middle">
             <span class="font s-12 c-black w-bold">Solo Fibra</span>
         </div>
         <div class="th-small">
            <span class="font s-12 c-black w-bold">Fibra</span>
        </div>
         <div class="th-small">
            <div style="width: 100%;">
                <div class="flex-content f-between">
                    <p class="font s-10 c-success no-bottom-medium">Completado</p>
                    <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                </div>
                <nz-progress [nzPercent]="100" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#50BE87', '100%': '#50BE87' }"></nz-progress>
            </div>
         </div>
         <div class="th-small last-th">
            <div class="flex-content a-center">
                <nz-badge nzStatus="processing"></nz-badge>
                <i class="icon i-rotate" nz-icon nzType="holder" nzTheme="outline"></i>
            </div>
         </div>
    </div>
</div>