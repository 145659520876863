<nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" (nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()"
    nzWidth="687px" nzCentered>
    <ng-container *nzModalContent class="wrapper-modal">
        <!-- action step -->
        <div class="body-modal" *ngIf="!isLoading" [formGroup]="userSearchFormGroup">
            <p class="font s-28 w-bold a-center margins m-b-30">Añadir usuario bloqueado</p>
            <div style="width: 100%;">
                <div *ngIf="!user">
                    <div nz-row>
                        <div nz-col nzSpan="16" nzOffset="4">
                            <div class="form-group" style="min-height: 100px;">
                              <div nz-row>
                                <div nz-col nzSpan="23">
                                  <input type="text" required formControlName="username" (keydown.enter)="searchUser()"  [ngClass]="{'has-value': userSearchFormGroup.get('username')?.value}">
                                  <label for="input" class="control-label flex-content f-between" style="width: 100%">
                                      <div>
                                          <i nz-icon nzType="search" nzTheme="outline" style="margin-right: 10px;"></i>
                                          <span class=""><b>Buscar</b> usuario</span>
                                      </div>
                                  </label>
                                </div>
                                <div nz-col nzSpan="1">
                                  <i nz-icon nzType="arrow-right" nzTheme="outline" (click)="searchUser()" class="pointer"></i>
                                </div>
                              </div>
                                <i class="bar"></i>
                                <span class="error-form" *ngIf="!isLoadingSearch && userSearchFormGroup.get('username')?.touched && userSearchFormGroup.get('username')?.errors?.['required']">
                                    Por favor, introduzca un nombre de usuario válido
                                </span>
                                <span class="error-form" *ngIf="isErrorSearch && !isLoadingSearch">
                                    {{ErrorMsg}}
                                </span>
                                <div align="center" *ngIf="isLoadingSearch">
                                    <img src="./assets/images/loader.svg" width="60" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body-modal" *ngIf="user && !isLoadingSearch && !isAdded && !isErrorSearch" [formGroup]="userBlockFormGroup">
                    <div *ngIf="!finalStepConfirm">
                      <div class="margins m-b-10" style="width: 100%;" nz-row nzGutter="24">
                        <div nz-col [nzSpan]="user.type === 'pdv' ? '8':'10'">
                          <p><b>Nombre de usuario: </b>{{user.username}}</p>
                        </div>
                        <div nz-col [nzSpan]="user.type === 'pdv' ? '10':'14'">
                          <div class="type" [ngSwitch]="user.type">
                            <div *ngSwitchCase="'pdv'">
                              <p><b>Nombre Pdv: </b>{{user.pdv}}</p>
                            </div>
                            <div *ngSwitchCase="'merchan'">
                              <p><b>GPV: </b>{{user.merchan}}</p>
                            </div>
                            <div *ngSwitchCase="'distributor'">
                              <p><b>Distribuidor: </b>{{user.distributor}}</p>
                            </div>
                            <div *ngSwitchDefault>
                            </div>
                          </div>
                        </div>
                        <div nz-col nzSpan="6">
                          <p *ngIf="user.type === 'pdv'"><b>ID Pdv: </b>{{user.id_pdv}}</p>
                        </div>
                      </div>
                      <div style="width: 100%;" nz-row nzGutter="24">
                        <div nz-col nzSpan="24">
                          <p><b>Comentarios: </b></p>
                          <textarea formControlName="comments" name="comments" rows="5" cols="80"></textarea>
                        </div>
                      </div>
                    </div>
                    <div align="center" *ngIf="finalStepConfirm && userBlockFormGroup.get('comments')?.value !== ''" class="font s-20">
                      <p>El usuario <b>{{user.username}}</b> quedará <b>{{userBlockFormGroup.get('blocked_status')?.value ? "bloqueado" : "desbloqueado"}}</b> comentando:<br>"{{userBlockFormGroup.get('comments')?.value}}"</p>
                      <p><b>¿Quiere guardar estos cambios?</b></p>
                    </div>
                    <div align="center" *ngIf="finalStepConfirm && userBlockFormGroup.get('comments')?.value === ''" class="font s-20">
                      <p>El usuario <b>{{user.username}}</b> quedará <b>{{userBlockFormGroup.get('blocked_status')?.value ? "bloqueado" : "desbloqueado"}}</b> sin añadir ningún comentario.</p>
                      <p><b>¿Quiere guardar estos cambios?</b></p>
                    </div>
                    <span class="error-form" *ngIf="isErrorAdd">
                      {{ErrorMsg}}
                    </span>
                    <div nz-row nzGutter="24" class="margins m-t-20">
                        <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                            <button *ngIf="!user" nz-button class="c-primary size-large" (click)="searchUser()" nzBlock>Buscar</button>
                            <button *ngIf="user" nz-button class="c-primary size-large" (click)="checkConfirmation()" nzBlock>Bloquear usuario</button>
    
                        </div>
    
                        <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                            <button nz-button class="c-dark size-large" (click)="handleCancel()" nzBlock>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div align="center" *ngIf="isLoading">
            <img src="./assets/images/loader.svg" width="60" alt="">
        </div>
        <div align="center" class="body-modal-confirm" *ngIf="isAdded">
            <img src="../../../../assets/images/pdv/icon-check.svg" class="margins m-b-70" alt="Success" />
            <p class="font s-22 a-center w-bold">Se ha bloqueado correctamente el usuario</p>
          </div>
    </ng-container>
</nz-modal>