
<div class="cards-other-data" (click)="showModal()">
    <div class="flex-content a-column a-center">
        <i class="icon-type" nz-icon nzType="safety-certificate" nzTheme="outline"></i>
        <p class="font s-14 w-bold">Rebranding</p>
    </div>
    <i class="icon-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
</div>
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    nzWidth="1262px"
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="wrapper-modal">
            <div class="header-modal">
                <div class="flex-content a-center">
                    <span class="font s-28 w-bold a-center margins m-r-10">Rebranding Simyo</span>
                    <span class="tag purple" style="margin-top: 10px;">
                        <span>{{ pdv.denominacion ?? pdv.pdv_name }}</span>
                    </span>
                </div>
            </div>
            <div class="wrapper-list" style="margin-bottom: 10px" *ngIf="!showErrorGetPeriodImages">
                <div class="wrapper-card-contracted"
                    style="border: 1px solid lightgrey;"
                    [ngClass]="{'is-collapse-padding': $any(period.value)['showMoreInfo'] }"
                    *ngFor="let period of $any(periodImages.images) | keyvalue">
                    <div nz-row nzAlign="bottom">
                        <div nz-col [nzSpan]="'6'">
                            <div class="content-card">
                                <span class="font s-13 c-gray t-up">Periodo</span>
                                <p>{{ $any(period.value)['name'] }}</p>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="'6'">
                            <div class="content-card">
                                <span class="font s-13 c-gray t-up">Estado</span>
                                <p>{{ translatePeriodStatus[$any(period.value)['period_status']] || '-' }}</p>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="'11'" *ngIf="authService.userCan('pdv.editar') || authService.userCan('pdv.validar_rebranding')">
                            <div class="content-card"
                                *ngIf="$any(period.value)['can_validate']">
                                <span class="font s-13 c-gray t-up">
                                    Validar Rebranding
                                </span>
                                <div *ngIf="!$any(period.value)['loadingChangeStatus']">
                                    <button nz-button
                                        class="c-dark margins m-r-10"
                                        (click)="changeStatusRebranding('accepted', $any(period))">
                                        Aceptar
                                    </button>
                                    <button nz-button
                                        class="c-primary"
                                        (click)="changeStatusRebranding('rejected', $any(period))">
                                        Rechazar
                                    </button>
                                </div>
                                <div *ngIf="$any(period.value)['loadingChangeStatus']">
                                    <img src="./assets/images/loader.svg" width="40" alt="">
                                </div>
                            </div>
                        </div>
                        <div nz-col nzSpan="1" class="cursor-pointer" (click)="showHiddenCarousel($any(period.value))">
                            <div class="content-arrow flex-content a-center">
                                <label nz-icon nzType="right"
                                    nzTheme="outline"
                                    class="cursor-pointer"
                                    [ngClass]="$any(period.value)['showMoreInfo'] === true ? 'arrow-active' : 'arrow-no-active'">
                                </label>
                            </div>
                        </div>
                        <div nz-col nzSpan="24" *ngIf="$any(period.value)['showErrorChangeStatus']">
                            <span class="font s-13">
                                *Error al cambiar el estado del rebranding. Por favor, inténtelo de nuevo más tarde
                            </span>
                        </div>
                        <ng-container *ngIf="$any(period.value)['showMoreInfo']">
                            <div nz-col [nzSpan]="'24'" style="text-align: center;">
                                <div *ngIf="!$any(period.value)['imgs']?.length" style="display: inline-grid;">
                                    <img src="./assets/images/pdv-placeholder.png" alt="PDV" class="margins m-b-15 m-t-15">
                                    <span class="font s-14 c-light-gray margins m-b-15">No hay ninguna foto de este mes</span>
                                </div>
                                <ng-container *ngIf="$any(period.value)['imgs']?.length">
                                    <div class="content-image-selected">
                                        <img [src]="imgSelected" width="100%" [alt]="imgSelected" onerror="this.src='./assets/images/img-broken.jpg'" (click)="isFullImageVisible = true"/>
                                        <br>
                                        <small (click)="isFullImageVisible = true">[Ver imagen a pantalla completa]</small>
                                    </div>
                                    <div class="carousel-images">
                                        <ul>
                                            <li *ngFor="let img of $any(period.value)['imgs']" (click)="imgSelected = img.url">
                                                <div class="figure" align="center">
                                                    <img [src]="img.url" width="100%" [alt]="img.url" onerror="this.src='./assets/images/img-broken.jpg'"/>
                                                    <div class="figcaption">{{ img.fecha_creacion | date: 'dd/MM/yyyy' }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="showErrorGetPeriodImages">
                Ha ocurrido un error al obtener las imágenes. Por favor, inténtelo de nuevo más tarde.
            </div>
        </div>
    </ng-container>
</nz-modal>

<nz-modal
    [(nzVisible)]="isFullImageVisible"
    [nzFooter]="null"
    [nzClosable]="false"
    [nzBodyStyle]="{display: 'flex', flexDirection: 'column'}"
    (nzOnOk)="isFullImageVisible = false"
    nzWidth="100%"
    nzClassName="modal-black"
    nzCentered>
    <ng-container *nzModalContent class="wrapper-modal">
        <div nz-row class="flex-content" style="width: 100%; place-content: end;">
            <span nz-icon nzType="close" nzTheme="outline" (click)="isFullImageVisible = false" class="cursor-pointer icon-type" style="padding: 15px; font-size: 20px;"></span>
        </div>

        <img [src]="imgSelected" width="100%" [alt]="imgSelected" onerror="this.src='./assets/images/img-broken.jpg'" class="img-fluid"/>
    </ng-container>

</nz-modal>
