<div class="wrapper-menu">
        <!--<h3 class="font s-14 c-primary">{{ item | titlecase}}</h3>-->
        <div class="wrapper-menu-desktop">
            <div class="menu-block" *ngFor="let menublock of menuElements; let i = index" >
                <div *ngFor="let menuSection of menublock.contents">
                    <p class="menu-block-title font s-14 c-black down-line w-bold title{{ menublock.colspan ? menublock.colspan : '1' }}" style="white-space: nowrap" *ngIf="menuSection.title" class="">{{ menuSection.title }}</p>
                    <ul class="{{ 'group' + (menublock.colspan ? menublock.colspan : '1') }}">
                        <li [ngStyle]="{'margin-left': menuSection.title ? '10px' : '-10px'}" class="box4" *ngFor="let link of menuSection.links">
                            <a nz-menu-item 
                                *ngIf="!isIterable(link)" 
                                (click)="gotoAndClose(link)" 
                                class="font s-12 c-black click-link" 
                                [title]="link.title">
                                {{link.label}}
                            </a>

                            <ul style="margin-bottom: 10px;" *ngIf="isIterable(link)">
                                <div class="header-list">
                                    <span class="font s-12 c-black down-line">{{ getName(link) }}</span>
                                </div>
                                <li style="margin-left: 10px;" class="box4" *ngFor="let link2 of link[getName(link)]">
                                    <a nz-menu-item 
                                        *ngIf="!isIterable(link2)" 
                                        (click)="gotoAndClose(link2)" 
                                        class="font s-12 c-black click-link" 
                                        [title]="link2.title">
                                        {{link2.label}}
                                    </a>

                                    <ul style="margin-bottom: 10px;" *ngIf="isIterable(link2)">
                                        <div class="header-list">
                                            <span class="font s-14 c-black down-line">{{ getName(link2) }}</span>
                                        </div>
                                        <li style="margin-left: 10px;" class="box4" *ngFor="let link3 of link2[getName(link2)]">
                                            <a nz-menu-item 
                                                *ngIf="!isIterable(link3)" 
                                                (click)="gotoAndClose(link3)" 
                                                class="font s-12 c-black click-link" 
                                                [title]="link3.title">
                                                {{link3.label}}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
</div>
