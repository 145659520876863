import { Component, OnInit } from '@angular/core';
import {pageLimit} from "../../../../shared/constantes";
import {LogisticsService} from "../../../../services/http/herramientas/logistics.service";
import {ListsFiltersService} from "../../../../services/lists.filters.service";
import {ArticleService} from "../../../../services/http/herramientas/article.service";
import {environment} from "../../../../../environments/environment";
import {isIterable} from "../../../../core/functions";
import {AuthService} from "../../../../services/auth.service";
import {AccessService} from "../../../../services/access.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-articulos',
    templateUrl: './articulos.component.html',
    styleUrls: ['./articulos.component.scss']
})
export class ArticulosComponent implements OnInit {

    public groups: any = [];

    public loading = false;
    public error: string | boolean = false;
    public paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filters = [
        {
            type: 'text',
            name: 'description',
            label: 'Nombre',
            value: null
        },
        {
            type: 'select',
            name: 'idArticleGroup',
            label: 'Grupo',
            options: [
                {name: 'Todos', value: ""}
            ],
            value: null
        },
        {
            type: 'select',
            name: 'discontinued',
            label: 'Estado DigoApp',
            options: [
                {name: 'Todos', value: ""},
                {name: 'Disponible', value: "0"},
                {name: 'Descatalogado', value: "1"}
            ],
            value: null
        },
        {
            type: 'select',
            name: 'noActivation',
            label: 'Estado Digo',
            options: [
                {name: 'Todos', value: ""},
                {name: 'Permite activación', value: "0"},
                {name: 'No permite activación', value: "1"}
            ],
            value: null
        }


        /*,
        {
            type: 'text',
            name: 'codeSap',
            label: 'Código SAP',
            value: null
        },
        {
            type: 'select',
            name: 'idArticle', // (idArticleGroup), esta mal escrito
            label: 'Grupo',
            options: [],
            value: null
        },
        {
            type: 'select',
            name: 'available',
            label: 'Estado',
            options: [
                {name: 'Todos', value: ""},
                {name: 'Agotado', value: "0"},
                {name: 'Disponible', value: "1"}
            ],
            value: null
        }*/
    ];

    public table: any = null;

    constructor(
        private httpService: ArticleService,
        private filterService: ListsFiltersService,
        private access: AccessService
    ) {
    }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/general/articulos')
        this.loadAll()
        // this.loadGroups()
    }

    // loadGroups() {
    //     this.loading = true;
    //     this.httpService.getGroups().subscribe(data => {
    //         if (data) {
    //             this.groups = data.data;
    //             (this.filters[2].options as any).push({name: 'Todos', value: ''});
    //             Object.keys(this.groups).forEach(key => {
    //                 (this.filters[2].options as any).push({name: this.groups[key].name, value: key});
    //             });
    //             this.load();
    //         }
    //     }, err => this.loading = false);
    // }

    loadAll() {
        this.loading = true;
        this.httpService.getGroups().subscribe((data: any) => {
            if (isIterable(data.data)) {
                data.data.forEach((group: any) => {
                    this.filters[1].options!.push({
                        name: group.name,
                        value: group.idArticleGroup
                    })
                })
                this.load()
            }

        }, () => this.load());
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.httpService.getArticles(this.filterService.getPayload(this.paginator, this.filters))
        .subscribe({
            next: (data: any) => {
                this.error = false;
                console.log(data);
                if (data) {
                    this.prepareTable(data.data);
                    this.preparePaginator(data);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.prepareTable([]);
                this.error = "ERROR";
                this.loading = false;
            },
            complete:() => {
                this.loading = false;
            }
        });
        
    }

    tableClicked(ev: any) {
        if (ev.rowId) {
            window.location.href = environment.oldDigoFront + 'articulo.itx?ac=r&articulo_id_articulo=' + ev.rowId
        }
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'ID'},
                            {value: 'Nombre'},
                            {value: 'Grupo'},
                            {value: 'Estado DigoApp'},
                            {value: 'Estado Digo'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            console.log(this.getBorderStyle(item))
            table.body.rows.push({
                clickValue: item.idArticle,
                cells: [
                    {value: item.idArticle, styles: this.getBorderStyle(item)},
                    {value: item.description},
                    {value: item.groupName ? item.groupName : ''},
                    {
                        styles: this.getDigoAppStateStyle(item),
                        value: this.getDigoAppStateName(item)
                    },
                    {
                        styles: this.getStateStyle(item),
                        value: this.getStateName(item)
                    },
                ]
            })
        });

        this.table = table;
    }

    getGroupName(item: any) {
        if (typeof this.groups[item.idGroupArticle] !== 'undefined') {
            return this.groups[item.idGroupArticle].name;
        }
        return '';
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    getStateStyle(item: any) {
        switch (item.noActivation) {
            case "0": return {'color': '#009900'};
            case "1": return {'color': '#990000'};
        }
        return {}
    }

    getBorderStyle(item: any) {
        const base = '5px solid ';
        switch (item.noActivation) {
            case "0": return {'border-left': base + '#009900'};
            case "1": return {'border-left': base + '#990000'};
        }
        return {}
    }

    getStateName(item: any) {
        switch (item.noActivation) {
            case "0": return 'Activo';
            case "1": return 'No Activo'
        }
        return '';
    }

    getDigoAppStateStyle(item: any) {
        switch (item.discontinued) {
            case "0": return {'color': '#009900'};
            case "1": return {'color': '#990000'};
        }
        return {}
    }
    getDigoAppStateName(item: any) {
        switch (item.discontinued) {
            case "0": return 'Disponible';
            case "1": return 'Descatalogado'
        }
        return '';
    }

    pageChanged(paginator: any) {
        console.log(paginator)
        this.paginator = paginator;
        this.load();
    }

    filtersApplied(filters: any) {
        console.log(filters);
        this.filters = filters;
        this.paginator.currentPage = 1;
        this.load();
    }


}
