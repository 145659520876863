import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'orderHeader'
})

export class OrderHeaderPipe implements PipeTransform  {

    constructor(){}

    transform(array: any) {
        if (array && array.length) {
            array.sort((a: any, b: any) => {
                if (this.getOrderByName(a) < this.getOrderByName(b)) {
                    return -1;
                } else if (this.getOrderByName(a) > this.getOrderByName(b)) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }
        return array;
    }

    getOrderByName(value: any) {

        switch (value.key) {
            case 'Amena':
                return 2;
            case 'DigoApp':
                return 7;
            case 'Herramientas':
                return 6;
            case 'Jazztel':
                return 5;
            case 'Orange':
                return 1;
            case 'Perfiles':
                return 8;
            case 'RM':
                return 3;
            case 'SIMYO':
            case 'Simyo':
                return 4;
            default:
                return 0;
        }
    }
}
