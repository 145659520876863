import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { allLocalStorage } from 'src/app/shared/constantes';
import {DigoService} from '../../services/digo.service';
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-jwt',
    templateUrl: './jwt.component.html',
    styleUrls: ['./jwt.component.scss']
})
export class JwtComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private digoService: DigoService,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params: any) => {
            const jwt = params.jwt;
            const brand = params.brand;
            const state = params.state;
            console.log(params);

            if (jwt !== undefined && jwt !== null && jwt !== '') {
                localStorage.clear();
                this.auth.setToken(jwt);
                if (this.auth.checkIfTokenHasExpired()) {
                    if (environment.fakeLogin) {
                        this.router.navigate(['/login'])
                    } else {
                        window.location.href = environment.oldDigoFront + 'signin.itx'
                    }
                }

                this.auth.setUsername(this.auth.usernameFromJwt(jwt));

                this.router.navigate(['/' + (brand ? brand : '') + (state ? '/' + state : '')]);
            } else {
                window.location.href = environment.oldDigoFront + 'signin.itx'
            }
        });

    }

}
