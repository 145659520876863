<div (click)="showModal()">
    <i nz-icon [nzType]="user.status ? 'delete' : 'reload'" nzTheme="outline" class="margins m-r-10"></i>
    {{ user.status ? 'Desactivar' : 'Activar' }}
</div>
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    nzWidth="687px"
    nzCentered
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal">
            <p class="font s-28 w-bold a-center margins m-b-30">¿Estás seguro que quieres {{ user.status ? 'desactivar' : 'activar' }} este usuario?</p>
            <div nz-row nzGutter="24" *ngIf="!isLoading && !showMsgError">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24" >
                    <button nz-button class="c-primary size-large" nzBlock (click)="changeStatus()">{{ user.status ? 'Desactivar' : 'Activar' }}</button>
                </div>
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                    <button nz-button class="c-dark size-large" nzBlock (click)="handleCancel()">Cancelar</button>
                </div>
            </div>
            <div nz-row nzGutter="24" *ngIf="isLoading">
                <div nz-col nzSm="24" align="center">
                    <img src="./assets/images/loader.svg" width="60" alt="">
                </div>
            </div>
            <div nz-row nzGutter="24" *ngIf="!isLoading && showMsgError">
                <div nz-col nzSm="24" align="center">
                    <p class="font s-14 w-bold margins m-b-30 c-danger"><i nz-icon nzType="exclamation-circle" nzTheme="outline"></i> {{ msgError }}</p>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>

