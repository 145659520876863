import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {NZ_ICONS} from "ng-zorro-antd/icon";
import {IconDefinition} from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";
import {JwtComponent} from './core/jwt/jwt.component';
import {HerramientasModule} from "./pages/herramientas/herramientas.module";
import localeEs from '@angular/common/locales/es-419';
import {registerLocaleData} from "@angular/common";
import { es_ES, NZ_I18N } from 'ng-zorro-antd/i18n';
import { BlockPasteDirective } from './core/directives/block-paste.directive';

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
    key => antDesignIcons[key]
);

registerLocaleData(localeEs)

@NgModule({
    declarations: [
        AppComponent,
        JwtComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        SharedModule,
        HerramientasModule
    ],
    providers: [
        { provide: NZ_ICONS, useValue: icons },
        { provide: LOCALE_ID, useValue: "es-419" },
        { provide: NZ_I18N, useValue: es_ES }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
