<div id="chart">
    <apx-chart #chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [markers]="chartOptions.markers"
        [stroke]="chartOptions.stroke"
        [dataLabels]="chartOptions.dataLabels"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
    ></apx-chart>
  </div>