import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'trustUrl'
})

export class TrustUrl implements PipeTransform  {

    constructor(private _sanitizer: DomSanitizer){}

    transform(v: string) : SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(v);
    }
}
