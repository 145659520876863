<div class="wrapper-inner-modal">


    <!--<span class="font s-10 c-gray">Estás guardando</span>-->
    <p class="font s-16 c-black w-bold">{{ this.suscription ? 'Editar' : 'Nueva'}} suscripcion</p>
    <p class="font s-12 c-black">Completa los datos de tu suscripción</p>
    <!--<p class="font s-8 c-gray">Alias del informe</p>-->
    <br>
    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!loading && form">
        <div class="form-group">
            <!--<p class="font s-10 c-black">Informe</p>-->
            <select required id="code" formControlName="code">
                <option value="">Seleccione Área/Sección</option>
                <option *ngFor="let report of reportsList" [value]="report.code">{{ report.title }}</option>
            </select>
            <label for="code" class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>Seleccione un informe</b></span>
                </div>
            </label><i class="bar"></i>
            <span class="error-form" *ngIf="form.get('code')?.touched && form.get('code')?.errors?.['required']">
                Campo obligatorio
            </span>
        </div>

        <span class="font s-12 c-black">Periodo solicitado</span>
        <div class="digo-button-group" style="padding: 0px;">
            <input label="Mes en curso" formControlName="filtro_fecha" type="radio" value="1" checked>
            <input label="Últimos 15 días" formControlName="filtro_fecha" type="radio"  value="2">
            <input label="Últimos 30 días" formControlName="filtro_fecha" type="radio"  value="3">
            <!--<input [disabled]="true" label="Mes anterior" formControlName="filtro_fecha"  type="radio"  value="4" style="opacity: 0.5">-->
        </div>

        <span class="font s-12 c-black">Periodicidad</span>
        <div class="digo-button-group" style="padding: 0px;">
            <input label="Mensual (día 1)" formControlName="periodicidad" type="radio" value="1" checked>
            <input label="Semanal (lunes)" formControlName="periodicidad" type="radio"  value="2">
            <input label="Diaria" formControlName="periodicidad" type="radio"  value="3">
        </div>

        <div nz-row [nzGutter]="24">
            <div nz-col [nzXs]="24" [nzSm]="12" [nzLg]="12" [nzMd]="12"[nzLg]="12"[nzXl]="12"[nzXXl]="12">
                <label for="filter_fromto_from" class=" flex-content f-between" style="width: 100%">
                    <div>
                        <span class="font s-12 c-black"><b>Desde</b></span>
                    </div>
                </label>
                <input id="filter_fromto_from" formControlName="fecha_inicio" type="date" oninvalid="" >
                <i class="bar"></i>
            </div>
            <div nz-col [nzXs]="24" [nzSm]="12" [nzLg]="12" [nzMd]="12"[nzLg]="12"[nzXl]="12"[nzXXl]="12">
                <label for="filter_fromto_to" class=" flex-content f-between" style="width: 100%">
                    <div>
                        <span class="font s-12 c-black"><b>Hasta</b></span>
                    </div>
                </label>
                <div nz-row>
                    <div class="digo-button-group" style="padding: 0px; height: 28px;">
                        <input label="Sin fin" formControlName="fecha_fin" type="radio" [value]="null" (click)="selectInifinityDate()">
                    </div>
                    <input id="filter_fromto_to" formControlName="fecha_fin" type="date" (change)="selectDate($event)">
                </div>
            </div>
        </div>

        <div class="form-group margins m-t-20">
            <select id="area" formControlName="filtro_area">
                <option value="">Seleccione Área/seccion</option>
                <option value="3">Grandes Superficies</option>
                <option value="5">No exclusivo</option>
                <option value="2">Nuevos Canales</option>
                <option value="6">Stands</option>
                <option value="4">Televenta</option>
                <option value="1">Tiendas Jazztel</option>
            </select>
            <label for="area" class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>Area</b></span>
                </div>
            </label><i class="bar"></i>
        </div>

        <div class="form-group margins m-t-20">
            <input type="text" required="" id="email" formControlName="email" [ngClass]="{'has-value': form.get('email')?.value}">
            <label for="email" class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class="font s-12 c-black">Email</span>
                </div>
            </label><i class="bar"></i>
            <span class="error-form" *ngIf="form.get('email')?.touched && form.get('email')?.errors?.['required']">
                Campo obligatorio
            </span>
        </div>
        <div class="footer-inner-modal">
            <div class="form-group form-group-footer">
                <div class="flex-content a-center">
                    <button nz-button class="c-primary size-large" nzBlock>Guardar</button>
                </div>
            </div>
        </div>
    </form>

</div>
