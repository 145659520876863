import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/core/validators/mustmatch.validators';
import { HasLower } from 'src/app/core/validators/hasLower.validators';
import { HasUpper } from 'src/app/core/validators/hasUpper.validators';
import { HasNumber } from 'src/app/core/validators/hasNumber.validators';
import { ProfilesService } from 'src/app/services/http/profiles/profiles.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NoSpace } from 'src/app/core/validators/noSpace.validators';
import { MustNotMatch } from 'src/app/core/validators/mustNotMatch.validators';
import { HasSpecial } from 'src/app/core/validators/hasSpecial.validators';

@Component({
  selector: 'app-modal-global-change-password',
  templateUrl: './modal-global-change-password.component.html',
  styleUrls: ['./modal-global-change-password.component.scss']
})
export class ModalGlobalChangePasswordComponent implements OnInit {

  @Output() showModalEvent = new EventEmitter<boolean>();

  public menuEntriesCount = 0;
  public itemSelected: string = "";
  public showMenu: boolean = true;
  public loadingModal: boolean = false;
  public resetPasswordFormGroup!: FormGroup;
  public resetPasswordError: string = '';
  public resetPasswordOk: boolean = false;
  public iframeSrc: string = '';
  public isVisible: boolean = false;
  public showErrorMsg: boolean = false;
  public errorCode: number = 0;

  constructor(
      private fb: FormBuilder,
      private profileService: ProfilesService,
      private router: Router,
      private auth: AuthService
  ) { }

  ngOnInit(): void {
      this.resetPasswordFormGroup = this.fb.group({
          currentPassword: [null, [Validators.required]],
          newPassword: [null, [Validators.required, Validators.minLength(8)]],
          newRepeatPassword: [null, [Validators.required]],
      }, {
          validator: [MustNotMatch('currentPassword', 'newPassword'), MustMatch('newPassword', 'newRepeatPassword'), HasNumber('newPassword'), HasUpper('newPassword'), HasLower('newPassword'), NoSpace('newPassword'), HasSpecial('newPassword')]
      });
  }

  public confirmModal() {
      this.resetPasswordFormGroup.markAllAsTouched();
      this.resetPasswordFormGroup.updateValueAndValidity();
      if (this.resetPasswordFormGroup.valid) {
          this.loadingModal = true;
          this.resetPasswordError = '';
          this.resetPasswordOk = false;
          this.showErrorMsg = true;
          this.profileService.changePassUser(this.resetPasswordFormGroup.get('currentPassword')?.value, this.resetPasswordFormGroup.get('newPassword')?.value)
              .subscribe({
                  next: (response: any) => {
                      if (response?.msg?.original?.error?.msg && response?.msg?.original?.error?.msg !== '') {
                          this.resetPasswordError = response?.msg?.original?.error?.msg;
                          this.errorCode = response?.msg?.original?.error?.errorCode;
                      } else {
                          this.resetPasswordOk = true;
                          setTimeout(() => { 
                              this.closeModal();
                          }, 2000);
                      }
                      this.loadingModal = false;
                  },
                  error: (error: HttpErrorResponse) => {
                      this.loadingModal = false;
                      this.resetPasswordError = 'Ha ocurrido un error al cambiar la contraseña. inténtelo de nuevo más tarde.';
                  },
                  complete: () => {
                      this.loadingModal = false;
                  }
              });
      }
  }

  public closeModal() {
      this.showModalEvent.emit(false);
      this.resetPasswordOk = false;
      this.resetPasswordError = '';
      this.resetPasswordFormGroup.reset();
  }
  public auth2FA():void{
    this.closeModal();
    let url = '2fa.itx';
    if (this.auth.getIncognitoMode() || this.auth.iOS()) {
        window.location.href = environment.oldDigoFront + url + (url?.includes('?') ? '&' : '?') + 'jwt=' + this.auth.getToken() + '&noFrameMode=1';
    } else {
        this.router.navigate(['herramientas/' + '2FA' + '/' + url]);
    }
  }
  public showRequirements(){
    this.isVisible = true;
  }
  public closeRequirements(){
    this.isVisible = false;
  }
  public onFocus(pass: string){ // pass = 'nueva' o 'actual' para diferenciar el input
    if (this.errorCode === 1007 && pass === 'actual') { //Error de contraseña actual
      this.showErrorMsg = false;
      this.errorCode = 0;
    }
    else if (this.errorCode === 1008 && pass === 'nueva') { //Error de contraseña nueva
      this.showErrorMsg = false;
      this.errorCode = 0;
    }
    else if (this.errorCode !== 1007 && this.errorCode !== 1008) { //Error no identificado
      this.showErrorMsg = false;
      this.errorCode = 0;
    }
  }
  //Password requeriments
  public diff():boolean{
    return (this.resetPasswordFormGroup.get('currentPassword')?.value && this.resetPasswordFormGroup.get('newPassword')?.value &&
     this.resetPasswordFormGroup.get('currentPassword')?.value !== this.resetPasswordFormGroup.get('newPassword')?.value) ? true : false;
  }
  public longEnough():boolean{
    return String(this.resetPasswordFormGroup.get('newPassword')?.value).length >= 8 ? true : false;
  }
  public upperLower():boolean{
    return /[A-Z]/.test(this.resetPasswordFormGroup.get('newPassword')?.value) && /[a-z]/.test(this.resetPasswordFormGroup.get('newPassword')?.value) ? true : false;
  }
  public number():boolean{
    return /\d/.test(this.resetPasswordFormGroup.get('newPassword')?.value) ? true : false;
  }
  public noSpaces():boolean{
    return this.resetPasswordFormGroup.get('newPassword')?.value && /^\S*$/.test(this.resetPasswordFormGroup.get('newPassword')?.value) ? true : false;
  }
  public special():boolean{
    return /[\W]/.test(this.resetPasswordFormGroup.get('newPassword')?.value) ? true : false;
  }
  public match():boolean{
    return (this.resetPasswordFormGroup.get('newPassword')?.value && this.resetPasswordFormGroup.get('newRepeatPassword')?.value &&
     this.resetPasswordFormGroup.get('newPassword')?.value === this.resetPasswordFormGroup.get('newRepeatPassword')?.value) ? true : false;
  }
}
