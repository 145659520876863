<div class="container-menu">
    <div class="inner-menu">
        <ul class="list-option">
            <ng-container *ngFor="let item of menus | keyvalue: originalOrder; let i = index">
                <li *ngIf="item.key.toString() == 'divider'" class="divider"></li>
                <li *ngIf="item.key.toString() != 'divider'">
                    <ng-container *ngIf="getPath(item.value) === false">
                        <!--<a [ngClass]="{'active': itemSelected === item.key}" class="font s-13 c-black link w-bold" (mouseenter)="setSelectedItem(item.key)"  (nzVisibleChange)="resetSelected($event)" nz-dropdown [nzDropdownMenu]="menuDesp" style="padding: 15px 0">-->
                        <a [ngClass]="{'active': this.itemSelected && itemSelected == $any(item.key)}"
                        class="font s-13 c-black link w-bold" (mouseenter)="setSelectedItem(item.key)"
                        (nzVisibleChange)="resetSelected($event)" nz-dropdown
                        [nzDropdownMenu]="menuDesp" style="padding: 15px 0">
                            {{ item.key }}
                        </a>
                        <nz-dropdown-menu #menuDesp="nzDropdownMenu">
                            <app-menu-desktop
                                [menus]="menus"
                                [item]="$any(item.key)">
                            </app-menu-desktop>
                        </nz-dropdown-menu>
                    </ng-container>

                    <a nz-tooltip [nzTooltipTitle]="getTooltip(item.value)" nzTooltipPlacement="bottom" class="font s-13 c-black link w-bold" [routerLink]="getPath(item.value)" *ngIf="getPath(item.value) !== false" style="padding: 15px 0">
                        {{ item.key }}
                    </a>
                </li>
            </ng-container>
        </ul>
        <div>
            <ul class="list-options">
                <li>
                    <i nz-icon nzType="safety" class="icon s-22 c-orange" nzTheme="outline"></i>
                    <a class="font s-13 c-primary link w-bold" (click)="changePassword(true)">
                        Cambiar contraseña
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<app-modal-global-change-password *ngIf="showModal" (showModalEvent)="changePassword($event)"></app-modal-global-change-password>
