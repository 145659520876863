import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUserSearched } from 'src/app/pages/herramientas/models/blockedUsers.models';
import { BlockUsersService } from 'src/app/services/http/herramientas/block-users.service';

@Component({
  selector: 'app-modal-edit-blocked-user',
  templateUrl: './modal-edit-blocked-user.component.html',
  styleUrls: ['./modal-edit-blocked-user.component.scss']
})
export class ModalEditBlockedUserComponent implements OnInit {
  @Input() blockedUser: string = '';
  @Input() isVisible: boolean = false;
  @Output() hideDetail = new EventEmitter<boolean>();
  @Output() updateTable = new EventEmitter<boolean>();


  public user!: BlockUserSearched;

  //Loaders
  public isLoadingSearch: boolean = false;
  public isLoadingEdit: boolean = false;

  //ErrorMsg
  public ErrorMsg: string = '';

  //Results
  public isChanged: boolean = false;
  public finalStepConfirm: boolean = false;
  public isEdited: boolean = false;
  public isError: boolean = false;

  //form
  public userFormGroup!: FormGroup;


  constructor(
    private blockUsersService: BlockUsersService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.userFormGroup = this.fb.group({
      user_id: ['', [Validators.required]],
      comments: [''],
      blocked_status: [null, [Validators.required]]
    });
    this.searchAndFillUserData();
  }

  public searchAndFillUserData(){
    this.isLoadingSearch = true;
    this.blockUsersService.searchBlockedUsers(this.blockedUser)
    .subscribe({
        next: (response: any) => {
            if (response && response.data && response.data[0]) {
              this.user = response.data[0];
              this.fillForm(this.user);
            }
        },
        error: (error: HttpErrorResponse) => {
          this.ErrorMsg = 'Ha ocurrido un error, inténtelo de nuevo más tarde';
          this.isLoadingSearch = false;
          this.isError = true;
        },
        complete: () => {
          this.isLoadingSearch = false;
        }
    });
  }

  //form
  public fillForm(user : BlockUserSearched){
    this.userFormGroup.get('user_id')?.patchValue(user?.id_user);
    this.userFormGroup.get('comments')?.patchValue(user?.comments);
    this.userFormGroup.get('blocked_status')?.patchValue(user?.status);
  }

  public onChangeBlockedStatus(){
    this.userFormGroup.get('blocked_status')?.patchValue(!this.userFormGroup.get('blocked_status')?.value);
  }
  
  public checkConfirmation(){
    if (this.finalStepConfirm && this.userFormGroup.valid) {
      this.applyEditBlockUser()
    }
    this.finalStepConfirm = true;
  }
  public applyEditBlockUser(){
    if(this.userFormGroup.valid){
      this.isLoadingEdit = true;
      const modifyUserData = this.transformUserForm()
      this.blockUsersService.modifyBlockedUser(modifyUserData)
          .subscribe({
              next: (response: any) => {
                  if (response && response.data && response.data[0]) {
                    this.user = response.data[0];
                  }
              },
              error: (error: HttpErrorResponse) => {
                  this.ErrorMsg = 'Ha ocurrido un error, inténtelo de nuevo más tarde';
                  if (error?.error?.error?.errorCode === 1000) {
                      this.ErrorMsg = 'Usuario no encontrado';
                  }
                  this.isLoadingEdit = false;
                  this.isError = true;
                  this.finalStepConfirm = false;
              },
              complete: () => {
                  this.isLoadingEdit = false;
                  this.isEdited = true;
                  this.updateTable.emit(true);
              }
          });
    }
  }

  public transformUserForm(){
    var newForm: { user_id: string, comments: string, blocked_status: string } = {
      user_id: this.userFormGroup.get("user_id")?.value,
      comments: this.userFormGroup.get("comments")?.value,
      blocked_status: this.userFormGroup.get("blocked_status")?.value === true ? "blocked": "unblocked"
    };
    return newForm;
  }

  //Modal handlers
  handleOk(): void {
    this.isVisible = false;
    this.hideDetail.emit(true);
    this.isEdited = false;
    this.isError = false;
    this.finalStepConfirm = false;
  }

  handleCancel(): void {
    if (!this.finalStepConfirm) {
      this.userFormGroup.reset();
      this.isVisible = false;
      this.hideDetail.emit(true);
      this.isEdited = false;
      this.isError = false;
    }
    this.finalStepConfirm = false;

  }

}
