<div class="wrapper-search-clients">
    <div class="section-input-search-clients">
        <div class="form-group" style="width: 400px;">
            <div class="form-group">
                <input type="text" oninvalid="" required="">
                <label for="input" class="control-label flex-content f-between" style="width: 100%">
                    <div>
                        <i nz-icon nzType="search" nzTheme="outline" style="margin-right: 10px;"></i>
                        <span class=""><b>Buscador</b> (Nombre / DNI / Nº de contrato)</span>
                    </div>
                    <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                </label><i class="bar"></i>
             </div>
        </div>
    </div>
    <div class="datadivider"></div>
    <div class="section-input-search-clients">
        <ul class="list-checkboxes">
            <li>
                <input type="checkbox" id="cb1" />
                <label for="cb1">
                    <img src="../../../../assets/images/logo-orange-large.png" />
                </label>
            </li>
            <li>
                <input type="checkbox" id="cb2" />
                <label for="cb2">
                    <img src="../../../../assets/images/logo-republica-large.png" />
                </label>
            </li>
            <li>
                <input type="checkbox" id="cb3" />
                <label for="cb3">
                    <img src="../../../../assets/images/logo-simyo-large.png" />
                </label>
            </li>
            <li>
                <input type="checkbox" id="cb4" />
                <label for="cb4">
                    <img src="../../../../assets/images/logo-amena-large.png" />
                </label>
            </li>
        </ul>

    </div>
</div>
