import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of} from 'rxjs';
import {AuthService} from '../../auth.service';
import {ApiService} from '../api.service';
import {environment} from 'src/environments/environment';
import {IListRequestBody} from 'src/app/core/models/lists.models';
import {IorderListResponseBodySimyo} from 'src/app/pages/simyo/models/IorderListSimyo';
import {IorderDetailResponseBodySimyo} from 'src/app/pages/simyo/models/IorderDetailSimyo';
import {IsimSwapDetailResponseBodySimyo} from 'src/app/pages/simyo/models/IsimSwapDetailSimyo';
import {IsimSwapListResponseBodySimyo} from 'src/app/pages/simyo/models/IsimSwapListSimyo';
import { pageLimit } from 'src/app/shared/constantes';

@Injectable({
    providedIn: 'root',
})
export class SimyoService extends ApiService {
    private filters: any = null;
    private duplicadoFilters: any = null;
    private paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0,
    };
    private duplicadoPagintor: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0,
    };
    private orderBy: any = null;
    private customFilters: any = null;

    constructor(auth: AuthService, http: HttpClient) {
        super(auth, http);
    }

    setFilters(filters: any) {
        this.filters = filters;
    }

    getFilters(): any {
        return this.filters;
    }

    setDuplicadoFilters(filters: any) {
        this.duplicadoFilters = filters;
    }

    getDuplicadoFilters(): any {
        return this.duplicadoFilters;
    }

    public setPaginator(paginator: any) {
        this.paginator = paginator;
    }

    public getPaginator(): any {
        return this.paginator;
    }

    public setOrderBy(orderBy: any) {
        this.orderBy = orderBy;
    }

    public getOrderBy(): any {
        return this.orderBy;
    }

    public setDuplicadoPaginator(pagintor: any) {
        this.duplicadoPagintor = pagintor;
    }

    public getDuplicadoPaginator(): any {
        return this.duplicadoPagintor;
    }

    public getCustomFilters(): any {
        return this.customFilters;
    }

    public setCustomFilters(filters: any) {
        this.customFilters = filters;
    }

    public getSimyoListOrderFilter(
        body: IListRequestBody
    ): Observable<IorderListResponseBodySimyo> {
        return this.http
            .post<IorderListResponseBodySimyo>(
                environment.digoApiEndpoint + 'simyo/order/list/filter',
                body,
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getSimyoDetailOrder(
        orderId: any
    ): Observable<IorderDetailResponseBodySimyo> {
        return this.http
            .get<IorderDetailResponseBodySimyo>(
                environment.digoApiEndpoint + `simyo/order/${orderId}`,
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg[0]));
    }

    public getSimyoListSimSwapFilter(
        body: IListRequestBody
    ): Observable<IsimSwapListResponseBodySimyo> {
        return this.http
            .post<IsimSwapListResponseBodySimyo>(
                environment.digoApiEndpoint + 'simyo/sim-swap/list/filter',
                body,
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getSimyoDetailSimSwap(
        orderId: any
    ): Observable<IsimSwapDetailResponseBodySimyo> {
        return this.http
            .get<IsimSwapDetailResponseBodySimyo>(
                environment.digoApiEndpoint + `simyo/sim-swap/${orderId}`,
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getMasterSimyo(): Observable<any> {
        return this.http.get<any>(
            environment.digoApiEndpoint + '/simyo/order/form_filter/masters',
            { headers: this.getHeaders() }
        );
    }

    public getMasterSimyoSimSwap(): Observable<any> {
        return this.http.get<any>(
            environment.digoApiEndpoint + '/simyo/sim-swap/form_filter/masters',
            { headers: this.getHeaders() }
        );
    }

    public getCompanyByDistributorSimyo(distributor: any): Observable<any> {
        return this.http
            .post<any>(
                environment.digoApiEndpoint +
                    '/simyo/order/form_filter/company',
                { filters: { idDistributor: distributor } },
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getCompanyByDistributorSimyoSimSwap(
        distributor: any
    ): Observable<any> {
        return this.http
            .post<any>(
                environment.digoApiEndpoint +
                    '/simyo/sim-swap/form_filter/company',
                { filters: { idDistributor: distributor } },
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getDistributors() {
        return this.http
            .post(
                environment.digoApiEndpoint +
                    'simyo/order/form_filter/distributor',
                {},
                { headers: this.getHeaders() }
            )
            .pipe(map((response: any) => response.msg));
    }

    public getConfirmPendingUser(filters: any = null) {
        return this.http.get<any>(
            environment.digoApiEndpoint + 'user/list/inactive',
            { headers: this.getHeaders() }
        );
    }

    public confirmUser(idUser: any = 'all') {
        return this.http.post<any>(
            environment.digoApiEndpoint + 'user/confirm',
            { id_usuario: idUser },
            { headers: this.getHeaders() }
        );
    }

    public getContractsCount(filters: any) {
        return this.http.post<any>(
            environment.simyoEndPoint + 'digo/contracts/count',
            filters,
            { headers: this.getHeaders() }
        );
    }

    public getContracts(filters: any) {
        console.log(filters);
        return this.http.post<any>(
            environment.simyoEndPoint + 'digo/contracts',
            filters,
            { headers: this.getHeaders() }
        );
    }

    public getContractFilter(): Observable<any> {
        return this.http.get<any>(
            environment.simyoEndPoint + 'digo/contracts/list/filter',
            { headers: this.getHeaders() }
        );
    }

    public getContractAutoactivacionesFilter(): Observable<any> {
        return this.http.get<any>(
            environment.simyoEndPoint + 'digo/contracts/list/filter/autosim',
            { headers: this.getHeaders() }
        );
    }

    public getOrderListActivation(filters: any): Observable<any> {
        return this.http.post<any>(
            environment.simyoEndPoint + 'digo/order/activation/list',
            filters,
            { headers: this.getHeaders() }
        );
    }

    public getCountActivation(filters: any): Observable<any> {
        return this.http.post<any>(
            environment.simyoEndPoint + 'digo/order/status/count',
            filters,
            { headers: this.getHeaders() }
        );
    }

    public getOrderFiles(orderId: number): Observable<any> {
        return this.http.get<any>(
            `${environment.simyoEndPoint}digo/order/${orderId}/files`,
            { headers: this.getHeaders() }
        );
    }
    public getOrderDuplicatedFiles(simSwapId: number): Observable<any> {
        return this.http.get<any>(
            `${environment.simyoEndPoint}digo/sim-swap/documentation/${simSwapId}/files`,
            { headers: this.getHeaders() }
        );
    }

    public changeOrderStatus( orderId: number, status: string, annotationPublic: string, annotationPrivate: string, warnings: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/order/${orderId}/status`,
            {
                status,
                annotation_private: annotationPrivate,
                annotation_public: annotationPublic,
                warnings,
            },
            { headers: this.getHeaders() }
        );
    }
    public changeOrderDuplicatedStatus( simSwapId: number, status: string, annotationPublic: string, annotationPrivate: string, warning: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/sim-swap/documentation/${simSwapId}/status`,
            {
                status,
                annotation_private: annotationPrivate ?? '',
                annotation_public: annotationPublic ?? '',
                warning: warning ?? ''
            },
            { headers: this.getHeaders() }
        );
    }
    public changeFileStatus( orderId: number, status: string, annotation: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/document/${orderId}/status`,
            {
                status,
                annotation,
            },
            { headers: this.getHeaders() }
        );
    }
    public changeFileDuplicatedStatus( simSwapId: number, status: string, annotation: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/sim-swap/documentation/document/${simSwapId}/status `,
            {
                status,
                annotation,
            },
            { headers: this.getHeaders() }
        );
    }

    public changeAnnotationFile( orderId: number, annotation: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/document/${orderId}/data`,
            {
                annotation,
            },
            { headers: this.getHeaders() }
        );
    }
    public changeAnnotationFileDuplicated( simSwapId: number, annotation: string ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/sim-swap/documentation/document/${simSwapId}/data `,
            {
                annotation,
            },
            { headers: this.getHeaders() }
        );
    }

    public changeAnnotationOrder( orderId: number, data: any ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/order/${orderId}/data`,
            data,
            { headers: this.getHeaders() }
        );
    }
    public changeAnnotationOrderDuplicated( simSwapId: number, data: any ): Observable<any> {
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/sim-swap/documentation/${simSwapId}/data`,
            data,
            { headers: this.getHeaders() }
        );
    }

    public uploadOrderDocuments(orderId:string, file: string) {
        /*let request = {
            'multipart': {                
            {
            'name'     : 'file',
            'contents' : file,
            'filename' : 'custom_filename.pdf'
            }
        }};*/
        return this.http.post<any>(
            `${environment.simyoEndPoint}digo/order/${orderId}/document/upload-multiple `,
            {},
            { headers: this.getHeaders() }
        );
    }

    getImageUploadEndpoint(orderId: any): string {
        return `${environment.simyoEndPoint}order/${orderId}/document/upload-multiple`;
    }
}
