import {FormGroup} from '@angular/forms';

export function NoSpace(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors['noSpace']) {
            // return if another validator has already found an error on the control
            return;
        }

        // set error on control if validation fails
        if (!/^\S*$/.test(control.value)) {
            control.setErrors({noSpace: true});
        } else {
            control.setErrors(null);
        }
    };
}