<div class="container-max">
    <app-breadcrumbs
            [title]="'Logística <span class=\'font c-primary\'>Artículos</span>'"
            [breacrumbList]="['Herramientas', 'Logística', 'Listado Artículos']"
    ></app-breadcrumbs>
    <div class="inner-content-max" style="position: relative; top: 40px; left: 5px; z-index: 1" *ngIf="!loading && !error">
        <div nz-col nzSpan="2" nzOffset="6">
            <i title="Añadir artículo" (click)="gotoNewArticle()" nz-icon nzType="plus-square" nzTheme="outline" class="icon s-22" style="font-size: 30px; cursor:pointer;"></i>
        </div>
    </div>
    <app-list
            (tableClick)="tableClicked($event)"
            (pageChanged)="pageChanged($event)"
            (filtersApplied)="filtersApplied($event)"

            [filters]="filters"
            [table]="table"
            [orderBy]="orderBy"
            [paginator]="paginator"
            [loading]="loading"
            [error]="error"
    >

    </app-list>
</div>