import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexStroke,
    ApexTitleSubtitle,
    ApexDataLabels,
    ApexMarkers,
    ApexXAxis,
    ApexYAxis
} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    markers: ApexMarkers;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    title: ApexTitleSubtitle;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
};
@Component({
    selector: 'app-chart-operations',
    templateUrl: './operations.component.html',
    styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, OnChanges {

    @ViewChild("chart") chart: any;
    @Input() series: ApexAxisChartSeries = [];
    @Input() labels: string[] = [];
    public chartOptions: Partial<ChartOptions> | any;

    constructor() {
        this.chartOptions = {
            series: this.series,
            chart: {
                type: "line",
                height: 381,
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: this.labels
            },
            yaxis: {
                decimalsInFloat: 0,
                labels: {
                    formatter: function (val:any) {
                        return val.toFixed(0);
                    }
                }
            },
            markers: {
                hover: {
                    sizeOffset: 4
                }
            },
            stroke: {
                width: 2,
            }
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.chart && changes?.['series']?.currentValue && changes?.['series']?.currentValue.length) {
            this.chart.updateSeries(changes?.['series'].currentValue);
        }

        if (this.chart && this.labels && this.labels.length) {
            this.chart.updateOptions( {
                xaxis: {
                    categories: this.labels
                }
            });
        }
    }

    ngOnInit(): void {
    }

}
