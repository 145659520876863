<div class="container-max">
  <app-breadcrumbs
          [breacrumbList]="['Herramientas', 'Log bloquear usuarios']"
          [title]="'Herramientas <span class=\'font c-primary\'>Bloquear usuarios</span>'"
  ></app-breadcrumbs>

    <app-list
    (tableClick)="tableClicked($event)"
    (pageChanged)="pageChanged($event)"

    [table]="table"
    [orderBy]="orderBy"
    [paginator]="paginator"
    [loading]="loading"
    [error]="error"
  >

  </app-list>
</div>