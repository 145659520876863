import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlynumbers]'
})
export class OnlynumbersDirective {

  constructor() { }

  @HostListener('keypress', ['$event']) onPaste(event: any) {
    console.log('keypress event');
    const charCode = (event.which) ? event.which : event.keyCode;

    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
