
<div class="container-max">
    <div class="inner-content-max">
        <div class="head-content-max">
            <nz-breadcrumb class="font s-10" nzSeparator=">">
                <nz-breadcrumb-item>Dashboard</nz-breadcrumb-item>
                <nz-breadcrumb-item>Informes</nz-breadcrumb-item>
            </nz-breadcrumb>
            <span class="font s-10">Miércoles, 4 Febrero 2022</span>
        </div>
        <div>
            <h1 class="font s-16 c-black">Listado <span class="font c-primary">Prepago</span></h1>
        </div>
        <nz-tabset>
            <nz-tab nzTitle="Informes">
                <app-tab-reports></app-tab-reports>
            </nz-tab>
            <nz-tab nzTitle="Mapas estadísticos2">Mapas estadísticos</nz-tab>
            <nz-tab nzTitle="Informes solicitados">Informes solicitados</nz-tab>
            <nz-tab nzTitle="Informes diarios">Informes diarios</nz-tab>
            <nz-tab nzTitle="Suscripción a informes">Suscripción a informes</nz-tab>
            <nz-tab nzTitle="Informes guardados">
                <app-saved-reports></app-saved-reports>
            </nz-tab>
        </nz-tabset>
    </div>
  </div>

