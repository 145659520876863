<div nz-row class="inner-content-max">
    <div nz-col [nzXs]="18" [nzSm]="18" [nzMd]="18"[nzLg]="18"[nzXl]="18"[nzXXl]="18" class="inner-content-max" style="padding-right: 0px; padding-left: 0px; margin-bottom: 8px;">
        <div>
            <nz-breadcrumb class="font s-10" nzSeparator=">">
                <nz-breadcrumb-item *ngFor="let breadcrumb of breacrumbList">
                    {{ breadcrumb }}
                </nz-breadcrumb-item>
            </nz-breadcrumb>
        </div>
    </div>
    <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6"[nzLg]="6"[nzXl]="6"[nzXXl]="6" class="font s-10" align="end">
        {{ today | date: 'EEEE, d MMMM yyyy' }}
    </div>
    
    <div nz-col [nzXs]="18" [nzSm]="18" [nzMd]="18"[nzLg]="18"[nzXl]="18"[nzXXl]="18" class="title-content-max" style="margin-bottom: 0px;">
        <h1 class="font s-16 c-black w-bold" [innerHTML]="title"></h1>
    </div>
    <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6"[nzLg]="6"[nzXl]="6"[nzXXl]="6" align="right" *ngIf="showButtonBack">
        <button nz-button
            class="c-dark custom-button-back"
            (click)="goBack()">
            <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            Volver
        </button>
    </div>
    <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6"[nzLg]="6"[nzXl]="6"[nzXXl]="6" align="right" *ngIf="showButtonCancel">
        <button nz-button
            class="c-dark custom-button-back"
            (click)="goBack()">
            Cancelar
        </button>
    </div>
</div>