import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// import { TokenHasExpiredComponent } from 'src/app/core/components/token-has-expired/token-has-expired.component';
import { allLocalStorage } from '../shared/constantes';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userInfo: any;
    private userInfoSubscription: BehaviorSubject<any>;
    private username: BehaviorSubject<string>;
    private tokenSet: BehaviorSubject<string>;
    private resetIframe: BehaviorSubject<boolean>;
    private incognitoMode: boolean = false;
    private permissions: any;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private _http: HttpClient
    ) {
        this.username = new BehaviorSubject<string>('');
        this.tokenSet = new BehaviorSubject<string>('');
        this.userInfoSubscription = new BehaviorSubject<any>(null);
        this.resetIframe = new BehaviorSubject<boolean>(false);
    }

    // comprobar login / mandar a pantalla de login
    check() {
        this.getToken();
    }

    usernameFromJwt(jwt: string = '') {
        if (!jwt) {
            jwt = this.getToken() + '';
        }
        const data = this.parseJwt(jwt);
        return data.data.username;
    }

    getUsername() {
        return this.username.asObservable();
    }

    setUsername(value: string) {
        if (value) {
            this.setUsernameStorage(value);
            this.username.next(value);
        }
    }

    getIncognitoMode() {
        return this.incognitoMode;
    }

    setIncognitoMode(value: boolean) {
        this.incognitoMode = value;
    }

    private parseJwt(token: any): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch(error) {
            return false;
        }
    }

    checkIfTokenHasExpired(): boolean {
        const access_token = localStorage.getItem(allLocalStorage.accessToken);

        if (access_token && access_token !== '') {
            const jwt = this.parseJwt(access_token);
            if (!jwt) {
                return true;
            }
            const exp = jwt.exp * 1000;

            return new Date().getTime() >= exp;
        }

        return true;
    }

    getToken(): string | null | undefined {
        if (this.checkIfTokenHasExpired()) {
            const redirect = environment.fakeLogin ? 'login' : 'jwt';
            this.router.navigate((['/' + redirect]));

            // if (this.dialogTokenHasExpired == null) {
            //     /*this.dialogTokenHasExpired = this.dialog.open(TokenHasExpiredComponent, {
            //       // position: {top: '300px'},
            //       width: '350px',
            //       disableClose: true
            //     });*/
            // }
            //
            // return;
        }

        return localStorage.getItem(allLocalStorage.accessToken);
    }

    getTokenSet() {
        return this.tokenSet.asObservable();
    }

    setToken(token: string) {
        localStorage.setItem(allLocalStorage.accessToken, token);
        this.tokenSet.next(token);
    }

    getResetIframeState() {
        return this.resetIframe.asObservable();
    }

    setResetIframeState(state: boolean) {
        this.resetIframe.next(state);
    }

    setUsernameStorage(username: string) {
        if (username) {
            localStorage.setItem(allLocalStorage.username, username);
        }

    }

    // token que nunca expira. TODO: Borrar cuando haya sistema de login
    fakeLogin() {
        //var jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDYwNDIyMTIsImV4cCI6MTY0NjEyODYxMiwiZGF0YSI6eyJzZXNzaW9uSWQiOjYxMjgwNTEsImRpc3RyaWJ1dG9ySWQiOiIxODkiLCJvcmlnaW5hbFVzZXJJZCI6IiIsInBkdklkIjoiNCIsInVzZXJJZCI6IjY1IiwidXNlcm5hbWUiOiJkZW1vX3BkdiIsInJvbGUiOiJ1c2VyX3BkdiJ9fQ.js_N6aU3caNQphvEWVr5kfK0I7hdyNHQ4W5w_KuoN7k';
        const jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjMzMTgxMzEsImV4cCI6NDc3NjkxODEzMSwiZGF0YSI6eyJzZXNzaW9uSWQiOjE4MzE4OTYsInBkdklkIjo0LCJ1c2VySWQiOjY1LCJ1c2VybmFtZSI6ImRlbW9fcGR2Iiwicm9sZSI6InVzZXJfcGR2In19.fgkowX-pAlcNcpNngN6wnqMbhtuQ2brGr74x4WYsxN4';
        this.setToken(jwt);
        return localStorage.getItem("access_token");
    }

    // token que pillamos a pelo de un endpoint magico. TODO: Borrar cuando haya sistema de login
    fakeLogin2() {
        const fixedData = {
            "user_id": 27148,
            "username": "cesar.pombo",
            "role": "user_pdv",
            "distributor_id": null,
            "pdv_id":1

        };
        return this._http.post('https://ms-comun.lamarr.digo.tecalis.dev/user/login', fixedData, {
            headers: new HttpHeaders({
               'x-api-key': '04065dbb-83db-4a56-8f46-e092521d9392'
            })
        });
    }

    getUserInfoSuscription() {
        return this.userInfoSubscription.asObservable()
    }

    getUserInfo() {
        return this.userInfo;
    }

    setUserInfo(userInfo: any) {
        this.userInfo = userInfo;
        this.userInfoSubscription.next(this.userInfo);
        this.permissions = userInfo.permissions;
    }

    userCan(permission: string): boolean {
        const pieces = permission.split('.');
        let permResult = this.userInfo ? this.userInfo.permissions : null;
        pieces.forEach((piece: any) => {
            if (permResult && typeof permResult[piece] !== 'undefined') {
                permResult = permResult[piece];
            }
        });

        return typeof permResult === 'boolean' ? permResult : false;
    }

    iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    closeSession() {
        localStorage.clear();
        window.location.href = environment.oldDigoFront + '/signin.itx?ac=999';
    }

    getPermissions() {
        return this.permissions;
    }
    
    public userIsTradeUser() {
        return this.getUserInfo().specialUser === 'TRADE_USER';
    }
}
