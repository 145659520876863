import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyService} from "../../../services/http/company/company.service";
import { catchError, finalize, of, take, tap } from 'rxjs';
import { SupervisorService } from 'src/app/services/http/profiles/supervisor.service';

@Component({
    selector: 'app-modal-confirm-delete-supervisor',
    templateUrl: './modal-confirm-delete-supervisor.component.html',
    styleUrls: ['./modal-confirm-delete-supervisor.component.scss']
})
export class ModalConfirmDeleteSupervisorComponent implements OnInit {
    @Input() supervisor: any
    @Input() isVisible: boolean = false;

    @Output() updateList = new EventEmitter();
    @Output() cancelEvent = new EventEmitter();
    isLoading = false
    showMsgError = false;
    msgError = '';
    constructor(private supervisorService: SupervisorService) {
    }

    handleOk(): void {
        this.updateList.emit(this.supervisor);
    }

    handleCancel(): void {
        this.cancelEvent.emit();
    }

    ngOnInit(): void {
    }

    changeStatus() {
        this.isLoading = true
        let action = this.supervisor.activado === '1' ? this.supervisorService.deactivate(this.supervisor.id_cabecera_dho) : this.supervisorService.activate(this.supervisor.id_cabecera_dho);
        action.pipe(
            take(1),
            tap(response => {
                this.supervisor.activado = this.supervisor.activado === '1' ? '0' : '1';
                this.handleOk()
            }),
            catchError(err => {
                this.showMsgError = true;
                this.msgError = err.error?.error?.msg_complete !== '' ? err.error?.error?.msg_complete : 'Ha ocurrido un error al realizar el cambio de estado.';
                return of({});
            }),
            finalize(() => this.isLoading = false)
        )
        .subscribe()
    }

}
