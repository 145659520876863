<div class="container-max">
    <app-breadcrumbs
            [title]="'Herramientas <span class=\'font c-primary\'>Permisos</span>'"
            [breacrumbList]="['Herramientas', 'Permisos']"
    ></app-breadcrumbs>
    <div class="inner-content-max">
        <div class="wrapper-prepaid-list" style="justify-content: center;" *ngIf="loading">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
        <!-- listado permisos -->
        <div nz-row nzGutter="50" *ngIf="!loading">

            <div *ngIf="getScreenWidth < 992" class="btn-collapsed">
                <button (click)="isCollapsed = !isCollapsed" nz-button nzBlock class="c-primary">
                    {{ isCollapsed ? 'Mostrar permisos' : 'Ocultar permisos' }}
                </button>
            </div>
            
            <div nz-col nzXXl="{{ selectedPermission ? 10 : 24 }}" nzXl="{{ selectedPermission ? 10 : 24 }}" nzLg="{{ selectedPermission ? 10 : 24 }}" nzMd="24" nzXs="24" nzSm="24"
                 [ngStyle]="{borderRight: selectedPermission ? '1px solid gray' : 'none'}" [hidden]="isCollapsed">
                <div class="form-group">
                    <div class="form-group margins m-b-15">
                        <input type="text" id="search" oninvalid="" (input)="filter($event)" required>
                        <label for="search" class="control-label flex-content f-between" style="width: 100%">
                            <div>
                                <span class=""><b>Buscar</b></span>
                            </div>
                        </label><i class="bar"></i>
                    </div>
                </div>

                <div nz-row nzGutter="10">
                    <div nz-col nzXXl="{{ selectedPermission ? 12 : 6 }}" nzXl="{{ selectedPermission ? 12 : 6 }}" nzLg="{{ selectedPermission ? 12 : 6 }}" nzMd="24" nzXs="24" nzSm="24" 
                        *ngFor="let permission of permissionsFiltered"
                        (click)="selectPermission(permission)">
                        <div class="card-permission" [ngClass]="{'active' : selectedPermission && selectedPermission.id_pass === permission.id_pass}">
                            <div>
                                <span class="font s-14 w-bold">{{ permission.nombre }}</span>
                                <i nz-icon nzType="info-circle" nzTheme="outline" class="icon size-18 c-default" [title]="permission.descripcion"></i>
                            </div>
                        </div>
                    </div>
                    <p style="margin-left: 20px;" *ngIf="permissionsFiltered.length === 0">No se han encontrado resultados</p>

                </div>
            </div>




            <!-- configurador permiso -->
            <div nz-col nzXXl="14" nzXl="14" nzLg="14" nzMd="24" nzXs="24" nzSm="24" *ngIf="selectedPermission">
                <a *ngIf="getScreenWidth >= 992" class="flex-content margins m-b-15" (click)="selectedPermission = null">
                    <i nz-icon nzType="arrow-left" nzTheme="outline" class="icon c-orange size-14 margins m-r-10"></i>
                    <span class="font s-14 c-primary">Volver a permisos</span>
                </a>

                <app-configurador-permiso [screenWidth]="getScreenWidth" [selectedPermission]="selectedPermission"></app-configurador-permiso>

            </div>
        </div>
    </div>
</div>

