import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AuthService} from '../../auth.service';
import {ApiService} from '../api.service';
import { IincentiveListResponse } from 'src/app/pages/profiles/models/IpointsPdv';

@Injectable({
    providedIn: 'root'
})
export class ProfilesService extends ApiService {
    private currentTab = 0;
    private filters: any = null;
    private filtersDelegations: any = null;
    private filtersDistributors: any = null;
    private page = 1;
    private orderBy: any = null;
    private orderByDelegations: any = null;
    private delegationsPage = 1;
    private state: any = {};

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    saveState(type: 'distributor' | 'delegation', state: any) {
        this.state[type] = state;
    }

    getState(type: 'distributor' | 'delegation') {
        return this.state[type] || null;
    }

    clearState(type: 'distributor' | 'delegation') {
        delete this.state[type];
    }

    setCurrentTab(tab: number) {
        this.currentTab = tab
    }

    getCurrentTab(): number {
        return this.currentTab
    }

    setOrderBy(orderBy: any) {
        this.orderBy = orderBy;
    }

    setOrderByDelegations(orderBy: any) {
        this.orderByDelegations = orderBy;
    }

    getOrderBy(): any {
        return this.orderBy;
    }

    getOrderByDelegations(): any {
        return this.orderByDelegations;
    }

    changePassUser(oldPassword: any, newPassword: any): Observable<any> {
        return this.http.post<any>(
            environment.digoApiEndpoint + 'user/update_pass',
            {now_pass: oldPassword, new_pass: newPassword},
            {headers: this.getHeaders()}
        );
    }

    deleteDistributorUser(distributorId: any) {
        return this.http.delete<any>(environment.digoApiEndpoint + `distributor/${distributorId}`, {headers: this.getHeaders()});
    }

    setActionUser(user_id: string, action: string, parameters: any = null) {
        let body: any = parameters ?? {}
        body.ac = action
        return this.http.post(`${this.endpoint}distributor/users/${user_id}`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response))
    }

    getDistributors(body: any): Observable<IincentiveListResponse> {
        return this.http.post<IincentiveListResponse>(environment.digoApiEndpoint + `distributor/list/filter`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getDistributorsListFilterMaster(): Observable<any> {
        return this.http.get<any>(`${environment.digoApiEndpoint}distributor/list_form_filter/masters`, {headers: this.getHeaders()}).pipe(map((response: any) => response));
    }

    getListFilter() {
        return this.http.get(environment.digoApiEndpoint + 'distributor/list_form_filter/masters', {headers: this.getHeaders()}).pipe(map((response: any) => response))
    }

    getDistributorDetail(distributorId: any): Observable<any> {
        return this.http.get<any>(`${environment.digoApiEndpoint}distributor/${distributorId}`, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg.data));
    }

    getDistributorUsers(distributorId: any, body: any): Observable<any> {
        return this.http.post<any>(`${environment.digoApiEndpoint}distributor/${distributorId}/users`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg.data));
    }

    addNewUser(distributorId: any, body: any): Observable<any> {
        return this.http.post<any>(`${environment.digoApiEndpoint}distributor/${distributorId}/add/user`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg.data));
    }

    getDistributorImageUploadHeaders(): any {
        return {Authorization: 'Bearer ' + this.auth.getToken()}
    }

    getImageUploadEndpoint(distributorId: any): string {
        return `${environment.digoApiEndpoint}distributor/${distributorId}/image/upload`
    } 

    deleteDistributorImage(distributorId: any, imageId: any) {
        return this.http.get<any>(environment.digoApiEndpoint + `distributor/${distributorId}/image/${imageId}/delete`, {headers: this.getHeaders()});
    }

    getDelegations(body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `delegation/list/filter`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    getDelegationsListFilterMaster(): Observable<any> {
        return this.http.get<any>(`${environment.digoApiEndpoint}delegation/list_form_filter/masters`, {headers: this.getHeaders()}).pipe(map((response: any) => response));
    }

    getDelegationDetail(delegationId: any): Observable<any> {
        return this.http.get<any>(`${environment.digoApiEndpoint}delegation/${delegationId}`, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg.data));
    }

    createDistributor(body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `distributor/add`, body, {headers: this.getHeaders()});
    }

    editDistributor(id: number, body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `distributor/${id}`, body, {headers: this.getHeaders()});
    }

    createDelegation(body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `delegation/add`, body, {headers: this.getHeaders()});
    }

    editDelegation(id: number, body: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + `delegation/${id}`, body, {headers: this.getHeaders()});
    }

    getMasterDistributorCreate(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + `distributor/add_form_filter/masters`, {headers: this.getHeaders()});
    }

    getMasterDelegationCreate(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + `delegation/add_form_filter/masters`, {headers: this.getHeaders()});
    }

    getMasterDistributorList(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + `distributor/list_form_filter/masters`, {headers: this.getHeaders()});
    }

    setFilters(filters: any) {
        this.filters = filters
    }

    getFilters(): any {
        return this.filters
    }

    setFiltersDistributors(filters: any) {
        this.filters = this.filtersDistributors
    }

    getFiltersDistributors(): any {
        return this.filtersDistributors
    }

    setFiltersDelegations(filters: any) {
        this.filters = this.filtersDelegations
    }

    getFiltersDelegations(): any {
        return this.filtersDelegations
    }

    setPage(page: number) {
        this.page = page
    }

    setDelegationsPage(page: number) {
        this.delegationsPage = page
    }

    getPage(): number {
        return this.page
    }
}
