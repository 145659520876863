import {FormGroup} from '@angular/forms';

export function HasUpper(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors['hasUpper']) {
            // return if another validator has already found an error on the control
            return;
        }

        // set error on control if validation fails
        if (!/[A-Z]/.test(control.value)) {
            control.setErrors({hasUpper: true});
        } else {
            control.setErrors(null);
        }
    };
}