import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IClickRowTable, IListFilter, IListPaginator, ITableDigo } from 'src/app/core/models/lists.models';
import { BlockUsersService } from 'src/app/services/http/herramientas/block-users.service';

@Component({
  selector: 'app-log-block-users',
  templateUrl: './log-block-users.component.html',
  styleUrls: ['./log-block-users.component.scss']
})
export class LogBlockUsersComponent implements OnInit {
  public error: string | boolean = false;
  public loading: boolean = false;
  public filters!: IListFilter[];
  public table: ITableDigo | null = null;
  public orderBy: any;

  public paginator: IListPaginator = {
    limit: 20,
    totalPages: 1,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private blockUsersService: BlockUsersService,
    private router: Router
    ) 
    { }

  ngOnInit() {
    if (!this.blockUsersService.checkSuperAdmin()) {
      this.goHome();
    }
    this.blockUsersService.setPaginator(this.paginator);
    this.getActiveBlockedUsers();
  }

  public goHome(){
    this.router.navigate((['/'])).then()
  }

  public getActiveBlockedUsers(){
    this.loading = true;
    this.blockUsersService.getHistoricBlockedUsers()
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                          this.prepareTable(response.data);
                          this.preparePaginator(response);
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                      this.error = true;
                      this.loading = false;
                    },
                    complete: () => {
                      this.error = false;
                      this.loading = false;
                    }
                });
  }

  public updateTable(event: any){
    if (event) {
      this.getActiveBlockedUsers();
    }
  }

  public tableClicked(rowEvent: IClickRowTable) {
    if (rowEvent && rowEvent.rowId && rowEvent.rowId !== '') {
    }
  }
  
  public updatePageClicked(event: boolean) {
    if (event) {
        this.getActiveBlockedUsers();
    }
  }


  private prepareTable(data: any) { //any por lo que me manden
    const table: ITableDigo = {
        headers: {
            rows: [
                {
                    cells: [
                        { value: 'ID usuario' },
                        { value: 'Nombre de usuario' },
                        { value: 'Tipo de usuario'},
                        { value: 'Bloqueado por'},
                        { value: 'Fecha de bloqueo'},
                        { value: 'Comentarios'},
                        { value: 'Estado'}
                    ]
                }
            ]
        },
        body: {
            rows: []
        }
    };

    Object.keys(data).forEach((key: any) => {
        const item: any = data[key]; //any pro lo que me manden
        this.setDataTable(item, table);
    });

    this.table = table;
}

private setDataTable(row: any, table: ITableDigo) {
    table.body.rows.push({
        clickValue: row.username,
        cells: [
            {
            value: `<b>${row.id_user}</b>`,
            classes: this.getRowStyleByStatus(row.status)
          },
            {value: `<b>${row.username}</b>`,
            styles: {'min-width': '100px'}},
            {value: row.type,
              styles: {'min-width': '100px'}},
            {value: row.blocked_by,
              styles: {'min-width': '100px'}},
            {value: this.convertDate(row.blocked_at),
              styles: {'min-width': '150px'}},
            {value: this.changeCommentLength(row.comments),
            styles: {'max-width': '200px', 'overflow-wrap': 'break-word'}},
            {value: this.getBlockedStatus(row.status),
            title: row.status ? 'Bloqueado' : 'Desbloqueado',
            styles: {'min-width': '100px'}},
        ],
        classes: 'h-middle'
    });
    return table;
}

//Por si en el futuro se decide controlar el largo de los comentarios en el log
private changeCommentLength(comments: string){
  /*if (comments.length > 100) {
    return (comments.slice(0, 100) + '...');
  }*/ 
  return comments;
}

private getBlockedStatus(status: boolean){
  if (status) { //blocked
    return `<span class="user-block"></span>`;
  }
else{ //unblocked

  return `<span class="user-unblock"></span>`;
}
}

private getRowStyleByStatus(status: boolean): string {
      if (status) { //blocked
          return 'c-blocked';
      }
      else{ //unblocked
        return 'c-unblocked';
      }

}

private convertDate(date: string): string {
  return date && date !== '' ? moment(date).format('DD/MM/YYYY, HH:mm') : '-';
}

//Paginator
private preparePaginator(data: any) {
  this.paginator.currentPage = data.page;
  this.paginator.limit = 20;
  this.paginator.totalItems = data.total;
  this.paginator.totalPages = this.calculateTotalPages(this.paginator);
}

public pageChanged(paginator: IListPaginator) {
  this.paginator = paginator;
  this.blockUsersService.setPaginator(paginator);
  this.getActiveBlockedUsers();
}

private calculateTotalPages(data:any){
  if (data.totalItems <= data.limit) {
    return 1;
  }
  else{
    if (data.totalItems % data.limit == 0) {
      return Math.floor((data.totalItems/data.limit))
    }
    else{
      return Math.floor((data.totalItems/data.limit)) + 1
    }
  }
}

}
