import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ReportsService} from "../../../../../services/http/reports/reports.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import * as moment from 'moment';
import {isIterable} from "../../../../../core/functions";
import {AuthService} from "../../../../../services/auth.service";

@Component({
    selector: 'app-informes-listado',
    templateUrl: './informes-listado.component.html',
    styleUrls: ['./informes-listado.component.scss']
})
export class InformesListadoComponent implements OnInit {
    @Input() mapMode: boolean = false;
    @Output() gotoIndex = new EventEmitter();
    public reportsList: any[] = [];
    public reportsListPage: any[] = [];
    public loading = true;
    public selectedReport: any;
    public selectedReportCode: string = '';
    public exportMode: string = 'none';
    public form: FormGroup = new FormGroup({});
    public qpers: string|null = null;
    public qdirecta: string|null = null;
    public categories: number[] = [];
    public categoriesList: string[] = [];
    public iframeSrc: string = '';

    public tr: boolean[] = [true, false, false, false, false, false, false, false, false];

    isColumn = false;
    public getScreenWidth: any;
    public isCollapsed: boolean = true;

    constructor(
        private reportsService: ReportsService,
        private fb: FormBuilder,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.getScreenWidth = window.innerWidth;
        this.stateFilters();
        this.form = this.fb.group({
            report_limite: ['MA', Validators.required],
            buscar_report_fecha_desde: [moment().startOf('month').format('YYYY-MM-DD')],
            buscar_report_fecha_hasta: [moment().format('YYYY-MM-DD')],
            mc: ['', Validators.required],
        });

        this.loadList()

        // este es un mensaje que nos llega de digo cuando se abre informes_solicitados.itx dentro de un iframe
        window.onmessage = (event) => {
            console.log(event);
            if (event.data === "informes_solicitados.itx opened") {
                this.iframeSrc = '';
                this.gotoIndex.emit({tab: 'solicitados'});
            }
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.getScreenWidth = window.innerWidth;
        this.stateFilters();
    }

    stateFilters() {
        this.isCollapsed = this.getScreenWidth < 992 && this.isCollapsed;
    }

    loadList() {
        if (!this.mapMode) {
            this.reportsService.list({page: 1, limit: 100000})
                .subscribe((reports: any) => {
                    Object.keys(reports?.data?.reports).forEach(key => {
                        this.reportsList.push(reports.data.reports[key]);
                    });
                    this.categoriesList = reports?.data?.categories;
                    this.loading = false;
                    this.applyCategories()
                });
        } else {
            this.reportsService.listMaps({page: 1, limit: 100000})
                .subscribe((reports: any) => {
                    Object.keys(reports.data.reports).forEach(key => {
                        this.reportsList.push(reports.data.reports[key]);
                    });
                    this.categoriesList = reports?.data?.categories;
                    this.loading = false;
                    this.applyCategories()
                });
        }
    }

    applyCategories() {
        this.reportsListPage = this.reportsList.filter(report => {
            if (this.categories.length === 0 || this.categories.includes(1)) {
                return true;
            }
            let show = false;
            if (isIterable(report.categories_id)) {
                report.categories_id.forEach((category: any) => {
                    if (category !== '') {
                        if (this.categories.includes(Number(category))) {
                            show = true;
                        }
                    }
                })
            }
            return show;
        })
    }

    selectReport(report: any) {
        this.selectedReport = report;
        this.selectedReportCode = report.code;

        this.exportMode = !this.mapMode && report.scheduled ? 'programmed' : 'normal';

        if (this.exportMode === 'programmed') {
            this.form.get('mc')!.patchValue('solicitud_informe');
        } else {
            this.form.get('mc')!.patchValue('web');
        }
        window.scrollTo(0, 250);
    }

    onShow() {
        this.isColumn = !this.isColumn;
    }

    submit() {
        setTimeout(() => {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();

            if (this.form.valid) {
                let url = '';
                console.log(this.form.getRawValue())
                if (!this.mapMode) {
                    url = this.normalReportUrl(this.form.getRawValue());
                } else {
                    url = this.mapReportUrl(this.form.getRawValue());
                }

                if (url) {
                    // if (this.exportMode === 'programmed') {
                    //     window.location.href = url;
                    // }
                    //
                    if (this.auth.getIncognitoMode() || this.auth.iOS()){
                        window.location.href = url + '&noFrameMode=1&jwt=' + this.auth.getToken();
                    } else {
                        this.iframeSrc = url + '&iframe_mode=1&jwt=' + this.auth.getToken();
                    }
                }
            } else {
                console.log(this.form.errors)
            }
        }, 20)
    }

    normalReportUrl(value: any) {
        value.report_informe = this.selectedReportCode;
        if (this.qpers) {
            value.qpers = this.qpers;
        } else {
            value.qdirecta = this.qdirecta;
        }
        let url = environment.oldDigoFront + 'report_pnt.itx';
        let firstParam = true;
        Object.keys(value).forEach(key => {
            if (key === 'buscar_report_fecha_desde' || key === 'buscar_report_fecha_hasta') {
                value[key] = moment(value[key]).format('DD/MM/YYYY')
            }

            url += firstParam ? '?' : '&';
            url+= key + '=' + value[key];
            firstParam = false;
        });
        return url;
    }

    mapReportUrl(value: any) {
        let url = '';
        value.report_informe = this.selectedReportCode;
        if (this.qpers) {
            // value.qpers = this.qpers;
            url = environment.oldDigoFront + 'reports/statmaps/' + this.selectedReportCode + '/pers.itx';

        } else {
            // value.qdirecta = this.qdirecta;
            url = environment.oldDigoFront + 'reports/statmaps/' + this.selectedReportCode + '/map.itx';
        }
        let firstParam = true;
        Object.keys(value).forEach(key => {
            url += firstParam ? '?' : '&';
            url+= key + '=' + value[key];
            firstParam = false;
        });
        return url;
    }

    setMode(mode: string) {
        if (mode == 'pers') {
            this.qpers = 'Personalizada';
            this.qdirecta = null;
        } else {
            this.qpers = null;
            this.qdirecta = 'Consulta directa';
        }
    }

    changeDates(option: string) {
        const format = 'YYYY-MM-DD';
        const now = moment();
        let from = '';
        let to = now.format(format);
        switch (option) {
            case 'TO':
                to = '';
                break;
            case 'MA':
                from = now.startOf('month').format(format);
                break;
            case 'US':
                from = now.add(-7, 'days').format(format);
                break;
            case 'UQ':
                from = now.add(-15, 'days').format(format);
                break;
            case 'UM':
                from = now.add(-1, 'month').format(format);
                break;
            case 'USE':
                from = now.add(-6, 'month').format(format);
                break;
            case 'UA':
                from = now.add(-1, 'year').format(format);
                break;
            case 'AA':
                from = now.startOf('year').format(format);
                break;
        }
        this.form.get('buscar_report_fecha_desde')!.patchValue(from);
        this.form.get('buscar_report_fecha_hasta')!.patchValue(to);
    }

    toggleCategory(id: number, ev: any) {
        if (ev.target.checked) {
            if (id === 1) {
                // Si es todos deseleccionamos el resto y buscamos todos
                this.tr = [true, false, false, false, false, false, false, false];
                this.categories = [];
                this.categories.push(id);
            } else {
                // Si no es todos, deseleccionamos todos
                this.tr[0] = false;
                // Lo búscamos dentro de categoría para eliminarlo
                const index = this.categories.indexOf(1);
                if (index !== -1) {
                    this.categories.splice(index, 1);
                }
                // Añadimos la categoría seleccionada
                if (!this.categories.includes(id)) {
                    this.categories.push(id);
                }
            }
        } else {
            if (id === 1) {
                this.tr = [true, false, false, false, false, false, false, false];
                this.categories = [];
                this.categories.push(id);
            } else {
                this.tr[id - 1] = false;
                // Buscamos si hay alguno seleccionado
                const found = this.tr.find(value => value);
                if (!found) {
                    this.tr[0] = true;
                }
                const index = this.categories.indexOf(id);
                if (index !== -1) {
                    this.categories.splice(index, 1);
                }
            }
        }
        this.applyCategories()
    }

    gotourl(url: string) {
        window.location.href = url;
    }

}
