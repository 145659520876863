import {Component, Input, OnInit} from '@angular/core';
import {CompanyService} from "../../../services/http/company/company.service";

@Component({
    selector: 'app-modal-change-password',
    templateUrl: './modal-change-password.component.html',
    styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {
    @Input() user: any

    isVisible = false;
    isLoading = false
    isChanged = false

    constructor(private companyService: CompanyService) {
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleClose(): void {
        this.isVisible = false;
        this.isLoading = false
        this.isChanged = false
    }

    ngOnInit(): void {
    }

    changePassword = () => {
        this.isLoading = true
        this.companyService.setActionUser(this.user.id_user, 'cambiar_pass').subscribe(response => {
            this.isLoading = false
            this.isChanged = true
            //this.handleOk()
        })
    }

}
