import {Component, Input, OnInit} from '@angular/core';
import {CompanyService} from "../../../services/http/company/company.service";

@Component({
    selector: 'app-modal-change-user',
    templateUrl: './modal-change-user.component.html',
    styleUrls: ['./modal-change-user.component.scss']
})
export class ModalChangeUserComponent implements OnInit {
    @Input() pdv_name: string = "";
    @Input() user: any;

    public isVisible = false;
    public isLoading = false;
    public alias = "";
    public isChanged: boolean = false;

    constructor(private companyService: CompanyService) {
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleCancel(): void {
        this.isVisible = false;
        this.isChanged = false;
    }

    ngOnInit(): void {
        this.alias = this.user.alias
    }

    setUsername(target: any) {
        this.alias = target.value
    }

    submit() {
        if (this.alias == null || this.alias.trim() == '') {
            return
        }

        let parameters = {
            alias: this.alias
        }

        this.isLoading = true
        this.companyService.setActionUser(this.user.id_user, 'cambiar_alias', parameters).subscribe(response => {
            this.isLoading = false
            this.user.alias = this.alias
            this.isChanged = true;
        })
    }

}
