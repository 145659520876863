import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { IClickRowTable, ITableDigo } from 'src/app/core/models/lists.models';
import { superStatus } from 'src/app/shared/constantes';
import {isIterable} from "../../../../../functions";

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

    @Input() table: any = null;
    @Input() orderBy?: string;
    @Input() noResultsText?: string;
    @Output() clickEvent = new EventEmitter<IClickRowTable>();

    public superStatus: { [key: string]: string }  = superStatus;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.orderBy && this.orderBy !== '' && this.table?.headers?.rows.length && this.table?.headers?.rows[0]?.cells) {
            this.table?.headers?.rows[0]?.cells.forEach((unit: any, index: number) => {
                if (unit.order && (unit.order.desc === this.orderBy || unit.order.asc === this.orderBy)) {
                    unit.orderValue = this.orderBy;
                }
            })
        }
    }

    returnClickValue(event: any, row: any, cellIndex: number, cell: any) {
        if (event.view.getSelection().toString().length === 0) {
            if (cell?.order) {
                this.table.headers.rows[0].cells.forEach((unit: any, index: number) => {
                    if (index === cellIndex) {
                        // cambiamos a descendente
                        if (!cell.orderValue) {
                            cell.orderValue = cell.order.desc;
                        } else if (cell.orderValue.includes('asc')) {
                            // cambiamos a sin orden
                            cell.orderValue = null;
                        } else if (cell.orderValue.includes('desc')) {
                            // cambiamos a ascendente
                            cell.orderValue = cell.order.asc;
                        }
                    } else {
                        if (unit.order) {
                            unit.orderValue = null;
                        }
                    }
                })
            }
            this.clickEvent.emit({
                'event': event,
                'rowId': typeof row.clickValue !== 'undefined' ? row.clickValue : null,
                'cellIndex': cellIndex,
                'cell': cell
            });
        }
    }

    getClasses(item: any) {
        if (item && isIterable(item.classes)) {
            return item.classes.join(' ');
        }
        return ''
    }
}
