<header [ngClass]="{'container-header-collapsed': isCollapsed === true, 'container-header' : isCollapsed === false }" class="container-header">
    <div class="inner-header">
        <div class="content-brand">
            <div class="cursor-pointer" routerLink="/">
                <img src="../../../../assets/images/logo-orange.png"/>
                <span class="font s-18 c-primary w-ligth">digo{{' '}}<span class="font c-white">admin</span></span>
                <img src="../../../../assets/images/logo_fnac.png" class="logo-fnac" *ngIf="userInfo?.is_fnac"/>
            </div>
            <div class="open-menu-mobile" (click)="onShow()">
                <i 
                    nz-icon nzType="menu" 
                    class="icon s-14 c-white" 
                    nzTheme="outline">        
                </i>
            </div>
        </div>
        <div *ngIf="!isCollapsed" class="content-user">
            <div class="subcontent-name" style="margin: 0px;">
                <span *ngIf="username"
                    class="font s-13 c-primary">
                    Hola {{ username }}
                    <span class="font c-white">
                        ({{ username }})
                    </span>
                </span>
            </div>
            <div class="subcontent-alerts" tabindex="1">
                <!--<i nz-icon nzType="bell" class="icon s-14 c-white" nzTheme="outline"></i>-->
            </div>
            <div class="subcontent-menu">
                <a nz-dropdown [nzDropdownMenu]="menu">
                    <i nz-icon nzType="user" class="icon s-14 c-white icon-user" nzTheme="outline"></i>
                    <i nz-icon nzType="down" class="icon s-14 c-white" nzTheme="outline" tabindex="2"></i>
                </a>
                <nz-dropdown-menu  #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                    <!--<li nz-menu-item>1st menu item</li>-->
                    <!--<li nz-menu-item>2nd menu item</li>-->
                    <!--<li nz-menu-item>3rd menu item</li>-->
                    <li nz-menu-item nzDanger (click)="closeSession()">Cerrar sesión</li>
                </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</header> 
<div class="content-menu-mobile" *ngIf="isCollapsed">
    <app-menu-mobile></app-menu-mobile>
</div>



