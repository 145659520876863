import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saved-reports',
  templateUrl: './saved-reports.component.html',
  styleUrls: ['./saved-reports.component.scss']
})
export class SavedReportsComponent implements OnInit {

  reportsList = [
    { id: 1, name: "Solicitudes (Antifraude) (013)" },
    { id: 2, name: "Reporte Datos MSISDN (por fechas) (018vb2)" },
    { id: 3, name: "Reporte Datos MISISDN (por fecha) (018B)" },
    { id: 4, name: "Reporte Datos MISISDN (por fecha) (018B)" },
  ];

  isColumn = false;
  
  onShow() {
    this.isColumn = !this.isColumn;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
