import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ceil',
  templateUrl: './ceil.component.html',
  styleUrls: ['./ceil.component.scss']
})
export class CeilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
