<div class="container-max">
    <app-breadcrumbs
        [breacrumbList]="page"
        [showButtonBack]="iframeSrc.includes('logis_articulo.itx?ac=r&logis_articulo_id_logis_articulo')"
    ></app-breadcrumbs>
    
    <p class="font s-12" align="center" *ngIf="isIncognitoMode">
        Con el navegador en incógnito la navegación en DIGO anterior puede no funcionar correctamente
    </p>
    
    <div class="inner-content-max">
        <iframe
                #myIframe
                *ngIf="iframeSrc && iframeSrc !== ''"
                [hidden]="hiddenIframe"
                sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-downloads"
                [src]="iframeSrc | trustUrl"
                style="width: 100%; min-height: 1200px; border: none"
                (load)="onloadIframe($event, iframeSrc)">
        </iframe>
        <div align="center" *ngIf="hiddenIframe">
            <h5 class="font s-16 margins m-t-70">Cargando...</h5>
            <img src="./assets/images/loader.svg" width="60" alt="">
        </div>
    </div>
</div>
