import { HttpErrorResponse } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/core/validators/mustmatch.validators';
import {PdvService} from "../../../services/http/pdv/pdv.service";
import { AuthService } from 'src/app/services/auth.service';
import { emailValidator } from 'src/app/core/validators/email.validator';

@Component({
    selector: 'app-modal-add-user',
    templateUrl: './modal-add-user.component.html',
    styleUrls: ['./modal-add-user.component.scss']
})
export class ModalAddUserComponent implements OnInit {
    @Input() pdv: any;

    public userFormGroup!: FormGroup;

    
    public isVisible:boolean = false;
    public isLoading:boolean = false;
    public isAdded:boolean = false;
    public isError:boolean = false;

    constructor(
        private pdvService: PdvService,
        private fb: FormBuilder,
        public authService: AuthService
    ) { }

    ngOnInit(): void {
        this.userFormGroup = this.fb.group({
            alias: [null],
            email: [null, [Validators.required, emailValidator()]],
            emailConfirmation: [{value:null, disabled:true}, [Validators.required, emailValidator()]],

        }, {
            validator: MustMatch('email', 'emailConfirmation')
        });
        this.userFormGroup.get('email')?.valueChanges.subscribe(() => {
            let emailConfirmation = this.userFormGroup.get('emailConfirmation');
            emailConfirmation?.markAsUntouched();
            emailConfirmation?.setValue('');
            emailConfirmation?.disable();
            
            if (this.userFormGroup.get('email')?.valid) {
                emailConfirmation?.enable();
            }
        })
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleClose() {
        this.userFormGroup.reset();
        this.isVisible = false;
        this.isAdded = false;
        this.isError = false;
    }

    createUser() {
        this.userFormGroup.markAllAsTouched();
        if (this.userFormGroup.valid) {
            this.isLoading = true;
            this.pdvService.addNewUser(this.pdv.id_pdv, this.pdv.denominacion, this.userFormGroup.get('alias')?.value, this.userFormGroup.get('email')?.value) 
            .subscribe({
                next: (response: any) => {
                    if (response === true) {
                        this.getPdv();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.isError = true;
                    this.isAdded = false;
                },
                complete: () => {
                    this.isLoading = false;
                    this.userFormGroup.reset();
                }
            });
        }
    }

    getPdv() {
        this.pdvService.get(this.pdv.id_pdv).subscribe(response => {
            this.pdv.users = response.data.users;
            this.isLoading = false;
            this.isAdded = true;
            this.isError = false;
        })
    }

    public getControl(controlName:string) {
        return this.userFormGroup ? (this.userFormGroup?.get(controlName) as FormControl) : new FormControl;
      }

}