import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { AuthService } from "../../auth.service";
import { HttpClient } from "@angular/common/http";
import { pageLimit } from "src/app/shared/constantes";
import { Observable, ReplaySubject, map } from "rxjs";
import { environment } from "src/environments/environment";
import { IListRequestBody } from "src/app/core/models/lists.models";
import { ISupervisorListDetail, ISupervisorListResponse, ISupervisorListResponseBody } from "src/app/pages/profiles/supervisor/models/ISupervisorList";

@Injectable({
    providedIn: 'root'
})
export class SupervisorService extends ApiService {

    private filters: any = null;
    private pagintor: any = {limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0};
    private orderBy: any = null;
    private supervisor: ReplaySubject<ISupervisorListDetail>;
    private supervisorState: any = null;


    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.supervisor = new ReplaySubject<ISupervisorListDetail>();
    }

    saveSupervisorState(state: any) {
        this.supervisorState = state;
    }

    getSupervisorState() {
        return this.supervisorState;
    }

    clearSupervisorState() {
        this.supervisorState = null;
    }

    public setFilters(filters: any) {
        this.filters = filters;
    }

    public getFilters(): any {
        return this.filters;
    }

    public setPaginator(pagintor: any) {
        this.pagintor = pagintor;
    }

    public getPaginator(): any {
        return this.pagintor;
    }

    public setOrderBy(orderBy: any) {
        this.orderBy = orderBy;
    }

    public getOrderBy(): any {
        return this.orderBy;
    }

    public setSupervisor(supervisor: ISupervisorListDetail) {
        this.supervisor.next(supervisor);
    }

    public getSupervisor(id: any): Observable<ISupervisorListDetail> {
        return this.http.get<any>(`${environment.digoApiEndpoint}supervisor/${id}`, { headers: this.getHeaders()}).pipe(map((response: any) => response.msg.data));;
    }

    public getMasterFilters(): Observable<any> {
        return this.http.get<any>(`${environment.digoApiEndpoint}supervisor/list_form_filter/masters`, { headers: this.getHeaders()} );
    }

    public getList(filters: IListRequestBody): Observable<ISupervisorListResponseBody> {
        return this.http.post<ISupervisorListResponseBody>(`${environment.digoApiEndpoint}supervisor/list/filter`, filters, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public add(data: any) {
        return this.http.post<any>(`${environment.digoApiEndpoint}supervisor/add`, data, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public edit(id: string, data: any) {
        return this.http.post<any>(`${environment.digoApiEndpoint}supervisor/${id}`, data, { headers: this.getHeaders()} ).pipe(map((response: any) => response.msg));
    }

    public deactivate(id: string) {
        return this.http.delete(`${environment.digoApiEndpoint}supervisor/${id}`, { headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }

    public activate(id: string) {
        return this.http.post(`${environment.digoApiEndpoint}supervisor/${id}/activate`, '', { headers: this.getHeaders()}).pipe(map((response: any) => response.msg));
    }
}