import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUsersService } from 'src/app/services/http/herramientas/block-users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BlockUserSearched } from 'src/app/pages/herramientas/models/blockedUsers.models';


@Component({
  selector: 'app-modal-add-blocked-user',
  templateUrl: './modal-add-blocked-user.component.html',
  styleUrls: ['./modal-add-blocked-user.component.scss']
})
export class ModalAddBlockedUserComponent implements OnInit {
    @Input() isVisible: boolean = false;
    @Output() confirmAddBlockedUser = new EventEmitter<boolean>();
    @Output() hideModal = new EventEmitter<boolean>();
    @Output() updateTable = new EventEmitter<boolean>();

  
    //Loaders
    public isLoading: boolean = false;
    public isLoadingSearch: boolean = false;
    public isLoadingAdd: boolean = false;

    //ErrorMsg
    public ErrorMsg: string = '';

    //Results
    public isChanged: boolean = false;
    public finalStepConfirm: boolean = false;
    public isAdded: boolean = false;
    public isErrorAdd: boolean = false;
    public isErrorSearch: boolean = false;

    

    public user!: BlockUserSearched;

    //form
    public userSearchFormGroup!: FormGroup;
    public userBlockFormGroup!: FormGroup;

  
    constructor(
      private blockUsersService: BlockUsersService,
      private fb: FormBuilder
    ) { }
  
    ngOnInit() {
        this.userSearchFormGroup = this.fb.group({
            username: ['', [Validators.required]]
        });

        this.userBlockFormGroup = this.fb.group({
          user_id: ['', [Validators.required]],
          comments: [''],
          blocked_status: ["blocked", [Validators.required]]
        });

        this.userSearchFormGroup.valueChanges.subscribe(data => {
          if (this.userSearchFormGroup.get('username')?.value === '') {
              this.isErrorSearch = false;
          }
      })
    }

    public searchUser(){
        if(this.userSearchFormGroup.valid){
            this.isLoadingSearch = true;
            this.blockUsersService.searchBlockedUsers(this.userSearchFormGroup.get('username')?.value)
                .subscribe({
                    next: (response: any) => {
                        if (response && response.data && response.data[0]) {
                          if (!this.userAlreadyBlocked(response.data[0])) {
                            this.user = response.data[0];
                            this.userBlockFormGroup.get("user_id")?.patchValue(this.user.id_user);
                            this.isErrorSearch = false;
                          }
                          else{
                            this.ErrorMsg = 'El usuario indicado ya se encuentra bloqueado';
                            this.isErrorSearch = true;
                          }
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.ErrorMsg = 'Ha ocurrido un error, inténtelo de nuevo más tarde';
                        if (error?.error?.error?.errorCode === 0) {
                            this.ErrorMsg = 'El usuario indicado no se ha encontrado';
                        }
                        this.isLoadingSearch = false;
                        this.isErrorSearch = true;
                    },
                    complete: () => {
                        this.isLoadingSearch = false;
                    }
                });
          }
    }

    public userAlreadyBlocked(user:BlockUserSearched){
      return user.status;
    }

    public checkConfirmation(){
      if (this.finalStepConfirm && this.userBlockFormGroup.valid) {
        this.applyBlockUser();
      }
      this.finalStepConfirm = true;
    }

    public applyBlockUser(){
      if (this.userBlockFormGroup.valid) {
        this.blockUsersService.modifyBlockedUser(this.userBlockFormGroup.value)
          .subscribe({
              next: (response: any) => {
                  if (response && response.data && response.data[0]) {
                    this.user = response.data[0];
                  }
              },
              error: (error: HttpErrorResponse) => {
                  this.ErrorMsg = 'Ha ocurrido un error, inténtelo de nuevo más tarde';
                  if (error?.error?.error?.errorCode === 1000) {
                      this.ErrorMsg = 'Usuario no encontrado';
                  }
                  this.isLoadingAdd = false;
                  this.isErrorAdd = true;
                  this.finalStepConfirm = false;
              },
              complete: () => {
                  this.isLoadingAdd = false;
                  this.isErrorAdd = false;
                  this.isAdded = true;
                  this.updateTable.emit(true);
              }
          });
      }
    }

    //Modal handlers
    handleOk(): void {
      this.isVisible = false;
      this.hideModal.emit(true);
      this.userSearchFormGroup.reset();
      this.userBlockFormGroup.reset();
      this.finalStepConfirm = false;

    }
  
    handleCancel(): void {
      if (!this.finalStepConfirm) {
        this.userSearchFormGroup.reset();
        this.userBlockFormGroup.reset();
        this.isVisible = false;
        this.hideModal.emit(true);
      }
      this.finalStepConfirm = false;
    }
  
  }
  