import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IClickRowTable, IListFilter, IListPaginator, ITableDigo } from 'src/app/core/models/lists.models';
import { AuthService } from 'src/app/services/auth.service';
import { BlockUsersService } from 'src/app/services/http/herramientas/block-users.service';

@Component({
  selector: 'app-block-users',
  templateUrl: './block-users.component.html',
  styleUrls: ['./block-users.component.scss']
})
export class BlockUsersComponent implements OnInit {
  public error: string | boolean = false;
  public loading: boolean = false;
  public filters!: IListFilter[];
  public table: ITableDigo | null = null;
  public orderBy: any;
  public showUserDetail: boolean = false;
  public userDetail: string = '';
  public showAddUser: boolean = false;

  public paginator: IListPaginator = {
    limit: 5,
    totalPages: 1,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private blockUsersService: BlockUsersService,
    private router: Router
    ) 
    { }

  ngOnInit() {
    if (!this.blockUsersService.checkSuperAdmin()) {
      this.goHome();
    }
    this.blockUsersService.setPaginator(this.paginator);
    this.getActiveBlockedUsers();
  }

  public goHome(){
    this.router.navigate((['/'])).then()
  }

  public getActiveBlockedUsers(){
    this.loading = true;
    this.blockUsersService.getActiveBlockedUsers()
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                          this.prepareTable(response.data);
                          this.preparePaginator(response);
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                      this.error = true;
                      this.loading = false;
                    },
                    complete: () => {
                      this.error = false;
                      this.loading = false;

                    }
                });
  }

  public updateTable(event: any){
    if (event) {
      this.getActiveBlockedUsers();
    }
  }

  public tableClicked(rowEvent: IClickRowTable) {
    if (rowEvent && rowEvent.rowId && rowEvent.rowId !== '') {
      this.showDetail(rowEvent.rowId);
    }
  }
  
  public updatePageClicked(event: boolean) {
    if (event) {
        this.getActiveBlockedUsers();
    }
  }

  //Bloquear a un usuario
  public AddBlockedUser(){
    this.showAddUser = true;
  }

  public showDetail(username: string){
    this.userDetail = username;
    this.showUserDetail = true;
  }

  private prepareTable(data: any) { //any por lo que me manden
    const table: ITableDigo = {
        headers: {
            rows: [
                {
                    cells: [
                        { value: 'ID usuario' },
                        { value: 'Nombre de usuario' },
                        { value: 'Tipo de usuario'},
                        { value: 'Bloqueado por'},
                        { value: 'Fecha de bloqueo'},
                        { value: 'Comentarios'},
                    ]
                }
            ]
        },
        body: {
            rows: []
        }
    };

    Object.keys(data).forEach((key: any) => {
        const item: any = data[key]; //any pro lo que me manden
        this.setDataTable(item, table);
    });

    this.table = table;
}

private setDataTable(row: any, table: ITableDigo) {
    table.body.rows.push({
        clickValue: row.username,
        cells: [
            {value: `<b>${row.id_user}</b>`},
            {value: `<b>${row.username}</b>`},
            {value: row.type},
            {value: row.blocked_by},
            {value: this.convertDate(row.blocked_at)},
            {value: this.changeCommentLength(row.comments),
              styles: {'max-width': '20%'}
            },
        ],
        classes: 'h-middle'
    });
    return table;
}

private changeCommentLength(comments: string){
  if (comments.length > 130) {
    return (comments.slice(0, 130) + '...');
  }
  return comments;
}

private convertDate(date: string): string {
  return date && date !== '' ? moment(date).format('DD/MM/YYYY, HH:mm') : '-';
}

//Paginator
private preparePaginator(data: any) {
  this.paginator.currentPage = data.page;
  this.paginator.limit = 5;
  this.paginator.totalItems = data.total;
  this.paginator.totalPages = this.calculateTotalPages(this.paginator);
}

public pageChanged(paginator: IListPaginator) {
  this.paginator = paginator;
  this.blockUsersService.setPaginator(paginator);
  this.getActiveBlockedUsers();
}

private calculateTotalPages(data:any){
  if (data.totalItems <= data.limit) {
    return 1;
  }
  else{
    if (data.totalItems % data.limit == 0) {
      return Math.floor((data.totalItems/data.limit))
    }
    else{
      return Math.floor((data.totalItems/data.limit)) + 1
    }
  }
}

}
