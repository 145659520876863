import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {AuthService} from "../../auth.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {IcreateCompanyBodyRequest} from 'src/app/pages/profiles/models/IcreateCompany';
import {IeditCompanyBodyRequest} from 'src/app/pages/profiles/models/IeditCompany';
import {DatePipe} from "@angular/common";
import {CompanyOrder} from "../../../core/models/lists.models";

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends ApiService {
    private page = 1
    private filters: any = {activated: 'true'}

    constructor(auth: AuthService, http: HttpClient) {
        super(auth, http);
    }

    list(page = 1, filters = null, order = CompanyOrder.EMPRESA_RAZON_SOCIAL, limit = 15) {
        let url = `${this.endpoint}company/list/filter`
        let headers = this.getHeaders()
        let params = {
            limit,
            page,
            ordenar: order,
            filters
        }

        return this.http.post(url, params, {headers}).pipe(map((response: any) => response.msg))
    }

    get(companyId: any) {
        let url = `${this.endpoint}company/${companyId}`

        return this.http.get(url, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    getMasters() {
        let url = `${this.endpoint}company/list_form_filter/masters`

        return this.http.get(url, {headers: this.getHeaders()}).pipe(map((response: any) => response))
    }

    getActivations(companyId: any, date: Date) {
        let datePipe = new DatePipe("es-419");
        let date_from = new Date(date.getFullYear(), date.getMonth() - 1, 1)
        let date_to = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        let url = `${this.endpoint}company/${companyId}/stats`
        let body = {
            date_from: datePipe.transform(date_from, "yyyy-MM-dd"),
            date_to: datePipe.transform(date_to, "yyyy-MM-dd")
        }

        return this.http.post(url, body, {headers: this.getHeaders()}).pipe(map((response: any) => response.msg))
    }

    setActionUser(user_id: string, action: string, parameters: any = null) {
        let body: any = parameters ?? {}

        body.ac = action

        return this.http.post(`${this.endpoint}company/users/${user_id}`, body, {headers: this.getHeaders()}).pipe(map((response: any) => response))
    }

    setPage(page: number) {
        this.page = page
    }

    getPage(): number {
        return this.page
    }

    setFilters(filters: any) {
        this.filters = filters
    }

    getFilters(): any {
        return this.filters
    }

    public createCompany(body: IcreateCompanyBodyRequest): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'company/add', body, {headers: this.getHeaders()});
    }

    public getMasterCompany(): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + 'company/add_form_filter/masters', {headers: this.getHeaders()});
    }

    public editCompany(id: any, body: IeditCompanyBodyRequest): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'company/' + id, body, {headers: this.getHeaders()});
    }
    
    public getDelegationCompany(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'company/form_filter/delegation', {filters: {id_distributor: distributor}}, {headers: this.getHeaders()});
    }

    public getMerchanCompany(distributor: any): Observable<any> {
        return this.http.post<any>(environment.digoApiEndpoint + 'company/form_filter/merchan', {filters: {id_distributor: distributor}}, {headers: this.getHeaders()});
    }

    public changeDataComisionado(id: any): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + 'company/' + id + '/commissioner/renew', {headers: this.getHeaders()});
    }

    public reactivateComisionado(id: any): Observable<any> {
        return this.http.get<any>(environment.digoApiEndpoint + 'company/' + id + '/commissioner/reset/manual', {headers: this.getHeaders()});
    }
}
