<div [ngSwitch]="status" class="inner-content-card {{class}}" [title]="title && title !== '' ? title : ''">
    <ng-container *ngSwitchCase="superStatus['draft']">
        <div class="flex-content f-between">
            <span class="font s-10 c-success status-name margins m-t-10">Borrador</span>
        </div>
        <nz-progress [nzStrokeWidth]="2" [nzPercent]="0" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#000', '100%': '#000' }"></nz-progress>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['active']">
        <div class="flex-content f-between">
            <span class="font s-10 c-success status-name margins m-t-10">
                {{ statusName && statusName !== '' ? statusName : 'Completado' }}
            </span>
        </div>
        <nz-progress [nzStrokeWidth]="2" [nzPercent]="100" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#50BE87', '100%': '#50BE87' }"></nz-progress>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['in_process']">
        <div class="flex-content f-between">
            <span class="font s-10 c-pending status-name margins m-t-10" *ngIf="brand === 'orange'">
                {{ statusName && statusName !== '' ? statusName : 'Pendiente' }}
            </span>
            <span class="font s-10 c-pending status-name margins m-t-10" *ngIf="brand !== 'orange'">
                {{ statusName && statusName !== '' ? statusName : 'En proceso' }}
            </span>
            <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
        </div>
        <nz-progress [nzStrokeWidth]="2" [nzPercent]="70" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#008ffb', '100%': '#008ffb' }"></nz-progress>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['cancelled']">
        <span class="font s-12 c-danger cursor-default" style="margin-right: 5px;">
            {{ statusName && statusName !== '' ?  statusName : 'Cancelada' }}
        </span>
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['rejected']">
        <span class="font s-12 c-danger cursor-default" style="margin-right: 5px;">
            Rechazado
        </span>
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['error']">
        <span class="font s-12 c-danger cursor-default" style="margin-right: 5px;">
            {{ statusName && statusName !== '' ? statusName : 'Error' }}
        </span>
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="superStatus['normal']">
        <span class="font s-12 cursor-default" style="margin-right: 5px;">
            {{ statusName && statusName !== '' ? statusName : status }}
        </span>
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <span class="font s-12 c-danger cursor-default" style="margin-right: 5px;">
            {{ status }}
        </span>
        <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
    </ng-container>
</div>

<ng-template #statusDescriptionTooltip>
    {{ statusDescription }}
</ng-template>

<ng-template #infoIcon>
    <i *ngIf="statusDescription && statusDescription !== ''"
        nz-tooltip
        [nzTooltipTitle]="statusDescriptionTooltip"
        class="cursor-pointer"
        nz-icon
        nzType="info-circle"
        nzTheme="outline">
    </i>
</ng-template>