<div class="flex-content f-between">
    <ul class="list-header-prepaid">
        <li>
            <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
        </li>
        <li>
            <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></button>
        </li>
    </ul>
    <span class="font s-12 w-bold">Página 1/4566</span>
</div>
<div class="wrapper-prepaid-list">
    <div class="header-table-prepaid">
        <div class="th-large">
           <span class="font s-13 c-black">Nombre</span>
        </div>
        <div class="th-small">
            <span class="font s-13 c-black">MSISDN</span>
        </div>
        <div class="th-large">
            <span class="font s-13 c-black">Punto de venta</span>
        </div>
        <div class="th-middle">
            <span class="font s-13 c-black">Fecha</span>
        </div>
        <div class="th-small">
            <span class="font s-13 c-black">Estado</span> 
        </div>
        <div class="th-small">
            <span class="font s-13 c-black">Documentación</span>
        </div>
    </div>
    <div class="card-prepaid c-pending">
        <div class="th-large">
            <span class="font s-12 c-black w-bold">Alberto Fernández García</span>
         </div>
         <div class="th-small">
             <span class="font s-12 c-black w-bold">666777444</span>
         </div>
         <div class="th-large">
             <span class="font s-12 c-black">Tienda Gran Vía, 34 Bajo, 28013 - Madrid</span>
         </div>
         <div class="th-middle">
             <span class="font s-12 c-black">15/01/2021 - 08:58</span>
         </div>
         <div class="th-small">
            <div style="width: 100%;">
                <div class="flex-content f-between">
                    <p class="font s-10 c-pending no-bottom-medium">Pendiente</p>
                    <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                </div>
                <nz-progress [nzPercent]="90" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#A885D8', '100%': '#A885D8' }"></nz-progress>
            </div>
         </div>
         <div class="th-small last-th">
            <div>
                <nz-badge nzStatus="processing"></nz-badge>
                <span class="font s-13 c-black">TE</span>
            </div>
            <i nz-icon nzType="holder" nzTheme="outline"></i>
         </div>
    </div>
    <div class="card-prepaid c-complete">
        <div class="th-large">
            <span class="font s-12 c-black w-bold">Alberto Fernández García</span>
         </div>
         <div class="th-small">
             <span class="font s-12 c-black w-bold">666777444</span>
         </div>
         <div class="th-large">
             <span class="font s-12 c-black">Tienda Gran Vía, 34 Bajo, 28013 - Madrid</span>
         </div>
         <div class="th-middle">
             <span class="font s-12 c-black">15/01/2021 - 08:58</span>
         </div>
         <div class="th-small">
            <div style="width: 100%;">
                <div class="flex-content f-between">
                    <p class="font s-10 c-success no-bottom-medium">Completado</p>
                    <i nz-icon nzType="clock-circle" nzTheme="outline" class="icon s-10 c-default"></i>
                </div>
                <nz-progress [nzPercent]="100" [nzShowInfo]="false" [nzStrokeColor]="{ '0%': '#50BE87', '100%': '#50BE87' }"></nz-progress>
            </div>
         </div>
         <div class="th-small last-th">
            <div>
                <nz-badge nzStatus="processing"></nz-badge>
                <span class="font s-13 c-black">TE</span>
            </div>
            <i nz-icon nzType="holder" nzTheme="outline"></i>
         </div>
    </div>
</div>

