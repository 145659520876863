<div class="cards-other-data" (click)="showModal()">
    <div class="flex-content a-column a-center">
        <i class="icon-type" nz-icon nzType="user" nzTheme="outline"></i>
        <p class="font s-14 w-bold">Usuario</p>
    </div>
    <i class="icon-arrow" nz-icon nzType="arrow-right" nzTheme="outline"></i>
</div>
<nz-modal
    [(nzVisible)]="isVisible" 
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    nzWidth="550px"
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="wrapper-modal">
            <div class="header-modal">
                <div>
                    <p class="font s-28 w-bold a-center">Cyber Locutorio<br/>Orange Barcelona</p>
                    <div class="flex-content a-center">
                        <span class="tag purple">
                            <span class="text-first">584684</span>
                            <i nz-tooltip nzTooltipTitle="ID" nz-icon nzType="info-circle" nzTheme="outline"></i>
                        </span>
                        <div class="flex-content a-base">
                            <div class="dot c-amena margins m-r-10"></div>
                            <p class="font s-14 c-black margins m-r-30">Activo</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-modal">
                <form nz-form class="modal-user-pdv-form">
                    <div style="width: 100%;">
                      <div class="form-group">
                          <input type="text" oninvalid="" required="" formControlName="userName">
                          <label for="input" class="control-label flex-content f-between" style="width: 100%">
                              <div>
                                  <span class=""><b>Usuario</b></span>
                              </div>
                          </label><i class="bar"></i>
                       </div>
                       <div class="form-group">
                          <input type="password" oninvalid="" required="" formControlName="password">
                          <label for="input" class="control-label flex-content f-between" style="width: 100%">
                              <div>
                                  <span class=""><b>Contraseña*</b></span>
                              </div>
                          </label><i class="bar"></i>
                       </div>
                    </div>
                </form>
            </div>
            <div class="footer-modal">
                <div class="flex-content a-center margins m-b-30">
                    <a class="font s-14 c-primary a-center">Desactivar usuario</a>
                </div>
                <!-- <div class="flex-content f-evenly">
                    <div>
                        <p class="font s-12 c-light-gray">Fecha alta</p>
                        <p>30/10/2020</p>
                    </div>
                    <div>
                        <p class="font s-12 c-light-gray">Último acceso</p>
                        <p>30/10/2020</p>
                    </div>
                    <div>
                        <p class="font s-12 c-light-gray">Nº accesos</p>
                        <p>150</p>
                    </div>
                </div> -->
                <div nz-row nzGutter="25">
                    <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="8">
                        <div class="flex-content a-center a-column">
                            <div>
                                <p class="font s-12 c-light-gray">Fecha alta</p>
                                <p>30/10/2020</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="8">
                        <div class="flex-content a-center a-column">
                            <div>
                                <p class="font s-12 c-light-gray">Último acceso</p>
                                <p>30/10/2020</p>
                            </div>
                        </div>
                    </div>
                    <div nz-col nzXXl="8" nzXl="8" nzLg="8" nzMd="8" nzXs="24" nzSm="8">
                        <div class="flex-content a-center a-column">
                            <div>
                                <p class="font s-12 c-light-gray">Nº accesos</p>
                                <p>150</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nz-modal>