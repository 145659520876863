import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-mapas-listado',
    templateUrl: './mapas-listado.component.html',
    styleUrls: ['./mapas-listado.component.scss']
})
export class MapasListadoComponent implements OnInit {

    @Output() gotoIndex = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
    }

}
