<div class="container-max">
    <app-breadcrumbs
        [title]="'Desbloqueo acceso'"
        [breacrumbList]="['Herramientas', 'Desbloqueo acceso']"
    ></app-breadcrumbs>

    <div class="inner-content-max">
        <div nz-col nzSm="24" style="padding-bottom: 24px;">
            <div nz-row class="wrapper-card-form f-between flex-content">

                <div class="form-group" style="width: 50%;">
                    <input id="formControl" [formControl]="formControl" type="text" oninvalid="" required="">
                    <label for="formControl" class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>IP o usuario bloqueado</b></span>
                        </div>
                    </label><i class="bar"></i>
                    <span class="error-form" *ngIf="formControl?.touched && formControl?.errors?.['required']">
                        Campo obligatorio
                    </span>
                    <span class="error-form" *ngIf="formControl?.touched && formControl?.errors?.['minlength']">
                        Al menos 3 caracteres
                    </span>
                </div>

                <div class="form-group form-group-footer margins m-t-30">
                    <div class="flex-content a-center">
                        <button nz-button class="c-dark size-large" nzBlock (click)="searchBlock()">Buscar</button>
                    </div>
                </div>
                
            </div>
        </div>
        <div *ngIf="!loading && !errorRequest && blockTable?.length">
            <div class="wrapper-list" style="margin-bottom: 10px">
                <div class="wrapper-card-contracted" style="border: 1px solid lightgrey;" 
                    [ngClass]="{'is-collapse-padding': user.showMoreInfo }" 
                    *ngFor="let user of blockTable">
                    <div nz-row nzAlign="bottom">
                        <div nz-col [nzSpan]="'6'">
                            <div class="content-card">
                                <span class="font s-13 c-gray t-up">Usuario/IP</span>
                                <p>{{ user.accesos_fallidos[0].username + '/' + user.accesos_fallidos[0].ip }}</p>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="'6'">
                            <div class="content-card">
                                <span class="font s-13 c-gray t-up">Bloqueo hasta</span>
                                <p>{{ user.bloqueo_hasta | date: 'dd/MM/yyyy, h:mm:ss' || '-' }}</p>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="'11'" style="align-self: center;">
                            <div class="content-card">
                                <div *ngIf="!user.loadingUnlock && !user.correctUnblock">
                                    <button nz-button
                                        class="c-dark margins m-r-10" (click)="unblockUser(user)">
                                        Desbloquear
                                    </button>
                                </div>
                                <div *ngIf="user.correctUnblock">
                                    <span class="font s-13 c-gray t-up">
                                        Desbloquear
                                    </span>
                                    <p>Usuario desbloqueado correctamente</p>
                                </div>
                                <div *ngIf="user.loadingUnlock">
                                    <img src="./assets/images/loader.svg" width="40" alt="">
                                </div>
                            </div>
                        </div>
                        <div nz-col nzSpan="1" class="cursor-pointer" (click)="user.showMoreInfo = !user.showMoreInfo">
                            <div class="content-arrow flex-content a-center">
                                <label nz-icon nzType="right"
                                    nzTheme="outline"
                                    class="cursor-pointer"
                                    [ngClass]="user.showMoreInfo === true ? 'arrow-active' : 'arrow-no-active'">
                                </label>
                            </div>
                        </div>
                        <div nz-col nzSpan="24" *ngIf="user.showErrorUnlock">
                            <span class="font s-13">
                                *Error al desbloquear el usuario. Por favor, inténtelo de nuevo más tarde
                            </span>
                        </div>
                        <div nz-col nzSpan="24" *ngIf="user.showMoreInfo" class="margins m-t-15 m-b-20">
                            <div nz-row *ngFor="let acceso of user.accesos_fallidos; let i = index">
                                <div nz-col [nzSpan]="'3'">
                                    <div class="content-card">
                                        <span class="font s-13 c-gray t-up" *ngIf="i === 0">
                                            Id
                                        </span>
                                        <span>{{ acceso.id_acceso_fallido || '-' }}</span>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="'6'">
                                    <div class="content-card">
                                        <span class="font s-13 c-gray t-up" *ngIf="i === 0">
                                            Tipo de error
                                        </span>
                                        <span>{{ acceso.tipo_error || '-' }}</span>
                                    </div>
                                </div>
                                <div nz-col [nzSpan]="'6'">
                                    <div class="content-card">
                                        <span class="font s-13 c-gray t-up" *ngIf="i === 0">
                                            Fecha acceso
                                        </span>
                                        <span>{{ acceso.fecha_acceso | date: 'dd/MM/yyyy, h:mm:ss' || '-' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading && !errorRequest && blockTable !== null && blockTable.length === 0">
            Usuario o IP no encontrada
        </div>
        <div *ngIf="loading" align="center">
            <h5 class="font s-16" style="margin-top: 50px;">Buscando usuario bloqueado...</h5>
            <img src="./assets/images/loader.svg" width="60" alt="">
        </div>
        <div *ngIf="errorRequest">
            {{ errorRequest || 'Error interno al obtener los del datos del bloqueo. Por favor, inténtelo de nuevo más tarde' }}
        </div>
    </div>
</div>