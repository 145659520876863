<div nz-row [nzGutter]="16">
    <ng-container *ngIf="!iframeSrc">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24"[nzLg]="6"[nzXl]="6"[nzXXl]="6" style="margin-bottom: 16px;">

            <!-- filtro por categoria -->
            <div class="card-section">
                <div [ngClass]="{'header': !isCollapsed}" class="flex-content f-between">
                    <span class="font s-10 c-primary w-bold t-up">Categorías</span>
                    <button (click)="isCollapsed = !isCollapsed" nz-button class="c-primary" *ngIf="getScreenWidth < 992">
                        {{ isCollapsed ? 'Mostrar categorías' : 'Ocultar categorías' }}
                    </button>
                </div>
                <div class="body" [hidden]="isCollapsed">
                    <div class="section-input-search-clients">
                        <ul class="list-reports-checkboxes">
                            <li *ngIf="categoriesList.includes('1')" [ngStyle]="{'pointer-events': tr[0] ? 'none' : ''}">
                                <input type="checkbox" id="tr1" (click)="toggleCategory(1, $event)" [(ngModel)]="tr[0]"/>
                                <label for="tr1" class="content-li">
                                    <i nz-icon nzType="menu" nzTheme="outline"></i>
                                    <span class="font s-10">Todos</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('2')">
                                <input type="checkbox" id="tr2" (click)="toggleCategory(2, $event)" [(ngModel)]="tr[1]"/>
                                <label for="tr2" class="content-li">
                                    <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
                                    <span class="font s-10">Detalle de ventas</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('3')">
                                <input type="checkbox" id="tr3" (click)="toggleCategory(3, $event)" [(ngModel)]="tr[2]"/>
                                <label for="tr3" class="content-li">
                                    <i nz-icon nzType="eye" nzTheme="outline"></i>
                                    <span class="font s-10">Fraude</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('4')">
                                <input type="checkbox" id="tr4" (click)="toggleCategory(4, $event)" [(ngModel)]="tr[3]"/>
                                <label for="tr4" class="content-li">
                                    <i nz-icon nzType="layout" nzTheme="outline"></i>
                                    <span class="font s-10">Bases de datos</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('5')">
                                <input type="checkbox" id="tr5" (click)="toggleCategory(5, $event)" [(ngModel)]="tr[4]"/>
                                <label for="tr5" class="content-li">
                                    <i nz-icon nzType="euro" nzTheme="outline"></i>
                                    <span class="font s-10">Comisiones</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('6')">
                                <input type="checkbox" id="tr6" (click)="toggleCategory(6, $event)" [(ngModel)]="tr[5]" />
                                <label for="tr6" class="content-li">
                                    <i nz-icon nzType="mobile" nzTheme="outline"></i>
                                    <span class="font s-10">Digoapp</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('7')">
                                <input type="checkbox" id="tr7" (click)="toggleCategory(7, $event)" [(ngModel)]="tr[6]"/>
                                <label for="tr7" class="content-li">
                                    <i nz-icon nzType="appstore" nzTheme="outline"></i>
                                    <span class="font s-10">Otros</span>
                                </label>
                            </li>
                            <li *ngIf="categoriesList.includes('8')">
                                <input type="checkbox" id="tr8" (click)="toggleCategory(8, $event)" [(ngModel)]="tr[7]"/>
                                <label for="tr8" class="content-li">
                                    <i nz-icon nzType="line-chart" nzTheme="outline"></i>
                                    <span class="font s-10">Reportes de actividad</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Listado de informes -->
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24"[nzLg]="18"[nzXl]="18"[nzXXl]="18">
            <div class="card-section">
                <!-- Cabecera -->
                <div class="header flex-content f-between">
                    <div>
                        <span class="font s-10 c-primary w-bold t-up">{{ selectedReport?.title && selectedReport?.title !== '' ? selectedReport.title : 'Elige el informe' }}</span>&nbsp;
                        <i class="icon s-22 drop" nz-icon nzType="pic-center" (click)="onShow()" nzTheme="outline"></i>
                        <i class="icon s-22 drop" nz-icon nzType="menu" (click)="onShow()" nzTheme="outline"></i>
                    </div>
                    <div class="tag-info" *ngIf="!mapMode">
                        <i class="icon-tag-info" nz-icon nzType="info-circle" nzTheme="outline"></i>
                        <span class="font s-10 c-default">Posible variación de datos de hasta 4 horas</span>
                    </div>
                    <br>
                </div>
                <br>

                <!-- Configurador informes-->
                <ng-container *ngIf="selectedReport">
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <p class="font s-8 w-bold c-primary t-up">Exportación de datos</p>
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="24" [nzSm]="24" [nzLg]="8" [nzMd]="8"[nzLg]="8"[nzXl]="8"[nzXXl]="8" class="margins m-b-10">
                                <nz-select nzPlaceHolder="Límites" formControlName="report_limite" style="width:200px" (ngModelChange)="changeDates($event)">
                                    <nz-option nzValue="TO" nzLabel="Todo">Todo</nz-option>
                                    <nz-option nzValue="MA" nzLabel="Mes actual" selected>Mes actual</nz-option>
                                    <nz-option nzValue="US" nzLabel="Última semana">Última semana</nz-option>
                                    <nz-option nzValue="UQ" nzLabel="Última quincena">Última quincena</nz-option>
                                    <nz-option nzValue="UM" nzLabel="Último mes">Último mes</nz-option>
                                    <nz-option nzValue="USE" nzLabel="Último semestre">Último semestre</nz-option>
                                    <nz-option nzValue="UA" nzLabel="Últimos 12 meses">Últimos 12 meses</nz-option>
                                    <nz-option nzValue="AA" nzLabel="Año actual">Año actual</nz-option>
                                </nz-select>
                            </div>
                            <div nz-col [nzXs]="24" [nzSm]="12" [nzLg]="8" [nzMd]="8"[nzLg]="8"[nzXl]="8"[nzXXl]="8" class="margins m-b-10">
                                <input id="filter_fromto_from" formControlName="buscar_report_fecha_desde" type="date" oninvalid="" style="width:200px">
                                <label for="filter_fromto_from" class="control-label flex-content f-between" style="width: 100%">
                                    <div>
                                        <span class=""><b>(Desde)</b></span>
                                    </div>
                                </label><i class="bar"></i>
                            </div>
                            <div nz-col [nzXs]="24" [nzSm]="12" [nzLg]="8" [nzMd]="8"[nzLg]="8"[nzXl]="8"[nzXXl]="8" class="margins m-b-10">
                                <input id="filter_fromto_to" formControlName="buscar_report_fecha_hasta" type="date" oninvalid="" style="width:200px">
                                <label for="filter_fromto_to" class="control-label flex-content f-between" style="width: 100%">
                                    <div>
                                        <span class=""><b>(Hasta)</b></span>
                                    </div>
                                </label><i class="bar"></i>
                            </div>
                        </div>

                        <div nz-row [nzGutter]="24" >
                            <div nz-col nzSpan="18">
                                <br>
                                <div class="digo-button-group">

                                    <ng-container *ngIf="mapMode">
                                        <input label="Mapa" type="radio"  formControlName="mc" value="web" checked>
                                    </ng-container>

                                    <ng-container *ngIf="!mapMode">
                                        <ng-container *ngIf="exportMode === 'normal'">
                                            <input label="Web" type="radio" formControlName="mc" value="web" checked>
                                            <input label="Imprimir" type="radio" formControlName="mc" value="print">
                                            <input label="Word" type="radio" formControlName="mc" value="word">
                                            <input label="Excel" type="radio" formControlName="mc" value="excel">
                                            <input label="Email" type="radio" formControlName="mc" value="mail">
                                        </ng-container>

                                        <ng-container *ngIf="exportMode === 'programmed'">
                                            <input [hidden]="true" label="Solicitud programada" type="radio" formControlName="mc" value="solicitud_informe" checked>
                                        </ng-container>
                                    </ng-container>

                                </div>
                                <br>
                            </div>
                        </div>

                        <div nz-row [nzGutter]="24" >
                            <br>
                            <!--<div nz-col nzSpan="6">-->
                                <!--<div class="flex-content a-center">-->
                                    <!--<button (click)="setMode('')" nz-button class="c-primary size-large" nzBlock>Consulta directa</button>-->
                                <!--</div>-->
                            <!--</div>-->

                            <div nz-col>
                                <div class="flex-content a-center">
                                    <button (click)="setMode('pers')" nz-button class="c-primary size-large">Configurar informe</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <nz-divider></nz-divider>
                </ng-container>

                <!--Listado-->
                <div class="body" style="max-height: 800px; overflow-y: scroll">
                    <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
                    <div nz-row *ngIf="!loading">
                        <div *ngFor="let report of reportsListPage" nz-col [nzSpan]="isColumn ? '8' : '24'" (click)="selectReport(report)">
                            <div class="card-link-report " [ngClass]="{'inline-column' : isColumn,  'inline-row': !isColumn, 'selected': report.code === selectedReport?.code}">
                                <span class="font s-12 w-bold">{{report.title}}</span>
                                <i nz-icon nzType="arrow-right" nzTheme="outline" ></i>
                            </div>
                        </div>
                    </div>
                </div>
                <p style="margin-left: 20px;" *ngIf="!loading && reportsListPage.length === 0">- No hay informes disponibles -</p>

            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="iframeSrc">
        <div nz-row [nzJustify]="'end'" class="button-container" style="margin: 10px">
            <button nz-col nz-button class="c-dark custom-button-back" (click)="iframeSrc= ''" title="Volver">
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
                Volver
            </button>
        </div>
        
        <iframe sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-downloads"
        #frame [src]="iframeSrc | trustUrl" style="width: 100%; min-height: 700px; border: none"></iframe>
    </ng-container>
</div>
