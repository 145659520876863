import { Component, OnInit } from '@angular/core';
import {pageLimit} from "../../../../../shared/constantes";
import {LogisticsService} from "../../../../../services/http/herramientas/logistics.service";
import {ListsFiltersService} from "../../../../../services/lists.filters.service";
import {Router} from "@angular/router";
import {environment} from "../../../../../../environments/environment";
import {AccessService} from "../../../../../services/access.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-listado-grupos',
  templateUrl: './listado-grupos.component.html',
  styleUrls: ['./listado-grupos.component.scss']
})
export class ListadoGruposComponent implements OnInit {

    public loading = false;
    public error: string | boolean = false;

    public paginator: any = {
        limit: pageLimit,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filters: any = null
    public table: any = null;
    public orderBy: any;

    constructor(
        private logistic: LogisticsService,
        private filterService: ListsFiltersService,
        private router: Router,
        private access: AccessService
    ) {
    }

    ngOnInit(): void {
        this.access.checkAccess('herramientas/logistica/grupos');
        this.setFilters();
        this.load();
    }

    setFilters() {
        this.filters = this.logistic.getListadoGruposFilter();
        this.paginator = this.logistic.getListadoGruposPaginator();
        this.orderBy = this.logistic.getListadoGruposOrderBy();

        if (this.filters == null) {
            this.filters = [
                {
                    type: 'text',
                    name: 'name',
                    label: 'Nombre',
                    value: null
                },
                {
                    type: 'select',
                    name: 'active',
                    label: 'Estado',
                    options: [
                        {name: 'Todos', value: ''},
                        {name: 'Activos', value: '1'},
                        {name: 'Inactivos', value: '0'}
                    ],
                    value: null
                }
            ];
            this.logistic.setListadoGruposFilter(this.filters);
            this.logistic.setListadoGruposPaginator(this.paginator);
            this.logistic.setListadoGruposOrderBy(this.orderBy);
        }
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.logistic.getGroups(this.filterService.getPayload(this.paginator, this.filters, this.orderBy))
            .subscribe({
                next: (data: any) => {
                    this.error = false;
                    console.log(data);
                    if (data) {
                        this.prepareTable(data.data);
                        this.preparePaginator(data);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.prepareTable([]);
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                    this.loading = false;
                }
            });
    }

    tableClicked(ev: any) {
        // Estamos pulsando el header para ordenar
        if (ev && !ev.rowId && ev.cell?.order) {
            this.orderBy = ev.cell.orderValue;
            this.logistic.setListadoGruposOrderBy(this.orderBy);
            this.load();
        }
        // Estamos pulsando en el body para acceder al detalle
        if (ev && ev.rowId) {
            this.router.navigate(['/herramientas/logistica/grupos/' + ev.rowId]).then();
        }
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {
                                value: 'Nombre',
                                order: {
                                    desc: 'name_desc',
                                    asc: 'name_asc'
                                },
                                orderValue: null
                            }, {
                                value: 'Estado',
                                order: {
                                    desc: 'status_desc',
                                    asc: 'status_asc'
                                },
                                orderValue: null
                            }
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            table.body.rows.push({
                clickValue: item.idGroupArticle,
                cells: [
                    {value: item.name},
                    {value: this.getStateName(item)},
                ]
            })
        });

        this.table = table;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    getStateName(item: any) {
        switch (item.active) {
            case "0": return 'Inactivo';
            case "1": return 'Activo';
        }
        return {}
    }

    pageChanged(paginator: any) {
        console.log(paginator)
        this.paginator = paginator;
        this.logistic.setListadoGruposPaginator(this.paginator);
        this.load();
    }

    filtersApplied(filters: any) {
        console.log(filters);
        this.filters = filters;
        this.paginator.currentPage = 1;
        this.logistic.setListadoGruposPaginator(this.paginator);
        this.logistic.setListadoGruposFilter(this.filters);
        this.load();
    }

}
