import { HttpErrorResponse } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/core/validators/mustmatch.validators';
import {CompanyService} from "../../../services/http/company/company.service";
import { emailValidator } from 'src/app/core/validators/email.validator';

@Component({
  selector: 'app-modal-change-email',
  templateUrl: './modal-change-email.component.html',
  styleUrls: ['./modal-change-email.component.scss']
})
export class ModalChangeEmailComponent implements OnInit {
    @Input() pdv_name: string = "";
    @Input() user: any;

    public userFormGroup!: FormGroup;

    public isVisible = false;
    public isLoading = false;
    public email = "";
    public emailIsValid = true;
    public tried = false;
    public isChanged = false;
    public isError = false;
    public msgError: boolean | string = false;

    constructor(
        private companyService: CompanyService,
        private fb: FormBuilder
    ) { }
    
    showModal(): void {
        this.isVisible = true;
        this.userFormGroup = this.fb.group({
            email: [this.getEmail(), [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],
            emailConfirmation: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],
        }, {
            validator: MustMatch('email', 'emailConfirmation')
        });
    }

    handleCancel(): void {
        this.userFormGroup.reset();
        this.isVisible = false;
        this.isChanged = false;
        this.isError = false;
    }

    ngOnInit(): void {
        this.userFormGroup = this.fb.group({
            email: [this.getEmail(), [Validators.required, emailValidator()]],
            emailConfirmation: [null, [Validators.required, emailValidator()]],

        }, {
            validator: MustMatch('email', 'emailConfirmation')
        });
        this.userFormGroup.get('email')?.valueChanges.subscribe(() => {
            let emailConfirmation = this.userFormGroup.get('emailConfirmation');
            emailConfirmation?.markAsUntouched();
            emailConfirmation?.setValue('');
            emailConfirmation?.disable();
            
            if (this.userFormGroup.get('email')?.valid) {
                emailConfirmation?.enable();
            }
        })
    }

    getEmail(){
        return this.user.legacy_email !== null ? this.user.legacy_email : this.user.email;
    }

    submit() {
        this.userFormGroup.markAllAsTouched();
        if (this.userFormGroup.valid) {
            let parameters = {
                email: this.userFormGroup.get('email')?.value
            }
            this.isLoading = true
            this.companyService.setActionUser(this.user.id_user, 'cambiar_email', parameters)
            .subscribe({
                next: (response: any) => {
                    if (response.error && response.msg === 0){
                        this.msgError = response.error;
                        this.isError = true;
                        this.isChanged = false;
                    }
                    else{
                        this.user.email = this.userFormGroup.get('email')?.value
                        this.user.legacy_origin = null;
                        this.isChanged = true;
                        this.msgError = false;
                        this.isError = false;
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.isError = true;
                    this.isChanged = false;
                },
                complete: () => {
                    this.isLoading = false;
                    this.userFormGroup.reset();
                }
            });
        }
    }
}
