<div (click)="showModal()" class="onclick-modal">
    <img src="../../../../assets/images/pdv/icon-clipboard.svg" width="14" class="margins m-r-10 icon"/>
    <span class="font s-16 margins m-r-15">Observaciones</span>
</div>
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="handleCancel()"
    (nzOnOk)="handleOk()"
    nzWidth="687px"
    nzCentered
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="header-modal">
            <img src="../../../../assets/images/pdv/icon-clipboard.svg" class="margins m-r-15 "/>
            <span class="font s-28 w-bold margins m-r-15 m-t--10">Observaciones</span>
            <span class="tag purple">
                <span class="text-first">{{ company.idCompany }}</span>
                <i nz-tooltip nzTooltipTitle="ID" nz-icon nzType="info-circle" nzTheme="outline"></i>
            </span>
        </div>
        <div class="body-modal">
            <p *ngIf="company.observations">{{ company.observations }}</p>
            <p *ngIf="company.observations2">{{ company.observations2 }}</p>
        </div>
    </ng-container>
</nz-modal>

