<div class="card-add" *ngIf="imgSelected">
    <img [src]="imgSelected" [alt]="imgSelected" class="img-front" onerror="this.src='./assets/images/img-broken.jpg'" (click)="showModal()" />
    <span *ngIf="pdv.imgs && pdv.imgs.length > 1" class="font s-14 c-light-gray margins m-b-15">* Pincha en la foto para ver más</span>
    <nz-upload *ngIf="authService.userCan('pdv.editar')" [nzAction]="imgUploadEndpoint" [nzHeaders]="imgUploadHeaders" [nzShowUploadList]="showUploadList" (nzChange)="handleChange($event)">
        <button nz-button class="font w-bold c-primary margins m-b-15">+ Añadir</button>
    </nz-upload>
</div>

<div class="card-add" *ngIf="imgSelected == null">
    <img src="./assets/images/pdv-placeholder.png" alt="PDV" class="margins m-b-15  m-t-15">
    <span class="font s-14 c-light-gray margins m-b-15">Todavía no hay ninguna foto del PDV</span>
    <nz-upload *ngIf="authService.userCan('pdv.editar')" [nzAction]="imgUploadEndpoint" [nzHeaders]="imgUploadHeaders" [nzShowUploadList]="showUploadList" (nzChange)="handleChange($event)">
        <button nz-button class="font w-bold c-primary margins m-b-15">+ Añadir</button>
    </nz-upload>
</div>

<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    [nzClosable]="false"
    (nzOnOk)="handleOk()"
    nzWidth="100%"
    nzClassName="modal-black"
    nzCentered>

    <ng-container *nzModalContent class="wrapper-modal" style="padding: 0 !important;">
        <div class="body-modal">
            <div nz-row class="flex-content" style="width: 100%; max-width: 1200px; place-content: end; margin-bottom: 15px;">
                <span class="icon-type" nz-icon nzType="close" nzTheme="outline" (click)="handleCancel()" class="cursor-pointer" style="padding: 15px; font-size: 20px;"></span>
            </div>
            <div nz-row class="margin m-b-30" style="width: 100%;max-width: 1200px;">
                <div nz-col nzFlex="auto">
                    <h2 class="font s-28 w-bold">{{ pdv.denominacion }}</h2>
                </div>
                <div nz-col>
                    <nz-upload *ngIf="authService.userCan('pdv.editar')" [nzAction]="imgUploadEndpoint" [nzHeaders]="imgUploadHeaders" [nzShowUploadList]="showUploadList" (nzChange)="handleChange($event)">
                        <button nz-button class="font w-bold c-black">+ Añadir</button>
                    </nz-upload>
                </div>
            </div>

            <div class="content-image-selected">
                <img [src]="imgSelected" width="100%" [alt]="imgSelected" onerror="this.src='./assets/images/img-broken.jpg'"/>
            </div>
            <div class="carousel-images">
                <ul>
                    <li *ngFor="let image of pdv.imgs; index as i" (click)="setImgSelected(image)">
                        <div class="figure" align="center" *ngIf="!loadingPdvImgs[i]">
                            <img [src]="image" width="100%" [alt]="image" onerror="this.src='./assets/images/img-broken.jpg'"/>
                            <div class="figcaption">{{setDate(image)}}</div>
                            <button *ngIf="authService.userCan('pdv.editar')" nz-button class="c-primary size-large" (click)="removeImg(image, i)">
                                <span nz-icon nzType="delete" nzTheme="outline"></span>
                            </button>
                        </div>
                        <div class="figure" align="center" *ngIf="loadingPdvImgs[i]">
                            <img src="./assets/images/loader.svg" width="30" alt="">
                        </div>
                    </li>
                </ul>
            </div>
            <span class="error-form" *ngIf="errorRemoveImg">
                Error al eliminar la imagen. Inténtelo de nuevo más tarde
            </span>
        </div>
    </ng-container>
</nz-modal>

