import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccessService } from 'src/app/services/access.service';
import { UserBlockService } from 'src/app/services/http/herramientas/user-block.service';

@Component({
    selector: 'app-desbloqueo-acceso',
    templateUrl: './desbloqueo-acceso.component.html',
    styleUrls: ['./desbloqueo-acceso.component.scss']
})
export class DesbloqueoAccesoComponent implements OnInit {

    public formControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
    public loading: boolean = false;
    public errorRequest: string = '';
    public blockTable: Array<any> | null = null;

    constructor(
        private userBlockService: UserBlockService,
        private accessService: AccessService
    ) { }

    ngOnInit(): void {
        this.accessService.checkAccess('herramientas/desbloqueo')
    }

    public searchBlock(): void {
        if (this.formControl.valid) {
            this.loading = true;
            this.errorRequest = '';
            this.blockTable = null;
            this.userBlockService.getUserBlockFromService(this.formControl.value)
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                            if (response.error) {
                                this.errorRequest = response.error || 'Error interno al obtener los del datos del bloqueo. Por favor, inténtelo de nuevo más tarde';
                            } else if (response.msg) {
                                this.blockTable = response.msg;
                            }
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.loading = false;
                        this.errorRequest = error.error.error || 'Error interno al obtener los del datos del bloqueo. Por favor, inténtelo de nuevo más tarde';
                    },
                    complete: () => {
                        this.loading = false;
                    }
                });
        } else {
            this.formControl.markAllAsTouched();
        }
    }

    public unblockUser(user: any): void {
        user.loadingUnlock = true;
        user.showErrorUnlock = false;
        this.userBlockService.resetLockUser(user.id_acceso_bloqueo_ip)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        if (response.error) {
                            user.showErrorUnlock = true;
                        } else if (response.msg) {
                            user.correctUnblock = true;
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    user.loadingUnlock = false;
                    user.showErrorUnlock = true;
                },
                complete: () => {
                    user.loadingUnlock = false;
                }
            });
    }

}
