import { Component, OnInit, ViewChild  } from '@angular/core';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
@Component({
  selector: 'app-commissioner-chart',
  templateUrl: './commissioner-chart.component.html',
  styleUrls: ['./commissioner-chart.component.scss']
})
export class CommissionerChartComponent implements OnInit {

  @ViewChild("chart") chart: any;
  public chartOptions: Partial<ChartOptions> | any ;

  constructor() {
    this.chartOptions = {
      series: [65, 15, 20],
      chart: {
        type: "donut",
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        zoom: {
          enabled: false
        }
      },
      donut: {
        size: '65%',
        background: 'transparent',
        labels: {
          show: false,
        }
      },
      labels: {
        show: false
      },
      legend: {
        enabled: false
        },
        dataLabels: {
          enabled: false,
          show: false
        }  
    };
  }

  ngOnInit(): void {
  }

}
