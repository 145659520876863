<div class="container-max" align="center" *ngIf="pdvs_loading">
    <img src="./assets/images/loader.svg" width="60" alt="">
</div>
<div *ngIf="!pdvs_loading && pdvs_data.total > 0 && pdvs_data != null">

    <div class="flex-content f-end">
        <!-- <a routerLink="/perfiles/crear-pdv" nz-button class="c-primary margins m-b-15">
            <i nz-icon nzType="plus" nzTheme="outline"></i>Añadir PDV
        </a> -->
        <div class="flex-content a-center">
            <!-- <ul class="list-header-prepaid">
                <li>
                    <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></button>
                </li>
                <li>
                    <button nz-button class="size-xxs c-primary"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></button>
                </li>
            </ul> -->
            <p class="font s-14 margins m-r-15" *ngIf="pdvs_data?.total && pdvs_data?.total !== ''">
                Elementos: <b>{{ pdvs_data?.total }}</b>
            </p>
            <p class="font s-14 margins m-r-15" *ngIf="pdvs_data != null">
                Página: <b>{{ pdvs_data.current_page + "/" + pdvs_data.last_page }}</b>
            </p>

            <ul class="list-header-prepaid">
                <li *ngIf="pdvs_data != null && pdvs_data.current_page > 1">
                    <button nz-button class="size-md c-primary" [disabled]="pdvs_loading" (click)="setCurrentPage(pdvs_data.current_page - 1)">
                        <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
                    </button>
                </li>
                <li *ngIf="pdvs_data != null && pdvs_data.current_page < pdvs_data.last_page">
                    <button nz-button class="size-md c-primary" [disabled]="pdvs_loading" (click)="setCurrentPage(pdvs_data.current_page + 1)">
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="wrapper-pdv-table">
        <div class="header-table-pdv">
            <div class="th th-extra-extra-large" (click)="setPdvOrder(order != PdvOrder.PDV_DENOMINACION ? PdvOrder.PDV_DENOMINACION : PdvOrder.PDV_DENOMINACION_DESC)">
                <span class="font s-13 w-bold c-black">Nombre</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order == PdvOrder.PDV_DENOMINACION"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.PDV_DENOMINACION_DESC"></i>
            </div>
            <div class="th th-large" (click)="setPdvOrder(order != PdvOrder.EMPRESA_RAZON_SOCIAL ? PdvOrder.EMPRESA_RAZON_SOCIAL : PdvOrder.EMPRESA_RAZON_SOCIAL_DESC)">
                <span class="font s-13 w-bold c-black">Empresa</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order ==PdvOrder.EMPRESA_RAZON_SOCIAL"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.EMPRESA_RAZON_SOCIAL_DESC"></i>
            </div>
            <div class="th th-large" (click)="setPdvOrder(order != PdvOrder.TIPO_CANAL_NOMBRE ? PdvOrder.TIPO_CANAL_NOMBRE : PdvOrder.TIPO_CANAL_NOMBRE_DESC)">
                <span class="font s-13 w-bold c-black">Tipo Canal</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order ==PdvOrder.TIPO_CANAL_NOMBRE"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.TIPO_CANAL_NOMBRE_DESC"></i>
            </div>
            <div class="th th-middle" (click)="setPdvOrder(order != PdvOrder.MERCHAN_NOMBRE ? PdvOrder.MERCHAN_NOMBRE : PdvOrder.MERCHAN_NOMBRE_DESC)">
                <span class="font s-13 w-bold c-black">GPV</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order ==PdvOrder.MERCHAN_NOMBRE"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.MERCHAN_NOMBRE_DESC"></i>
            </div>
            <div class="th th-large" (click)="setPdvOrder(order != PdvOrder.PDV_ACTIVADO ? PdvOrder.PDV_ACTIVADO : PdvOrder.PDV_ACTIVADO_DESC)">
                <span class="font s-13 w-bold c-black">Marcas</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order ==PdvOrder.PDV_ACTIVADO"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.PDV_ACTIVADO_DESC"></i>
            </div>
            <div class="th th-small" (click)="setPdvOrder(order != PdvOrder.SEGMENTACION ? PdvOrder.SEGMENTACION : PdvOrder.SEGMENTACION_DESC)">
                <span class="font s-13 w-bold c-black">Segmentación</span>
                <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="order ==PdvOrder.SEGMENTACION"></i>
                <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="order ==PdvOrder.SEGMENTACION_DESC"></i>
            </div>
            <!--<div class="th-small">
                <span class="font s-13 w-bold c-black">PDV</span>
            </div>-->
        </div>

        <div class="container-max" align="center" *ngIf="pdvs_loading">
            <img src="./assets/images/loader.svg" width="60" alt="">
        </div>

        <ng-container *ngIf="pdvs_data != null && !pdvs_loading">
            <a [routerLink]="['/perfiles/pdv', pdv.id_pdv]" class="card-pdv font" *ngFor="let pdv of pdvs_data.data" [ngClass]="pdv.activated != 1 ? 'c-gray': 'c-black'">
                <div class="th-extra-extra-large" style="padding-right: 5px;">
                    <span class="font s-14 w-bold">{{ pdv.denominacion }}</span>
                </div>
                <div class="th-large" style="padding-right: 5px;">
                    <span class="font s-13">{{ pdv.company_business_name }}</span>
                </div>
                <div class="th-large" style="padding-right: 5px;">
                    <span class="font s-13">{{ pdv.channel_type }}</span>
                </div>
                <div class="th-middle" style="padding-right: 5px;">
                    <span class="font s-13">{{ pdv.merchan }}</span>
                </div>
                <div class="th-large">
                    <div class="content-images">
                        <!--<div>
                            <img src="../../../../assets/images/pdv/rebranding-active.svg" class="element" alt="Rebranding" *ngIf="pdv.rebranding"/>
                            <img src="../../../../assets/images/pdv/rebranding-disabled.svg" class="element" alt="Rebranding" *ngIf="!pdv.rebranding"/>
                        </div>-->
                        <div>
                            <img src="../../../../assets/images/pdv/jazztel-active.svg" class="element" alt="Jazztel" *ngIf="pdv.sell_jazztel"/>
                            <img src="../../../../assets/images/pdv/jazztel-disabled.svg" class="element" alt="Jazztel" *ngIf="!pdv.sell_jazztel"/>
                        </div>
                        <div>
                            <img src="../../../../assets/images/pdv/simyo-active.svg" class="element" alt="Simyo" *ngIf="pdv.sell_simyo"/>
                            <img src="../../../../assets/images/pdv/simyo-disabled.svg" class="element" alt="Simyo" *ngIf="!pdv.sell_simyo"/>
                        </div>
                        <!-- <div class="classification-center">
                            <img src="./assets/images/pdv/estado-null.svg" class="element" alt="Estado" *ngIf="pdv.clasificacion == null"/>
                            <span class="letters success" *ngIf="pdv.clasificacion">{{ getClassification(pdv) }}</span>
                        </div> -->
                    </div>
                </div>
                <div class="th-small">
                    <span class="font s-10">{{ pdv.clasificacion }}</span>
                </div>
            </a>
        </ng-container>
    </div>
</div>
<div *ngIf="!pdvs_loading && (pdvs_data.total == null || pdvs_data.total == 0)" align="center">
    Los sentimos pero con estos filtros no hay datos para mostrar.
    <br>
    Seleccione otros filtros para obtener un resultado
</div>
