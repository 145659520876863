<nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" (nzOnCancel)="handleCancel()" (nzOnOk)="handleCancel()"
  nzWidth="687px" nzCentered>
  <ng-container *nzModalContent class="wrapper-modal">
    <!-- action step -->
    <div class="body-modal" *ngIf="user && !isLoadingSearch && !isEdited" [formGroup]="userFormGroup">
      <p class="font s-28 w-bold a-center margins m-b-30">Editar usuario bloqueado</p>
      <div *ngIf="!finalStepConfirm">
        <div class="margins m-b-10" style="width: 100%;" nz-row nzGutter="24">
          <div nz-col [nzSpan]="user.type === 'pdv' ? '8':'10'">
            <p><b>Nombre de usuario: </b>{{user.username}}</p>
          </div>
          <div nz-col [nzSpan]="user.type === 'pdv' ? '10':'14'">
            <div class="type" [ngSwitch]="user.type">
              <div *ngSwitchCase="'pdv'">
                <p><b>Nombre Pdv: </b>{{user.pdv}}</p>
              </div>
              <div *ngSwitchCase="'merchan'">
                <p><b>GPV: </b>{{user.merchan}}</p>
              </div>
              <div *ngSwitchCase="'distributor'">
                <p><b>Distribuidor: </b>{{user.distributor}}</p>
              </div>
              <div *ngSwitchDefault>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="6">
            <p *ngIf="user.type === 'pdv'"><b>ID Pdv: </b>{{user.id_pdv}}</p>
          </div>
        </div>
        <div class="margins m-b-20" style="width: 100%;" nz-row nzGutter="24">
          <div nz-col nzSpan="24">
            <p><b>Comentarios: </b></p>
            <textarea formControlName="comments" name="comments" rows="5" cols="80"></textarea>
          </div>
        </div>
        <div style="width: 100%;" nz-row nzGutter="24">
          <div nz-col nzSpan="8">
            <input type="checkbox" formControlName="blocked_status" id="status" (click)="onChangeBlockedStatus()" />
            <label for="status">
              Usuario <b>{{userFormGroup.get('blocked_status')?.value ? "bloqueado" : "desbloqueado"}}</b>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="userFormGroup.get('comments')?.value !== '' && finalStepConfirm" align="center" class="font s-20">
        <p>El usuario <b>{{user.username}}</b> quedará <b>{{userFormGroup.get('blocked_status')?.value ? "bloqueado" : "desbloqueado"}}</b> comentando:<br>"{{userFormGroup.get('comments')?.value}}"</p>
        <p><b>¿Quiere guardar estos cambios?</b></p>
      </div>
      <div align="center" *ngIf="userFormGroup.get('comments')?.value === '' && finalStepConfirm" class="font s-20">
        <p>El usuario <b>{{user.username}}</b> quedará <b>{{userFormGroup.get('blocked_status')?.value ? "bloqueado" : "desbloqueado"}}</b> sin añadir ningún comentario.</p>
        <p><b>¿Quiere guardar estos cambios?</b></p>
      </div>
      <span class="error-form" *ngIf="isError">
        {{ErrorMsg}}
    </span>
      <div nz-row nzGutter="24" class="margins m-t-20">
        <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
          <button nz-button class="c-primary size-large" (click)="checkConfirmation()" [disabled]="!userFormGroup.dirty" nzBlock>Guardar</button>
        </div>
        <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
          <button nz-button class="c-dark size-large" (click)="handleCancel()" nzBlock>Cancelar</button>
        </div>
      </div>
    </div>
    <div align="center" *ngIf="isLoadingSearch">
      <img src="./assets/images/loader.svg" width="60" alt="">
    </div>
    <div align="center" class="body-modal-confirm" *ngIf="isEdited">
      <img src="../../../../assets/images/pdv/icon-check.svg" class="margins m-b-70" alt="Success" />
      <p class="font s-22 a-center w-bold">Se ha editado correctamente el usuario</p>
    </div>
  </ng-container>

</nz-modal>