<!--<div class="wrapper-cards no-action">-->
<!--<img src="../../../../../assets/images/pdv/icon-unlock.svg" class="margins m-b-15" />-->
<!--<p class="font s-18 a-center c-light-gray">Selecciona un permiso para configurarlo</p>-->
<!--</div>-->
<div class="margins m-t-15">
    <p class="font s-22 c-primary">{{ selectedPermission.nombre }}</p>
    <p class="font s-16 c-light-gray">{{ selectedPermission.descripcion }}</p>
</div>
<div class="wrapper-cards actions">
    <p class="font s-18">Selecciona el perfil a configurar</p>
    <div class="wrapper-buttons">
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'general'}" (click)="setProfile('general')" *ngIf="selectedPermission.general == 1">General</button>
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'distributor'}" (click)="setProfile('distributor')" *ngIf="selectedPermission.distribuidor == 1">Distribuidor</button>
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'group'}" (click)="setProfile('group')" *ngIf="selectedPermission.grupo == 1">Grupo Comercial</button>
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'merchan'}" (click)="setProfile('merchan')" *ngIf="selectedPermission.merchan == 1">GPV</button>
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'company'}" (click)="setProfile('company')" *ngIf="selectedPermission.empresa == 1">Empresa</button>
        <button nz-button class="c-dark-orange" [ngClass]="{active: profile === 'pdv'}" (click)="setProfile('pdv')" *ngIf="selectedPermission.pdv == 1">PDV</button>
    </div>
    <!-- Content Tab General -->

    <ng-container *ngIf="loadingList">
        <div class="wrapper-prepaid-list" style="justify-content: center;">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </ng-container>
    <ng-container *ngIf="!loadingList">

        <div *ngIf="profile === 'general'">
            <nz-divider></nz-divider>
            <div class="form-group" [style]="drawerWidth()">
                <p>Permiso</p>
                <div class="form-group margins m-b-50">
                    <nz-select class="select-prepaid" [(ngModel)]="generalValue" nzPlaceHolder="Selecciona el permiso" nzBorderless style="width: 100%;" (ngModelChange)="editGeneralPermission($event)">
                        <nz-option nzLabel="No definido" nzValue=""></nz-option>
                        <nz-option
                                *ngFor="let pass_value of selectedPermission.pass_values; let i = index"
                                nzValue="{{ pass_value.id_pass_value }}"
                                nzLabel="{{ pass_value.descripcion }}">

                        </nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
                <!--<div class="form-group margins m-b-50">-->
                    <!--<nz-select class="select-prepaid" [(ngModel)]="selectedPermission.pass_values[0]" nzPlaceHolder="Selecciona el permiso" nzBorderless style="width: 100%;">-->
                        <!--<nz-option *ngFor="let entity of entityList;" nzValue="{{ entity.id_value }}" nzLabel="{{ entity.general_value }}"></nz-option>-->
                    <!--</nz-select>-->
                    <!--<i class="bar"></i>-->
                <!--</div>-->
            </div>
        </div>

        <div *ngIf="profile && profile !== 'general'">
            <nz-divider></nz-divider>

                <p *ngIf="listError">{{ listError }}</p>
                <div class="content-open-drawer" (click)="open()">
                    <i nz-icon nzType="filter" nzTheme="outline" class="margins m-r-10"></i>
                    <span class="font s-14">Filtrar</span>
                </div>
                <div class="flex-content a-wrap margins">
                    <!--<span class="tag purple closable">-->
                        <!--<span class="text-first">Solicitar foto y firma</span>-->
                        <!--<i nz-icon  nzType="close" nzTheme="outline"></i>-->
                    <!--</span>-->

                    <!--<span class="tag purple closable" *ngFor="let value of filters.values" >-->
                        <!--<span class="text-first">{{ value.label }}</span>-->
                        <!--<i nz-icon  nzType="close" nzTheme="outline" (click)="deleteFilter(value.value, 'values')"></i>-->
                    <!--</span>-->
                    <ng-container *ngFor="let filter of $any(filters) | keyvalue" >
                        <ng-container *ngIf="$any(filter.key) === 'values'">
                            <span class="tag purple closable" *ngFor="let value of $any(filter.value)">
                                <span class="text-first">{{ value.label }}</span>
                                <i nz-icon  nzType="close" nzTheme="outline" (click)="deleteFilter(value.value, $any(filter.key))"></i>
                            </span>
                        </ng-container>

                        <ng-container *ngIf="$any(filter.key) !== 'values' && $any(filter.value).value">
                            <span class="tag purple closable">
                                <span class="text-first">{{ $any(filter.value).label }}</span>
                                <i nz-icon  nzType="close" nzTheme="outline" (click)="deleteFilter($any(filter.value).value, $any(filter.key))"></i>
                            </span>
                        </ng-container>

                    </ng-container>
                </div>

                <div class="form-group form-group-footer margins">
                    <div class="flex-content a-center" style="width: 200px">
                        <button nz-button class="c-dark size-large" [ngClass]="{disabled: !canQueryPermissions()}"
                            nz-tooltip [nzTooltipTitle]="disabledFilterText()"
                            nzBlock (click)="doSearch()">
                            Consultar
                        </button>
                    </div>
                </div>

                <ng-container *ngIf="!notResult">
                    <div class="card-delivery" *ngFor="let entity of entityList; let e = index" >
                        <div class="section-delivery left">
                            <span class="font s-14 w-bold c-black">{{ getEntityName(entity) }}
                                <i
                                    *ngIf="entity.is_inherited"
                                    nz-icon nzType="branches"
                                    class="icon c-default"
                                    nzTheme="outline"
                                    [nzTooltipTitle]="entity.value ? 'Permiso heredado: ' + entity.value: 'Permiso heredado: No definido'"
                                    nzTooltipPlacement="top"
                                    nz-tooltip
                            ></i></span>
                        </div>
                        <div class="section-delivery right">
                            <div class="form-group pass" style="width: 100%;">
                                
                                    <div (click)="editPermission(-1, entity, e)" class="pass_value" [ngClass]="{'slected_pass_value': checkSelected(-1, entity, e)}" nz-row [nzGutter]="16" *ngIf="!entity.is_inherited">
                                        <div nz-col [nzSpan]="24">
                                            <div class="inner-box">Heredar permiso</div>
                                        </div>
                                    </div>
                                    <div (click)="editPermission(pass_value.id_pass_value, entity, e)" class="pass_value" [ngClass]="{'slected_pass_value': checkSelected(pass_value.id_pass_value, entity, e)}" nz-row [nzGutter]="16" *ngFor="let pass_value of selectedPermission.pass_values; let i = index">
                                        <div nz-col [nzSpan]="24">
                                            <div class="inner-box">{{pass_value.descripcion}}</div>
                                        </div>
                                    </div>
                                
                                <!--
                                <span class="font s-12 c-gray no-bottom-small">Permiso</span>
                                <div class="form-group margins m-t-0">
                                    <nz-select nzShowSearch nzAllowClear class="select-prepaid" [(ngModel)]="entity.id_pass_value" nzPlaceHolder="Selecciona el permiso" (ngModelChange)="editPermission($event, entity)" nzBorderless style="width: 100%;">
                                        <nz-option *ngIf="!entity.is_inherited"
                                                nzValue=""
                                                nzLabel="Heredar permiso"></nz-option>

                                        <nz-option
                                                *ngFor="let pass_value of selectedPermission.pass_values; let i = index"
                                                nzValue="{{ pass_value.id_pass_value }}"
                                                nzLabel="{{ pass_value.descripcion }}"></nz-option>
                                    </nz-select>
                                    <i class="bar"></i>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="notResult && canQueryPermissions()">
                    <div class="card-delivery" *ngFor="let entity of searchedList; let e = index" >
                        <div class="section-delivery left">
                            <span class="font s-14 w-bold c-black">{{ entity.name }} (ID: {{ entity.id}})
                                <i
                                    nz-icon nzType="branches"
                                    class="icon c-default"
                                    nzTheme="outline"
                                    [nzTooltipTitle]="entity.value ? 'Permiso heredado: ' + entity.value: 'Permiso heredado: No definido'"
                                    nzTooltipPlacement="top"
                                    nz-tooltip
                            ></i></span>
                        </div>
                        <div class="section-delivery right">
                            <div class="form-group pass" style="width: 100%;">
                                    <div (click)="setPermission(pass_value.id_pass_value, entity, e)" class="pass_value" [ngClass]="{'slected_pass_value': checkSelected(pass_value.id_pass_value, entity, e)}" nz-row [nzGutter]="16" *ngFor="let pass_value of selectedPermission.pass_values; let i = index">
                                        <div nz-col [nzSpan]="24">
                                            <div class="inner-box">{{pass_value.descripcion}}</div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- content Drawer -->

                <nz-drawer
                        [nzClosable]="false"
                        [nzVisible]="visible"
                        nzPlacement="right"
                        (nzOnClose)="close()"
                        class="content-drawer"
                        [nzWidth]="drawerWidth()"
                >
                    <ng-container *nzDrawerContent>
                        <div class="content-drawer">
                            <div class="header-drawer">
                                <i
                                        nz-icon
                                        nzType="close"
                                        nzTheme="outline"
                                        (click)="close()"
                                        class="icon size-18"
                                ></i>
                            </div>
                            <p class="font s-22 w-bold c-black">Filtrar</p>
                            <p class="font s-14 c-primary">{{ selectedPermission.nombre }}</p>
                            <p class="font s-14 w-bold">Buscar por valor del permiso</p>
                            <div class="margins m-b-10" *ngFor="let pass of selectedPermission.pass_values">
                                <input type="checkbox" [checked]="includedInValuesFilter(pass.id_pass_value)" class="font s-12 c-black checkbox-pass-value" (click)="toggleFilterValue(pass.id_pass_value, pass.descripcion )">  {{ pass.descripcion }}
                                <!--<label nz-checkbox  class="font s-12 c-black"  >-->
                                    <!--<span (click)="toggleFilterValue(pass.id_pass_value)">{{ pass.descripcion }}</span>-->
                                <!--</label>-->
                            </div>
                            <div class="body-drawer">
                                <div class="form-group" *ngIf="filters.distributor.list.length > 0">
                                    <span class="font s-12 c-black w-bold no-bottom-small">Buscar por distribuidor</span>
                                    <div class="form-group margins m-b-20">
                                        <nz-select nzShowSearch nzAllowClear class="select-prepaid" [(ngModel)]="filters.distributor.value" nzPlaceHolder="Selecciona Distribuidor" (ngModelChange)="addFilter($event, 'distributor')" nzBorderless style="width: 100%;">
                                            <nz-option *ngFor="let distributor of filters.distributor.list" [nzValue]="distributor.id" nzLabel="{{ distributor.name }}"></nz-option>
                                        </nz-select>
                                        <i class="bar"></i>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="filters.group.list.length > 0">
                                    <span class="font s-12 c-black w-bold no-bottom-small">Buscar por grupo</span>
                                    <div class="form-group margins m-b-20">
                                        <nz-select nzShowSearch nzAllowClear class="select-prepaid" [(ngModel)]="filters.group.value" nzPlaceHolder="Selecciona Grupo" nzBorderless style="width: 100%;" (ngModelChange)="addFilter($event, 'group')">
                                            <nz-option *ngFor="let group of filters.group.list" [nzValue]="group.id" nzLabel="{{ group.name }}"></nz-option>
                                        </nz-select>
                                        <i class="bar"></i>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="filters.merchan.list.length > 0">
                                    <span class="font s-12 c-black w-bold no-bottom-small">Buscar por GPV</span>
                                    <div class="form-group margins m-b-20">
                                        <nz-select nzShowSearch nzAllowClear class="select-prepaid" [(ngModel)]="filters.merchan.value" nzPlaceHolder="Selecciona GPV" nzBorderless style="width: 100%;" (ngModelChange)="addFilter($event, 'merchan')">
                                            <nz-option *ngFor="let merchan of filters.merchan.list" [nzValue]="merchan.id" nzLabel="{{ merchan.name }}"></nz-option>
                                        </nz-select>
                                        <i class="bar"></i>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="filters.company.list.length > 0" (ngModelChange)="addFilter($event, 'company')">
                                    <span class="font s-12 c-black w-bold no-bottom-small">Buscar por Empresa</span>
                                    <div class="form-group margins m-b-20">
                                        <nz-select nzShowSearch nzAllowClear class="select-prepaid" [(ngModel)]="filters.company.value" nzPlaceHolder="Selecciona Empresa" (ngModelChange)="addFilter($event, 'company')" nzBorderless style="width: 100%;">
                                            <nz-option *ngFor="let company of filters.company.list" [nzValue]="company.id" nzLabel="{{ company.name }}"></nz-option>
                                        </nz-select>
                                        <i class="bar"></i>
                                    </div>
                                </div>
                                <div class="margins m-t-50 form-group" *ngIf="profile === 'pdv'">
                                    <label for="id_input" class="font s-14 c-black w-bold" style="width: 100%">Buscar por ID de PDV</label>
                                    <input id="id_input" [(ngModel)]="filters.pdv.value" (input)="applyFilters()"  nz-input type="text" nzBorderless (ngModelChange)="addFilter($event, 'pdv')">
                                    <i class="bar"></i>
                                </div>
                                <div class="margins m-t-70">
                                    <div nz-row nzGutter="24">
                                        <div class="margins m-b-10" nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                                            <button nz-button class="c-text size-large" nzBlock (click)="resetFilters(true)">Borrar selección</button>
                                        </div>
                                        <div class="margins m-b-10" nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                                            <button nz-button class="c-dark size-large"
                                                    [ngClass]="{disabled: !canQueryPermissions()}" nzBlock
                                                    nz-tooltip [nzTooltipTitle]="disabledFilterText()"
                                                    (click)="doSearch()">Aplicar filtros</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </nz-drawer>



    </div>
    </ng-container>
</div>