import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-observations',
    templateUrl: './observations.component.html',
    styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent implements OnInit, OnChanges {

    @Input() value!: string;
    @Input() title: string = 'Observaciones';
    @Input() readOnly: boolean = false;
    @Input() emptyText: string = '';
    @Output() updateObservation = new EventEmitter<any>();

    public showObservations: boolean = false;
    public showRemoveMsg: boolean = false;
    public textAreaForm: FormControl = new FormControl('');

    constructor() { }

    ngOnInit(): void {
    } 

    ngOnChanges(changes: SimpleChanges): void {
        if (this.value && this.value !== '') {
            this.textAreaForm.patchValue(this.value);
            this.changeStateObservations(true);
            //this.saveObservation();
            this.textAreaForm?.disable();
        } else {
            this.changeStateObservations(false);
        }
    }

    public changeStateObservations(show: boolean): void {
        this.showObservations = show;
    }

    public saveObservation(): void {
        if (this.textAreaForm?.value && this.textAreaForm?.value !== '') {
            this.updateObservation.emit(this.textAreaForm?.value);
            this.textAreaForm?.disable();
        }
    }

    public removeObservations(): void {
        this.showRemoveMsg = true;
        setTimeout(() => { 
            this.showRemoveMsg = false;
        }, 2000);
        this.textAreaForm?.enable();
        this.textAreaForm?.patchValue('');
        this.updateObservation.emit(this.textAreaForm?.value);
    }

    public editObservations(): void {
        this.textAreaForm?.enable();
    }
}
