import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {MenuService} from "./menu.service";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})


export class AccessService {

    constructor(
        private auth: AuthService,
        private menu: MenuService,
        private router: Router
    ) {
    }

    checkAccess(url: string = '', permissions: Array<string> = []) {
        this.auth.check();

        if (url) {
            if (!this._checkMenuAccess(url)) {
                console.log('Redirect home: No tienes acceso a ' + url);
                this.router.navigate(['/']);
            }
        }

        if (permissions.length) {
            for (let i = 0; i < permissions.length; i++) {
                if (!this.auth.userCan(permissions[i])) {
                    console.log('Redirect home: No tienes permiso ' + permissions[i])
                    this.router.navigate(['/']);
                }
            }
        }
    }

    _checkMenuAccess(url: string) {
        return this.menu.userHasAccess(url);
    }
}