import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './http/api.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DigoService extends ApiService {

    private menus: any;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
    }

    public getAllMenus(): Observable<any> {
        return this.http.get<any>(environment.oldDigoFront + 'api-micros/admin/getMenu.php' + (environment.fakeLogin ? '?dev' : ''), {headers: this.getHeaders()});
    }

    public keepAlive(): Observable<any> {
        return this.http.get<any>(environment.oldDigoFront + 'keepAlive.php', {headers: this.getHeaders()});
    }

    public goLogin() {
        if (!environment.production) {
            if (!location.origin.includes('localhost')) {
                window.location.href = 'https://int.web-pdv.digo.tecalis.dev/';
            }
        } else {
            window.location.href = 'https://digo.dexga.com';
        }
    }
}
