<div (click)="showModal()">
    <i nz-icon nzType="edit" nzTheme="outline" class="margins m-r-10"></i>
    Cambiar contraseña
</div>
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="handleClose()"
    (nzOnOk)="handleClose()"
    nzWidth="687px"
    nzCentered
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal" *ngIf="!isChanged">
            <p class="font s-28 w-bold a-center margins m-b-30">Si continúa, se reseteará la contraseña del usuario.</p>
            <div nz-row nzGutter="24">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24" *ngIf="!isLoading">
                    <button nz-button class="c-primary size-large" nzBlock (click)="changePassword()">Continuar</button>
                </div>
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24" *ngIf="!isLoading">
                    <button nz-button class="c-dark size-large" nzBlock (click)="handleClose()">Cancelar</button>
                </div>
                <div nz-col nzSm="24" align="center" *ngIf="isLoading">
                    <img src="./assets/images/loader.svg" width="60" alt="">
                </div>
            </div>
        </div>

        <div class="body-modal-confirm" *ngIf="isChanged">
            <img src="../../../../assets/images/pdv/icon-check.svg" class="margins m-b-70" alt="Success" />
            <p class="font s-22 a-center w-bold">Se ha cambiado correctamente la contraseña del usuario</p>
        </div>
    </ng-container>
</nz-modal>
