import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { PaginatorComponent } from './components/list/paginator/paginator.component';
import { FiltersComponent } from './components/list/filters/filters.component';
import { TableComponent } from './components/list/table/table/table.component';
import { RowComponent } from './components/list/table/row/row.component';
import { CeilComponent } from './components/list/table/ceil/ceil.component';
import { SharedModule } from "../../shared/shared.module";
import { CustomPipesModule } from "../custom-pipes/custom-pipes.module";



@NgModule({
    declarations: [
        ListComponent,
        PaginatorComponent,
        FiltersComponent,
        TableComponent,
        RowComponent,
        CeilComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CustomPipesModule
    ],
    exports: [ListComponent, PaginatorComponent]
})
export class ListsModule { }
