import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {CompanyOrder} from "../../../core/models/lists.models";

@Component({
    selector: 'app-company-table',
    templateUrl: './company-table.component.html',
    styleUrls: ['./company-table.component.scss']
})
export class CompanyTableComponent implements OnInit {
    @Input() companies_data: any = null;
    @Input() companies_loading: boolean = true;
    @Input() setCurrentPage = (page: number) => {};
    @Output() setOrder = new EventEmitter<CompanyOrder>();

    CompanyOrder = CompanyOrder;
    order = CompanyOrder.EMPRESA_RAZON_SOCIAL;

    constructor(
        public authService: AuthService
    ) { }

    ngOnInit(): void {
    }

    isRebranding(company: any): boolean {
        if (company.pdv) {
            return company.pdv.isRebranding;
        }
        return false;
    }

    isJazztel(company: any): boolean {
        if (company.pdv) {
            return company.pdv.isJazztel;
        }
        return false;
    }

    isSimyo(company: any): boolean {
        if (company.pdv) {
            return company.pdv.isSimyo;
        }
        return false;
    }

    getClassification(company: any): string {
        switch (company.pdv.clasificacion) {
            case "TOP":
                return "T";
            case "VALOR":
                return "V";
            case "PREPAGO TOP":
                return "X";
            case "PREPAGO COLABORADOR":
                return "C";
            case "AAEE":
                return "A";
            case "PREPAGO":
                return "R";
            case "PREPAGO MEDIUM":
                return "M";
            case "EXPERTO":
                return "E";
            case "BASICO":
                return "B";
            case "PREMIUM":
                return "P";
            default:
                return "";
        }
    }

    setCompanyOrder(order: CompanyOrder) {
        this.order = order;
        this.setOrder.emit(this.order);
    }
}
