import { Injectable } from "@angular/core";
import * as moment from 'moment';
import { pageLimit } from "../shared/constantes";
import { isIterable } from "../core/functions";
import { IListFilter, IListPaginator, IListRequestBody } from "../core/models/lists.models";

@Injectable({
    providedIn: 'root'
})
export class ListsFiltersService {

    // metodo estandarizado de los listados para aplicar el paginador y los filtros en sus llamadas http
    public getPayload(paginator: IListPaginator | null = null, filters: IListFilter[] | any = null, orderBy: any = null): IListRequestBody {
        const payload: IListRequestBody = {
            limit: pageLimit,
            page: 1
        };

        if (orderBy) {
            payload.orderBy = this.isJSON(orderBy) ? JSON.parse(orderBy) : orderBy;
        }

        if (paginator) {
            payload.limit = paginator.limit;
            payload.page = paginator.currentPage
        }

        if (filters && isIterable(filters)) {
            payload.filters = {};
            filters.forEach((filter: IListFilter | any) => {
                // filtros corrientes (text, select, etc)
                if (typeof filter.value !== 'undefined' && filter.value !== null && filter.value !== '') {
                    payload.filters[filter.name] = filter.value;
                }

                //filtros from-to (hay que convertirlo en dos parametros de filtrado)
                if (!filter.format_date || filter.format_date === 'normal') {
                    if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '') {
                        payload.filters[filter.name_from] = filter.value_from;
                    }
                    if (typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_to] = filter.value_to;
                    }
                }
                if (filter.format_date && filter.format_date === 'with-hours') {
                    if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '') {
                        payload.filters[filter.name_from] = moment(filter.value_from).format('YYYY-MM-DD 00:00:00');
                    }
                    if (typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_to] = moment(filter.value_to).format('YYYY-MM-DD 23:59:59');
                    }
                }

                if (filter.format_date && filter.format_date === 'one-line-with-hours') {
                    if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '' && typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_from] = 'bt|' + moment(filter.value_from).format('YYYY-MM-DD 00:00:00') + '|' + moment(filter.value_to).format('YYYY-MM-DD 23:59:59');
                    } else if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '') {
                        payload.filters[filter.name_from] = 'gte|' + moment(filter.value_from).format('YYYY-MM-DD 00:00:00');
                    } else if (typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_to] = 'lte|' + moment(filter.value_to).format('YYYY-MM-DD 23:59:59');
                    }
                }

                if (filter.format_date && filter.format_date === 'one-line') {
                    if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '' && typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_from] = 'bt|' + moment(filter.value_from).format('YYYY-MM-DD') + '|' + moment(filter.value_to).format('YYYY-MM-DD');
                    } else if (typeof filter.value_from !== 'undefined' && filter.value_from !== null && filter.value_from !== '') {
                        payload.filters[filter.name_from] = 'gte|' + moment(filter.value_from).format('YYYY-MM-DD');
                    } else if (typeof filter.value_to !== 'undefined' && filter.value_to !== null && filter.value_to !== '') {
                        payload.filters[filter.name_to] = 'lte|' + moment(filter.value_to).format('YYYY-MM-DD');
                    }
                }
            })
        }
        return payload;
    }

    isJSON(str: string) {
        try {
            return JSON.parse(str) && !!str;
        } catch (e) {
            return false;
        }
    }
}