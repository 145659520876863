import { Component, OnInit } from '@angular/core';
import { ReportsService } from "../../../../services/http/reports/reports.service";
import { ListsFiltersService } from "../../../../services/lists.filters.service";
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-suscripcion-informes',
  templateUrl: './suscripcion-informes.component.html',
  styleUrls: ['./suscripcion-informes.component.scss']
})
export class SuscripcionInformesComponent implements OnInit {
    public suscription: any = null;
    public suscriptions: any = null;
    public modalVisible: boolean = false;
    public deleteModal: boolean = false;
    public deleteCandidate: any = null;
    public loading: boolean = true;
    public error: string | boolean = false;
    public isColumn: boolean = false;

    public paginator: any = {
        limit: 10000,
        totalPages: 0,
        currentPage: 1,
        totalItems: 0
    };
    private requestList!: any;
    public filtroFecha: any = {
        "1": "Desde el día 1",
        "2": "Últimos 15 días",
        "3": "Últimos 30 días",
    }

    public area: any = {
        "1": "Tiendas Jazztel",
        "2": "Nuevos canales",
        "3": "Grandes superficies",
        "4": "Televenta",
        "5": "No exclusivo",
        "6": "Stands",
    }

    public periodicidad: any = {
        "1": "Mensual (día 1)",
        "2": "Semanal (lunes)",
        "3": "Diaria",
    }

    public table: any = null;

    constructor(
        private reportsService: ReportsService,
        private filterService: ListsFiltersService,
    ) { }

    onShow() {
        this.isColumn = !this.isColumn;
    }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;
        if (this.requestList) this.requestList.unsubscribe();
        this.requestList = this.reportsService.listSubscriptions(this.filterService.getPayload(this.paginator, []))
            .subscribe({
                next: (reports:any) => {
                    this.error = false;
                    if (reports.data) {
                        this.suscriptions = reports.data;
                        this.prepareTable(reports.data);
                        this.preparePaginator(reports);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.prepareTable([]);
                    this.error = "ERROR";
                    this.loading = false;
                },
                complete:() => {
                    this.loading = false;
                }
            });
    }

    prepareTable(data: any = null) {
        const table: any = {
            headers: {
                rows: [
                    {
                        cells: [
                            {value: 'Informe'},
                            {value: 'Filtro Fecha'},
                            {value: 'Filtro Área'},
                            {value: 'Periodicidad'},
                            {value: 'Fecha inicio'},
                            {value: 'Fecha fin'},
                            {value: 'Acciones'},
                        ]
                    }
                ]
            },
            body: {
                rows: []
            }
        };

        Object.keys(data).forEach(key => {
            const item: any = data[key];
            table.body.rows.push({
                clickValue: item.idReportSubscription,
                cells: [
                    {value: item.idReportSubscriptionParametersCode},
                    {value: this.filtroFecha[item.idReportSubscriptionParametersFilterDate] ?? ''},
                    {value: this.area[item.idReportSubscriptionParametersFilterArea] ?? ''},
                    {value: this.periodicidad[item.periodicity] ?? ''},
                    {value: this.convertDate(item.dateStart)},
                    {value: this.getDateEnd(item.dateEnd)},
                    {value: this.getActions(item)}
                ]
            })
        });

        this.table = table;
    }

    preparePaginator(data: any) {
        this.paginator.currentPage = data.current_page;
        this.paginator.totalPages = data.last_page;
        this.paginator.totalItems = data.total;
    }

    tableClicked(ev: any){
        const target = ev.event.target;
        if (target.closest('.edit-suscription')) {
            const subs = this.suscriptions.filter((sub: any) => sub.idReportSubscription == ev.rowId)[0];

            this.suscription = {
                idReportSubscription: subs.idReportSubscription,
                code: subs.idReportSubscriptionParametersCode,
                filtro_fecha: subs.idReportSubscriptionParametersFilterDate,
                filtro_area: subs.idReportSubscriptionParametersFilterArea,
                periodicidad: subs.periodicity,
                fecha_inicio: subs.dateStart,
                fecha_fin: subs.dateEnd,
                email: subs.email,
            };
            this.modalVisible = true;
        }

        if (target.closest('.delete-suscription')) {
            this.deleteModal = true;
            this.deleteCandidate = ev.rowId;
        }
    }

    deleteSubscription() {
        if (this.deleteCandidate) {
            this.deleteModal = false;
            this.loading = true;
            this.reportsService.deleteReportSubscription(this.deleteCandidate)
                .subscribe(res => {})
                .add(() => window.location.reload());
        }
    }

    pageChanged(paginator: any) {
        this.paginator = paginator;
        this.load();
    }

    getActions(item: any) {
        const btn_edit = document.querySelector('#edition_template .edit-suscription')!.cloneNode(true) as HTMLElement;
        const btn_delete = document.querySelector('#edition_template .delete-suscription')!.cloneNode(true) as HTMLElement;
        btn_edit.dataset['idreport'] = item.idReportSubscription;
        btn_delete.dataset['idreport'] = item.idReportSubscription;

        let html = '';
        html += btn_edit.outerHTML;
        html += btn_delete.outerHTML;

        return html;
    }

    private getDateEnd(date: string): string {
        if (date && date !== '') {
            return this.convertDate(date);
        }
        return 'No tiene';
    }

    getStatusText(item: any) {
        const status = parseInt(item.finalizado);
        switch (status) {
            case 0:
                return 'En curso';
            case 1:
                return 'Finalizado';
            case 2:
            case 4:
                return 'Sin resultados'
            case 3:
                return 'Cancelado'
        }
    }

    getRowStyle(item: any) {
        const base = '5px solid ';
        const status = parseInt(item.finalizado);
        switch (status) {
            case 0:
                return {};
            case 1:
                return {'border-left': base + '#009900'}
            case 2:
            case 4:
                return {'border-left': base + '#42bff5'};
            case 3:
                return {'border-left': base + 'red'}
        }
        return {};
    }

    modalSuscripcion(suscription: any = null) {
        this.suscription = null;
        this.modalVisible = true;
    }

    saveModalSuscription(ev: any) {
        if (ev && ev.suscription) {
            if (typeof ev.suscription.idReportSubscription !== 'undefined') {
                this.reportsService.updateReportSubscription(ev.suscription, ev.suscription.idReportSubscription)
                    .subscribe(res => {
                        window.location.reload();
                    });
            } else {
                this.reportsService.addReportSubscription(ev.suscription)
                    .subscribe(res => {
                        window.location.reload();
                    })
            }
        }
    }

    private convertDate(date: string): string {
        return date && date !== '' ? moment(date).format('DD/MM/YYYY') : '-';
    }

}
