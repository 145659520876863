import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (control: AbstractControl): ValidationErrors | null => {
    // Check if the value is null or undefined
    if (!control.value) {
      return null;
    }
    const valid = emailPattern.test(control.value);
    return valid ? null : { email: { valid: false, value: control.value } };
  };
}