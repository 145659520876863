
<app-list
        (tableClick)="tableClicked($event)"
        (pageChanged)="pageChanged($event)"
        (filtersApplied)="filtersApplied($event)"

        [filters]="[]"
        [table]="table"
        [paginator]="null"
        [loading]="loading"
        [error]="error"
>
</app-list>