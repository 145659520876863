<div class="content-open-drawer" (click)="open()">
    <i nz-icon nzType="filter" nzTheme="outline" class="margins m-r-10"></i>
    <span class="font s-14">Filtrar</span>
</div>
<div class="flex-content a-wrap margins m-b-50">
    <span class="tag purple closable">
        <span class="text-first">Solicitar foto y firma</span>
        <i nz-icon  nzType="close" nzTheme="outline"></i>
    </span>
    <span class="tag purple closable">
        <span class="text-first">Solicitar foto y firma</span>
        <i nz-icon  nzType="close" nzTheme="outline"></i>
    </span>
</div>
<div class="card-delivery">
    <div class="section-delivery left">
        <span class="font s-14 w-bold c-black">Orange Distribuidor S.L.</span>
    </div>
    <div class="section-delivery right">
        <div class="form-group" style="width: 100%;">
            <span class="font s-12 c-gray no-bottom-small">Permiso</span>
            <div class="form-group margins m-t-0">
                <nz-select class="select-prepaid" ngModel="mes" nzPlaceHolder="Selecciona el permiso" nzBorderless style="width: 100%;">
                    <nz-option nzValue="a" nzLabel="Solicitare solo foto"></nz-option>
                    <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                    <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                </nz-select>
                <i class="bar"></i>
            </div>
        </div>
    </div>
</div>
<div class="card-delivery">
    <div class="section-delivery left">
        <span class="font s-14 w-bold c-black">Orange Distribuidor S.L.</span>
    </div>
    <div class="section-delivery right">
        <div class="form-group" style="width: 100%;">
            <span class="font s-12 c-gray no-bottom-small">Permiso</span>
            <div class="form-group margins m-t-0">
                <nz-select class="select-prepaid" ngModel="mes" nzPlaceHolder="Selecciona el permiso" nzBorderless style="width: 100%;">
                    <nz-option nzValue="a" nzLabel="Solicitare solo foto"></nz-option>
                    <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                    <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                </nz-select>
                <i class="bar"></i>
            </div>
        </div>
    </div>
</div>


<!-- content Drawer -->

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  (nzOnClose)="close()"
  class="content-drawer"
  nzWidth="440px"
>
  <ng-container *nzDrawerContent>
      <div class="content-drawer">
        <div class="header-drawer">
            <i 
                nz-icon
                nzType="close"
                nzTheme="outline"
                (click)="close()"
                class="icon size-18"
            ></i>
        </div>
        <p class="font s-22 w-bold c-black">Filtrar</p>
        <p class="font s-14 c-primary">Acceso a Ciclo</p>
        <p class="font s-14 w-bold">Por permiso</p>
        <div class="margins m-b-20">
            <label nz-checkbox formControlName="select" class="font s-12 c-black">
                <span>Solicitar foto y firma</span>
            </label>
        </div>
        <div class="body-drawer">
            <div class="form-group">
                <span class="font s-12 c-black w-bold no-bottom-small">Por distribuidor</span>
                <div class="form-group margins m-b-50">
                    <nz-select class="select-prepaid" ngModel="mes" nzPlaceHolder="Selecciona el distribuidor" nzBorderless style="width: 100%;">
                        <nz-option nzValue="a" nzLabel="Solicitare solo foto"></nz-option>
                        <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                        <nz-option nzValue="b" nzLabel="Tipo de permiso a aplicar en el grupo"></nz-option>
                    </nz-select>
                    <i class="bar"></i>
                </div>
            </div>
            <div class="margins m-t-70">
                <div nz-row nzGutter="24">
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                        <button nz-button class="c-text size-large" nzBlock>Borrar selección</button>
                    </div>
                    <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                        <button nz-button class="c-dark size-large" nzBlock>Consulta directa</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </ng-container>
</nz-drawer>
