<nz-modal
    [nzVisible]="true"
    [nzFooter]="null"
    (nzOnCancel)="closeModal()"
    (nzOnOk)="confirmModal()"
    nzCentered>

    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal" *ngIf="!loadingModal && !resetPasswordOk" [formGroup]="resetPasswordFormGroup">
            <p class="font s-22 w-bold a-center margins m-b-20">Cambiar contraseña</p>
            <div nz-row nzGutter="24" style="justify-content: left;">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="12" nzSm="12">
                    <div class="form-group margins m-b-20">
                        <input (focus)="onFocus('actual')" type="password" required formControlName="currentPassword">
                        <label for="input" class="control-label flex-content f-between left-inherit" style="width: 100%">
                            <div>
                                <span><b>Contraseña actual*</b></span>
                            </div>
                        </label><i class="bar"></i>
                    </div>
                </div>
            </div>
            <div nz-row nzGutter="24" style="justify-content: left;">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="12" nzSm="12">
                    <div class="form-group margins m-b-20">
                        <input (focus)="onFocus('nueva')" type="password" required formControlName="newPassword">
                        <label for="input" class="control-label flex-content f-between left-inherit" style="width: 100%">
                            <div>
                                <span><b>Contraseña nueva*</b></span>
                            </div>
                        </label><i class="bar"></i>
                        <div class="requeriments">
                            <span [ngClass]="diff() ? 'valid' : 'error'">
                                <i *ngIf="diff()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!diff()" nz-icon nzType="close" nzTheme="outline"></i>
                                Diferente a la actual</span><br>
                            <span [ngClass]="longEnough() ? 'valid' : 'error'">
                                <i *ngIf="longEnough()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!longEnough()" nz-icon nzType="close" nzTheme="outline"></i>
                                8 caracteres</span><br>
                            <span [ngClass]="upperLower() ? 'valid' : 'error'">
                                <i *ngIf="upperLower()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!upperLower()" nz-icon nzType="close" nzTheme="outline"></i>
                                Mayúscula/s y minúscula/s</span><br>
                            <span [ngClass]="number() ? 'valid' : 'error'">
                                <i *ngIf="number()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!number()" nz-icon nzType="close" nzTheme="outline"></i>
                                Número/s</span><br>
                            <span [ngClass]="noSpaces() ? 'valid' : 'error'">
                                <i *ngIf="noSpaces()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!noSpaces()" nz-icon nzType="close" nzTheme="outline"></i>
                                Sin espacio/s</span><br>
                            <span [ngClass]="special() ? 'valid' : 'error'">
                                <i *ngIf="special()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!special()" nz-icon nzType="close" nzTheme="outline"></i>
                                Caracter especial</span>
                        </div>
                    </div>
                </div>
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="12" nzSm="12">
                    <div class="form-group margins m-b-20">
                        <input (focus)="onFocus('nueva')" type="password" required formControlName="newRepeatPassword">
                        <label for="input" class="control-label flex-content f-between left-inherit" style="width: 100%">
                            <div>
                                <span><b>Contraseña nueva (repetir)*</b></span>
                            </div>
                        </label><i class="bar"></i>
                        <div class="requeriments">
                            <span [ngClass]="match() ? 'valid' : 'error'">
                                <i *ngIf="match()" nz-icon nzType="check" nzTheme="outline"></i>
                                <i *ngIf="!match()" nz-icon nzType="close" nzTheme="outline"></i>
                                Coinciden</span><br>
                        </div>
                    </div>
                </div>
            </div>
            <div nz-row nzGutter="24" class="error-form margins m-b-20" style="justify-content: center;" *ngIf="resetPasswordError && resetPasswordError !== '' && showErrorMsg">
                {{ resetPasswordError || 'Ha ocurrido un error al cambiar la contraseña. inténtelo de nuevo más tarde.' }}
            </div>
            <div nz-row nzGutter="24">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                    <button nz-button class="c-primary size-large" nzBlock [disabled]="!resetPasswordFormGroup.valid" (click)="confirmModal()">Cambiar contraseña</button>
                </div>
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                    <button nz-button class="c-dark size-large" nzBlock (click)="closeModal()">Cancelar</button>
                </div>
            </div>
        </div>
        <div align="center" *ngIf="resetPasswordOk">
            <img src="./assets/images/ok-blue.svg" width="60" alt="">
            <h5 class="font s-16 margins m-t-10">Contraseña cambiada correctamente</h5>
        </div>
        <div align="center" *ngIf="loadingModal">
            <h5 class="font s-16 margins m-t-20">Reseteando contraseña...</h5>
            <img src="./assets/images/loader.svg" width="60" alt="">
        </div>
        <button *ngIf="!loadingModal && !resetPasswordOk" 
        nz-button nzBlock
        class="margins m-t-20 c-primary"
        (click)="auth2FA()">
        Autenticación 2FA
        </button>
    </ng-container>
</nz-modal>

<!--ShowRequirements-->
<nz-modal
    [(nzVisible)]="isVisible"
    [nzFooter]="null"
    (nzOnCancel)="closeRequirements()"
    (nzOnOk)="closeRequirements()"
    nzWidth="687px"
    nzCentered
>
    <ng-container *nzModalContent class="wrapper-modal">
        <div class="body-modal">
            <p class="font s-22 w-bold a-center margins m-b-30">Requisitos nueva constraseña.</p>
            <div>
                <ul class="requeriments-list">
                    <li>La nueva contraseña debe ser diferente de la actual</li>
                    <li>La contraseña debe contener al menos 8 caracteres</li>
                    <li>La contraseña debe contener al menos 1 número</li>
                    <li>La contraseña debe contener al menos 1 letra mayúscula</li>
                    <li>La contraseña debe contener al menos 1 letra minúscula</li>
                    <li>La contraseña no puede contener espacios en blanco</li>
                    <li>La contraseña debe contener al menos un caracter especial (%^&@.,*# etc...)</li>
                </ul>
            </div>
        </div>
    </ng-container>
</nz-modal>