import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import { IListFilter } from 'src/app/core/models/lists.models';
import {isIterable} from "../../../../functions";
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

    public expandFilters: boolean = false;
    public form: FormGroup = new FormGroup({});
    public selectFocused: string = '';

    @Input() filters: any = [];
    @Input() maxFiltersVisible: number = 100;
    @Output() filtersAplied = new EventEmitter();
    @Output() filterChanged = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        let fields: any = {};
        if (isIterable(this.filters)) {
            this.filters.forEach((filter: any, index: number) => {
                if (typeof filter.name_from !== 'undefined' && typeof filter.name_to !== 'undefined') {
                    const valueFrom = filter.value_from != null && filter.value_from != '' ? formatDate(filter.value_from, 'YYYY-MM-dd', 'en') : null;
                    const valueTo = filter.value_to != null && filter.value_to != '' ? formatDate(filter.value_to, 'YYYY-MM-dd', 'en') : null;
                    fields[filter.name_from] = new FormControl(valueFrom);
                    fields[filter.name_to] = new FormControl(valueTo);
                    // if (index >= this.maxFiltersVisible && ((filter?.value_from && filter?.value_from !== '') || (filter?.value_to && filter?.value_to !== ''))) {
                    //     this.expandFilters = true;
                    // }
                } else {
                    fields[filter.name] = new FormControl(filter.value);
                    // if (index >= this.maxFiltersVisible && filter?.value && filter?.value !== '') {
                    //     this.expandFilters = true;
                    // }
                }
            })
        }
        this.form = new FormGroup(fields);
        document.addEventListener("keypress", (event) => {
            if (event.keyCode == 13) {
                this.submit();
            }
        });
    }

    getLabel(field: any) {
        return field.label ? field.label : field.name;
    }

    submit() {
        for (let [key, value] of Object.entries(this.form.value)) {
            this.filters.forEach((filter: any) => {
                if (filter.name === key) {
                    filter.value = value;
                }
                if (filter.name_from === key) {
                    filter.value_from = value;
                }
                if (filter.name_to === key) {
                    filter.value_to = value;
                }
            })
        }
        this.filtersAplied.emit(this.filters);
    }

    public changeFilter(filterChanged: IListFilter) {
        for (let [key, value] of Object.entries(this.form.value)) {
            this.filters.forEach((filter: any) => {
                if (filterChanged.name === key) {
                    filterChanged.value = value;
                }
                if (filterChanged.name_from === key) {
                    filterChanged.value_from = value;
                }
                if (filterChanged.name_to === key) {
                    filterChanged.value_to = value;
                }
            })
        }
        if (filterChanged.parent_form_from && filterChanged.parent_form_from.length) {
            filterChanged.parent_form_from.forEach(form => {
                this.form.get(form)?.patchValue(null);
            });
        }
        this.filterChanged.emit(filterChanged);
    }

    public showLabelSelect(name: string) {
        const found = this.filters.find((filter : any) => filter.name === name || filter.label === name);

        if (found) {

            return (found.value && found.value !== '') || (found.default_value && found.default_value.value === found.value) || (found.options && found.options.length && found.options.length > 1 && found.options[0].value === found.value) || (found.label === this.selectFocused)
        }
        return false;
    }

    public focusSelect(name: string, focused: boolean) {
        this.selectFocused = '';
        if (focused)
            this.selectFocused = name;
    }

}
