import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportsService } from 'src/app/services/http/reports/reports.service';

@Component({
    selector: 'app-modal-suscripcion',
    templateUrl: './modal-suscripcion.component.html',
    styleUrls: ['./modal-suscripcion.component.scss']
})
export class ModalSuscripcionComponent implements OnInit {
    public loading: boolean = true;
    public form: FormGroup | null = null;
    public reportsList: any;

    @Input() suscription: any = null;
    @Output() saved = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private reportsService: ReportsService,
    ) { }

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.form = this.fb.group(
            {
                code: [this.getVal('code'), Validators.required],
                filtro_fecha: [this.getVal('filtro_fecha', '1'), Validators.required],
                filtro_area: [this.getVal('filtro_area')],
                periodicidad: [this.getVal('periodicidad', '1'), Validators.required],
                fecha_inicio: [this.getVal('fecha_inicio', moment().format('YYYY-MM-DD'))],
                fecha_fin: [this.getVal('fecha_fin', null)],
                email: [this.getVal('email'), [Validators.required, Validators.email]],
            }
        );
        this.getReports();
    }

    getVal(key:string, defval:any = '') {
        return this.suscription && typeof this.suscription[key] !== 'undefined' ? this.suscription[key] : defval
    }

    submit() {
        this.form!.markAllAsTouched();
        this.form!.updateValueAndValidity();
        if (this.form!.valid) {
            const susc = this.form!.getRawValue();
            if (this.suscription) {
                susc.idReportSubscription = this.suscription.idReportSubscription
            }
            this.loading = true;
            this.saved.emit({suscription: susc});
        }
    }

    selectInifinityDate() {
        this.form?.get('fecha_fin')?.patchValue(null);
    }

    selectDate(date: any) {
        this.form?.get('fecha_fin')?.patchValue(date?.currentTarget?.value && date?.currentTarget?.value !== '' ? date?.currentTarget?.value : null);
    }

    private getReports() {
        this.reportsService.getReportsList()
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.reportsList = response.data;
                    }
                },
                error: (error: HttpErrorResponse) => {
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }
}
