import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-menu-desktop',
    templateUrl: './menu-desktop.component.html',
    styleUrls: ['./menu-desktop.component.scss']
})
export class MenuDesktopComponent implements OnInit {

    @Input() item: string = '';
    @Input() menus: any;

    public menuElements: any;

    constructor(
        private router: Router,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.menuElements = this.menus[this.item];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (typeof changes['item'] !== 'undefined') {
            this.menuElements = this.menus[changes['item'].currentValue];
        }
    }

    isIterable(obj: any) {
        return Array.isArray(obj[Object.keys(obj)[0]]);
    }

    public gotoAndClose(item: any) {
        if (item.newAdmin) {
            this.router.navigate([item.url]);
        } else if (this.auth.getIncognitoMode() || this.auth.iOS()) {
            window.location.href = environment.oldDigoFront + item.url + (item.url?.includes('?') ? '&' : '?') + 'jwt=' + this.auth.getToken() + '&noFrameMode=1';
        } else {
            this.router.navigate(['herramientas/' + this.item + '-' + item.label + '/' + item.url]);
        }
    }

    public getName(obj: any) {
        return Object.keys(obj)[0];
    }

}
