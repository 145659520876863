import { Component, OnInit, ViewChild  } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};


@Component({
  selector: 'app-prepaid',
  templateUrl: './prepaid.component.html',
  styleUrls: ['./prepaid.component.scss']
})
export class PrepaidComponent implements OnInit {

  @ViewChild("chart") chart: any;
  public chartOptions: Partial<ChartOptions> | any;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Desktops",
          data: [10, 40, 65, 91, 69, 28]
        }
      ],
      chart: {
        height: 85,
        type: "line",
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        }
      },

      dataLabels: {
        enabled: false
      },

      stroke: {
        curve: 'smooth',
        show: true,
        lineCap: 'butt',
        width: 3,
      },

      tooltip: {
          x: {
              show: false
          },
          y: {
              show: false
          }
      },

      grid: {
        show: false,
        row: {
          colors: [ "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },

      xaxis: {
        show: false,
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep"
        ],      
      }
    };
  }

  ngOnInit(): void {
  }

}
