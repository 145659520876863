import { Component, OnInit } from '@angular/core';
import { MenuService } from "../../../services/menu.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { isIterable } from "../../../core/functions";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    public menu: any = null;
    public showAll: boolean = false;

    constructor(
        private menuService: MenuService,
        private auth: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.auth.check();
        this.menuService.getMenu().subscribe(menu => {
            if (menu) {
                const m = menu;
                if (!m.Herramientas || !this.getPath(m.Herramientas)) {
                    this.router.navigate((['/'])).then()
                } else {
                    this.menu = m.Herramientas
                }
            }
        })
    }

    getImage(defSrc: string = '', value: any = null) {
        return '../../../../../assets/images/pdv/' + defSrc
    }

    getMainColSize() {
        if (this.menu) {
            return parseInt((24 / this.menu.length) + '');
        }
        return 8;
    }

    // debo dividir en bloques separados los links de este bloque content?
    mustFragmentThisContent(content: any) {
        return !content.title
    }

    gotoLink(item: any, label?: any) {
        if (item.newAdmin) {
            this.router.navigate([item.url]).then();
        } else if (this.auth.getIncognitoMode() || this.auth.iOS()) {
            window.location.href = environment.oldDigoFront + item.url + (item.url?.includes('?') ? '&' : '?') + 'jwt=' + this.auth.getToken() + '&noFrameMode=1';
        } else {
            this.router.navigate(['herramientas/' + 'Herramientas-' + (label && label !== '' ? (label + '-') : '') + item.label + '/' + item.url]);
        }
    }

    isIterable(obj: any) {
        return Array.isArray(obj[Object.keys(obj)[0]]);
    }

    getName(obj: any) {
        return Object.keys(obj)[0];
    }

    getPath(itemValue: any) {
        let path: any = false;
        if (isIterable(itemValue)) {
            itemValue.forEach((item:any) => {
                if (item.path) {
                    path = item.path;
                }
            })
        }
        return path;
    }

    isVisible(item: any) {
        return item.fixed || this.showAll
    }


    hasHiddeableElements() {
        let hasHiddeableElements: boolean = false;
        this.menu.forEach((column: any) => {
            column.contents.forEach((block: any) => {
                if (!hasHiddeableElements) {
                    if (!block.fixed) {
                        hasHiddeableElements = true;
                    } else if (!block.title) {
                        block.links.forEach((link: any) => {
                            if (!link.fixed) {
                                hasHiddeableElements = true;
                            }
                        })
                    }
                }

            });
        })

        return hasHiddeableElements;
    }


    lastVisible(item: any) {
        let lastElement: any = null;
        this.menu[0].contents.forEach((block: any) => {
            if (block.fixed) {
                lastElement = block
            } else if (!block.title) {
                block.links.forEach((link: any) => {
                    if (link.fixed) {
                        lastElement = link;
                    }
                })
            }
        });

        return this.deepEqual(lastElement, item);
    }

    deepEqual(object1: any, object2: any) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (
                areObjects && !this.deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }
        return true;
    }


    isObject(object: any) {
        return object != null && typeof object === 'object';
    }
}
