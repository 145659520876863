import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-user-pdv',
  templateUrl: './modal-user-pdv.component.html',
  styleUrls: ['./modal-user-pdv.component.scss']
})
export class ModalUserPdvComponent implements OnInit {

  isVisible = false;

  constructor() { }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  ngOnInit(): void {
  }

}
