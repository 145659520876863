<div class="wrapper-table">
    <table class="wrapper-prepaid-list" *ngIf="table && table?.body?.rows?.length > 0">
        <thead *ngIf="table.headers && table?.headers?.rows?.length > 0">
            <tr class="header-table-postpaid" *ngFor="let hrow of table.headers.rows" [ngStyle]="hrow.styles">
    
                <th class="" [colSpan]="th.colspan ? th.colspan : 1" *ngFor="let th of hrow.cells; let i = index" (click)="returnClickValue($event, th, i, th)" [ngStyle]="{'cursor': th.order ? 'pointer' : 'inherit'}">
                    <span class="font s-13" [innerHTML]="th.value | trustHtml"></span>
                    <i nz-icon nzType="caret-down" nzTheme="outline" *ngIf="th.order && th.orderValue?.includes('desc')"></i>
                    <i nz-icon nzType="caret-up" nzTheme="outline" *ngIf="th.order && th.orderValue?.includes('asc')"></i>
                </th>
    
            </tr>
        </thead>
    
    
        <tbody *ngIf="table?.body?.rows?.length > 0">
            <ng-container *ngFor="let row of table.body.rows">
                <tr [ngClass]="row.classes" class="tr-list-module" [ngStyle]="row.styles" [style.cursor]="row.clickValue ? 'pointer' : 'inherit'">
    
                    <td [colSpan]="cell.colspan ? cell.colspan : 1" (click)="returnClickValue($event, row, i, cell)" [ngClass]="cell.classes" [ngStyle]="cell.styles" *ngFor="let cell of row.cells; let i = index">
                        <img *ngIf="cell.contingency !== null && cell.contingency === true" style="margin-right: 5px; width: 15px; height: 15px;" src="assets/images/icono-teleoperador.png" nzTooltipTitle="Alta por soporte" nzTooltipPlacement="top" nz-tooltip/>
                        <span *ngIf="!cell.type || cell.type === ''" class="font s-12" [innerHTML]="cell.value | trustHtml" [title]="cell.title && cell.title !== '' ? cell.title : ''"></span>
                        <app-status-order *ngIf="cell.type && cell.type === 'status'" [statusName]="cell.name" [status]="cell.value" [title]="cell.title && cell.title !== '' ? cell.title : ''" [brand]="cell.brand"></app-status-order>
                        <img *ngIf="cell.type == 'image'" [src]="cell.value">
                    </td>
    
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<div *ngIf="table && table?.body?.rows?.length === 0 && !noResultsText" align="center">
        Los sentimos pero con estos filtros no hay datos para mostrar.
        <br>
        Seleccione otros filtros para obtener un resultado
</div>

<div *ngIf="table && table?.body?.rows?.length === 0 && noResultsText" align="center">
    {{noResultsText}}
</div>
