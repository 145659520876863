import { Component, Input, OnInit } from '@angular/core';
import { IOrderDetailAddress, IOrderDetailAddressValue, IorderDetailCustomer, IorderDetailCustomerDonor } from '../../models/IorderDetail';
import * as moment from 'moment';

@Component({
    selector: 'app-data-client-card',
    templateUrl: './data-client-card.component.html',
    styleUrls: ['./data-client-card.component.scss']
})
export class DataClientCardComponent implements OnInit {

    @Input() customer: IorderDetailCustomer | undefined;
    @Input() customerDonor: IorderDetailCustomerDonor | undefined;

    isCollapsed = false;
    showMoreInfoDonor = false;

    onShow() {
        this.isCollapsed = !this.isCollapsed;
    }

    constructor() { }

    ngOnInit(): void {
    }

    public changeShowMoreInfo(address: IOrderDetailAddress): void {
        address.showMoreInfo = !address.showMoreInfo;
        address.elements.forEach((element: IOrderDetailAddressValue) => {if (typeof element.hidden == "boolean") element.hidden = !element.hidden})
    }

    public changeShowMoreInfoDonor(){
        this.showMoreInfoDonor = !this.showMoreInfoDonor;
    }

    public convertDate(date: string, withHours: boolean = true): string {
        return date && date !== '' ? moment(date).format('DD/MM/YYYY' + (withHours ? ', HH:mm' : '')) : '-';
    }
}
