<nz-modal
    [nzVisible]="true" 
    [nzFooter]="null"
    (nzOnCancel)="closeModal()"
    (nzOnOk)="cancelOrder()"
    nzCentered>
>
    <ng-container *nzModalContent class="wrapper-modal">
        <p class="font s-16 w-bold a-center c-black">Se va a cancelar el pedido</p>
        <br>
        <p class="font s-16  a-center margins m-b-30">¿Deseas continuar?</p>
        <div nz-row nzGutter="24">
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                <button nz-button class="c-primary size-large" (click)="cancelOrder()" nzBlock>Sí</button>
            </div>
            <div nz-col nzXXl="12" nzXl="12" nzLg="12" nzMd="12" nzXs="24" nzSm="24">
                <button nz-button class="c-dark size-large" nzBlock (click)="closeModal()">No</button>
            </div>
        </div>
    </ng-container>
</nz-modal>
<app-modal-error *ngIf="error" (showModalEvent)="showError($event)" [error_msg]="error_msg"></app-modal-error>

