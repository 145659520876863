import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PdvService } from 'src/app/services/http/pdv/pdv.service';

@Component({
    selector: 'app-modal-rebranding',
    templateUrl: './modal-rebranding.component.html',
    styleUrls: ['./modal-rebranding.component.scss']
})
export class ModalRebrandingComponent implements OnInit {

    @Input() pdv: any;

    public isVisible: boolean = false;
    public isFullImageVisible: boolean = false;
    public imgSelected: string = '';
    public showErrorGetPeriodImages: boolean = false;
    public periodImages: any;
    public translatePeriodStatus: any = {
        accepted: 'Aceptado',
        rejected: 'Rechazado',
        not_send: 'No revisado',
        not_set: 'Pdte. revisión'
    };

    constructor(
        private pdvService: PdvService,
        public authService: AuthService
    ) { }

    ngOnInit(): void {
        this.getRebrandingData();
    }

    private getRebrandingData(): void {
        this.showErrorGetPeriodImages = false;
        this.pdvService.getImagesPdvRebranding(this.pdv.id_pdv)
            .subscribe({
                next: (response: any) => {
                    this.periodImages = response.msg;
                },
                error: (error: any) => {
                    this.showErrorGetPeriodImages = true;
                },
                complete: () => {
                    this.showErrorGetPeriodImages = false;
                }
            });
    }

    public changeStatusRebranding(status: 'accepted' | 'rejected', periodSelected: any): void {
        periodSelected.value.showErrorChangeStatus = false;
        periodSelected.value.loadingChangeStatus = true;
        this.pdvService.changeStatusPdvRebranding(this.pdv.id_pdv, {status: status, date: periodSelected.key})
            .subscribe({
                next: (response: any) => {
                    periodSelected.value.can_validate = false;
                    periodSelected.value.period_status = status;
                },
                error: (error: any) => {
                    periodSelected.value.showErrorChangeStatus = true;
                    periodSelected.value.loadingChangeStatus = false;
                },
                complete: () => {
                    periodSelected.value.showErrorChangeStatus = false;
                    periodSelected.value.loadingChangeStatus = false;
                }
            });
    }

    public showHiddenCarousel(periodSelected: any): void {
        if (periodSelected.showMoreInfo) {
            Object.keys(this.periodImages.images).forEach((period: any) => {
                this.periodImages.images[period].showMoreInfo = false;
            });
        } else {
            Object.keys(this.periodImages.images).forEach((period: any) => {
                this.periodImages.images[period].showMoreInfo = false;
            });
            periodSelected.showMoreInfo = true;
            if (periodSelected?.imgs?.length) {
                this.imgSelected = periodSelected.imgs[0].url;
            }
        }
    }

    showModal(): void {
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
    }

    handleCancel(): void {
        this.isVisible = false;
    }

}
