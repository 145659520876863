export const environment = {
    production: false,
    // digoApiEndpoint: 'https://int.ms-admin.digo.tecalis.dev/',
    digoApiEndpoint: 'https://ms-admin.lamarr.digo.tecalis.dev/', //TODO: restaurar el otro endpoint cuando funcione
    comunEndpoint: 'https://ms-comun.lamarr.digo.tecalis.dev/',
    fakeLogin: false,
    allowConsoleLog: true,
    oldDigoFront: "https://int-b.dev.tecalis.com/digo/",
    userAllowedDashboard: ['diego.perez'],
    simyoEndPoint: 'https://ms-simyo.lamarr.digo.tecalis.dev/',
};
